import { apiTypes, db } from "lib-fullstack";

// Components
import { DayRange } from "components/Home/ReportCard/ReportCardFilterDropdown";

// Utils
import { CorrelationResult, Progress } from "lib-fullstack/api/apiTypes";
import { formatTopCorrelationInsightText } from "lib-fullstack/utils/DashboardInsightUtils";

const METRIC_CORRELATION_MIN_SCORE = 0.5;
const MAX_CARDS_EACH = 2;

export type FormattedReportCardResult = {
  analytic: string;
  isPositive: boolean;
  description: string;
  isOccurrenceCount: boolean;
};

const generateHappyWord = () => {
  const messages = ["Congrats!", "Bravo!", "Nice work!", "Great job!", "Kudos!", "Right on!"];
  return messages[Math.floor(Math.random() * messages.length)];
};

const generateEncouragingWord = () => {
  const messages = ["Keep it up!", "You're doing great!", "You're on a roll!", "Keep striving!"];
  return messages[Math.floor(Math.random() * messages.length)];
};

export const formatValueWithUnits = (
  value: number,
  measureUnits: string,
  analytic: db.AggregateAnalyticEnum,
  isBeforeAfter: boolean,
): string => {
  let num = value;
  if (analytic === db.AggregateAnalyticEnum.CONCISENESS) {
    num = isBeforeAfter ? value * 100 : (1 - value) * 100;
  }

  let units;
  if (measureUnits === "count") {
    units = " times per Yoodli";
  } else if (measureUnits === "wpm") {
    units = " words per minute";
  } else {
    units = "%";
  }
  // If rounding the number results in .0, then don't show decimal.
  const rounded = Math.round(num * 10) / 10;
  return `${Math.abs(rounded)}${units}`;
};

const generateHitThresholdAnalyticCopy = (analytic: db.AggregateAnalyticEnum) => {
  switch (analytic) {
    case db.AggregateAnalyticEnum.FILLER:
      return "low filler words";
    case db.AggregateAnalyticEnum.WEAK:
      return "low weak words";
    case db.AggregateAnalyticEnum.REPETITION:
      return "low repetition";
    case db.AggregateAnalyticEnum.PACING:
      return "perfect pacing";
    case db.AggregateAnalyticEnum.CONCISENESS:
      return "concise language";
    case db.AggregateAnalyticEnum.SENSITIVE:
      return "inclusive language";
    case db.AggregateAnalyticEnum.SENTENCE_STARTERS:
      return "minimal sentence starters";
    default:
      return "";
  }
};

const generateAnalyticNameCopy = (analytic: db.AggregateAnalyticEnum) => {
  switch (analytic) {
    case db.AggregateAnalyticEnum.FILLER:
      return "filler word usage";
    case db.AggregateAnalyticEnum.WEAK:
      return "weak word usage";
    case db.AggregateAnalyticEnum.REPETITION:
      return "repetition";
    case db.AggregateAnalyticEnum.PACING:
      return "pacing";
    case db.AggregateAnalyticEnum.CONCISENESS:
      return "excess words";
    case db.AggregateAnalyticEnum.SENSITIVE:
      return "non inclusive language";
    case db.AggregateAnalyticEnum.SENTENCE_STARTERS:
      return "repeated sentence starters";
    default:
      return "";
  }
};

const generateMostCommonAnalyticCopy = (analytic: db.AggregateAnalyticEnum) => {
  switch (analytic) {
    case db.AggregateAnalyticEnum.FILLER:
      return "filler word";
    case db.AggregateAnalyticEnum.WEAK:
      return "weak word";
    case db.AggregateAnalyticEnum.SENTENCE_STARTERS:
      return "sentence starter";
    default:
      return "";
  }
};

export const formatImprovedBeforeAfterResults = (
  analytic: db.AggregateAnalyticEnum,
  difference: number,
  measureUnit: string,
  dayRange: DayRange,
  happyWordGenerator: () => string = generateHappyWord,
): string => {
  return `${happyWordGenerator()} Your ${generateAnalyticNameCopy(
    analytic,
  )} improved by ${formatValueWithUnits(
    difference,
    measureUnit,
    analytic,
    true,
  )} compared to last ${dayRange}!`;
};

export const formatRegressedBeforeAfterResults = (
  analytic: db.AggregateAnalyticEnum,
  difference: number,
  measureUnit: string,
  dayRange: DayRange,
): string => {
  return `Compared to the previous ${dayRange}, your ${generateAnalyticNameCopy(
    analytic,
  )} worsened by ${formatValueWithUnits(difference, measureUnit, analytic, true)}.`;
};

export const formatOccurrenceCountResults = (
  results: apiTypes.ReportCardResponse,
  dayRange: DayRange,
  happyWordGenerator: () => string = generateHappyWord,
): FormattedReportCardResult[] => {
  if (!results.occurrence_count) {
    return undefined;
  }

  const occurrenceCountResults = results.occurrence_count;
  let maxCountSpeeches = 0;
  let maxCountSpeechesAnalytic: db.AggregateAnalyticEnum;
  let maxCountDays = 0;
  let maxCountDaysAnalytic: db.AggregateAnalyticEnum;

  for (const key in occurrenceCountResults) {
    const { countSpeeches, countDays } = occurrenceCountResults[key];
    if (countSpeeches > maxCountSpeeches) {
      maxCountSpeeches = countSpeeches;
      maxCountSpeechesAnalytic = key as db.AggregateAnalyticEnum;
    }
    if (countDays > maxCountDays) {
      maxCountDays = countDays;
      maxCountDaysAnalytic = key as db.AggregateAnalyticEnum;
    }
  }

  const ret = [];
  if (maxCountSpeeches > 1) {
    const maxYoodlisItem = {
      analytic: maxCountSpeechesAnalytic,
      isPositive: true,
      isOccurrenceCount: true,
      description: `${happyWordGenerator()} You scored ${maxCountSpeeches} Yoodlis with ${generateHitThresholdAnalyticCopy(
        maxCountSpeechesAnalytic,
      )} in the past ${dayRange}!`,
    };
    ret.push(maxYoodlisItem);
  }
  if (maxCountDays > 1) {
    const maxDaysItem = {
      analytic: maxCountDaysAnalytic,
      isPositive: true,
      isOccurrenceCount: true,
      description: `You've hit ${maxCountDays} Yoodlis with ${generateHitThresholdAnalyticCopy(
        maxCountDaysAnalytic,
      )} in the past ${dayRange}!`,
    };
    ret.push(maxDaysItem);
  }
  return ret;
};

export const formatBeforeAfterResults = (
  results: apiTypes.ReportCardResponse,
  dayRange: DayRange,
): FormattedReportCardResult[] => {
  if (!results.before_after) {
    return undefined;
  }
  const beforeAfterResults = results.before_after;
  const formattedBeforeAfterItems = Object.entries(beforeAfterResults)
    .filter(([, a]) => a.progress !== Progress.NO_CHANGE)
    .sort(([, a], [, b]) => b.score - a.score)
    .map((obj) => {
      const key = obj[0];
      const value = obj[1];
      const temp = {
        analytic: key,
        isPositive: value.progress === Progress.IMPROVING,
        description:
          value.progress === Progress.IMPROVING
            ? formatImprovedBeforeAfterResults(
                key as db.AggregateAnalyticEnum,
                value.difference,
                value.measure,
                dayRange,
              )
            : formatRegressedBeforeAfterResults(
                key as db.AggregateAnalyticEnum,
                value.difference,
                value.measure,
                dayRange,
              ),
        isOccurrenceCount: false,
      };
      return temp;
    }, {});
  return formattedBeforeAfterItems;
};

const generateSkillSnapshotCopy = (
  dayRange: DayRange,
  value: number,
  measure: string,
  analytic: db.AggregateAnalyticEnum,
  isPositive: boolean,
  happyWordGenerator: () => string = generateHappyWord,
  encouragingWordGenerator: () => string = generateEncouragingWord,
): string => {
  let ret = "";
  if (isPositive) {
    ret += `${happyWordGenerator()} `;
  }

  if (measure === "count") {
    ret += `In the past ${dayRange}, you used ${generateAnalyticNameCopy(
      analytic,
    )} an average of ${formatValueWithUnits(
      value,
      measure,
      analytic as db.AggregateAnalyticEnum,
      false,
    )}.`;
  } else {
    ret += `You averaged ${formatValueWithUnits(
      value,
      measure,
      analytic,
      false,
    )} ${generateAnalyticNameCopy(analytic)} in the past ${dayRange}.`;
  }

  if (isPositive) {
    ret += ` ${encouragingWordGenerator()}`;
  }

  return ret;
};

export const formatGoodSkillSnapshotResults = (
  results: apiTypes.ReportCardResponse,
  dayRange: DayRange,
  happyWordGenerator: () => string = generateHappyWord,
  encouragingWordGenerator: () => string = generateEncouragingWord,
): FormattedReportCardResult[] => {
  if (!results.skill_snapshot) {
    return undefined;
  }

  const skillSnapshotResults = results.skill_snapshot;

  return Object.entries(skillSnapshotResults)
    .filter(([, a]) => a.meetsThreshold)
    .map((obj) => {
      const key = obj[0];
      const value = obj[1];
      return {
        analytic: key,
        isPositive: value.meetsThreshold,
        description: generateSkillSnapshotCopy(
          dayRange,
          value.average,
          value.measure,
          key as db.AggregateAnalyticEnum,
          true,
          happyWordGenerator,
          encouragingWordGenerator,
        ),
        isOccurrenceCount: false,
      };
    });
};

export const formatBadSkillSnapshotResults = (
  results: apiTypes.ReportCardResponse,
  dayRange: DayRange,
): FormattedReportCardResult[] => {
  if (!results.skill_snapshot) {
    return undefined;
  }
  const skillSnapshotResults = results.skill_snapshot;
  return Object.entries(skillSnapshotResults)
    .filter(([, a]) => !a.meetsThreshold)
    .map((obj) => {
      const key = obj[0];
      const value = obj[1];
      return {
        analytic: key,
        isPositive: value.meetsThreshold,
        description: generateSkillSnapshotCopy(
          dayRange,
          value.average,
          value.measure,
          key as db.AggregateAnalyticEnum,
          false,
        ),
        isOccurrenceCount: false,
      };
    });
};

export const formatMostCommonWordResults = (
  results: apiTypes.ReportCardResponse,
  dayRange: DayRange,
): FormattedReportCardResult[] => {
  if (!results.most_common_word || Object.keys(results.most_common_word).length === 0) {
    return undefined;
  }

  const mostCommonWordResults = results.most_common_word;
  let maxCountTimes = 0;
  let maxCountKey: db.AggregateAnalyticEnum;
  let maxCountWord = "";
  for (const key in mostCommonWordResults) {
    const { word, count } = mostCommonWordResults[key];
    if (count > maxCountTimes) {
      maxCountTimes = count;
      maxCountKey = key as db.AggregateAnalyticEnum;
      maxCountWord = word;
    }
  }
  const mostCommonWordResult = {
    analytic: maxCountKey,
    isPositive: false,
    description: `Your most common ${generateMostCommonAnalyticCopy(
      maxCountKey,
    )} in the past ${dayRange} was "${maxCountWord}", which you said ${maxCountTimes} times.`,
    isOccurrenceCount: false,
  };
  return [mostCommonWordResult];
};

export function determineReportCardResults(
  results: apiTypes.ReportCardResponse,
  dayRange: DayRange,
): [FormattedReportCardResult[], FormattedReportCardResult[]] {
  if (!results) {
    return [[], []];
  }

  const usedAnalyticKeys = new Set<string>();
  const positiveResults = [];
  const negativeResults = [];
  let keepAddingCards = true;

  const addToPositiveResults = (cardResult: FormattedReportCardResult) => {
    if (positiveResults.length < MAX_CARDS_EACH) {
      if (!usedAnalyticKeys.has(cardResult.analytic)) {
        positiveResults.push(cardResult);
        usedAnalyticKeys.add(cardResult.analytic);
      }
      keepAddingCards = true;
    }
  };
  const addToNegativeResults = (cardResult: FormattedReportCardResult) => {
    if (negativeResults.length < MAX_CARDS_EACH && !usedAnalyticKeys.has(cardResult.analytic)) {
      if (!usedAnalyticKeys.has(cardResult.analytic)) {
        negativeResults.push(cardResult);
        usedAnalyticKeys.add(cardResult.analytic);
      }
      keepAddingCards = true;
    }
  };

  if (results.metric_correlation) {
    const metricCorrelation = results.metric_correlation;
    if (metricCorrelation.score > METRIC_CORRELATION_MIN_SCORE) {
      const desc = formatTopCorrelationInsightText(metricCorrelation as CorrelationResult);
      const isPositive = metricCorrelation["meetsThreshold"];
      const tempMetricCorrelation = {
        analytic: metricCorrelation.analytic,
        isPositive,
        description: desc,
        isOccurrenceCount: false,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isPositive
        ? addToPositiveResults(tempMetricCorrelation)
        : addToNegativeResults(tempMetricCorrelation);
    }
  }

  const beforeAfterResults = formatBeforeAfterResults(results, dayRange);
  const occurrenceCountResults = formatOccurrenceCountResults(results, dayRange);
  const goodSkillSnapshotResults = formatGoodSkillSnapshotResults(results, dayRange);
  const badSkillSnapshotResults = formatBadSkillSnapshotResults(results, dayRange);
  const mostCommonWordResults = formatMostCommonWordResults(results, dayRange);

  let beforeAfterIdx = 0;
  let occurrenceCountIdx = 0;
  let goodSkillSnapshotIdx = 0;
  let badSkillSnapshotIdx = 0;
  let mostCommonWordIdx = 0;

  // Loop until we have enough cards or don't have data to add any more cards:
  while (
    (positiveResults.length < MAX_CARDS_EACH || negativeResults.length < MAX_CARDS_EACH) &&
    keepAddingCards
  ) {
    keepAddingCards = false;
    if (beforeAfterIdx < beforeAfterResults?.length) {
      const beforeAfterResult = beforeAfterResults[beforeAfterIdx];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      beforeAfterResult.isPositive
        ? addToPositiveResults(beforeAfterResult)
        : addToNegativeResults(beforeAfterResult);
      beforeAfterIdx++;
    }

    if (occurrenceCountIdx < occurrenceCountResults?.length) {
      const occurrenceCountResult = occurrenceCountResults[occurrenceCountIdx];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      occurrenceCountResult.isPositive
        ? addToPositiveResults(occurrenceCountResult)
        : addToNegativeResults(occurrenceCountResult);
      occurrenceCountIdx++;
    }

    if (goodSkillSnapshotIdx < goodSkillSnapshotResults?.length) {
      const goodSkillSnapshotResult = goodSkillSnapshotResults[goodSkillSnapshotIdx];
      addToPositiveResults(goodSkillSnapshotResult);
      goodSkillSnapshotIdx++;
    }

    if (badSkillSnapshotIdx < badSkillSnapshotResults?.length) {
      const badSkillSnapshotResult = badSkillSnapshotResults[badSkillSnapshotIdx];
      addToNegativeResults(badSkillSnapshotResult);
      badSkillSnapshotIdx++;
    }

    if (mostCommonWordIdx < mostCommonWordResults?.length) {
      const mostCommonWordResult = mostCommonWordResults[mostCommonWordIdx];
      addToNegativeResults(mostCommonWordResult);
      mostCommonWordIdx++;
    }
  }

  return [positiveResults, negativeResults];
}

export const AnalyticToCardText = {
  [db.AggregateAnalyticEnum.FILLER]: {
    label: "Filler Words",
    learningResourceButtonCopy: "How to avoid filler words",
  },
  [db.AggregateAnalyticEnum.WEAK]: {
    label: "Weak Words",
    learningResourceButtonCopy: "How to avoid weak words",
  },
  [db.AggregateAnalyticEnum.REPETITION]: {
    label: "Repetition",
    learningResourceButtonCopy: "How to reduce repetition",
  },
  [db.AggregateAnalyticEnum.PACING]: {
    label: "Pacing",
    learningResourceButtonCopy: "Learn about proper pacing",
  },
  [db.AggregateAnalyticEnum.CONCISENESS]: {
    label: "Conciseness",
    learningResourceButtonCopy: "How to be more concise",
  },
  [db.AggregateAnalyticEnum.SENSITIVE]: {
    label: "Non Inclusive",
    learningResourceButtonCopy: "How to use inclusive language",
  },
  [db.AggregateAnalyticEnum.SENTENCE_STARTERS]: {
    label: "Sentence Starters",
    learningResourceButtonCopy: "Learn more about sentence starters",
  },
};
