import { getClientEnvConfig } from "../client_env";

export async function getSpeechThumbnails(
  speechIdTuples: { speechId: string; ownerId: string }[],
  authHeaders?: Record<string, string>,
): Promise<string[]> {
  const response = await fetch(`${getClientEnvConfig().url.WEB_SERVER}/api/speech/get_thumbnails`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(authHeaders ?? {}),
    },
    body: JSON.stringify({
      speeches: speechIdTuples,
    }),
  });

  if (!response.ok) {
    throw new Error("getSpeechThumbnails response was not ok");
  }

  const data = await response.json();
  return data.urls;
}
