import React from "react";

// Components
import { Button, FormControl, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { AddLink as AddLinkIcon, Language as LanguageIcon } from "@mui/icons-material";
import WorksWithX from "../WorksWithX";
import videoLightbox from "ui/VideoLightbox";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  YoodliSelect,
  YoodliSelectVariant,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";
import { RecordingSupportedLanguageToString } from "utils/Utilities";
import { RecordingSupportedLanguage } from "lib-fullstack/utils/enums";

const TutorialVideoLink = ({ videoUrl }: { videoUrl?: string }): JSX.Element => {
  const { lightbox, setLightboxURL } = videoLightbox("");
  return (
    <>
      <Typography
        sx={{
          mr: 2,
          color: getDynamicColor("primary"),
          cursor: "pointer",
          fontWeight: 700,
          fontSize: 12,
          whiteSpace: "nowrap",
          textDecoration: "none",
          position: "relative",
          width: "fit-content",
          "::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            borderRadius: 1,
            width: "100%",
            height: "0.1em",
            backgroundColor: getDynamicColor("primary"),
            opacity: 1,
            transform: "scale(0)",
            transformOrigin: "center",
            transition: "opacity 300ms, transform 300ms",
          },
          "&:hover::after": {
            transform: "scale(1)",
          },
        }}
        onClick={() => setLightboxURL(videoUrl)}
      >
        Watch Video
      </Typography>
      {lightbox}
    </>
  );
};

const MeetingLinkInput = ({
  resetError = () => null,
  tutorialVideoUrl,
  language,
  setLanguage,
}: {
  resetError: () => void;
  tutorialVideoUrl?: string;
  language: RecordingSupportedLanguage;
  setLanguage: React.Dispatch<React.SetStateAction<RecordingSupportedLanguage>>;
}): JSX.Element => {
  const [inputValue, setInputValue] = React.useState<string>("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      <TextField
        value={inputValue}
        placeholder="Ex: us05web.zoom.us/j/1234abcd"
        sx={{
          borderRadius: "12px",
          flexGrow: 1,
          width: { xs: "100%", md: "auto" },
          minWidth: "min(100%, 400px)",
        }}
        InputProps={{
          name: "meetingURL",
          startAdornment: <AddLinkIcon sx={{ mr: 1 }} />,
          endAdornment: (
            <FormControl>
              <YoodliSelect
                value={language}
                yoodliVariant={YoodliSelectVariant.SECONDARY}
                options={Object.entries(RecordingSupportedLanguageToString).map(
                  ([value, label]) => ({
                    label,
                    value: value as RecordingSupportedLanguage,
                    optionSx: {
                      color: getDynamicColor("purple3"),
                    },
                  }),
                )}
                onChange={(e) => setLanguage(e.target.value as RecordingSupportedLanguage)}
                renderValue={() => {
                  return (
                    <Stack
                      direction="row"
                      gap={0.5}
                      alignItems="center"
                      sx={{
                        color: getDynamicColor("primary"),
                        minWidth: 56,
                      }}
                    >
                      <LanguageIcon />
                      <Typography
                        sx={{ fontSize: 14, fontWeight: 600, textTransform: "uppercase" }}
                      >
                        {language.split("-")[0]}
                      </Typography>
                    </Stack>
                  );
                }}
                sx={{
                  minWidth: "90px",
                  border: "none !important",
                  ".MuiSelect-select": {
                    p: 1,
                    pr: 2,
                  },
                }}
              />
            </FormControl>
          ),
          sx: {
            borderRadius: "12px",
            pr: { xs: "14px", md: "0px !important" },

            "& input::placeholder": { fontSize: "13px !important", fontWeight: 600 },
          },
          onChange: (e) => {
            setInputValue(e.target.value);
            resetError();
          },
        }}
      />
      <Stack
        direction="row"
        gap={{ xs: 1, sm: 4 }}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <WorksWithX context="zoodli" />
        <Stack
          direction="row"
          alignItems="center"
          rowGap={2}
          justifyContent="flex-end"
          flexWrap="wrap-reverse"
          ml="auto"
        >
          {!!tutorialVideoUrl && <TutorialVideoLink videoUrl={tutorialVideoUrl ?? ""} />}
          <Tooltip
            title={
              !inputValue ? "Paste a meeting link above to enable joining!" : "Join an ongoing call"
            }
          >
            <span>
              <Button
                disabled={!inputValue}
                variant="contained"
                type="submit"
                sx={{
                  fontSize: 14,
                  whiteSpace: "nowrap",
                  borderRadius: "100px",
                  width: { xs: "100%", md: "auto" },
                  maxWidth: 300,
                  minWidth: 118,
                  fontWeight: 600,
                  "&.Mui-disabled": {
                    background: `${getDynamicColor("dark2")} !important`,
                  },
                }}
              >
                Join
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
    </div>
  );
};

export default MeetingLinkInput;
