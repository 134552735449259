import React from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfExtractor = ({
  pdfUrl,
  useDialog,
  onDialogClose,
}: {
  pdfUrl: string;
  useDialog: boolean;
  onDialogClose: () => void;
}): JSX.Element => {
  const pageWrapperRef = React.useRef(null);
  const [numPages, setNumPages] = React.useState(null);

  const { modalStyles } = useNavDrawerOffset();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const content = (
    <Box
      sx={{
        minHeight: "90vh",
        maxWidth: "100%",
        width: "fit-content",
        position: "relative",
        p: 2,
        pt: { xs: 5.5, md: 7 },
      }}
    >
      <Box
        sx={{
          pb: 2,
          position: "relative",
          width: "100%",
          minWidth: 300,
          ".pdf-document": {
            maxWidth: "100% !important",
            position: "relative",
            ".react-pdf__Page": {
              width: "100% !important",
              height: "100% !important",
            },
          },
        }}
      >
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
          {[...Array(numPages)].map((_, index) => (
            <Box
              ref={pageWrapperRef}
              key={index}
              sx={{
                border: `1px solid ${getDynamicColor("dark4")}`,
                mb: index === numPages - 1 ? 0 : 1,
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <Page
                key={index}
                pageNumber={index + 1}
                renderForms
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Box>
          ))}
        </Document>
      </Box>
    </Box>
  );
  if (useDialog) {
    return (
      <Dialog
        open={!!pdfUrl}
        onClose={onDialogClose}
        PaperProps={{
          sx: {
            maxWidth: "unset",
            position: "relative",
            m: { xs: 1, sm: 2, md: 4 },

            ...modalStyles,
          },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: getDynamicColor("dark4"),
            zIndex: 2,
            height: { xs: 30, md: 40 },
            width: { xs: 30, md: 40 },
          }}
          onClick={onDialogClose}
        >
          <CloseIcon />
        </IconButton>
        {content}
      </Dialog>
    );
  }
  return content;
};

export default PdfExtractor;
