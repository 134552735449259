// Utils
import { AuthorizationAPIHeaders, getAxiosInstance } from "./AxiosInstance";
import {
  CreateBotContentsV2Request,
  CreateBotContentsV2Response,
  CreateCoachBotResponse,
  GetAllBotContentsV2Response,
  GetBotBrandingResponse,
  GetCoachBotResponse,
  ListCoachBotsQueryParams,
  ListCoachBotsResponse,
  PatchCoachBotRequest,
  SpaceIdQueryParams,
} from "lib-fullstack/api/coachBotApiTypes";

export const createCoachBot = async (
  orgId: string,
  spaceId: string,
  coachBotName: string,
  creatorEmail: string,
  isActive?: boolean,
): Promise<CreateCoachBotResponse> => {
  const headers = await AuthorizationAPIHeaders();
  const ret = await getAxiosInstance().post<CreateCoachBotResponse>(
    `/v2/orgs/${orgId}/coach_bots`,
    { name: coachBotName, creatorEmail, isActive: isActive ?? false, spaceId: spaceId },
    { headers: headers },
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return ret.data;
};

export const updateCoachBot = async (
  orgId: string,
  botId: string,
  options: PatchCoachBotRequest,
): Promise<boolean> => {
  const headers = await AuthorizationAPIHeaders();
  const ret = await getAxiosInstance().patch<void>(
    `/v2/orgs/${orgId}/coach_bots/${botId}`,
    options,
    {
      headers: headers,
    },
  );

  if (Math.floor(ret.status / 100) !== 2) {
    throw Error("Bad api request, status " + ret.status);
  }
  return true;
};

export const getCoachBot = async (
  orgId: string,
  coachbotId: string,
): Promise<GetCoachBotResponse> => {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await getAxiosInstance().get<GetCoachBotResponse>(
      `/v2/orgs/${orgId}/coach_bots/${coachbotId}`,
      {
        headers,
      },
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getCoachBot request, status " + err.response?.status);
  }
};

export async function getAllCoachBots(
  orgId: string,
  params: ListCoachBotsQueryParams,
): Promise<ListCoachBotsResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await getAxiosInstance().get<ListCoachBotsResponse>(
      `/v2/orgs/${orgId}/coach_bots`,
      {
        headers,
        params,
      },
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getAllCoachBot request, status " + err.response?.status);
  }
}

export async function deleteCoachbot(orgId: string, coachbotId: string): Promise<number> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await getAxiosInstance().delete(`/v2/orgs/${orgId}/coach_bots/${coachbotId}`, {
      headers,
    });
    return ret?.status;
  } catch (err) {
    throw Error("Bad deleteCoachbot request, status " + err.response?.status);
  }
}

export async function getCoachBotBranding(
  orgId: string,
  botId: string,
): Promise<GetBotBrandingResponse> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await getAxiosInstance().get<GetBotBrandingResponse>(
      `/v2/orgs/${orgId}/coach_bots/${botId}/branding`,
      {
        headers,
      },
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getCoachBotBranding request, status " + err.response?.status);
  }
}

/**
 * Add one or more org files to a coach bot
 * @param orgId The ID of the organization.
 * @param botId The ID of the coach bot.
 * @param body The request body.
 * @returns The response containing the list of files.
 */
export async function createCoachBotContentV2(
  orgId: string,
  botId: string,
  body: CreateBotContentsV2Request,
): Promise<CreateBotContentsV2Response> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await getAxiosInstance().post<CreateBotContentsV2Response>(
      `/v2/orgs/${orgId}/coach_bots/${botId}/contents`,
      body,
      {
        headers,
      },
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad createCoachBotContentV2 request, status " + err.response?.status);
  }
}

/**
 * Get all the content for a coach bot
 * @param orgId The ID of the organization.
 * @param botId The ID of the coach bot.
 * @param params The query parameters.
 * @returns The response containing the list of files.
 */
export async function getCoachBotContentV2(
  orgId: string,
  botId: string,
  params: SpaceIdQueryParams,
): Promise<GetAllBotContentsV2Response> {
  const headers = await AuthorizationAPIHeaders();
  try {
    const ret = await getAxiosInstance().get<GetAllBotContentsV2Response>(
      `/v2/orgs/${orgId}/coach_bots/${botId}/contents`,
      {
        headers,
        params,
      },
    );
    return ret.data;
  } catch (err) {
    throw Error("Bad getCoachBotContentV2 request, status " + err.response?.status);
  }
}

/**
 * Delete a content for a coach bot
 * @param orgId The ID of the organization.
 * @param botId The ID of the coach bot.
 * @param contentId The ID of the content to delete.
 * @param params The query parameters.
 */
export async function deleteCoachBotContentV2(
  orgId: string,
  botId: string,
  contentId: string,
  params: SpaceIdQueryParams,
): Promise<void> {
  const headers = await AuthorizationAPIHeaders();
  try {
    await getAxiosInstance().delete(`/v2/orgs/${orgId}/coach_bots/${botId}/contents/${contentId}`, {
      headers,
      params,
    });
  } catch (err) {
    throw Error("Bad deleteCoachBotContentV2 request, status " + err.response?.status);
  }
}
