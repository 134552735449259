/* eslint-disable @typescript-eslint/no-explicit-any */
/* https://github.com/Aminadav/react-useStateRef/tree/master w/ slight modifications to get types to play nicely */
import { useCallback, useRef, useState, SetStateAction, Dispatch } from "react";

const isFunction = <S>(setStateAction: SetStateAction<S>): setStateAction is (prevState: S) => S =>
  typeof setStateAction === "function";

type ReadOnlyRefObject<T> = {
  readonly current: T;
};

type UseStateRef = <S>(
  initialState?: S | (() => S),
) => [S, Dispatch<SetStateAction<S>>, ReadOnlyRefObject<S>];

export const useStateRef: UseStateRef = <S>(initialState?: S | (() => S)) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);

  const dispatch: typeof setState = useCallback((setStateAction: any) => {
    ref.current = isFunction(setStateAction) ? setStateAction(ref.current) : setStateAction;

    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};
