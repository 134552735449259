import React from "react";

// Components
import { Stack, Typography } from "@mui/material";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { AvailableContentTabEnum } from "lib-frontend/utils/orgUtils";
import {
  AvailableContentQueryKey,
  getFilteredScenarioByType,
  getScenarioTypeLabel,
} from "components/ConvoScenarios/convoScenarioUtils";
import { getAllPersonasMember, listGroupScenarios } from "lib-frontend/modules/AxiosInstance";
import firebase from "firebase/app";
import { ReadOnlyScenarioCard } from "./ReadOnlyScenarioCard";

export const ScenariosTab = ({
  groupId,
  scenarioTabType,
}: {
  groupId: string;
  scenarioTabType: AvailableContentTabEnum;
}): JSX.Element => {
  const { defaultOrgId, loading } = React.useContext(UserOrgContext);

  const scenariosQuery = useApiQuery({
    queryKey: [AvailableContentQueryKey.Scenarios, defaultOrgId, groupId],
    queryFn: async () => await listGroupScenarios(defaultOrgId, groupId),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrgId && !loading,
    refetchOnWindowFocus: false,
  });

  const personasQuery = useApiQuery({
    queryKey: [AvailableContentQueryKey.Personas, defaultOrgId],
    queryFn: async () => await getAllPersonasMember(defaultOrgId),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrgId && !loading,
    refetchOnWindowFocus: false,
  });

  // segregating roleplay and interview scenarios
  const scenarios = getFilteredScenarioByType(
    scenarioTabType,
    scenariosQuery.data?.contentArray ?? [],
  );

  const scenarioTypeLabel = getScenarioTypeLabel(scenarioTabType);

  return (
    <Stack gap={3} direction="column">
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "baseline" }}>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", alignItems: "baseline", fontFamily: "poppins", fontWeight: 700 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              textTransform: "capitalize",
              mr: 2,
            }}
          >
            {scenarioTypeLabel} Scenarios
          </Typography>
          {!!scenarios && scenarios.length > 0 && (
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                color: getDynamicColor("dark4"),
              }}
            >
              {scenarios?.length} scenario{scenarios?.length > 1 && "s"}
            </Typography>
          )}
        </Stack>
      </Stack>
      {scenarios.length === 0 && (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          No {scenarioTypeLabel} scenarios found
        </Typography>
      )}
      {scenarios?.map((scenario) => (
        <ReadOnlyScenarioCard
          key={scenario.id}
          selectedOrgId={defaultOrgId}
          scenario={scenario}
          persona={personasQuery?.data?.personas?.find((p) => p.id === scenario.personaIds[0])}
          personaLoading={personasQuery?.status === "pending"}
        />
      ))}
    </Stack>
  );
};
