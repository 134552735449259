import { db } from "lib-fullstack";
import React from "react";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { CircularProgress, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { SampleSpeechItem } from "components/Home/OnboardingChecklist/SampleSpeechItem";
import { DisplayableSampleSpeechInfo } from "lib-fullstack/db/db_runtypes_sample_speech";
import { getSpeechThumbnails } from "lib-fullstack/utils/speechUtils";

// Utils
import { useOnQuery } from "@typesaurus/react";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";

type SampleSpeechesModalProps = {
  showGifDialog: boolean;
  setShowGifDialog: (val: boolean) => void;
};

export const SampleSpeechesModal = ({
  showGifDialog,
  setShowGifDialog,
}: SampleSpeechesModalProps): JSX.Element => {
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();

  const [dbSampleSpeeches, dbSampleSpeechesMeta] = useOnQuery<db.SampleSpeech>(
    db.sampleSpeeches(getSiteId() ?? ""),
    [db.order("id", "asc")],
  );
  const [sampleSpeechInfo, setSampleSpeechInfo] = React.useState<DisplayableSampleSpeechInfo[]>([]);
  const [sampleSpeechesLoading, setSampleSpeechesLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (dbSampleSpeeches) {
      fetchSiteSampleSpeeches().catch(console.error);
    }
  }, [dbSampleSpeeches]);

  React.useEffect(() => {
    const error = dbSampleSpeechesMeta?.error;
    if (error) {
      console.error(error);
      throw new Error(dbSampleSpeechesMeta.error.toString());
    }
  }, [dbSampleSpeechesMeta?.error]);

  const fetchSiteSampleSpeeches = async (): Promise<void> => {
    const displayableSampleSpeechInfos: DisplayableSampleSpeechInfo[] = await Promise.all(
      dbSampleSpeeches.map(async (sampleSpeechItem: db.Doc<db.SampleSpeech>) => {
        // get corresponding Speech object
        const dbSpeech = await db.get(db.pathToRef<db.Speech>(sampleSpeechItem.data.path));
        const { userId, speechId } = db.pathToIds(sampleSpeechItem.data.path);
        const thumbnailsResult = await getSpeechThumbnails([
          { speechId: speechId, ownerId: userId },
        ]);
        return {
          name: dbSpeech.data.name,
          thumbnailPath: thumbnailsResult[0],
          wpm: sampleSpeechItem.data.indexData.wpm,
          percentFillerWords: sampleSpeechItem.data.indexData.percentFillerWords,
          topKeyWord: sampleSpeechItem.data.indexData.topKeyWord,
          topWeakWord: sampleSpeechItem.data.indexData.topWeakWord,
          slug: dbSpeech.data.slug,
        };
      }),
    );

    setSampleSpeechInfo(displayableSampleSpeechInfos);
    setSampleSpeechesLoading(false);
  };

  return (
    <Dialog
      open={showGifDialog}
      onClose={() => setShowGifDialog(false)}
      sx={{
        cursor: "default",
      }}
      PaperProps={{
        sx: {
          ...modalStyles,
          position: "relative",
          height: "min(85vh, 800px)",
          width: `min(720px, 100%)`,
          maxWidth: {
            xs: "calc(100% - 32px) !important",
            md: `calc(100% - ${navDrawerOffset + 32}px) !important`,
          },
          borderRadius: "16px",
          boxShadow: Y_SHADOWS.box_shadow_7,
          background: getDynamicColor("light1"),
          py: 2,
          m: 2,
          zIndex: 12,
          overflow: "hidden",
        },
      }}
    >
      <Stack gap={2} height={"100%"}>
        <IconButton
          onClick={() => setShowGifDialog(false)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <CloseIcon sx={{ width: "20px", height: "20px" }} />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 700,
            color: getDynamicColor("purple3"),
            fontSize: 24,
            pl: 6,
            pt: 3,
          }}
        >
          Sample reports
        </Typography>
        <Stack sx={{ overflowY: "auto", px: 6, pt: 2 }}>
          {sampleSpeechesLoading ? (
            <CircularProgress sx={{ mx: "auto", my: "auto" }} />
          ) : (
            sampleSpeechInfo.map((speech, index) => (
              <SampleSpeechItem speech={speech} index={index} key={speech.name} />
            ))
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};
