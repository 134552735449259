import React from "react";

// Components
import { Box, Button, Divider, Link, Stack, Typography } from "@mui/material";

import {
  OrgMembersSectionStatus,
  OrgMembersTabEnum,
} from "webclient/src/components/Orgs/Members/OrgMembers";

// Utils
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { EffectiveRole } from "lib-fullstack/utils/enums";
import { OrgMembersQueryParams } from "lib-fullstack/utils/queryParams";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { OrgSpaceAdmin } from "lib-fullstack/api/orgSpaceApiTypes";
import { currentUserEmail } from "lib-frontend/utils/AccountUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

import { useNavigate } from "react-router";
import { SupportPages } from "lib-frontend/utils/paths";

type OrgContentPermissionsProps = {
  setShowAddAdmin: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OrgContentPermissions = ({
  setShowAddAdmin,
}: OrgContentPermissionsProps): JSX.Element => {
  const handleAddAdminClick = () => {
    setShowAddAdmin(true);
  };

  const { spaces, curSpaceId } = React.useContext(ContentSpacesContext);
  const { userOrgRole } = React.useContext(UserOrgContext);
  const currentSpace = spaces.find((space) => space.id === curSpaceId);

  const isAdmin = [EffectiveRole.ORG_OWNER, EffectiveRole.ORG_ADMIN].includes(userOrgRole);
  const navigate = useNavigate();

  type AdminListProps = {
    admins: OrgSpaceAdmin[];
  };

  const AdminList = ({ admins }: AdminListProps) => {
    const isSmall = useIsSmallScreen();
    return (
      <Box
        sx={{
          border: `1px solid ${getDynamicColor("dark3")}`,
          borderRadius: "4px",
          padding: 2,
          mx: 2,
        }}
      >
        <Stack spacing={1}>
          {admins.map((admin, index) => {
            const navigateUrl = `${WebServerExternalPath.MEMBERS}?${OrgMembersQueryParams.TAB}=${OrgMembersTabEnum.Activity}&${OrgMembersQueryParams.SECTION}=${OrgMembersSectionStatus.ManageRole}&${OrgMembersQueryParams.USER_EMAIL}=${encodeURIComponent(admin.email)}`;

            return (
              <React.Fragment key={admin.email}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%", px: 2, flexWrap: "nowrap" }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      flexGrow: 1,
                      alignItems: "center",
                      gap: 2,
                      overflow: "hidden",
                    }}
                  >
                    {admin.display_name ? (
                      <Typography
                        sx={{
                          minWidth: "30%",
                          fontWeight: 700,
                          textWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {admin.display_name} {admin.email === currentUserEmail() && " (You)"}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          minWidth: "30%",
                          fontWeight: 700,
                          textWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: getDynamicColor("dark4"),
                        }}
                      >
                        Invited Member
                      </Typography>
                    )}
                    {!isSmall && (
                      <Stack
                        direction="row"
                        sx={{ gap: 2, flexGrow: 1, width: { sx: 0, md: "100%" } }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {admin.email}
                        </Typography>
                        {admin.is_invited && (
                          <Typography
                            sx={{
                              fontWeight: 600,
                              textWrap: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color: getDynamicColor("dark4"),
                            }}
                          >
                            Invite sent
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </Stack>
                  {isAdmin && (
                    <Button
                      onClick={() => navigate(navigateUrl)}
                      sx={{
                        fontWeight: 700,
                        flexShrink: 0,
                        color: getDynamicColor("primary"),
                        visibility: isAdmin ? "visible" : "hidden",
                      }}
                    >
                      Manage
                    </Button>
                  )}
                </Stack>
                {index < admins.length - 1 && (
                  <Divider sx={{ alignSelf: "center", width: "100%", mx: "auto" }} />
                )}
              </React.Fragment>
            );
          })}
        </Stack>
      </Box>
    );
  };

  return (
    <Stack gap={5} sx={{ pt: 5, pl: 4, pr: 4 }}>
      <Stack
        gap={4}
        direction={"row"}
        sx={{
          alignItems: { xs: "flex-start", md: "center" },
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <Stack gap={1}>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 700,
              color: getDynamicColor("purple3"),
              fontSize: { xs: 14, md: 16 },
            }}
          >
            Content Space Admin
          </Typography>
          <Stack gap={2}>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: 600,
                color: getDynamicColor("dark5"),
                fontSize: 12,
                height: "auto",
              }}
            >
              Add admin who manage and contribute to this content space.
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: 600,
                color: getDynamicColor("dark5"),
                fontSize: 10,
              }}
            >
              Want to learn more about content spaces? Check out this{" "}
              <Link href={SupportPages.CONTENT_SPACES} target="_blank">
                support article
              </Link>
              {"."}
            </Typography>
          </Stack>
        </Stack>
        {isAdmin && (
          <Button
            onClick={handleAddAdminClick}
            variant="contained"
            size="xlarge"
            sx={{ whiteSpace: "nowrap" }}
          >
            Add admin
          </Button>
        )}
      </Stack>
      {currentSpace?.admins?.length > 0 && <AdminList admins={currentSpace?.admins} />}
    </Stack>
  );
};
