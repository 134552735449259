import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import { ExpandMoreRounded as ExpandMoreRoundedIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { useMutation } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { patchUser } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

type DefaultOrgSwitcherProps = {
  expanded: boolean;
};

export const DefaultOrgSwitcher = ({ expanded }: DefaultOrgSwitcherProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    adminInfo: { defaultOrg, defaultOrgLoading },
    adminOrgList,
    invalidateDefaultOrgQuery,
    invalidateUserOrgQuery,
    orgListLoading,
  } = React.useContext(UserOrgContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!isOpen) return;

      if (e.key.length === 1) {
        setSearchText((prev) => prev + e.key);
      } else if (e.key === "Backspace") {
        setSearchText((prev) => prev.slice(0, -1));
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isOpen]);

  let options = [];
  if (adminOrgList && defaultOrg) {
    const allOptions = [...adminOrgList.map((org) => ({ label: org.name, value: org?.id }))];

    if (searchText) {
      // Filter and sort options based on search text
      const searchLower = searchText.toLowerCase();
      options = allOptions.sort((a, b) => {
        const aStartsWith = a.label.toLowerCase().startsWith(searchLower);
        const bStartsWith = b.label.toLowerCase().startsWith(searchLower);
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        return 0;
      });
    } else {
      options = allOptions;
    }
  }

  // After navigation, invalidate local org state if we just changed default orgs.
  React.useEffect(() => {
    if (location.state?.switchToOrg) {
      updateDefaultOrgMutation.mutate(location.state.switchToOrg);
    }
  }, [location, location.state?.switchToOrg]);

  const updateDefaultOrgMutation = useMutation({
    mutationFn: async (orgId: string) => {
      const result = await patchUser({ default_org_id: orgId });
      return result.toString();
    },
    onSuccess: async (orgId: string) => {
      Instrumentation.logDefaultOrgChanged(defaultOrg?.id, orgId);
      await invalidateUserOrgQuery();
      return invalidateDefaultOrgQuery();
    },
  });

  const handleOrgSwitch = (e) => {
    setSearchText(""); // Clear search on selection
    const newUrl = `${location.pathname}${decodeURIComponent(location.search)}`;
    navigate(newUrl, { state: { switchToOrg: e.target.value } });
  };

  return (
    <>
      {!orgListLoading && defaultOrg?.id && (
        <YoodliSelect
          hideTooltipWhenOpen
          tooltip={{
            title: <Typography sx={{ fontWeight: 400 }}>{defaultOrg?.name}</Typography>,
            enterDelay: 1000,
            placement: "right",
          }}
          sx={{
            borderRadius: expanded ? "4px" : 100,
            height: 48,
          }}
          value={defaultOrg.id}
          onChange={(e) => handleOrgSwitch(e)}
          options={options}
          loading={updateDefaultOrgMutation.isPending || defaultOrgLoading}
          customTheme={{
            inputSx: {
              pr: 0,
              height: "48px",
              paddingLeft: 0,
            },
            menuSx: {
              marginLeft: "14px",
            },
            selectedLabelSx: {
              display: expanded ? "block" : "none",
            },
            selectedOptionSx: {
              color: `${getDynamicColor("primary")} !important`,
              backgroundColor: getDynamicColor("light2"),
            },
            selectSx: {
              height: 32,
              backgroundColor: isOpen ? getDynamicColor("dark1") : "none",
            },
            menuListSx: { px: 2 },
            optionLabelSx: {
              color: getDynamicColor("purple3"),
              fontSize: 14,
              fontWeight: 600,
              padding: "6px",
            },
            iconSx: {
              fontSize: 32,
              position: "relative",
              height: "100%",
              width: "100%",
              visibility: `${defaultOrgLoading ? "hidden" : "visible"}`,
            },
            icon: ExpandMoreRoundedIcon,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            setIsOpen(false);
            setSearchText("");
          }}
        />
      )}
    </>
  );
};
