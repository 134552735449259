import firebase from "firebase/app";
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// Components
import AuthInput from "../components/AuthInput";
import {
  Box,
  Stack,
  Button,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import AuthButtonGroup from "auth/components/AuthButtonGroup";
import AuthWrapper from "auth/components/AuthWrapper";
import TermsAndPrivacyAgreementCopy from "auth/components/TermsAndPrivacyAgreementCopy";
import { Helmet } from "react-helmet";

// Assets
import { ReactComponent as EmailIcon } from "../../images/icons/WorkEmail.svg";

// Utils
import {
  getSignInSignUpReturnPath,
  historyPush,
  mergeReturnPathAndSearchParameters,
} from "../../utils/Utilities";
import authUrls from "../config/authUrls";
import {
  SignInResult,
  isPostRedirectSignIn,
  postRedirectSignIn,
  signInWithAuthProvider,
  getAuthSignInDefaultErrorMessage,
} from "../providers/authProvider";
import {
  AuthProviderConfig,
  getProviderConfigFromFirebaseId,
  createProviderConfigFromSsoOptions,
} from "../providers/AuthProviderConfig";
import {
  checkSsoMembershipAndUpdateDefaultOrg,
  completeUserLogin,
  fetchUrlSearchParams,
  generateAuthTokenForPoodliAndClose,
  ORG_ACCESS_INFO_QUERY_KEY,
  SSO_MEMBERSHIP_ERROR_MESSAGE,
} from "../utils";
import {
  useValidateEmail,
  useValidateStrongPassword,
  useValidateDisplayString,
  useValidatePassword,
} from "../utils/validator";
import PasswordProgress from "./PasswordProgress";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import {
  signUpWithEmail,
  signInWithEmail,
  signInWithCustomToken,
} from "auth/providers/EmailProvider";
import Cookies from "js-cookie";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { YoodliActivityContext } from "lib-frontend/contexts/YoodliActivityContext";
import useExternalScript from "lib-frontend/hooks/useExternalScript";
import {
  userAuthenticated,
  getSsoOptions,
  optimisticSendVerificationEmail,
  getOrgAccessInfo,
} from "lib-frontend/modules/AxiosInstance";
import { USER_NAME_MAX_CHAR_LENGTH } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { userDocsService } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import {
  SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM,
  KFA_PROVIDER,
  KFA_SIGN_IN_REDIRECT,
  ACCEPT_TOS_QUERY_PARAM,
} from "lib-fullstack/utils/auth";
import {
  YOODLI_INDEED_HUID,
  YOODLI_REFERRAL_PROGRAM,
  YOODLI_REFERRER,
} from "lib-fullstack/utils/constants";
import { AuthProvider, UITestId } from "lib-fullstack/utils/enums";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import {
  AuthQueryParams,
  HubsInviteRequiredQueryParams,
  OrgInviteQueryParams,
  ReferralProgramQueryParams,
} from "lib-fullstack/utils/queryParams";
import { ReferralProgram } from "lib-fullstack/utils/referralProgramUtils";
import { COOKIE_YES_COOKIE_BANNER_CLASS_NAME } from "utils/Constants";
import { WebServerInternalPath } from "utils/paths";
import { getReturnPath } from "utils/Utilities";
import { SsoPopupRedirectOption } from "lib-fullstack/utils/enums/ssoPopupRedirectOption";

type SignUpSignInProps = {
  /** true for sign-up, false for sign-in */
  isSignUp: boolean;
};

type RedirectSignInContext = {
  requestedReturnPath: string;
  activityId: string;
};

export default function SignUpSignIn({ isSignUp }: SignUpSignInProps): JSX.Element {
  const isSignIn = !isSignUp; // just for code readability
  const { invalidateDefaultOrgQuery, invalidateUserOrgQuery } = React.useContext(UserOrgContext);
  const {
    activity,
    updateActivityAndFetchReturnUrl,
    activityErrorMessage,
    activityRedirectUrl,
    activityLoading,
    isFocusedActivity,
  } = React.useContext(YoodliActivityContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [signInLoading, setSignInLoading] = React.useState<boolean>(false);
  const [showWorkEmailSignIn, setShowWorkEmailSignIn] = React.useState<boolean>(false);
  const [showUsernamePasswordSignUp, setShowUsernamePasswordSignUp] =
    React.useState<boolean>(false);
  const [customSso, setCustomSso] = React.useState<AuthProviderConfig | null>(null);
  const [customSsoFinalized, setCustomSsoFinalized] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(""); // used only by sign-up
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string | JSX.Element>("");
  const [signInResult, setSignInResult] = React.useState<SignInResult | null>(null);
  const [postRedirect, setPostRedirect] = React.useState<boolean>(false);

  const [privacyAccepted, setPrivacyAccepted] = React.useState(false); // used only by sign-up
  const passwordStrength = useValidateStrongPassword(password); // used only by sign-up
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const siteConf = getStaticFullSiteConf();

  const validateNameResult = useValidateDisplayString(name);
  const validatePasswordResult = useValidatePassword(password);
  const errors = {
    name: isSignUp ? validateNameResult : "",
    email: useValidateEmail(email),
    password: isSignUp ? validatePasswordResult : "",
    privacyAcceptanceNeeded:
      siteConf?.featureDiffs?.privacyPolicyConsentRequired && !privacyAccepted,
  };

  const hubId = params.get(AuthQueryParams.HUB_ID);
  const orgId =
    activity?.orgId ??
    params.get(AuthQueryParams.ORG_ID) ??
    params.get(OrgInviteQueryParams.OVERRIDE_ORG_ID);
  const isOrgSignUp = !!hubId || !!orgId;

  const inviteId = params.get(AuthQueryParams.INVITE_ID);
  const isV2Invite = params.get(AuthQueryParams.V2) === "true";
  const hubInviteDetails = hubId && inviteId ? { hubId, inviteId } : undefined;

  const ignoreSso = params.get(AuthQueryParams.IGNORE_SSO) === "true";
  const firebaseProviderForTest = params.get(AuthQueryParams.FIREBASE_PROVIDER_TEST);

  const clientEnv = getClientEnvConfig();
  if (clientEnv.cookieYesId && clientEnv.envName !== "local") {
    useExternalScript({
      id: "cookieyes",
      type: "text/javascript",
      src: `https://cdn-cookieyes.com/client_data/${clientEnv.cookieYesId}/script.js`,
    });
  }

  const orgAccessInfoQuery = useApiQuery({
    queryKey: [ORG_ACCESS_INFO_QUERY_KEY, orgId ?? hubId],
    queryFn: () => getOrgAccessInfo({ orgId: orgId, hubId: hubId }),
    enabled: isOrgSignUp,
    refetchOnWindowFocus: false,
  });

  const requestedReturnPath = location?.state
    ? mergeReturnPathAndSearchParameters(getReturnPath(location), location?.search)
    : null;

  // the cookie banner should be removed after the user enters the app
  const removeCookieBanner = (): void => {
    const cookieBanner = document.querySelector(`.${COOKIE_YES_COOKIE_BANNER_CLASS_NAME}`);
    if (cookieBanner) {
      cookieBanner.remove();
    }
  };

  /**
   * NOTE: This useEffect is intended to run on page load. However, part of the logic requires the YoodliActivityContext to be loaded async first.
   * This is potentially risky because other useEffects might run first.
   * Potential solution: Have the YoodliActivityContext return a promise that resolves when the activity is loaded,
   * then handle the relevant logic async using a .then block
   */
  React.useEffect(() => {
    // Wait until activity is loaded to determine redirect path
    if (activityLoading) {
      return;
    }
    console.debug(`SignUpSignIn onLoad query=${location.search}`);

    if (isPostRedirectSignIn()) {
      setPostRedirect(true);

      console.log(`Calling async postRedirectSignIn() and skip rest of onLoad`);

      postRedirectSignIn()
        .then((result) => {
          setSignInResult(result);
          if (result.user) {
            // Log for success. Log for failure should have been done inside postRedirectSignIn.
            console.log(`Redirect from sign-in. User: ${result.user.email}`);
            // Do not show normal UI components until navigating away.
            setLoading(true);
          } else {
            throw new Error("postRedirectSignIn returned null user");
          }
        })
        .catch((error) => {
          console.error(`Unexpected: error in postRedirectSignIn: ${error}`);
          setError(getAuthSignInDefaultErrorMessage());
        });

      // The rest of on-load logic should not run for post redirection
      return;
    }

    console.log(`Not a redirect from sign-in. Proceed to onLoad`);

    // If the user is already signed in, redirect them to the return path immediately
    const user = firebase.auth().currentUser;
    if (user && user["uid"]) {
      const { signInReturnPath } = getSignInSignUpReturnPath(requestedReturnPath);
      removeCookieBanner();
      historyPush(navigate, activityRedirectUrl ?? signInReturnPath, location.state);
    }

    // If hub invite information is passed by cookies, convert them to query parameters
    // and then redirect to the home page. The home page will redirect further to sign-up page
    // with the query parameters, so that we may initialize the sign-up page with them.
    // This was introduced for Spenser Stuart. See #7094
    if (
      HubsInviteRequiredQueryParams.every((param) =>
        Cookies.get(param, { domain: clientEnv.domain }),
      )
    ) {
      for (const param of HubsInviteRequiredQueryParams) {
        params.set(param, Cookies.get(param, { domain: clientEnv.domain }) as string);
        Cookies.remove(param, { domain: clientEnv.domain });
      }
      removeCookieBanner();
      historyPush(navigate, `${WebServerExternalPath.HOME_LOGGED_IN}?${params.toString()}`);
    }

    // Login via custom token
    if (params.get(SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM)) {
      const acceptTos = Boolean(params.get(ACCEPT_TOS_QUERY_PARAM));
      void handleSignInWithCustomToken(params.get(SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM), acceptTos);
    }

    // If test parameter is passed, configure that SSO option
    if (firebaseProviderForTest) {
      getProviderConfigFromFirebaseId(firebaseProviderForTest)
        .then((config) => setCustomSso(config))
        .catch((error) => console.error(`Error getProviderConfigFromFirebaseId ${error}`));
    }

    if (isSignUp) {
      Instrumentation.logSignupPageLoaded(orgId, hubId, isFocusedActivity);
    } else {
      Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNIN_PAGE_LOADED);
    }
  }, [activityLoading]);

  React.useEffect(() => {
    if (activityErrorMessage) {
      setError(activityErrorMessage);
    }
  }, [activityErrorMessage]);

  // When org query completes and it has sso_options, set custom SSO
  // with a flag to make UX only this SSO option (i.e. org SSO)
  React.useEffect(() => {
    console.log(`useEffect for orgAccessInfoQuery.isLoading = ${orgAccessInfoQuery.isLoading}`);
    if (!orgAccessInfoQuery.isLoading) {
      if (orgAccessInfoQuery.data && !firebaseProviderForTest) {
        const config = createProviderConfigFromSsoOptions(
          orgAccessInfoQuery.data.sso_options,
          true, // showOnlyThis
        );
        if (config) {
          console.log(`OrgAccessInfoQuery has custom SSO: ${config.displayName}`);
          setCustomSso(config);

          // Proceeding to SSO automatically if the org has auto_sso enabled.
          // Support redirection sign-in only because pop-up is generally blocked without click.
          const redirectSignIn = config.popupRedirectOption !== SsoPopupRedirectOption.USE_POPUP;
          if (!postRedirect && redirectSignIn && orgAccessInfoQuery.data.auto_sso) {
            handleSignInWithAuthProvider(null, config).catch((error) => {
              console.error(`Error after proceeding SSO automatically ${error}`);
            });
          }
        }
      }
      setCustomSsoFinalized(true);
    }
  }, [orgAccessInfoQuery.isLoading]);

  React.useEffect(() => {
    if (signInResult?.errorMessage) {
      setError(signInResult.errorMessage);
      setLoading(false);
    } else {
      setError("");
      // Not resetting loading here because this continues further processing by resolveSignIn()
    }
  }, [signInResult]);

  React.useEffect(() => {
    console.log(
      `useEffect for customSsoFinalized=${customSsoFinalized}, signInResult=${signInResult?.user?.email}`,
    );
    if (signInResult?.user && customSsoFinalized) {
      // resolveSignIn() needs customSso, therefore deferring to call it here finalized.
      resolveSignIn(signInResult.user, signInResult.isNewUser).catch((error) => {
        console.error("Error in resolveSignIn()", error);
      });
    }
  }, [signInResult, customSsoFinalized]);

  const handleWorkEmailSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const domain = email.split("@")[1];
    setSignInLoading(true);
    try {
      const res = await getSsoOptions({ emailDomain: domain });
      const config = createProviderConfigFromSsoOptions(res.sso_options, false); // showOnlyThis = false
      if (config && !ignoreSso) {
        // If email domain has a specific SSO option, set custom SSO, with other entries still displayed
        setCustomSso(config);
        setShowUsernamePasswordSignUp(false);
      } else {
        setShowUsernamePasswordSignUp(true);
        setShowWorkEmailSignIn(false);
      }
    } catch (_error) {
      setShowUsernamePasswordSignUp(true);
      setShowWorkEmailSignIn(false);
    } finally {
      setSignInLoading(false);
    }
  };

  const handleSignIn = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setLoading(true);

    if (isSignUp) {
      Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_EMAIL_PASSWORD_OPTION_CLICKED);
      if (![...Object.values(errors)].some((error) => !!error) && name.length > 0) {
        try {
          const user = await signUpWithEmail(email, password, name);
          await resolveSignIn(user, true);
        } catch (error) {
          setLoading(false);
          if (error.code === "auth/email-already-in-use") {
            setError(
              <>
                Sorry, this email address may already be in use. Please try to{" "}
                <Link
                  style={{ color: getDynamicColor("redError") }}
                  to={{
                    pathname: authUrls.signin,
                    search: fetchUrlSearchParams(params),
                  }}
                  state={location.state}
                >
                  sign in
                </Link>{" "}
                or{" "}
                <Link
                  style={{ color: getDynamicColor("redError") }}
                  to={{
                    pathname: authUrls.reset_password,
                    search: fetchUrlSearchParams(params),
                  }}
                  state={location.state}
                >
                  reset your password
                </Link>
                .
              </>,
            );
          } else {
            setError(error.message);
          }
          if (error.code?.startsWith("auth/")) {
            console.warn(error.code);
          } else {
            console.error(error.message);
          }
        }
      } else if (errors.privacyAcceptanceNeeded) {
        setError("Please consent to the above in order to continue!");
      } else if (name.length === 0 && !errors.name) {
        setError("Name field is required");
      }
    } else {
      // sign-in
      Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNIN_EMAIL_PASSWORD_OPTION_CLICKED);
      if (![...Object.values(errors)].some((error) => !!error) && email && password) {
        try {
          const user = await signInWithEmail(email, password);
          await resolveSignIn(user, false);
        } catch (error) {
          // special handling for Korn Ferry Advance users to redirect them to their KFA login page
          const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
          const kfaSignInMethods = signInMethods?.filter((method) => method === KFA_PROVIDER);
          if (kfaSignInMethods?.length > 0) {
            window.location.href = KFA_SIGN_IN_REDIRECT;
            return;
          }

          if (error?.code?.startsWith?.("auth/")) {
            console.warn(error?.code);
          } else {
            console.error(error?.code ?? error);
          }

          if (error?.code === "auth/internal-error") {
            setError("Sorry that's not quite right. Please try again or sign up.");
          } else {
            setError(error.message);
          }
        }
      } else if (!email.length) {
        setError("Email is required");
      } else if (!password.length) {
        setError("Password is required");
      }
    }

    setLoading(false);
  };

  const resolveSignIn = async (user: firebase.User, isNewUser: boolean) => {
    const code = params.get(AuthQueryParams.OT_AUTH_CODE); // Sent from electron app redirect

    // Location's "state" is not retained across redirection sign-in.
    // Existing (pop-up sign-in) code uses it to save return path,
    // but redirect sign-in needs to pass around by using the context.
    const effectiveRequestedReturnPath = postRedirect
      ? (signInResult.context as RedirectSignInContext).requestedReturnPath
      : requestedReturnPath;

    const activityId = (signInResult?.context as RedirectSignInContext)?.activityId;
    let activityRedirectPath: string = activityRedirectUrl;
    if (activityId && !activityRedirectPath) {
      activityRedirectPath = await updateActivityAndFetchReturnUrl(activityId);
    }

    const returnPaths = getSignInSignUpReturnPath(effectiveRequestedReturnPath);
    const returnPath = isNewUser ? returnPaths.signUpReturnPath : returnPaths.signInReturnPath;

    console.log(`resolveSignIn final=${returnPath} requested=${effectiveRequestedReturnPath}`);

    if (user?.uid) {
      // make sure the user doc service is updating - it should automatically, but this prevents race conditions
      userDocsService.resetLiveUserDocsOnAuthChange();

      const referralSlug = Cookies.get(YOODLI_REFERRER, { domain: clientEnv.domain }) ?? "";
      const indeedHuid =
        Cookies.get(YOODLI_INDEED_HUID, { domain: clientEnv.domain }) ??
        params.get(ReferralProgramQueryParams.INDEED_HUID) ??
        "";
      const referralProgram =
        Cookies.get(YOODLI_REFERRAL_PROGRAM, { domain: clientEnv.domain }) ??
        (indeedHuid ? ReferralProgram.INDEED : "");

      await userAuthenticated(
        referralProgram,
        indeedHuid ? { hashedUserId: indeedHuid } : null,
        isV2Invite ? null : hubInviteDetails,
      );

      // Fetch user's default org in order to make the page branded.
      // Refer PR comment on #12039 and linked Slack discussion for details.
      // WARNING: this does not work for password user sign-up because
      // email has not been verified and server returns an error.
      await invalidateUserOrgQuery();

      // If the user has used SSO for org sign up, check the membership
      // if the org membership has been given, and show a warning if not.
      if (isOrgSignUp && customSso?.showOnlyThis) {
        const hasMembership = await checkSsoMembershipAndUpdateDefaultOrg(
          orgAccessInfoQuery?.data,
          params,
          invalidateDefaultOrgQuery,
          invalidateUserOrgQuery,
        );

        if (!hasMembership && !error) {
          setError(SSO_MEMBERSHIP_ERROR_MESSAGE);
          setLoading(false);
          return;
        }
      }

      if (referralSlug) {
        Cookies.remove(YOODLI_REFERRER, { domain: clientEnv.domain });
      }
      if (referralProgram) {
        Cookies.remove(YOODLI_REFERRAL_PROGRAM, { domain: clientEnv.domain });
      }
      if (indeedHuid) {
        Cookies.remove(YOODLI_INDEED_HUID, { domain: clientEnv.domain });
      }

      await completeUserLogin(user.uid);

      if (code && user?.emailVerified) {
        await generateAuthTokenForPoodliAndClose(code);
      } else {
        let returnPathForUser: string;
        if (user?.emailVerified) {
          if (activityRedirectPath) {
            returnPathForUser = activityRedirectPath;
          } else {
            returnPathForUser = returnPath;
          }

          returnPathForUser = mergeReturnPathAndSearchParameters(
            returnPathForUser,
            location.search,
          );
        } else {
          returnPathForUser = mergeReturnPathAndSearchParameters(
            WebServerInternalPath.VERIFY_EMAIL,
            location.search,
          );

          await optimisticSendVerificationEmail(
            clientEnv.url.WEB_SERVER +
              mergeReturnPathAndSearchParameters(returnPaths.signUpReturnPath, location.search),
          ).catch((error) => {
            if (error.message === "auth/internal-error") {
              console.log("Error sending email, too many attempts!");
            } else {
              console.error("Error sending email!", error);
            }
          });
        }

        // ensure user docs have re-resolved before we continue
        await userDocsService.awaitUserDocsResolved();

        removeCookieBanner();
        historyPush(navigate, returnPathForUser, { returnPath, ...location.state });
      }
    } else {
      console.log("resolveSignIn did not return a user. Direct back to the same page to try again");
      removeCookieBanner();
      historyPush(navigate, location.pathname, { returnPath, ...location.state });
    }
  };

  const handleSignInWithAuthProvider = async (
    e: React.MouseEvent<HTMLButtonElement> | null,
    config: AuthProviderConfig,
  ) => {
    e?.preventDefault();

    setLoading(true);
    const result = await signInWithAuthProvider(config, {
      requestedReturnPath: requestedReturnPath,
      activityId: activity?.id,
    } as RedirectSignInContext);

    // The code reaches here if redirect sign-in was not initiated above.
    if (result) {
      setSignInResult(result);
    }
  };

  const handleChangeName = (name) => {
    setName(name);
    setError("");
  };

  const handleChangeEmail = (email) => {
    setEmail(email);
    setError("");
  };

  const handleChangePassword = (password) => {
    setPassword(password);
    setError("");
  };

  const handlePrivacyChecked = (checked) => {
    setPrivacyAccepted(checked);
    setError("");
  };

  const expandWorkEmailOption = () => {
    if (isSignUp) {
      Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_EMAIL_PASSWORD_OPTION_EXPANDED);
    }
    setShowWorkEmailSignIn(true);
  };

  const handleSignInWithCustomToken = async (customSigninToken: string, acceptTos: boolean) => {
    setLoading(true);
    try {
      const user = await signInWithCustomToken(customSigninToken);
      // custom tokens can never occur for new users so sign in!
      await resolveSignIn(user, acceptTos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.code?.startsWith?.("auth/")) {
        console.warn(error?.code);
      } else {
        console.error(error?.code ?? error);
      }
      setError(error.message);
    }
  };

  const handleSsoSignIn = async (e) => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_CUSTOM_SSO_OPTION_CLICKED);
    await handleSignInWithAuthProvider(e, customSso);
  };

  const renderUsernamePasswordForm = () => (
    <>
      <Divider sx={{ flexShrink: "initial", width: "100%" }} />
      <Stack gap={2} width="100%">
        <Box>
          <Typography
            px={2}
            color={getDynamicColor("dark6")}
            fontWeight={600}
            fontSize="14px"
            fontFamily="poppins"
          >
            {isSignUp ? "Sign up with work email" : "Sign in with work email"}
          </Typography>
        </Box>
        <form onSubmit={handleSignIn} style={{ width: "100%" }}>
          <Stack gap={1.5} alignItems="center">
            <AuthInput
              fullWidth
              autoComplete="email"
              value={email}
              placeholder={isSignUp ? "Enter your work email" : "Email"}
              label={email ? (isSignUp ? "Work Email" : "Email") : undefined}
              type="email"
              errorText={errors.email}
              onChange={(e) => handleChangeEmail(e.target.value)}
              inputProps={{
                "data-testid": UITestId.WorkEmailTextField,
              }}
            />
            {isSignUp && (
              <AuthInput
                fullWidth
                autoFocus
                value={name}
                autoComplete="given-name"
                placeholder="Enter your name"
                label={name ? "Name" : undefined}
                errorText={errors.name}
                type="text"
                onChange={(e) => handleChangeName(e.target.value)}
                inputProps={{
                  maxLength: USER_NAME_MAX_CHAR_LENGTH,
                  "data-testid": UITestId.NameTextField,
                }}
              />
            )}
            <AuthInput
              fullWidth
              value={password}
              autoComplete={isSignUp ? "new-password" : undefined}
              autoFocus={!isSignUp}
              placeholder={isSignUp ? "Create a password" : "Password"}
              label={password ? "Password" : undefined}
              type="password"
              errorText={errors.password}
              onChange={(e) => handleChangePassword(e.target.value)}
              inputProps={{
                "data-testid": UITestId.PasswordTextField,
              }}
            />
            {isSignUp && password.length > 0 && <PasswordProgress score={passwordStrength} />}
            <Stack width="100%" gap={1}>
              <Button
                variant="gradient"
                onClick={handleSignIn}
                disabled={
                  [...Object.values(errors)].some((error) => !!error) ||
                  (isSignUp && !name?.length) ||
                  !email ||
                  !password
                }
                sx={{
                  borderRadius: "4px",
                  height: 48,
                  width: "100%",
                }}
                data-testid={isSignUp ? UITestId.SignUpButton : UITestId.SignInButton}
              >
                {isSignUp ? "Sign up" : "Sign in"}
              </Button>
              {isSignIn && (
                <Link to={authUrls.forgot_password} style={{ textDecoration: "none" }}>
                  <Typography
                    fontFamily="poppins"
                    fontWeight={600}
                    fontSize="12px"
                    color={getDynamicColor("primary")}
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                    data-testid={UITestId.ForgotPassword}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              )}
              <input type="submit" style={{ display: "none" }} />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );

  /** showing the email sign-up / sign-in form */
  const renderWorkEmailForm = () => {
    const ctaDisabled = [...Object.values(errors)].some((error) => !!error) || !email;
    if (showWorkEmailSignIn) {
      return (
        <>
          <Divider sx={{ flexShrink: "initial", width: "100%" }} />
          <Stack gap={2} width="100%">
            <Box>
              <Typography
                color={getDynamicColor("dark6")}
                fontWeight={600}
                fontSize="14px"
                fontFamily="poppins"
              >
                {isSignUp ? "Sign up with work email" : "Sign in with work email"}
              </Typography>
            </Box>
            <form onSubmit={handleWorkEmailSubmit} style={{ width: "100%" }}>
              <Stack gap={1.5} alignItems="center">
                <AuthInput
                  fullWidth
                  autoComplete="email"
                  value={email}
                  placeholder={isSignUp ? "Enter your work email" : "Work Email"}
                  label={email ? "Work Email" : undefined}
                  disabled={!!customSso}
                  type="email"
                  errorText={errors.email}
                  onChange={(e) => handleChangeEmail(e.target.value)}
                  inputProps={{
                    "data-testid": UITestId.WorkEmailTextField,
                  }}
                />
                <Stack width="100%" gap={1}>
                  {signInLoading ? (
                    <Stack display="flex" justifyContent="center" alignItems="center" height="48px">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    // no custom SSO option yet -- show regular sign-in sign-up button
                    <>
                      {!customSso ? (
                        <Button
                          variant="gradient"
                          onClick={handleWorkEmailSubmit}
                          disabled={ctaDisabled}
                          sx={{
                            borderRadius: "4px",
                            height: 48,
                            width: "100%",
                          }}
                          data-testid={UITestId.NextButton}
                        >
                          Next
                        </Button>
                      ) : (
                        // found SSO option -- changing the button to sign them in/up with SSO
                        <Button
                          variant="gradient"
                          onClick={handleSsoSignIn}
                          disabled={ctaDisabled}
                          sx={{
                            borderRadius: "4px",
                            height: 48,
                            width: "100%",
                          }}
                        >
                          {isSignUp ? "Sign up with" : "Sign in with"} {customSso.displayName}
                        </Button>
                      )}
                    </>
                  )}
                  <input type="submit" style={{ display: "none" }} />
                </Stack>
              </Stack>
            </form>
          </Stack>
        </>
      );
    }
    if (
      !customSso &&
      (orgAccessInfoQuery.isPending ||
        orgAccessInfoQuery?.data?.sign_in_options?.includes(AuthProvider.EMAIL_PASSWORD))
    ) {
      return (
        <Button
          variant="contained"
          disabled={!!activityErrorMessage}
          startIcon={
            <EmailIcon width="28px" style={{ borderRadius: "50%", backgroundColor: "#FOFOFF" }} />
          }
          onClick={expandWorkEmailOption}
          sx={{
            backgroundColor: getDynamicColor("light1"),
            width: "100%",
            "&:hover": {
              backgroundColor: getDynamicColor("dark2"),
            },
            fontWeight: 600,
            fontSize: 14,
            color: getDynamicColor("dark6"),
            height: 48,
          }}
          data-testid={UITestId.SignInWithWorkEmailButton}
        >
          {isSignUp ? "Continue with work email" : "Sign in with work email"}
        </Button>
      );
    }
  };

  if (loading || orgAccessInfoQuery.isLoading) {
    return (
      <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        {/* Prevent search engines from indexing the auth pages, as they are behind LB and cannot be scraped currently
            resulting in poor SERP data. ("Must enable JS to run this app" error on SERP) */}
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <AuthWrapper
          showUsernamePasswordSignUp={showUsernamePasswordSignUp}
          isSignUp={isSignUp}
          orgAccessInfo={orgAccessInfoQuery?.data}
        >
          <Stack
            direction="column"
            width="100%"
            gap={2}
            sx={{
              ...(!customSso?.showOnlyThis && {
                backgroundColor: getDynamicColor("light2"),
                boxShadow: Y_SHADOWS.box_shadow_1,
              }),
              borderRadius: "8px",
              p: "20px",
              width: "min(385px, 100%)",
            }}
          >
            {error && (
              <Typography p={1} color="error" fontWeight={600} fontSize="12px">
                {error}
              </Typography>
            )}
            <AuthButtonGroup
              customSso={customSso}
              buttonHandler={handleSignInWithAuthProvider}
              disableButtons={!!activityErrorMessage}
              isSignUp={isSignUp}
              error={!!error}
              // [Note by Hiroshi 2024-10-27] Below is exact merge of SignUp.tsx and SignIn.tsx.
              // However, I am not sure if it's a right thing to determine isNewUser just by URL.
              // This is used only by limited situation where the user does not have target
              // org membership and the impact is minimum, and it is OK to keep as is for now.
              handleRedirect={() => {
                void resolveSignIn(firebase.auth().currentUser, isSignUp);
              }}
              signInOptions={
                orgAccessInfoQuery?.data?.sign_in_options ?? [
                  AuthProvider.EMAIL_PASSWORD,
                  AuthProvider.GOOGLE,
                  AuthProvider.MICROSOFT,
                ]
              }
            />
            {showUsernamePasswordSignUp ? renderUsernamePasswordForm() : renderWorkEmailForm()}
          </Stack>
          <Typography
            fontWeight={600}
            fontSize="14px"
            fontFamily="poppins"
            color={getDynamicColor("dark5")}
          >
            {isSignUp ? "Already have an account?" : "Don't have an account yet?"} &nbsp;
            <Link
              style={{ textDecoration: "none", color: getDynamicColor("primary") }}
              to={{
                pathname: isSignUp ? authUrls.signin : authUrls.signup,
                search: fetchUrlSearchParams(params),
              }}
              state={location.state}
              data-testid={UITestId.SignInSignUpLink}
            >
              {isSignUp ? "Sign in" : "Sign up"}
            </Link>
          </Typography>
          {siteConf?.featureDiffs?.privacyPolicyConsentRequired ? (
            isSignUp && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(_, checked) => handlePrivacyChecked(checked)}
                    value={privacyAccepted}
                  />
                }
                label={
                  <Typography fontSize="12px" textAlign="start">
                    I agree to Yoodli's{" "}
                    <a
                      href={getLandingPageExternalUrl(clientEnv, LandingPageExternalPath.PRIVACY)}
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    ,{" "}
                    <a
                      href={getLandingPageExternalUrl(
                        clientEnv,
                        LandingPageExternalPath.TERMS_OF_SERVICE,
                      )}
                      target="_blank"
                    >
                      Terms of Use
                    </a>
                    , and{" "}
                    <a
                      href={getLandingPageExternalUrl(clientEnv, LandingPageExternalPath.COPYRIGHT)}
                      target="_blank"
                    >
                      Copyright Policy
                    </a>
                  </Typography>
                }
              />
            )
          ) : (
            <TermsAndPrivacyAgreementCopy orgSignUp={!!inviteId} />
          )}
        </AuthWrapper>
      </>
    );
  }
}
