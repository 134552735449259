import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";

// Utils
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { getUser, getOrgV2, listOrgsV2, patchUser } from "lib-frontend/modules/AxiosInstance";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { GetUserFieldType } from "lib-fullstack/api/apiTypes";
import { OrgV2Response, OrgV2ListItemResponse } from "lib-fullstack/api/orgApiTypes";
import { EffectiveRole, OrgCustomerType } from "lib-fullstack/utils/enums";
import { OrgInviteQueryParams } from "lib-fullstack/utils/queryParams";
import { MEMBER_BUILDER_ENABLED } from "webclient/src/utils/Constants";
import { OrgSharingVisibilityEnum } from "lib-fullstack/db";

/**
 * Expose user context that can be fetched
 */
export interface IUserOrgContext {
  // org related context
  loading: boolean;
  userInOrg: boolean;
  effectiveRole: EffectiveRole;
  defaultOrgPrimaryLogo: string | null;
  defaultOrgSecondaryLogo: string | null;
  defaultOrgId: string;
  defaultOrgName: string | null;
  defaultOrgDisableAiFeedback: boolean;
  userInCoachOrganization: boolean;
  userInEnterpriseOrganization: boolean;
  isAnyOrgOwnerAdmin: boolean;
  isDefaultOrgOwnerAdmin: boolean;
  defaultOrgSharingVisibility: OrgSharingVisibilityEnum;

  // #13253 This is a temporary feature until we fully productize this
  // Some specific organizations want sign-out to revoke the refresh token, which normally is not revoked in firebase
  // To do this, unfortunately we need to revoke *all* refresh tokens, signing out a user on all devices
  // Thus this is enabled only for certain organizations
  logOutEverywhereEnforced: boolean;

  userOrgProfile: UserOrgProfile;
  setUserOrgProfile: (profile: UserOrgProfile) => void;
  userOrgRole: EffectiveRole;
  setUserOrgRole: (role: EffectiveRole) => void;

  // modules enabled/disabled by org, if applicable
  orgModuleAccess: OrgModuleAccess;

  // org list
  adminOrgList: OrgV2ListItemResponse[];
  fullOrgList: OrgV2ListItemResponse[];
  orgListLoading: boolean;

  // admin context info
  adminInfo: AdminInfo;

  // helpers to request refreshes of the context on changes
  invalidateUserOrgQuery: () => Promise<void>;
  invalidateDefaultOrgQuery: () => Promise<void>;
  invalidateOrgListQuery: () => Promise<void>;

  emailVerified: boolean;
  liveEventScaling: boolean;
}

// react-query keys for invalidation
// not exported as the relevant refresh functions on the context should be used instead
const userOrgContextQueryKey = "userOrgContext";
enum UserOrgContextSubQueryKeys {
  USER_INFO = "userInfo",
  DEFAULT_ORG = "defaultOrg",
  ORGS_LIST = "orgsList",
}

export enum UserOrgProfile {
  ORGANIZATION = "Admin",
  PERSONAL = "Member",
}

export type OrgModuleAccess = {
  interviewEnabled: boolean;
  presentationEnabled: boolean;
  roleplayEnabled: boolean;
  desktopAppEnabled: boolean;
  zoodliForUsersEnabled: boolean;
  pronunciationFeedbackEnabled: boolean;
  memberBuilderEnabled: boolean;
  videoUploadEnabled: boolean;
};

export type AdminInfo = {
  defaultOrg: OrgV2Response;
  defaultOrgLoading: boolean;
  defaultOrgRefetching: boolean;
};

const defaultOrgModuleAccess: OrgModuleAccess = {
  interviewEnabled: true,
  presentationEnabled: true,
  roleplayEnabled: true,
  desktopAppEnabled: true,
  pronunciationFeedbackEnabled: true,
  zoodliForUsersEnabled: true,
  memberBuilderEnabled:
    typeof localStorage !== "undefined"
      ? localStorage?.getItem(MEMBER_BUILDER_ENABLED) === "true"
      : false,
  videoUploadEnabled: true,
};

const defaultAdminInfo: AdminInfo = {
  defaultOrg: null,
  defaultOrgLoading: true,
  defaultOrgRefetching: false,
};

/**
 * Context to use to get information on a user's organization and hub memberships and content
 */
export const UserOrgContext = React.createContext<IUserOrgContext>({
  loading: true,
  defaultOrgId: null,
  defaultOrgName: null,
  effectiveRole: EffectiveRole.HUB_MEMBER,
  defaultOrgPrimaryLogo: null,
  defaultOrgSecondaryLogo: null,
  defaultOrgDisableAiFeedback: false,
  userInOrg: undefined,
  userInCoachOrganization: false,
  userInEnterpriseOrganization: false,
  isAnyOrgOwnerAdmin: false,
  isDefaultOrgOwnerAdmin: false,
  logOutEverywhereEnforced: false,
  defaultOrgSharingVisibility: OrgSharingVisibilityEnum.Private,

  orgModuleAccess: defaultOrgModuleAccess,

  adminInfo: defaultAdminInfo,

  adminOrgList: null,
  fullOrgList: null,
  orgListLoading: true,
  liveEventScaling: false,

  userOrgProfile: UserOrgProfile.ORGANIZATION,
  setUserOrgProfile: () => {
    // empty to provide a safe default to call
    return;
  },

  userOrgRole: EffectiveRole.HUB_MEMBER,
  setUserOrgRole: () => {
    // empty to provide a safe default to call
    return;
  },

  invalidateUserOrgQuery: () => {
    // empty to provide a safe default to call
    return Promise.resolve();
  },
  invalidateDefaultOrgQuery: () => {
    // empty to provide a safe default to call
    return Promise.resolve();
  },
  invalidateOrgListQuery: () => {
    // empty to provide a safe default to call
    return Promise.resolve();
  },

  emailVerified: false,
});

// #13253 This is a temporary feature until we fully productize this
// Some specific organizations want sign-out to revoke the refresh token, which normally is not revoked in firebase
// To do this, unfortunately we need to revoke *all* refresh tokens, signing out a user on all devices
// Thus this is enabled only for certain organizations
const LOGOUT_EVERYWHERE_ENFORCED_ORG_IDS = [
  "twdL", // dev
  "6wqR9FsncDtBqde964w4T6", // staging
  "rqgrxpqqQ36nhpFNJMcTR7", // prod
  "jHjX", // prod
];

/**
 * React element to provide the user org context in the tree
 */
export function UserOrgDataProvider({ children }: React.PropsWithChildren): JSX.Element {
  const queryClient = useQueryClient();

  const [userOrgProfile, setUserOrgProfile] = React.useState<UserOrgProfile>(
    (getLiveUserDocMain()?.lastUserOrgProfile as UserOrgProfile) ?? UserOrgProfile.ORGANIZATION,
  );

  const [userOrgRole, setUserOrgRole] = React.useState<EffectiveRole>(
    (getLiveUserDocMain()?.lastUserOrgRole as EffectiveRole) ?? EffectiveRole.HUB_MEMBER,
  );

  const [emailVerified, setEmailVerified] = React.useState<boolean>(
    !!firebase.auth()?.currentUser?.emailVerified,
  );

  // Polling mechanism to check email verification status
  React.useEffect(() => {
    const interval = setInterval(() => {
      const user = firebase.auth().currentUser;
      if (user && !emailVerified && !user?.uid?.startsWith("TMI")) {
        void user.reload().then(async () => {
          if (user.emailVerified) {
            const token = await firebase.auth().currentUser.getIdTokenResult(true); // Force refresh the token
            if (token?.claims?.email_verified) {
              clearInterval(interval);
              setEmailVerified(true);
            }
          } else {
            setEmailVerified(false);
          }
        });
      }
    }, 1000); // Check every 1 second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  // Fetch the user's org context
  // This is the core query that all other queries depend on
  // In OHR we'll be able to simplify this to use use the default org fetch + a content member view fetch
  const userQueryResults = useApiQuery({
    queryKey: [
      userOrgContextQueryKey,
      { userId: firebase.auth().currentUser?.uid, type: UserOrgContextSubQueryKeys.USER_INFO },
    ],
    queryFn: async () => {
      const ret = await getUser([
        GetUserFieldType.SHOW_ORG_ADMIN_UX,
        GetUserFieldType.DEFAULT_ORG_INFO,
        GetUserFieldType.FEATURE_FLAGS,
      ]);
      updateUserDocRole(ret.default_org_info?.effective_role ?? EffectiveRole.HUB_MEMBER);
      return ret;
    },
    enabled: !!firebase.auth().currentUser?.uid && !!emailVerified,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    const updateBrandingLogo = async () => {
      await updateThisUserDocMain({
        brandingLogoUrl: userQueryResults.data?.default_org_info?.logo_url ?? db.value("remove"),
        brandingSecondaryLogoUrl:
          userQueryResults.data?.default_org_info?.secondary_logo_url ?? db.value("remove"),
      });
    };
    if (
      userQueryResults.data?.default_org_info?.logo_url !== getLiveUserDocMain().brandingLogoUrl ||
      userQueryResults.data?.default_org_info?.secondary_logo_url !==
        getLiveUserDocMain().brandingSecondaryLogoUrl
    ) {
      updateBrandingLogo().catch((e) => {
        console.error(`Failed to update branding logo: ${e}`);
      });
    }
  }, [
    userQueryResults.data?.default_org_info?.logo_url,
    userQueryResults.data?.default_org_info?.secondary_logo_url,
  ]);

  // Ensure local storage is up to date with the user's feature flags
  React.useEffect(() => {
    if (
      userQueryResults?.data &&
      userQueryResults?.data?.feature_flags?.member_builder_enabled.toString() !==
        localStorage.getItem(MEMBER_BUILDER_ENABLED)
    ) {
      localStorage.setItem(
        MEMBER_BUILDER_ENABLED,
        userQueryResults?.data?.feature_flags?.member_builder_enabled.toString(),
      );
    }
  }, [userQueryResults?.data?.feature_flags]);

  // if the path includes an overrideOrgId, update the user's default_org_id and invalidate the context
  React.useEffect(() => {
    const overrideOrgId = new URLSearchParams(window.location.search).get(
      OrgInviteQueryParams.OVERRIDE_ORG_ID,
    );
    const currOrgId = userQueryResults.data?.default_org_info?.org_id;
    // if the user is not loaded yet, don't do anything
    if (!currOrgId) {
      return;
    }
    if (overrideOrgId && overrideOrgId !== currOrgId) {
      const updateUserDefaultOrg = async () => {
        await patchUser({ default_org_id: overrideOrgId });
        // remove the overrideOrgId from the URL after updating the user's default_org_id
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(OrgInviteQueryParams.OVERRIDE_ORG_ID);
        const paramsToAdd = searchParams.toString()?.length ? `?${searchParams.toString()}` : "";
        const newUrl = `${window.location.pathname}${paramsToAdd}${window.location.hash}`;
        window.history.replaceState({}, "", newUrl);
        console.log("UserOrgDataProvider: overrideOrgId changed invalidation");
        Instrumentation.logDefaultOrgChanged(overrideOrgId, currOrgId);
        void queryClient.invalidateQueries({ queryKey: [userOrgContextQueryKey] });
      };
      updateUserDefaultOrg().catch((er) => {
        console.error(
          `Failed to update user default org from ${currOrgId} to ${overrideOrgId}: ${er}`,
        );
      });
    }
  }, [userQueryResults.data?.default_org_info?.org_id]);

  const handleSetUserOrgProfile = React.useCallback((profile: UserOrgProfile) => {
    updateThisUserDocMain({ lastUserOrgProfile: profile }).catch((er) => {
      console.log(`Failed to update userdoc with latest userOrgProfile: ${er}`);
    });
    setUserOrgProfile(profile);
  }, []);

  const handleSetUserOrgRole = React.useCallback((role: EffectiveRole) => {
    // change owner roles to admin (for mapping the nav items as they are the same for the two roles)
    if (role === EffectiveRole.ORG_OWNER) {
      role = EffectiveRole.ORG_ADMIN;
    }
    updateThisUserDocMain({ lastUserOrgRole: role }).catch((er) => {
      console.log(`Failed to update userdoc with lastUserOrgRole: ${er}`);
    });
    setUserOrgRole(role);
  }, []);

  const updateUserDocRole = (role: EffectiveRole) => {
    // change owner roles to admin (for mapping the nav items as they are the same for the two roles)
    if (role === EffectiveRole.ORG_OWNER) {
      role = EffectiveRole.ORG_ADMIN;
    }
    if (role && role !== getLiveUserDocMain()?.lastUserOrgRole) {
      updateThisUserDocMain({ lastUserOrgRole: role }).catch((er) => {
        console.log(`Failed to update userdoc with lastUserOrgRole: ${er}`);
      });
      setUserOrgRole(role);
    }
  };

  // Full default org query
  const isCurrentAdmin =
    [
      EffectiveRole.ORG_OWNER,
      EffectiveRole.ORG_ADMIN,
      EffectiveRole.HUB_ADMIN,
      EffectiveRole.SPACE_ADMIN,
    ].includes(userQueryResults.data?.default_org_info?.effective_role) ?? false;

  const orgQueryResultsEnabled =
    userQueryResults.isSuccess &&
    !userQueryResults.isFetching &&
    !!userQueryResults.data?.default_org_info?.org_id &&
    isCurrentAdmin;
  const orgQueryResults = useApiQuery<OrgV2Response>({
    queryKey: [
      userOrgContextQueryKey,
      { userId: firebase.auth().currentUser?.uid, type: UserOrgContextSubQueryKeys.DEFAULT_ORG },
    ],
    queryFn: async (): Promise<OrgV2Response> => {
      const orgRes = await getOrgV2(userQueryResults.data?.default_org_info?.org_id);
      // whenever the default org changes, update the role in user doc to the current valid effective role
      updateUserDocRole(orgRes.effective_role);
      return orgRes;
    },
    enabled: orgQueryResultsEnabled,

    refetchOnWindowFocus: false,
  });

  // Org list query
  const orgsListQueryResults = useApiQuery({
    queryKey: [
      userOrgContextQueryKey,
      { userId: firebase.auth().currentUser?.uid, type: UserOrgContextSubQueryKeys.ORGS_LIST },
    ],
    queryFn: async () => listOrgsV2(),
    enabled: userQueryResults.isSuccess,
    refetchOnWindowFocus: false,
  });

  // TODO: potentially sort on backend
  const sortedOrgsList = React.useMemo(
    () => orgsListQueryResults.data?.orgs.sort((a, b) => a.name.localeCompare(b.name)),
    [orgsListQueryResults.data],
  );

  // Helper functions to allow elements further down the stack to easily request refreshes of the context
  // While react-query allows this via invalidation, it requires everything down the stack to be "smart"
  // so we encapsulate some of the complexities here
  const invalidateUserOrgQuery = React.useCallback(() => {
    // log for debugging purposes, as failures to refresh context can lead to complex issues
    console.log("userOrgContext: refreshing org membership");
    return queryClient.invalidateQueries({
      queryKey: [userOrgContextQueryKey, { type: UserOrgContextSubQueryKeys.USER_INFO }],
    });
  }, [queryClient]);

  const invalidateDefaultOrgQuery = React.useCallback(() => {
    // log for debugging purposes, as failures to refresh context can lead to complex issues
    console.log("userOrgContext: refreshing default org");
    return queryClient.invalidateQueries({
      queryKey: [userOrgContextQueryKey, { type: UserOrgContextSubQueryKeys.DEFAULT_ORG }],
    });
  }, [queryClient]);

  const invalidateOrgListQuery = React.useCallback(() => {
    // log for debugging purposes, as failures to refresh context can lead to complex issues
    console.log("userOrgContext: refreshing org list");
    return queryClient.invalidateQueries({
      queryKey: [userOrgContextQueryKey, { type: UserOrgContextSubQueryKeys.ORGS_LIST }],
    });
  }, [queryClient]);

  const getMemberBuilderEnabled = () => {
    if (localStorage.getItem(MEMBER_BUILDER_ENABLED) !== undefined) {
      return localStorage.getItem(MEMBER_BUILDER_ENABLED) === "true";
    } else {
      return userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.member_builder_enabled
        : true;
    }
  };

  // Construct the context value
  const userOrgContextValue: IUserOrgContext = {
    loading: userQueryResults.isPending,
    isDefaultOrgOwnerAdmin:
      userQueryResults.isSuccess &&
      [EffectiveRole.ORG_OWNER, EffectiveRole.ORG_ADMIN].includes(
        userQueryResults.data?.default_org_info?.effective_role,
      ),
    effectiveRole:
      userQueryResults.data?.default_org_info?.effective_role ?? EffectiveRole.HUB_MEMBER,
    defaultOrgPrimaryLogo:
      userQueryResults.data?.default_org_info?.logo_url || getLiveUserDocMain().brandingLogoUrl,
    defaultOrgSecondaryLogo:
      userQueryResults.data?.default_org_info?.secondary_logo_url ||
      getLiveUserDocMain().brandingSecondaryLogoUrl,
    defaultOrgId: userQueryResults.data?.default_org_info?.org_id ?? undefined,
    defaultOrgName: userQueryResults.data?.default_org_info?.org_name,
    defaultOrgDisableAiFeedback:
      userQueryResults.data?.default_org_info?.disable_ai_feedback ?? false,
    userInOrg: !userQueryResults?.isFetchedAfterMount
      ? undefined
      : userQueryResults.isSuccess && !!userQueryResults.data?.default_org_info?.org_id,
    userInCoachOrganization:
      userQueryResults.isSuccess &&
      userQueryResults.data?.default_org_info?.org_type === OrgCustomerType.COACH,
    userInEnterpriseOrganization:
      userQueryResults.isSuccess &&
      userQueryResults.data?.default_org_info?.org_type === OrgCustomerType.ENTERPRISE,
    isAnyOrgOwnerAdmin: userQueryResults.isSuccess && userQueryResults.data.show_org_admin_ux,
    logOutEverywhereEnforced: LOGOUT_EVERYWHERE_ENFORCED_ORG_IDS.includes(
      userQueryResults.data?.default_org_info?.org_id,
    ),
    defaultOrgSharingVisibility:
      userQueryResults.data?.default_org_info?.default_sharing_visibility ??
      OrgSharingVisibilityEnum.Private,
    liveEventScaling: userQueryResults.data?.default_org_info?.live_event_scaling ?? false,

    orgModuleAccess: {
      interviewEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.interview_enabled
        : true,
      zoodliForUsersEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.zoodli_for_users_enabled
        : true,
      presentationEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.presentation_enabled
        : true,
      roleplayEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.roleplay_enabled
        : true,
      desktopAppEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.desktop_app_enabled
        : true,
      pronunciationFeedbackEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.pronunciation_feedback_enabled
        : true,
      memberBuilderEnabled: getMemberBuilderEnabled(),
      videoUploadEnabled: userQueryResults.isSuccess
        ? userQueryResults.data.feature_flags.video_upload_enabled
        : true,
    },

    adminInfo: {
      defaultOrg:
        userQueryResults?.data?.default_org_info?.org_id && isCurrentAdmin
          ? orgQueryResults.data
          : undefined,
      defaultOrgLoading:
        orgQueryResultsEnabled &&
        (orgQueryResults.isPending ||
          orgQueryResults.isRefetching ||
          userQueryResults.isRefetching),
      defaultOrgRefetching: orgQueryResults.isRefetching,
    },

    adminOrgList: sortedOrgsList?.filter((org) =>
      [EffectiveRole.ORG_OWNER, EffectiveRole.ORG_ADMIN].includes(org.effective_role),
    ),
    fullOrgList: orgsListQueryResults.data?.orgs,
    orgListLoading: orgsListQueryResults.isPending,
    userOrgProfile,
    setUserOrgProfile: handleSetUserOrgProfile,
    userOrgRole,
    setUserOrgRole: handleSetUserOrgRole,

    invalidateDefaultOrgQuery,
    invalidateOrgListQuery,
    invalidateUserOrgQuery,

    emailVerified,
  };

  return <UserOrgContext.Provider value={userOrgContextValue}>{children}</UserOrgContext.Provider>;
}
