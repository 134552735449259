/**
 * ReliableTimer: time object that's meant to keep accurate time execute callbacks
 * for useState setter's.
 */
export class ReliableTimer {
  readonly targetTimeS: number;
  private readonly timerIncrementS: number = 0.1;
  private _timeAtLastCallMS: number;
  private readonly onRunningChanged;
  private readonly onTimeElapsedSChanged;
  private readonly onFinished;
  private readonly ctx: AudioContext;
  private readonly silence;
  private readonly onInterval;
  private readonly intervalS: number;

  /**
   * @param targetTimeS If positive, the target time. If less than 0, defaults to the internal standard (0.1)
   */
  constructor(
    targetTimeS: number,
    opts: {
      onRunningChanged?: (running: boolean) => void;
      onTimeElapsedSChanged?: (timeElapsedS: number) => void;
      onFinished?: () => void;
      onInterval?: () => void;
      intervalS?: number;
    } = {},
  ) {
    this.targetTimeS = targetTimeS;
    this.onRunningChanged = opts?.onRunningChanged;
    this.onTimeElapsedSChanged = opts?.onTimeElapsedSChanged;
    this.onFinished = opts?.onFinished;
    this.onInterval = opts?.onInterval;
    this.intervalS = opts?.intervalS || 1;

    if (this.intervalS <= 0) {
      throw Error("Interval must be positive");
    }
    if (this.intervalS < this.timerIncrementS) {
      this.timerIncrementS = this.intervalS;
    }

    // we're using the timer on the web audio api
    // this is a good explainer of it: https://sonoport.github.io/web-audio-clock.html
    // the reason we use this is because it does not get paused when the tab is unfocused like setTimeout/raf/etc do
    // ts-ignore is added because webkitAudioContext does not exist under Jest environment and TS would fail.
    // (ref. https://stackoverflow.com/a/55622311/2615326)
    // @ts-ignore
    this.ctx = new (window.AudioContext || window.webkitAudioContext)(); // safari sux
    this.silence = this.ctx.createGain();
    this.silence.gain.value = 0;
    this.silence.connect(this.ctx.destination);
    this.reset();
  }

  private _timeElapsedS: number;

  // Read-only public accessors
  get timeElapsedS(): number {
    return this._timeElapsedS;
  }

  private _running: boolean;

  get running(): boolean {
    return this._running;
  }

  private _finished: boolean;

  get finished(): boolean {
    return this._finished;
  }

  // Public interface for operating timer.
  start(): void {
    this.turnTimerOn();
  }

  stop(): void {
    // like 'pause+reset'
    this.setRunning(false);
    this.reset();
  }

  restart(): void {
    this.setTimeElapsedS(0); // set here to trigger callback
    this.stop();
    this.start();
  }

  pause(): void {
    this.setRunning(false);
  }

  unpause(): void {
    this.turnTimerOn();
  }

  toggle(): void {
    // play, pause, or unpause, depending.
    if (this._finished) return;
    if (this._running) {
      this.pause();
    } else {
      this.unpause();
    }
  }

  setTimeElapsedS(timeElapsedS: number): void {
    if (this.timeElapsedS === timeElapsedS) return;
    this._timeElapsedS = timeElapsedS;
    if (this.targetTimeS > 0 && this._timeElapsedS >= this.targetTimeS) {
      // Timer's up!
      this._timeElapsedS = this.targetTimeS; // Even if we overshoot, don't admit it :)
      this.setRunning(false);
      this.setFinished(true);
    }
    if (this.onTimeElapsedSChanged) this.onTimeElapsedSChanged(this._timeElapsedS);
  }

  // Private setters that initiate callbacks.
  private setRunning(running: boolean): void {
    if (this.running === running) return;
    this._running = running;
    if (this.onRunningChanged) this.onRunningChanged(this._running);
  }

  private setFinished(finished: boolean): void {
    if (this._finished === finished) return;
    this._finished = finished;
    if (this.onFinished) this.onFinished();
  }

  private audioTimeout(callback: () => void, frequencyS: number): void {
    const osc = this.ctx.createOscillator();
    osc.onended = callback;
    osc.connect(this.silence);
    osc.start(this.ctx.currentTime);
    osc.stop(this.ctx.currentTime + frequencyS);
  }

  // Start-timer using setTimeout's.
  private turnTimerOn() {
    this.ctx.resume().catch(console.error);
    if (this.running) {
      throw Error("`running` already is true");
    }
    this.setRunning(true);
    this._timeAtLastCallMS = Date.now();
    let lastIntervalTime = 0;

    const timerCallback = () => {
      if (!this._running) {
        return;
      } else {
        // Tally any elapsed time.
        const newNow = Date.now();
        const timePassedMS = newNow - this._timeAtLastCallMS;
        this._timeAtLastCallMS = newNow;
        this.setTimeElapsedS(this._timeElapsedS + timePassedMS / 1000);
        let intervalDiff = 0;

        // Call interval callback if needed.
        if (this.onInterval && this._timeElapsedS - lastIntervalTime >= this.intervalS) {
          this.onInterval();
          lastIntervalTime = this._timeElapsedS - (this._timeElapsedS % this.intervalS); // Round down to nearest interval.
          intervalDiff = this._timeElapsedS - lastIntervalTime; // amount of time we were late
        }

        // Shorten the next interval if needed so we don't overshoot
        // the target time.
        let nextInterval: number;
        if (this.targetTimeS > 0) {
          nextInterval = Math.min(this.targetTimeS - this._timeElapsedS, this.timerIncrementS);
        } else {
          nextInterval = this.timerIncrementS;
        }

        if (this.intervalS && this.onInterval) {
          nextInterval = Math.min(nextInterval, this.intervalS - intervalDiff); // try to catch up
        }

        // One final check, then we initiate next timer.
        if (this._running) this.audioTimeout(timerCallback.bind(this), nextInterval);
      }
    };

    this.audioTimeout(timerCallback, this.timerIncrementS);
  }

  private reset() {
    // does not trigger callbacks.
    this._timeElapsedS = 0;
    this._running = false;
    this._finished = false;
  }
}
