/**
 * RUNTYPES definitions for integrations.
 */

import {
  Record,
  Static,
  String,
  Boolean,
  Optional,
  Array as RTArray,
  Literal,
  Union,
} from "runtypes";
import { IntegrationTypeEnum } from "lib-fullstack/utils/enums/integrationType";

/**
 * Common base type for all integration instances.
 */
export const IntegrationBase = Record({
  /**
   * Integration ID.
   */
  id: String,
  /**
   * ISO8601 creation timestamp.
   */
  createdDate: String,
  /**
   * User ID of the creator.
   */
  createdBy: String,
  /**
   * ISO8601 modification timestamp.
   */
  modifiedDate: String,
  /**
   * User ID of the last modifier.
   */
  modifiedBy: String,
  /**
   * Descriptive user-supplied integration name.
   */
  name: String,
  /**
   * If true, enable debug-level logging for the integration. This can be used
   * to build the mock test data or enable troubleshooting live operations.
   */
  debugLogging: Boolean,
  /**
   * Key value used for indexing in the "orgIntegrations" collection group.
   */
  externalId: Optional(String),
  /**
   * If specified, users who flow into Yoodli with a verified email address
   * are automatically added to this group.
   */
  autoUserCreationGroupId: Optional(String),
});

/**
 * Common base type for all integration instances.
 */
export type IntegrationBase = Static<typeof IntegrationBase>;

/**
 * Integration type for use in test code.
 */
export const TestIntegration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.Test),
  /**
   * Test integration-specific field.
   */
  testField: Optional(String),
});

/**
 * Integration type for use in test code.
 */
export type TestIntegration = Static<typeof TestIntegration>;

/**
 * Integration type for LTI 1.1.
 */
export const Lti11Integration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.LTI_1_1),
  /**
   * ISO8601 timestamp of the last time the OAuth 1.0 key and secret were modified.
   */
  oauth10KeyAndSecretModifiedDate: Optional(String),
  /**
   * Consumer instance GUID generated by the LMS.
   * Provided by the Yoodli admin configuring the integration.
   */
  consumerInstanceGuid: Optional(String),
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration. This is required so we can validate URLs provided by the LMS
   * server before calling them. This protects against SSRF.
   */
  consumerHostName: Optional(String),
});

/**
 * Integration type for LTI 1.1.
 */
export type Lti11Integration = Static<typeof Lti11Integration>;

/**
 * Integration type for LTI 1.3.
 */
export const Lti13Integration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.LTI_1_3),
  /**
   * ISO8601 timestamp of the last time the OAuth 2.0 key and secret were modified.
   */
  oauth20KeyAndSecretModifiedDate: Optional(String),
  /**
   * Platform ID provided by the LMS. E.g., “https://yoodli-dev.moodlecloud.com”.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformId: Optional(String),
  /**
   * Client ID provided by the LMS. Uniquely identifies the tool to the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  clientId: Optional(String),
  /**
   * Deployment ID provided by the LMS. Indicates a specific deployment of the tool within the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  deploymentId: Optional(String),
  /**
   * Keyset URL for the public keys provided by the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformPublicKeysetUrl: Optional(String),
  /**
   * Access token URL used by the tool to generate access tokens for subsequent API calls to the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformAccessTokenUrl: Optional(String),
  /**
   * Authentication request URL used by the tool to respond to the login request from the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformAuthenticationRequestUrl: Optional(String),
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration. This is required so we can validate URLs provided by the LMS
   * server before calling them. This protects against SSRF.
   */
  platformHostName: Optional(String),
});

/**
 * Integration type for LTI 1.3.
 */
export type Lti13Integration = Static<typeof Lti13Integration>;

/**
 * Integration type for SCORM 1.2.
 */
export const Scorm12Integration = IntegrationBase.extend({
  /**
   * Integration type enum value.
   */
  type: Literal(IntegrationTypeEnum.SCORM_1_2),
  /**
   * Host name allow-list for CSP frame-ancestors policy.
   */
  hostNames: Optional(RTArray(String)),
});

/**
 * Integration type for SCORM 1.2.
 */
export type Scorm12Integration = Static<typeof Scorm12Integration>;

/**
 * Union of all integration types.
 */
export const IntegrationData = Union(
  Lti11Integration,
  Lti13Integration,
  Scorm12Integration,
  TestIntegration,
);

/**
 * Union of all integration types.
 */
export type IntegrationData = Static<typeof IntegrationData>;
