// Components
import CircleIcon from "@mui/icons-material/Circle";
import { Stack, Button, Typography, Box } from "@mui/material";
import UsageItem from "lib-frontend/components/UsageItem";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import { PricingUsageNavPromoCode } from "./PricingUsageNavPromoCode";
import { usePricingUsage } from "hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";
import { PricingModalCtaLocations } from "lib-fullstack/utils/productAnalyticEvents";
import { PRICING_DEFAULT_DISCOUNT_CODE } from "lib-fullstack/utils/constants";

type PricingUsageNavDisplayProps = {
  open: boolean;
  openPricingModal: () => void;
  handleDrawerOpen?: () => void;
  isMobileDrawer?: boolean;
};

export default function PricingUsageNavDisplay({
  open,
  openPricingModal,
  handleDrawerOpen = () => null,
  isMobileDrawer,
}: PricingUsageNavDisplayProps): JSX.Element {
  const { thresholdHit, thresholdAlmostHit, usedSpeeches, usagePlanType, quota } =
    usePricingUsage();
  const promoCode = PRICING_DEFAULT_DISCOUNT_CODE;
  // if in the future we want to go back to the unleash flag driven code, use this instead
  // getEnabledFlag("nav-pricing-promo-code") && getStringPayload("nav-pricing-promo-code");

  const handleOpenPricingModal = () => {
    openPricingModal();
    Instrumentation.logPricingModalViewed(
      PricingModalCtaLocations.SIDE_NAV,
      open ? "UPGRADE" : "",
      window.location.href,
    );
  };
  const renderContent = () => {
    if (open && thresholdHit) {
      return (
        <Stack
          gap={0.5}
          alignItems="center"
          sx={{
            color: getDynamicColor("purple3"),
            width: "100%",
            minWidth: 165,
            fontSize: "11px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>You’ve reached your limit</Typography>
          <Typography
            sx={{ whiteSpace: "break-spaces", fontWeight: 400, maxWidth: "80%", lineHeight: 1.3 }}
          >
            {`Upgrade${
              usagePlanType === UsagePlanType.PRO_V1 ? "" : " to Pro"
            } to get more out of Yoodli`}
          </Typography>
        </Stack>
      );
    }
    return (
      <Stack gap={1.5}>
        <UsageItem
          numUsed={usedSpeeches}
          quota={quota}
          usagePlanType={usagePlanType}
          icon={<CircleIcon sx={{ width: "12px", height: "12px" }} />}
          open={open}
          progressColor={getDynamicColor(thresholdAlmostHit ? "purple3" : "light1")}
          textSx={{
            fontSize: { xs: 12, md: 11 },
            color: getDynamicColor(thresholdAlmostHit ? "purple3" : "light1"),
          }}
          wrapperSx={{
            gap: 1,
            ml: "2px",
          }}
        />
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        background: getDynamicColor(thresholdAlmostHit ? "redErrorLight" : "purple3"),
        borderRadius: "8px",
        mx: 1.5,
        overflow: "hidden",
      }}
    >
      <Stack
        gap={thresholdHit ? 1 : 2}
        onClick={handleDrawerOpen}
        sx={{
          px: 1.5,
          py: 1.5,
          pl: "13px",
          background: getDynamicColor(thresholdAlmostHit ? "redErrorLight" : "purple3"),
          borderRadius: "8px",
          color: getDynamicColor(thresholdAlmostHit ? "purple3" : "light1"),
          fontWeight: 700,
          fontSize: "12px",
          alignItems: "flex-start",
          transition: "background 0.2s ease-out, color 0.2s ease-out",
        }}
      >
        {renderContent()}
        <Button
          variant="gradient"
          onClick={handleOpenPricingModal}
          startIcon={
            <Box sx={{ width: 16, height: 16 }}>
              <UpgradeIcon />
            </Box>
          }
          sx={{
            ".MuiButton-startIcon": {
              mr: !open ? 0 : "8px",
              ml: !open ? 0 : "-4px",
            },
            fontSize: "12px",
            letterSpacing: "0.6px",
            width: !open ? 24 : "100%",
            minWidth: open ? 158 : 0,
            p: open ? 0 : "initial",
            overflow: "hidden",
            whiteSpace: "nowrap",
            height: "24px",
            lineHeight: 0,
            transition: "all 0.2s",
          }}
        >
          {open ? "UPGRADE" : ""}
        </Button>
      </Stack>

      {usagePlanType === UsagePlanType.FREE && promoCode && (
        <PricingUsageNavPromoCode
          open={open}
          isMobileDrawer={isMobileDrawer}
          promoCode={promoCode}
          thresholdAlmostHit={thresholdAlmostHit}
        />
      )}
    </Stack>
  );
}
