import "./wdyr"; // <--- must be first import (Why Did You Render? pkg for tracking unnecessary re-renders)

import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";

// Utils
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { envConfig } from "./utils/Constants";
import * as Sentry from "@sentry/react";
import {
  getSiteId,
  getStaticFullSiteConf,
  pLiveSiteDocsReady,
} from "lib-frontend/utils/LiveSiteDocs";
import { sharedFrontendSentryErrorsToIgnore, getAppVersion } from "lib-fullstack/client_env";
import { sanitizeForLogging } from "lib-fullstack/utils/logging";
import * as LogRocket from "logrocket";
import * as sprintf from "tiny-sprintf";

// Annoying typescript  'module augmentation' needed here,
// see https://mui.com/customization/theming/#custom-variables
declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    // mixins: {
    customToolbar: { minHeight: number };
    // };
  }
}

let logCallbackFpr: (message: string) => void;
export function setLogCallbackFpr(callback: (message: string) => void): void {
  logCallbackFpr = callback;
}

const urlParams = new URLSearchParams(window.location.search);

pLiveSiteDocsReady
  .then(() => {
    document.title = getStaticFullSiteConf().title ?? document.title;
  })
  .catch(console.error);

// "Sentry Environment" helps us to filter errors between different environmens,
//  e.g., only receive alerts on Production errors.
console.log("Environment: " + envConfig.envName);
console.log("Release: " + getAppVersion());
if (envConfig.envName === "local") {
  console.log("Localhost detected; Sentry is disabled.");
} else {
  Sentry.init({
    dsn: "https://c740e80f5939498e8ef097ef42b81a07@o1093573.ingest.sentry.io/6112898",
    integrations: [Sentry.browserTracingIntegration({})],
    environment: envConfig.envName,
    ignoreErrors: sharedFrontendSentryErrorsToIgnore,
    release: getAppVersion(),

    // We do not care performance monitoring by Sentry, thus disabling it.
    // We always exceeded the quota before disabling this and no one cared.
    tracesSampleRate: 0.0,

    beforeSend: (event) => {
      event.extra = event.extra ?? {};
      event.extra["siteId"] = getSiteId("unknown-site-id");

      // Try to attach a logrocket session link to each error.
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        event.extra["LogRocket"] = logRocketSession;
      }

      return event;
    },
  });
}
// Google Tag Manager (https://www.npmjs.com/package/react-gtm-module)
const tagManagerArgs = { gtmId: envConfig.gtmContainerId };
TagManager.initialize(tagManagerArgs);

/**
 * In Production, mute all console output to console.log() and console.error().
 * However, *do* add logs as debug-level breadcrumbs that Sentry can upload.
 *
 * As an exception, allow a "debug=true" query param override if we need logging in the field
 */
let debugLoggingForceEnabled = false;
if (urlParams?.get("debug")?.toLowerCase() === "true") {
  debugLoggingForceEnabled = true;
}

const isLocal = window?.location?.host.includes("localhost:");

const cc = { ...console };
const oldLogFxn = console.log;
// no need to rebind console log if we're running locally (to preserve sourcemap attribution)
if (!isLocal) {
  cc.log = (arg, ...args) => {
    if (envConfig.envName !== "production" || debugLoggingForceEnabled) {
      // if not prod, then log to console; otherwise, is muted.
      oldLogFxn.bind(console)(arg, ...args);
    }
    // Combine all arguments into a single string
    const allArgs = [arg, ...args].map((argument) => {
      if (typeof argument === "string" || argument instanceof String) {
        return argument;
      }
      // JSON-ify non-string arguments
      return JSON.stringify(argument);
    });

    const payload = allArgs.join(", ");

    Sentry.addBreadcrumb({
      category: "console.log",
      level: "debug",
      message: payload,
    });

    logCallbackFpr?.(payload);
  };
}

if (envConfig.envName === "production" && !debugLoggingForceEnabled) {
  // in prod, don't actually print error messages to console; just do the
  //  sentry + logrocket + GA reporting below.
  cc.error = () => {
    return;
  };
}
window.console = cc; // needed to override console.log

if (!isLocal) {
  /** Replace console.error with a custom fxn that:
   * 1) Calls Sentry.captureException and LogRocket.captureException, since
   *    otherwise I think those services only see unhandled exceptions.
   * 2) Logs a 'x_error' event to Google Analytics
   * 3) Creates an error object so that we get a stack trace.
   * When we do (3), we might lose the line number from where it was thrown,
   * so it's probably best to do console.error(new Error(...));
   */
  (() => {
    const oldErrorFxn = console.error;
    const reportError = (error: Error, message?: string) => {
      Sentry.captureException(error);
      LogRocket.captureException(error);
      if (message) {
        logCallbackFpr?.(message);
      }
    };
    console.error = function (err, ...args) {
      // First, create a complete error message from all arguments
      const allArgs = [err, ...args].map((arg) => {
        if (arg instanceof Error) {
          return arg.message || arg.toString();
        }
        if (typeof arg === "string" || arg instanceof String) {
          return sanitizeForLogging(arg.toString());
        }
        return JSON.stringify(arg);
      });

      const fullErrorMessage = allArgs.join(" ");

      // Now handle based on whether the first argument was an Error
      if (err instanceof Error) {
        // Create a new error object instead of modifying the original to avoid
        // read-only property error.
        const enrichedError = new Error(
          `${err.message}${args.length ? ` | Additional context: ${fullErrorMessage}` : ""}`,
        );
        // Copy over the stack trace if it exists
        enrichedError.stack = err.stack;
        // Copy any other properties from the original error
        Object.assign(enrichedError, err);
        reportError(enrichedError);
      } else if (
        typeof err === "string" &&
        /%[sdifjoOc]/.test(err) && // Only treat as format string if it has actual format specifiers
        args.length > 0 // And has arguments to format
      ) {
        // attempt sprintf formatting, otherwise fall back to basic concatenated message
        try {
          const sprintfMessage = sprintf(err, ...args);
          const err2 = new Error(sprintfMessage.substring(0, 1000));
          reportError(err2, sprintfMessage);
        } catch {
          // If sprintf fails, fall back to concatenated message
          const err2 = new Error(fullErrorMessage.substring(0, 1000));
          reportError(err2, fullErrorMessage);
        }
      } else {
        // Regular case - use concatenated message
        const err2 = new Error(fullErrorMessage.substring(0, 1000));
        reportError(err2, fullErrorMessage);
      }
      oldErrorFxn.bind(console)(err, ...args);
    };
  })();
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
