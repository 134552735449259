import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { UPLOAD_WIDTH } from "./CoachBotUploadContent";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getAcceptedFileTypes } from "utils/orgContentUtils";

type CoachBotUploadDropzoneProps = {
  numFilesLeft: number;
  handleUploadContent: (files: File[]) => void;
};

export const CoachBotUploadDropzone = ({
  numFilesLeft,
  handleUploadContent,
}: CoachBotUploadDropzoneProps): JSX.Element => {
  const maxFilesReached = numFilesLeft <= 0;
  const fileTypes = getAcceptedFileTypes();
  const acceptsString = fileTypes.map((x) => x.mime).join(",");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => handleUploadContent(acceptedFiles),
    accept: acceptsString,
    maxFiles: numFilesLeft,
  });

  const fileInputRef = React.useRef(null);

  return (
    <Stack
      gap={1}
      alignItems="center"
      {...getRootProps()}
      onClick={undefined}
      sx={{
        borderRadius: "20px",
        border: {
          xs: "none",
          md: `2px dashed ${getDynamicColor(isDragActive ? "primary" : "dark3")}`,
        },
        py: { xs: 2, md: 3 },
        width: "100%",
        maxWidth: UPLOAD_WIDTH,
        transition: "all .5s ease",
      }}
    >
      <CloudUploadOutlinedIcon
        sx={{
          width: 24,
          height: 24,
          color: getDynamicColor(isDragActive ? "primary" : "dark4"),
        }}
      />
      <Typography
        sx={{
          fontFamily: "poppins",
          fontSize: "12px",
          color: getDynamicColor(isDragActive ? "primary" : "dark4"),
          transition: "all .5s ease",
        }}
      >
        Drag and drop your files here
      </Typography>
      <Button
        onClick={(e) => {
          if (!maxFilesReached) {
            getRootProps()?.onClick(e);
          }
        }}
        size="small"
        disabled={maxFilesReached}
        variant="outlined"
        sx={{
          height: 32,
          px: 2,
          py: 0.5,
          opacity: 1,
          border: `2px solid ${getDynamicColor("primary")}`,
          borderRadius: "50px",
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 600,
          transition:
            "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease, border .5s ease 0.2s",
        }}
      >
        Add from device
      </Button>
      <input
        type="file"
        accept={acceptsString}
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        {...getInputProps()}
      />
    </Stack>
  );
};
