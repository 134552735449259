import parse from "html-react-parser";
import React from "react";

// Components
import { Divider, Link, Stack, Switch, Typography } from "@mui/material";
import PageContent, { PageContentTitle } from "components/PageContent";
import PricingModal from "components/Pricing/PricingModal";
import { Save40 } from "lib-frontend/components/Save40";

// Utils
import CurrentPayment from "./CurrentPayment";
import CurrentPlan from "./CurrentPlan";
import GoAnnualBanner from "./GoAnnualBanner";
import PlanCard from "./PlanCard";
import TryEnterpriseCta from "./TryEnterpriseCta";
import { usePricingUsage } from "hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { PRICING_EXPERIMENT_CONFIG } from "lib-frontend/utils/PricingData";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { PlanCheckoutResult } from "lib-fullstack/api/planApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";
import { PricingModalCtaLocations } from "lib-fullstack/utils/productAnalyticEvents";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { handlePlanCheckout } from "utils/PricingUtils";

const PlanAndBilling = ({ headerHeight }: { headerHeight: number }): JSX.Element => {
  // Constants
  const {
    thresholdHit,
    usagePlanType,
    usagePlanDuration,
    usedSpeeches,
    usagePlanRenewalDate,
    nextUsagePlanType,
    nextUsagePlanDuration,
    nextUsagePlanStart,
    quota,
  } = usePricingUsage();
  const userDocMain = getLiveUserDocMain();
  const cardsToShow = PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.filter(
    (plan) => plan.type === UsagePlanType.PRO_V1 || plan.type === UsagePlanType.UNLIMITED,
  );
  const currentPlan = React.useMemo(() => {
    return PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards
      .slice()
      .reverse()
      .find((plan) => plan.type === usagePlanType);
  }, [usagePlanType]);
  const annualSavingsAmt = React.useMemo(() => {
    if (!currentPlan?.cost?.annually) {
      const annualCost = PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.find(
        (card) => card.type === UsagePlanType.UNLIMITED && !!card.cost.annually,
      ).cost.annually;
      const monthlyCost = PRICING_EXPERIMENT_CONFIG[userDocMain.pExpVersion].cards.find(
        (card) => card.type === UsagePlanType.UNLIMITED && !!card.cost.monthly,
      ).cost.monthly;

      return (
        (parseInt(monthlyCost.replace(/\D/g, ""), 10) -
          parseInt(annualCost.replace(/\D/g, ""), 10)) *
        12
      );
    }
    return (
      parseInt(currentPlan?.cost?.monthly.replace(/\D/g, "") ?? "0", 10) * 12 -
      parseInt(currentPlan?.cost?.annually.replace(/\D/g, "") ?? "0", 10) * 12
    );
  }, [currentPlan]);

  // State
  const [resetOnCancel, setResetOnCancel] = React.useState(false);
  const handleToggleResetOnCancel = () => setResetOnCancel(!resetOnCancel);
  const [showPricingModal, setShowPricingModal] = React.useState(false);
  const [currentDuration, setCurrentDuration] = React.useState(
    usagePlanDuration === UsagePlanDuration.NONE ? UsagePlanDuration.YEARLY : usagePlanDuration,
  );
  const [goAnnualError, _setGoAnnualError] = React.useState<PlanCheckoutResult | null>(null);
  const [hasPendingPlan, setHasPendingPlan] = React.useState(!!nextUsagePlanType);
  const isAnnual = currentDuration === UsagePlanDuration.YEARLY;
  const isEnterprise = usagePlanType === UsagePlanType.ENTERPRISE;

  React.useEffect(() => {
    setHasPendingPlan(!!nextUsagePlanType);
  }, [nextUsagePlanType, nextUsagePlanDuration, nextUsagePlanStart]);

  // Handlers
  const handleSetCurrentDuration = (duration?: UsagePlanDuration) => {
    setCurrentDuration(
      (duration ?? currentDuration === UsagePlanDuration.MONTHLY)
        ? UsagePlanDuration.YEARLY
        : UsagePlanDuration.MONTHLY,
    );
  };
  const handleTogglePricingModal = (
    open = !showPricingModal,
    copy?: string,
    _hasPendingPlan = !!nextUsagePlanType,
  ) => {
    setHasPendingPlan(_hasPendingPlan);
    setShowPricingModal(open);
    if (open) {
      Instrumentation.logPricingModalViewed(
        PricingModalCtaLocations.PLAN_AND_BILLING,
        copy ?? "UPGRADE",
        window.location.href,
      );
    }
  };
  const handleGoAnnual = async () => {
    try {
      handleSetCurrentDuration(UsagePlanDuration.YEARLY);
      await handlePlanCheckout(usagePlanType, UsagePlanDuration.YEARLY, true);
    } catch (er) {
      console.error(`Error clicking "Go Annual" from PlanAndBilling.tsx ${er}`);
    }
  };

  // Renderers
  const renderGoAnnualBanner = () => {
    if (
      nextUsagePlanDuration === UsagePlanDuration.YEARLY ||
      isAnnual ||
      usagePlanType === UsagePlanType.FREE ||
      usagePlanType === UsagePlanType.ENTERPRISE
    ) {
      return null;
    }
    return (
      <GoAnnualBanner
        annualSavingsAmt={annualSavingsAmt}
        handleGoAnnual={handleGoAnnual}
        goAnnualError={goAnnualError}
        hasPendingPlan={hasPendingPlan}
        handleTogglePricingModal={handleTogglePricingModal}
      />
    );
  };
  const renderTryEnterpriseCta = () => {
    if (isEnterprise) {
      return null;
    }
    return <TryEnterpriseCta />;
  };
  const renderPricingOptionCards = () => {
    if (![UsagePlanType.FREE, UsagePlanType.PRO_V1].includes(usagePlanType)) {
      return null;
    }
    return (
      <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: 2, md: 3 }}>
        {cardsToShow.map((card) => {
          return (
            <PlanCard
              key={card.id}
              data={card}
              usagePlanType={usagePlanType}
              nextUsagePlanType={nextUsagePlanType}
              nextUsagePlanDuration={nextUsagePlanDuration}
              currentDuration={currentDuration}
              handleGoAnnual={handleGoAnnual}
              goAnnualError={goAnnualError}
              isAnnual={card.cost.annually && isAnnual}
              annualSavingsAmt={annualSavingsAmt}
              hasPendingPlan={hasPendingPlan}
              handleTogglePricingModal={handleTogglePricingModal}
            />
          );
        })}
      </Stack>
    );
  };
  const renderPaymentSection = () => {
    if ([UsagePlanType.FREE, UsagePlanType.ENTERPRISE].includes(usagePlanType)) {
      return null;
    }
    return (
      <>
        <PageContentTitle title="Payment" />
        <CurrentPayment
          hasPendingPlan={hasPendingPlan}
          usagePlanRenewalDate={usagePlanRenewalDate}
          usagePlanType={usagePlanType}
          nextUsagePlanType={nextUsagePlanType}
          nextUsagePlanStart={nextUsagePlanStart}
          nextUsagePlanDuration={nextUsagePlanDuration}
          handleToggleResetOnCancel={handleToggleResetOnCancel}
          // if nextPlan exists, use that to check isAnnual, otherwise check the current plan
          isAnnual={(nextUsagePlanDuration ?? usagePlanDuration) === UsagePlanDuration.YEARLY}
        />
      </>
    );
  };

  const renderContactUs = () => {
    return (
      <Typography
        sx={{
          color: getDynamicColor("dark6"),
          fontWeight: 500,
          mx: 1,
          my: 2,
        }}
      >
        Questions?{" "}
        {isEnterprise
          ? "Contact your organization admin."
          : parse(
              `Check out our <a style="font-weight: 500; color: ${getDynamicColor(
                "purple3",
              )} !important;" target="_blank" href="${getLandingPageExternalUrl(
                getClientEnvConfig(),
                LandingPageExternalPath.PRICING,
              )}#pricing-faq">Billing FAQ</a>.`,
            )}{" "}
        {!isEnterprise && (
          <>
            Something not right?{" "}
            <Link
              href={WebServerExternalPath.SUPPORT}
              target="_blank"
              sx={{
                fontWeight: 500,
                color: `${getDynamicColor("purple3")} !important`,
                textDecoration: "underline",
              }}
            >
              Contact us!
            </Link>
          </>
        )}
      </Typography>
    );
  };
  const renderDurationToggle = () => {
    if (usagePlanType !== UsagePlanType.FREE) {
      return null;
    }

    if (!PRICING_EXPERIMENT_CONFIG[getLiveUserDocMain().pExpVersion].toggleAnnualMonthly) {
      return (
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            fontFamily: "poppins",
            p: 1,
            px: 1.5,
            height: "fit-content",
            width: "fit-content",
            mx: "auto",
            lineHeight: 1,
            borderRadius: "4px",
            backgroundColor: getDynamicColor("greenSuccessLight"),
            color: getDynamicColor("greenWarning"),
          }}
        >
          Save 50% with growth mode!
        </Typography>
      );
    }

    return (
      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: getDynamicColor(
                currentDuration === UsagePlanDuration.MONTHLY ? "primary" : "purple3",
              ),
            }}
          >
            Billed monthly
          </Typography>
          <Switch
            checked={currentDuration === UsagePlanDuration.YEARLY}
            onClick={() => handleSetCurrentDuration()}
            size="medium"
          />

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: getDynamicColor(
                currentDuration === UsagePlanDuration.YEARLY ? "primary" : "purple3",
              ),
            }}
          >
            Billed annually
          </Typography>
        </Stack>
        <Save40 isAnnual={isAnnual} />
      </Stack>
    );
  };
  return (
    <PageContent
      wrapperSx={{
        backgroundColor: getDynamicColor("light1"),
        minHeight: {
          xs: `calc(100vh - ${headerHeight + parseInt(WEBCLIENT_TOP_NAVBAR_HEIGHT, 10)}px)`,
          md: `calc(100vh - ${headerHeight}px)`,
        },
      }}
    >
      <Stack
        gap={3}
        sx={{
          px: { xs: 2, md: 4 },
          py: 4,
          height: "100%",
          maxWidth: "xl",
        }}
      >
        <CurrentPlan
          handleTogglePricingModal={handleTogglePricingModal}
          usedSpeeches={usedSpeeches}
          usagePlanType={usagePlanType}
          usagePlanDuration={usagePlanDuration}
          thresholdHit={thresholdHit}
          quota={quota}
        />
        {renderDurationToggle()}
        {renderPricingOptionCards()}
        {renderGoAnnualBanner()}
        {renderTryEnterpriseCta()}

        <Divider
          sx={{
            width: "100vw",
            position: "relative",
            left: -32,
            mt: 2,
          }}
        />

        {renderPaymentSection()}
        {renderContactUs()}
      </Stack>
      <PricingModal
        open={showPricingModal}
        onClose={() => handleTogglePricingModal(false)}
        hasPendingPlan={hasPendingPlan}
        annualSavingsAmt={annualSavingsAmt}
        handleGoAnnual={handleGoAnnual}
      />
    </PageContent>
  );
};

export default PlanAndBilling;
