import { useMutation } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createCoachBotContentV2 } from "lib-frontend/modules/axiosOrgCoachBot";
import React from "react";
import { useCoachBotContentQuery } from "./useCoachBotContentQuery";

type UseAddContentToCoachbotProps = {
  botId: string;
};

type UseAddContentToCoachbotResponse = {
  handleAddContentToCoachbot: (fileIds: string[]) => void;
};

export const useAddContentToCoachbot = ({
  botId,
}: UseAddContentToCoachbotProps): UseAddContentToCoachbotResponse => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const { refetchCoachBotContentQuery } = useCoachBotContentQuery(botId);

  const addContentToCoachbotMutation = useMutation({
    mutationFn: (fileIds: string[]) =>
      createCoachBotContentV2(defaultOrgId, botId, {
        file_ids: fileIds,
        space_id: curSpaceId,
      }),
    onSuccess: () => {
      return refetchCoachBotContentQuery();
    },
  });

  return {
    handleAddContentToCoachbot: addContentToCoachbotMutation.mutate,
  };
};
