import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";

// Components
import { makeSimplePopper } from "../../ui/SimplePopper";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import LinkIcon from "@mui/icons-material/Link";
// UI Utilities/Functions
import {
  Button,
  Box,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { IOSSwitch } from "components/Settings/Settings";
import { ShareSpeechByEmail } from "components/VideoSummary/ShareSpeechByEmail";

// Utils
import { classes as shareClasses, ShareSpeechWindowWrapper } from "./ShareSpeechWindowStyles";
import { useOnGet } from "@typesaurus/react";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { markOnboardingTaskCompleteAndEmitEvent } from "lib-frontend/utils/onboardingUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import {
  EventsKeyActionHows,
  KeyActionAnalyticsEvents,
} from "lib-fullstack/utils/productAnalyticEvents";
import { generateSpeechShareFullLink } from "utils/Utilities";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useShareSpeechMutation } from "./SpeechSummary/useShareSpeechMutation";

export const HAS_SEEN_SHARE_SPEECH_WINDOW = "share-window-seen"; // Local storage var of type string. Key and value need to be of same type in local storage

export const AGGREGATE_PERMISSIONS = {
  ALL: "Anyone with the link can view",
  NONE: "Only people invited can view",
  ORG: "Only my organization can view",
};

const COMMENTS_VISIBILITY = "Reviewers can view each other's feedback"; // Comments visibility is default on

const ShareSpeechSwitch = (props) => {
  return <IOSSwitch {...props} sx={{ transform: "scale(0.9)", "&.MuiSwitch-root": { m: 0 } }} />;
};

type ShareSpeechWindowProps = {
  dbSpeechRef: db.Ref<db.Speech>;
  onClose?: (MouseEvent?) => void;
  open: boolean;
  prePopulateShareEmails?: string[];
};

const ShareSpeechWindow = ({
  dbSpeechRef,
  onClose,
  open,
  prePopulateShareEmails,
}: ShareSpeechWindowProps): React.ReactElement => {
  const isSmallScreen = useIsSmallScreen();
  const [dbSpeech, dbSpeechMeta] = useOnGet(dbSpeechRef);
  const { userInOrg } = React.useContext(UserOrgContext);

  const linkSharingState = React.useMemo(() => {
    if (!dbSpeech) {
      return AGGREGATE_PERMISSIONS.NONE;
    }
    if ((dbSpeech && dbSpeech.data.linkSharing) || dbSpeech.data.linkSharing === undefined) {
      return AGGREGATE_PERMISSIONS.ALL;
    }
    if (dbSpeech && dbSpeech.data.orgLinkSharing) {
      return AGGREGATE_PERMISSIONS.ORG;
    }
    return AGGREGATE_PERMISSIONS.NONE;
  }, [dbSpeech]);

  const commentsVisibility = dbSpeech?.data?.commentsVisibility ?? true;

  React.useEffect(() => {
    if (dbSpeechMeta.error) {
      onClose();
    }
  }, [dbSpeechMeta.error]);

  const copyLinkPopper = makeSimplePopper("Link copied!");
  const handleCopyLinkButtonClicked = (event) => {
    const link = generateSpeechShareFullLink(dbSpeech.data);
    copyToMyClipboard(link);
    Instrumentation.logKeyAction(KeyActionAnalyticsEvents.SHARE_SPEECH, {
      link,
      userId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
      slug: dbSpeech.data.slug,
      how: EventsKeyActionHows.SHARE_LINK,
    });
    copyLinkPopper.handleClick(event);
    markOnboardingTaskCompleteAndEmitEvent(db.OnboardingChecklistTask.SHARE_SPEECH).catch((err) =>
      console.error(`Error marking onboarding task SHARE_SPEECH completed: ${err}`),
    );
  };

  const handleAggPermissionsChange = async (event) => {
    const newAggPermission = event.target.value;
    shareSpeechMutation.mutate({
      speechId: dbSpeech.ref.id,
      sharePermissions: newAggPermission,
      shareWithEvaluators: false,
    });
  };

  const handleCommentsVisChange = async (event) => {
    const newCommentsPermission = event.target.checked;

    await db.update(dbSpeech.ref, {
      commentsVisibility: newCommentsPermission,
    });
  };

  if (open) {
    localStorage.setItem(HAS_SEEN_SHARE_SPEECH_WINDOW, "true");
  }

  const shareSpeechMutation = useShareSpeechMutation();

  return (
    <ShareSpeechWindowWrapper onClose={onClose} open={open}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: { xs: "100%", md: "500px" },
          }}
        >
          <Typography className={shareClasses.headerLabel}>Share Speech</Typography>

          <IconButton onClick={onClose} className={shareClasses.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Divider />

      {dbSpeech ? (
        <React.Fragment>
          <Box px={{ xs: 1, md: 4 }} py={{ xs: 1, md: 3 }}>
            <Typography sx={{ fontWeight: 600, fontSize: 14, mb: { xs: 0, md: 2 } }}>
              Add emails. Press enter to submit
            </Typography>
            <ShareSpeechByEmail
              dbSpeech={dbSpeech}
              onClose={onClose}
              open={open}
              prePopulateShareEmails={prePopulateShareEmails}
            />
          </Box>

          <div className={shareClasses.privacySettingsContainer}>
            <Typography sx={{ fontWeight: 700, fontSize: 14, mb: isSmallScreen ? 0 : 2 }}>
              Customize and copy a shareable link
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: { xs: "initial", md: "center" },
                flexDirection: isSmallScreen ? "column" : "row",
                gap: isSmallScreen ? 0 : 2,
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  style={{ fontWeight: 600, fontSize: 14, color: getDynamicColor("dark4") }}
                >
                  Privacy Settings
                </Typography>
                <FormControl variant="standard">
                  <div className={shareClasses.formControl}>
                    <Select
                      value={linkSharingState}
                      onChange={(event) => handleAggPermissionsChange(event)}
                      sx={{ fontSize: "0.9rem" }}
                    >
                      <MenuItem value={AGGREGATE_PERMISSIONS.ALL}>
                        {AGGREGATE_PERMISSIONS.ALL}
                      </MenuItem>

                      <MenuItem value={AGGREGATE_PERMISSIONS.NONE}>
                        {AGGREGATE_PERMISSIONS.NONE}
                      </MenuItem>

                      {userInOrg && (
                        <MenuItem value={AGGREGATE_PERMISSIONS.ORG}>
                          {AGGREGATE_PERMISSIONS.ORG}
                        </MenuItem>
                      )}
                    </Select>
                  </div>
                </FormControl>
              </Box>

              <Button
                onClick={handleCopyLinkButtonClicked}
                variant="outlined"
                size="large"
                className={shareClasses.copyLinkButton}
                startIcon={<LinkIcon />}
              >
                <Typography sx={{ fontSize: "0.9rem", color: getDynamicColor("purple3") }}>
                  Copy Link
                </Typography>
              </Button>
            </Box>

            <div style={{ width: "100%", flexBasis: "" }}>
              <Stack direction="column" spacing={2}>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  py={1}
                  gap={2}
                >
                  <Typography sx={{ fontSize: "0.9rem" }}>{COMMENTS_VISIBILITY}</Typography>
                  <FormControlLabel
                    sx={{ justifyContent: "flex-end", marginRight: "0px" }}
                    control={
                      <ShareSpeechSwitch
                        checked={commentsVisibility}
                        onChange={handleCommentsVisChange}
                      />
                    }
                    label=""
                  />
                </Stack>
              </Stack>
            </div>

            {copyLinkPopper.el}
          </div>
        </React.Fragment>
      ) : (
        <CircularProgress />
      )}
    </ShareSpeechWindowWrapper>
  );
};

export default ShareSpeechWindow;
