import { RTA_MESSAGES } from "lib-fullstack/utils/realTimeAlertsUtils";
import { db } from "lib-fullstack";
import {
  CorrelationResult,
  MostSentenceStarter,
  PoodliAlertCounts,
} from "lib-fullstack/api/apiTypes";
import { sliceCategory } from "lib-fullstack/utils/metricCorrelation";

export const formatPoodliAlertsInsightText = (
  poodliAlerts: PoodliAlertCounts,
  numDays: number,
): string => {
  if (!poodliAlerts || !numDays) return null;

  const offenseAlerts = {
    [db.PoodliAlertStatuses.MONOLOGUING]: poodliAlerts?.MONOLOGUING,
    [db.PoodliAlertStatuses.PACING_FAST]: poodliAlerts?.PACING_FAST,
    [db.PoodliAlertStatuses.PACING_SLOW]: poodliAlerts?.PACING_SLOW,
  };
  const highestAlert = Object.keys(offenseAlerts).reduce((a, b) => {
    return offenseAlerts[a] > offenseAlerts[b] ? a : b;
  });

  return poodliAlerts
    ? `You got ${poodliAlerts ? poodliAlerts[highestAlert] : 0} alerts of "${
        RTA_MESSAGES[highestAlert]
      }" in the last ${numDays} days`
    : null;
};

export const formatSentenceStarterInsightText = (
  mostSentenceStarter: MostSentenceStarter,
): string => {
  return mostSentenceStarter
    ? `${mostSentenceStarter.pct}% of your sentences start with "${mostSentenceStarter.word}"`
    : null;
};

export type CelebrityComparison = { speech: db.SampleSpeech; hadAbove: boolean };
export const formatCelebInsightText = (celeb: CelebrityComparison): string => {
  return celeb
    ? `This week you ${
        celeb.hadAbove ? "had a lower filler word percentage than" : "spoke most like"
      } ${celeb.speech.name}`
    : null;
};

const upperCaseFirst = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1);

const mapRecorderType: { [key in db.SpeechSource]: string } = {
  LIVE: "the Yoodli website",
  POODLI: "the Yoodli desktop app",
  ZOOM_RECALL: "the Yoodli meeting recorder bot",
  UPLOADED: "a file upload", // should never happen
  CHROME_EXTENSION: "the Yoodli Chrome extension", // should never happen
};

const COPY_TEMPLATE = "{TREND} {SETTING}";
const settings: { [key in sliceCategory]: (category: string) => string } = {
  periodOfDay: (category) => `in the ${category}`,
  dayOfWeek: (category) => `on ${upperCaseFirst(category)}s`,
  contextType: (category) => `in your ${upperCaseFirst(category)}s`, // meeting, 1:1, presentation, interview
  recorderType: (category) =>
    `when you used ${mapRecorderType[category as db.SpeechSource]} to record`,
  interviewVsPractice: (category) =>
    `when you were ${category === "interview" ? "interviewing" : "practicing for an interview"}`,
  presentationVsPractice: (category) =>
    `when you were ${category === "presentation" ? "presenting" : "practicing for a presentation"}`,
  nParticipantsOver4: (category) =>
    `when you were ${
      category === "overFour"
        ? "in a meeting with more than 4 people"
        : "in a meeting with 4 or fewer people"
    }`,
  commonParticipant: (category) => `when you were in a meeting with ${category}`,
};
const trends: { [key in db.AggregateAnalyticTypes]?: (result: CorrelationResult) => string } = {
  filler: (result) =>
    `Your filler word percentage was ${Math.round(Math.abs(result.difference) * 10) / 10}% ${
      result.differenceNStdDevs > 0 ? "higher" : "lower"
    }`,
  weak: (result) =>
    `Your weak word percentage was ${Math.round(Math.abs(result.difference) * 10) / 10}% ${
      result.differenceNStdDevs > 0 ? "higher" : "lower"
    }`,
  repetition: (result) =>
    `Your repetition was ${Math.round(Math.abs(result.difference) * 10) / 10}% ${
      result.differenceNStdDevs > 0 ? "higher" : "lower"
    }`,
  pacing: (result) =>
    `You spoke ${Math.round(Math.abs(result.difference))} wpm ${
      result.differenceNStdDevs > 0 ? "faster" : "slower"
    }`,
  eyeContact: (result) =>
    `Your eye contact percentage was ${Math.round(Math.abs(result.difference) * 10) / 10}% ${
      result.differenceNStdDevs > 0 ? "higher" : "lower"
    }`,
  conciseness: (result) =>
    `You were ${Math.round(Math.abs(result.difference) * 10) / 10}% ${
      result.differenceNStdDevs > 0 ? "less" : "more" // note that this is reversed because backend returns eg 0.9 conciseness but we subtract that from 1 for 10% excess words
    } concise`,
};

export const formatTopCorrelationInsightText = (result: CorrelationResult): string => {
  if (!result) return null;

  const category = result.categoryName;
  const setting = settings[category](result.categoryValue);
  const trend = trends[result.analytic](result);
  return COPY_TEMPLATE.replace("{SETTING}", setting).replace("{TREND}", trend);
};
