import { AxiosInstance, AxiosResponse } from "axios";

// Utilities/Functions
import { db, apiTypes } from "lib-fullstack";
import { RecordingSupportedLanguage } from "lib-fullstack/utils/enums";

type AxiosHeaders = {
  "Content-Type": string;
  Authorization?: string;
};

export async function genericAddRecallZoomBot(
  userRef: db.Ref<db.User>,
  zoomUrl: string,
  joinMethod: db.RecallBotJoinMethod,
  axiosInstance: AxiosInstance,
  language: RecordingSupportedLanguage,
  headers?: AxiosHeaders,
): Promise<AxiosResponse> {
  if (headers) {
    axiosInstance.defaults.headers.common = headers;
  }
  return axiosInstance.post("/zoom/add-bot-to-call", {
    userRefPath: db.getRefPath(userRef),
    zoomUrl,
    joinMethod,
    language,
  } as apiTypes.AddRecallBotRequest);
}

export async function genericRemoveRecallZoomBot(
  botId: string,
  axiosInstance: AxiosInstance,
  headers?: AxiosHeaders,
): Promise<void> {
  if (headers) {
    axiosInstance.defaults.headers.common = headers;
  }
  return axiosInstance.post("/zoom/remove-bot-from-call", {
    botId,
  } as apiTypes.RemoveRecallBotRequest);
}
