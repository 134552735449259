import React from "react";

// Components
import { Box, Checkbox, Stack, Typography } from "@mui/material";

// Utils
import { SpaceAdminsRowData } from "./ManageRole";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

export type SpaceAdminsTableProps = {
  rowData: SpaceAdminsRowData[];
  setRowData: React.Dispatch<React.SetStateAction<SpaceAdminsRowData[]>>;
  searchText: string;
  setSearchText: (text: string) => void;
};

export const SpaceAdminsTable = ({
  rowData,
  setRowData,
  searchText,
  setSearchText,
}: SpaceAdminsTableProps): JSX.Element => {
  const columnHelper = createColumnHelper<SpaceAdminsRowData>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("spaceName", {
        id: "spaceName",
        header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Content space</Typography>,
        cell: (info) => (
          <Stack direction="row" gap={2} sx={{ alignItems: "center", ml: { xs: 1, md: 3 } }}>
            <Checkbox
              checked={!!info.row.original.isAdmin}
              onChange={(event) => {
                setRowData((prev) => {
                  return prev.map((row) => {
                    if (row.spaceId === info.row.original.spaceId) {
                      return { ...row, isAdmin: event.target.checked };
                    } else {
                      return row;
                    }
                  });
                });
              }}
            />
            <Typography>{info.getValue()}</Typography>
          </Stack>
        ),
      }),
    ],
    [rowData],
  );

  const filteredData = React.useMemo(() => {
    if (!searchText) return rowData;
    return rowData.filter((row) => row.spaceName.toLowerCase().includes(searchText.toLowerCase()));
  }, [rowData, searchText]);

  const table = useReactTable({
    data: filteredData,
    columns: columns,
    state: {
      globalFilter: searchText,
    },
    onGlobalFilterChange: setSearchText,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box
      sx={{
        maxHeight: "300px",
        overflowY: "auto",
        border: `1px solid ${getDynamicColor("dark2")}`,
        borderRadius: 2,
      }}
    >
      <table
        style={{
          width: "100%",
          boxShadow: Y_SHADOWS.dark_elevation,
          borderCollapse: "collapse",
          borderRadius: 2,
          borderSpacing: "0px",
        }}
      >
        <thead
          style={{
            backgroundColor: getDynamicColor("light1"),
            borderRadius: "8px",
            height: "40px",
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Box
              component="tr"
              key={headerGroup.id}
              sx={{
                width: "100%",
                borderBottom: `1px solid ${getDynamicColor("dark2")}`,
              }}
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    borderBottom: "1px solid lightgrey",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: getDynamicColor("light1"),
                    borderRadius: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      color: getDynamicColor("dark5"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      mr: 1,
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Stack>
                </th>
              ))}
            </Box>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Box
                component="tr"
                key={row.id}
                sx={{
                  width: "max-content",
                  height: "70px",
                  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  "&:last-of-type": {
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                    borderBottom: "unset",
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </Box>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};
