import React from "react";

// Components
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton, Stack, Typography, Box } from "@mui/material";
import {
  FileLibraryFilterOption,
  SortFilterMenu,
  SortFilterType,
  SortOption,
} from "components/Builder/SortFilterMenu";
import PdfExtractor from "components/PdfExtractor";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalState,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import { YoodliTable } from "lib-frontend/components/YoodliComponents/YoodliTable";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import videoLightbox from "ui/VideoLightbox";

// Utils
import { BulkActionDeleteChip } from "../ManageCoachBotTab/BulkActionDeleteChip";
import { FileUploadProgress } from "../ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/FileUploadProgress";
import { UploadingFile } from "./OrgFileLibrary";
import { useMutation } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createOrgFile, deleteOrgFile, patchOrgFile } from "lib-frontend/modules/axiosOrgFiles";
import { DynamicColorType, getDynamicColor } from "lib-frontend/utils/Colors";
import { getTimeDifference } from "lib-frontend/utils/orgUtils";
import { FileLibraryTableRow, TableRow } from "lib-frontend/utils/sharedTableUtils";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { CopyOrgFileRequest, OrgFileItem } from "lib-fullstack/api/orgFileApiTypes";
import { OrgFileState } from "lib-fullstack/utils/enums";
import { downloadFile, isPreviewableFile, parseFileType } from "utils/orgContentUtils";
import { CreateContentRequestKind } from "lib-fullstack/db";

const FILE_LIBRARY_PAGE_SIZE = 10;

enum FileTableSortOptions {
  NameAsc = "name",
  NameDesc = "-name",
  TypeAsc = "type",
  TypeDesc = "-type",
  LastUpdatedAsc = "updated_date",
  LastUpdatedDesc = "-updated_date",
}

type FileTableProps = {
  files: OrgFileItem[];
  uploadingFiles: UploadingFile[];
  handleCancelFileUpload: (fileId: string) => void;
};

export const FileTable = ({
  files,
  uploadingFiles,
  handleCancelFileUpload,
}: FileTableProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();
  const columnHelper = createColumnHelper<FileLibraryTableRow>();
  const { refetchOrgFilesQuery } = useOrgFilesQuery();

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId, copyContentInfo } = React.useContext(ContentSpacesContext);

  const [searchText, setSearchText] = React.useState<string>("");
  const [filterBy, setFilterBy] = React.useState<SortOption>(undefined);
  const [sortBy, setSortBy] = React.useState<string>(FileTableSortOptions.LastUpdatedDesc);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: FILE_LIBRARY_PAGE_SIZE,
  });
  const [bulkActionFiles, setBulkActionFiles] = React.useState<string[]>([]);
  const [filesToDelete, setFilesToDelete] = React.useState<string[]>([]);
  const [fileToRename, setFileToRename] = React.useState<FileLibraryTableRow>(null);
  const [updatedFileName, setUpdatedFileName] = React.useState<string>("");
  const [pdfUrl, setPdfUrl] = React.useState<string>("");
  const { lightbox, setLightboxURL } = videoLightbox("");

  const filesInUse = React.useMemo(() => {
    return files.filter((file) => file.coach_bot_content_ids.length > 0);
  }, [files]);

  const filterOptions = React.useMemo(() => {
    return [
      { value: FileLibraryFilterOption.All, label: "All" },
      { value: FileLibraryFilterOption.Pdf, label: "PDF" },
      { value: FileLibraryFilterOption.Document, label: "Document" },
      { value: FileLibraryFilterOption.Video, label: "Video" },
      { value: FileLibraryFilterOption.Audio, label: "Audio" },
      { value: FileLibraryFilterOption.Spreadsheet, label: "Spreadsheet" },
      { value: FileLibraryFilterOption.Presentation, label: "Presentation" },
    ];
  }, []);

  // Need to memoize this state update to prevent rerendering the table and unfocusing the textfield
  const handleTextfieldChange = React.useCallback((e) => {
    setUpdatedFileName(e.target.value);
  }, []);

  const renderLastUpdatedColumn = (file: FileLibraryTableRow) => {
    if (file.state === OrgFileState.Uploading) {
      return file.upload_pct !== undefined ? (
        <Box sx={{ maxWidth: 150 }}>
          <FileUploadProgress
            progress={file.upload_pct}
            status={file.state}
            fileId={file.id}
            editingFileId={fileToRename?.id}
            hideCancel={false}
            handleSetEditingFileId={(fileId) => setFileToRename(rows.find((x) => x.id === fileId))}
            handleRemoveFile={async (fileId) => cancelFileUploadMutation.mutate(fileId)}
          />
        </Box>
      ) : (
        <Typography sx={{ color: getDynamicColor("redError") }}>Error uploading file</Typography>
      );
    } else if (file.state === OrgFileState.Error) {
      return (
        <Typography sx={{ color: getDynamicColor("redError") }}>Error processing file</Typography>
      );
    } else {
      return (
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 400,
            color: getDynamicColor("dark5"),
          }}
        >
          {getTimeDifference(file.updated_date)}
        </Typography>
      );
    }
  };

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "File name",
        cell: (info) => {
          const fileName =
            renameFileMutation.isPending && fileToRename?.id === info.row.original.id
              ? updatedFileName
              : info.getValue();
          return (
            <Stack gap="6px">
              {fileToRename?.id === info.row.original.id ? (
                <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
                  <YoodliTextfield
                    value={updatedFileName}
                    onChange={handleTextfieldChange}
                    autoFocus
                    InputProps={{
                      sx: {
                        height: 36,
                        width: { xs: 175, lg: 250 },
                      },
                    }}
                    sx={{
                      width: { xs: 175, lg: 250 },
                    }}
                  />
                  <IconButton
                    onClick={() => renameFileMutation.mutate()}
                    sx={{ color: getDynamicColor("primary") }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Stack>
              ) : (
                <Stack
                  gap={1}
                  direction="row"
                  onClick={() =>
                    isPreviewableFile(info.row.original.type) &&
                    handleViewFile(info.row.original.id)
                  }
                  sx={{
                    alignItems: "center",
                    color: getDynamicColor(getFileNameColor(info.row.original)),
                    cursor: isPreviewableFile(info.row.original.type) ? "pointer" : "default",
                  }}
                >
                  <UploadFileIcon
                    sx={{
                      width: 20,
                      height: 20,
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      maxWidth: { xs: 70, sm: 175, lg: 250 },
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {fileName}
                  </Typography>
                </Stack>
              )}
              {isMediumScreen && (
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "10px",
                    fontWeight: 400,
                    color: getDynamicColor("dark5"),
                  }}
                >
                  Updated: {getTimeDifference(info.row.original.updated_date)}
                </Typography>
              )}
            </Stack>
          );
        },
      }),
      isMediumScreen
        ? null
        : columnHelper.accessor("updated_date", {
            id: "updated_date",
            header: () => "Last updated",
            cell: (info) => renderLastUpdatedColumn(info.row.original),
          }),
      columnHelper.accessor("type", {
        id: "type",
        header: () => (isMediumScreen ? "Type" : "Content type"),
        cell: (info) => (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 400,
              color: getDynamicColor("dark5"),
            }}
          >
            {parseFileType(info.getValue())}
          </Typography>
        ),
      }),
      columnHelper.accessor("id", {
        id: "actions",
        header: () => <></>,
        cell: (info) => (
          <YoodliMenu
            type={YoodliMenuButtonType.Icon}
            icon={<MoreVertIcon />}
            buttonSx={{ border: "none", color: getDynamicColor("dark4") }}
            menuDisabled={!!fileToRename}
            menuItems={[
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Delete
                  </Typography>
                ),
                disabledTooltip: "File is in use",
                type: YoodliMenuItemType.Primary,
                onClick: () => {
                  setFilesToDelete([info.getValue()]);
                },
                disabled: filesInUse.some((file) => file.id === info.getValue()),
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Preview
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => handleViewFile(info.getValue()),
                disabled: !isPreviewableFile(info.row.original.type),
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Rename
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => {
                  setFileToRename(info.row.original);
                  setUpdatedFileName(info.row.original.name);
                },
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Download
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => downloadFile(info.row.original.signed_url),
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Copy to
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: async () => {
                  copyContentInfo.handleIsCopyDrawerOpen(true);
                  copyContentInfo.handleSetCopyContentRequest({
                    copyHandler: async (destinationSpaceId: string) => {
                      const fileRequest: CopyOrgFileRequest = {
                        request_type: CreateContentRequestKind.Copy,
                        space_id: curSpaceId,
                        source_file_id: info.row.original.id,
                        destination_space_id: destinationSpaceId,
                      };
                      await createOrgFile(defaultOrgId, fileRequest);
                      if (curSpaceId === destinationSpaceId) {
                        void refetchOrgFilesQuery();
                      }
                    },
                    content: {
                      name: info.row.original.name,
                      type: "File",
                    },
                  });
                },
              },
            ]}
          />
        ),
      }),
    ].filter(Boolean);
  }, [
    files,
    bulkActionFiles,
    isMediumScreen,
    fileToRename,
    updatedFileName,
    setFileToRename,
    handleTextfieldChange,
  ]);

  const rows: FileLibraryTableRow[] = React.useMemo(() => {
    // Filter files based on search and menu filter
    const filteredFiles = files.filter((file) => {
      return (
        (!searchText || file.name.toLowerCase().includes(searchText.toLowerCase())) &&
        (!filterBy || parseFileType(file.mime_type).includes(filterBy.label))
      );
    });

    // Sort files based on filter
    filteredFiles.sort((a, b) => {
      if (sortBy === FileTableSortOptions.NameAsc) {
        return a.name.localeCompare(b.name);
      } else if (sortBy === FileTableSortOptions.NameDesc) {
        return b.name.localeCompare(a.name);
      } else if (sortBy === FileTableSortOptions.TypeAsc) {
        return a.mime_type.localeCompare(b.mime_type);
      } else if (sortBy === FileTableSortOptions.TypeDesc) {
        return b.mime_type.localeCompare(a.mime_type);
      } else if (sortBy === FileTableSortOptions.LastUpdatedAsc) {
        return a.updated_date.localeCompare(b.updated_date);
      } else if (sortBy === FileTableSortOptions.LastUpdatedDesc) {
        return b.updated_date.localeCompare(a.updated_date);
      }
      return 0;
    });

    // Paginate and map files
    const startIndex = pagination.pageIndex * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return filteredFiles.slice(startIndex, endIndex).map((file) => {
      const fileUploadState = uploadingFiles.find((f) => f.id === file.id);
      return {
        id: file.id,
        name: file.name,
        updated_date: file.updated_date,
        type: file.mime_type,
        signed_url: file.signed_url,
        state: file.state,
        upload_pct: fileUploadState?.progress,
      };
    });
  }, [files, searchText, sortBy, filterBy, pagination, uploadingFiles]);

  const cancelFileUploadMutation = useMutation({
    mutationFn: (fileId: string) => deleteOrgFile(defaultOrgId, fileId, { space_id: curSpaceId }),
    onMutate: (fileId: string) => handleCancelFileUpload(fileId),
    onSuccess: () => {
      return refetchOrgFilesQuery();
    },
  });

  const deleteFileMutation = useMutation({
    mutationFn: () =>
      Promise.all(
        filesToDelete.map((fileId) =>
          deleteOrgFile(defaultOrgId, fileId, { space_id: curSpaceId }),
        ),
      ),
    onSuccess: () => {
      setBulkActionFiles(bulkActionFiles.filter((fileId) => !filesToDelete.includes(fileId)));
      setFilesToDelete([]);
      return refetchOrgFilesQuery();
    },
  });

  const renameFileMutation = useMutation({
    mutationFn: () =>
      patchOrgFile(defaultOrgId, fileToRename.id, { space_id: curSpaceId, name: updatedFileName }),
    onSuccess: () => {
      setFileToRename(null);
      return refetchOrgFilesQuery();
    },
  });

  const handleSelectAllTableItems = () => {
    if (bulkActionFiles.length > 0) {
      setBulkActionFiles([]);
    } else {
      setBulkActionFiles(rows.map((row) => row.id));
    }
  };

  const handleSelectTableItem = (rowId: string) => {
    setBulkActionFiles((oldBulkActionFiles) => {
      if (oldBulkActionFiles.includes(rowId)) {
        return oldBulkActionFiles.filter((fileId) => fileId !== rowId);
      } else {
        return [...oldBulkActionFiles, rowId];
      }
    });
  };

  const handleViewFile = (fileId: string) => {
    const file = files.find((x) => x.id === fileId);
    if (file) {
      if (file.mime_type?.includes("video") || file.mime_type?.includes("audio")) {
        setLightboxURL(file.signed_url);
      } else if (file.mime_type.includes("pdf")) {
        setPdfUrl(file.signed_url);
      } else {
        console.error(`Unsupported file type for viewing: ${file.mime_type}`);
        return;
      }
    } else {
      console.error(`Could not find file with id: ${fileId}`);
      return;
    }
  };

  const getFileNameColor = (file: FileLibraryTableRow): DynamicColorType => {
    if (bulkActionFiles.includes(file.id) && filesInUse.some((f) => f.id === file.id)) {
      return "dark4";
    } else if (isPreviewableFile(file.type)) {
      return "primary";
    } else {
      return "purple3";
    }
  };

  const table = useReactTable({
    data: rows,
    columns: columns,
    state: {
      pagination,
    },
    rowCount: files.length,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  return (
    <>
      <Stack gap={2}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={1}
          justifyContent="space-between"
          sx={{ width: "100%", alignItems: { xs: "flex-end", md: "center" }, px: { xs: 1, md: 0 } }}
        >
          <BulkActionDeleteChip
            bulkActionItems={bulkActionFiles}
            clearBulkActionItems={() => setBulkActionFiles([])}
            disableDeleteAction={
              !!fileToRename || filesInUse.some((file) => bulkActionFiles.includes(file.id))
            }
            handleDelete={() => setFilesToDelete(bulkActionFiles)}
            tooltipText={
              filesInUse.some((file) => bulkActionFiles.includes(file.id))
                ? "Greyed out files in use and can’t be deleted right now."
                : undefined
            }
          />
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              gap: "20px",
              flexWrap: "nowrap",
              ml: "auto",
            }}
          >
            <SortFilterMenu
              sortFilterType={SortFilterType.Filter}
              sortOptions={filterOptions}
              sortBy={filterBy}
              setSortBy={setFilterBy}
            />
            <YoodliSearchBar
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              clearSearch={() => setSearchText("")}
              placeholder="Search Files"
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              sx={{
                minWidth: { xs: "125px", md: "150px" },
                maxWidth: { xs: "unset", sm: "210px" },
                height: "40px",
              }}
            />
          </Stack>
        </Stack>
        <YoodliTable
          table={table as Table<TableRow>} // TODO @danc: How to fix this type issue?
          numRows={rows.length}
          emptyState={
            <Typography
              sx={{
                p: 2,
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 600,
                color: getDynamicColor("dark5"),
              }}
            >
              No files found
            </Typography>
          }
          paginationProps={{
            pagination: pagination,
            setPagination: setPagination,
          }}
          sortProps={{
            sortAvailableColumns: ["name", "updated_date", "type"],
            setSortFilter: setSortBy,
          }}
          bulkActionProps={{
            showBulkAction: true,
            allChecked: bulkActionFiles.length === rows.length,
            someChecked: bulkActionFiles.length > 0,
            onHeaderChecked: handleSelectAllTableItems,
            handleRowItemChecked: handleSelectTableItem,
            isRowItemChecked: (row) => bulkActionFiles.includes(row),
          }}
          dataRowSxOverride={{
            height: { xs: 75, lg: 55 },
          }}
        />
      </Stack>
      <YoodliCtaModal
        open={!!filesToDelete.length}
        ctaBody={{
          title: `Are you sure you want to delete ${
            filesToDelete.length === 1
              ? files.find((file) => file.id === filesToDelete[0]).name
              : "the selected files"
          }?`,
          subtitle: "This will permanently remove it from your library and cannot be undone.",
        }}
        theme={YoodliCtaModalTheme.Danger}
        state={YoodliCtaModalState.Cta}
        close={() => setFilesToDelete([])}
        buttons={
          {
            primary: { text: "Delete", handler: deleteFileMutation.mutate },
            secondary: { text: "Cancel", handler: () => setFilesToDelete([]) },
          } as CtaButtonHandlers
        }
      />
      {lightbox}
      <PdfExtractor pdfUrl={pdfUrl} useDialog onDialogClose={() => setPdfUrl("")} />
    </>
  );
};
