import React from "react";

// Components
import { Box, CircularProgress } from "@mui/material";

// Utils
import { UploadingFile } from "../OrgFileLibrary/OrgFileLibrary";
import { CoachBotSectionStatus } from "./CoachBotLibrary";
import { ManageCoachBotTab } from "./ManageCoachBotTab";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getCoachBot } from "lib-frontend/modules/axiosOrgCoachBot";
import { CoachBotWithScenarioInfo, GetCoachBotResponse } from "lib-fullstack/api/coachBotApiTypes";

type CoachBotWrapperProps = {
  setShouldBlockNav: (shouldBlock: boolean) => void;
  selectedBot: CoachBotWithScenarioInfo;
  setDeleteCoachBotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSectionStatus: React.Dispatch<React.SetStateAction<CoachBotSectionStatus>>;
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  handleCancelFileUpload: (fileId: string) => void;
};

export const CoachBotWrapper = ({
  setShouldBlockNav,
  selectedBot,
  setDeleteCoachBotOpen,
  setSectionStatus,
  uploadingFiles,
  handleUploadContent,
  handleCancelFileUpload,
}: CoachBotWrapperProps): JSX.Element => {
  const {
    defaultOrgId,
    adminInfo: { defaultOrgRefetching },
  } = React.useContext(UserOrgContext);

  const [coachBot, setCoachBot] = React.useState<GetCoachBotResponse>(undefined);
  const [coachBotId, setCoachBotId] = React.useState<string>(undefined);

  React.useEffect(() => {
    if (coachBotId !== coachBot?.botId) {
      setCoachBotId(coachBot?.botId);
    }
  }, [coachBot?.botId]);

  // if i have a local coachBotId but no actual coachBot object, im creating a new bot and should block nav until completion
  // i.e. until the actual coachBot object exists
  React.useEffect(() => {
    setShouldBlockNav(!!coachBotId && !coachBot?.botId);
  }, [coachBotId, coachBot?.botId]);
  React.useEffect(() => {
    const fetchCoachBotData = async () => {
      try {
        const coachBot = await getCoachBot(defaultOrgId, selectedBot.botId);
        if (coachBot?.botId) {
          setCoachBot(coachBot);
        }
      } catch (er) {
        throw new Error(er);
      }
    };
    // refetch coachBot is i change orgs, or if i move from showing to not showing the creation wizard
    if (defaultOrgId) {
      fetchCoachBotData().catch((er) =>
        console.error(`Error fetching coachbot data for org ${defaultOrgId}: ${er}`),
      );
    }
  }, [defaultOrgId, selectedBot]);

  React.useEffect(() => {
    if (defaultOrgRefetching) {
      setSectionStatus(CoachBotSectionStatus.Library);
    }
  }, [defaultOrgRefetching]);

  if (!coachBot) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          minHeight: 300,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ManageCoachBotTab
      orgId={defaultOrgId}
      coachBot={coachBot}
      setCoachBot={setCoachBot}
      scenarioInfo={selectedBot.scenarioInfo}
      styleGuideContent={selectedBot?.styleGuide?.content}
      setDeleteCoachBotOpen={setDeleteCoachBotOpen}
      setSectionStatus={setSectionStatus}
      uploadingFiles={uploadingFiles}
      handleUploadContent={handleUploadContent}
      handleCancelFileUpload={handleCancelFileUpload}
    />
  );
};
