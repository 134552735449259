import React from "react";

// Components
import { Typography, Stack, CircularProgress, Box, TablePagination, Checkbox } from "@mui/material";
import { TableSortServer } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortServer";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import { MemberInfo } from "../Members/MembersTable";
import { OrgMembersQueryKeys } from "../Members/OrgMembers";
import { OrgHubsQueryKeys } from "./OrgHubs";
import {
  useQuery as useApiQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  listOrgInvitesV2,
  actOrgInviteV2,
  deleteOrgInviteV2,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { isHubAdmin, parseHubRole } from "lib-frontend/utils/orgUtils";
import { GetOrgInviteListSortOption, OrgInviteAction } from "lib-fullstack/api/orgApiTypes";
import {
  DEFAULT_ORG_MEMBER_FETCH_COUNT,
  ORG_MEMBER_FETCH_LIMIT,
} from "lib-fullstack/utils/constants";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { EffectiveRole, HubRole } from "lib-fullstack/utils/enums";

type HubInvitesTableRow = {
  email: string;
  date_invited: string;
  role: string;
  action: null;
};

type HubsInviteTableProps = {
  orgId: string;
  hubId: string;
  searchText: string;
  setSearchText: (searchText: string) => void;
  recalcTopContentHeight: () => void;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: React.Dispatch<React.SetStateAction<MemberInfo[]>>;
};

export const HubsInviteTable = ({
  orgId,
  hubId,
  searchText,
  setSearchText,
  recalcTopContentHeight,
  bulkActionUserList,
  setBulkActionUserList,
}: HubsInviteTableProps): JSX.Element => {
  const queryClient = useQueryClient();
  const {
    isDefaultOrgOwnerAdmin,
    effectiveRole,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const { showNotificationBanner } = useNotification();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_ORG_MEMBER_FETCH_COUNT,
  });
  const paginationStart = (pagination.pageIndex * pagination.pageSize).toString();
  const [sortFilter, setSortFilter] = React.useState<string>(
    GetOrgInviteListSortOption.EMAIL_ASC as string,
  );

  const selectedHub = React.useMemo(() => {
    return defaultOrg?.hubs.find((group) => group.id === hubId);
  }, [defaultOrg]);

  const anyFilterEnabled = React.useMemo(() => {
    return searchText?.length > 0;
  }, [searchText]);

  const columnHelper = createColumnHelper<HubInvitesTableRow>();

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0, pageSize: parseInt(event.target.value) };
    });
  };

  const resendInviteMutation = useMutation({
    mutationFn: async (email: string) => {
      await actOrgInviteV2(orgId, email, OrgInviteAction.RESEND);
    },
    onSuccess: (_, email: string) => {
      void queryClient.invalidateQueries({
        queryKey: [OrgHubsQueryKeys.HUB_INVITES, orgId, hubId],
      });
      showNotificationBanner(
        <>
          Resent invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Success },
      );
    },
    onError: (e: Error, email: string) => {
      console.log("Error in resending invite: ", e);
      showNotificationBanner(
        <>
          Failed to resend invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Danger },
      );
    },
  });

  const cancelInviteMutation = useMutation({
    mutationFn: async (email: string) => {
      await deleteOrgInviteV2(orgId, email);
    },

    onSuccess: (_, email) => {
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.HubUserCounts, orgId, hubId],
      });
      return queryClient
        .invalidateQueries({
          queryKey: [OrgHubsQueryKeys.HUB_INVITES, orgId, hubId],
        })
        .then(() => {
          showNotificationBanner(
            <>
              Cancelled invite to <strong>{email}</strong>
            </>,
            { variant: YoodliNotificationBannerVariant.Success },
          );
        });
    },
  });

  const hubInvitesQuery = useApiQuery({
    queryKey: [
      OrgHubsQueryKeys.HUB_INVITES,
      orgId,
      hubId,
      {
        start: paginationStart,
        limit: Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString(),
        sort: searchText
          ? GetOrgInviteListSortOption.EMAIL_ASC
          : ((sortFilter as GetOrgInviteListSortOption) ?? GetOrgInviteListSortOption.EMAIL_ASC),
        prefix: searchText,
      },
    ],
    queryFn: async () =>
      listOrgInvitesV2(orgId, {
        hub_id: hubId,
        start: paginationStart,
        limit: Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString(),
        sort: searchText
          ? GetOrgInviteListSortOption.EMAIL_ASC
          : ((sortFilter as GetOrgInviteListSortOption) ?? GetOrgInviteListSortOption.EMAIL_ASC),
        prefix: searchText,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
  // redefine the invites list whenever the data changes
  const invitesList = React.useMemo(
    () => hubInvitesQuery.data?.invites ?? [],
    [hubInvitesQuery.data?.invites],
  );

  const bulkActionUserEmailSet = new Set(bulkActionUserList.map((user) => user.email));

  React.useEffect(() => {
    recalcTopContentHeight();
  }, [invitesList]);

  const rowData = React.useMemo(
    () =>
      invitesList.map((invite) => ({
        email: invite.email,
        date_invited: invite.date_invited,
        role: parseHubRole(invite.hubs[0].role as HubRole),
        action: null,
      })),
    [invitesList],
  );

  const allChecked = React.useMemo(() => {
    return invitesList.every((member) => bulkActionUserEmailSet.has(member.email));
  }, [rowData, bulkActionUserEmailSet]);

  const someChecked = React.useMemo(() => {
    return invitesList.some((member) => bulkActionUserEmailSet.has(member.email));
  }, [rowData, bulkActionUserEmailSet]);

  const columns = React.useMemo(
    () =>
      [
        columnHelper.accessor("email", {
          id: "email",
          header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Invites</Typography>,
          cell: (info) => (
            <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
              <YoodliAvatar
                name={info.getValue()}
                sx={{
                  ml: { xs: 3, md: 5 },
                  my: 2.5,
                }}
              />
              <Typography
                sx={{
                  fontWeight: 700,
                  fontFamily: "poppins",
                  fontSize: "14px",
                  color: getDynamicColor("purple3"),
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: { xs: "150px", md: "300px" },
                }}
              >
                {info.getValue()}
              </Typography>
            </Stack>
          ),
        }),
        columnHelper.accessor("date_invited", {
          id: "date_invited",
          header: () => <Typography>Invited on</Typography>,
          cell: (info) => (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {getHumanReadableDate(info.getValue(), {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              })}
            </Typography>
          ),
        }),
        columnHelper.accessor("role", {
          id: "role",
          header: () => <>Role</>,
          cell: (info) => (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {info.getValue()}
            </Typography>
          ),
          filterFn: "arrIncludesSome",
        }),
        (effectiveRole !== EffectiveRole.SPACE_ADMIN || isHubAdmin(selectedHub)) &&
          columnHelper.accessor("action", {
            id: "action",
            header: () => <div style={{ width: "36px" }} />,
            cell: (info) => (
              <YoodliMenu
                type={YoodliMenuButtonType.Icon}
                menuItems={[
                  {
                    title: "Resend invite",
                    onClick: () => resendInviteMutation.mutate(info.row.original.email),
                    type: YoodliMenuItemType.Default,
                  },
                  {
                    title: "Cancel invite",
                    onClick: () => cancelInviteMutation.mutate(info.row.original.email),
                    type: YoodliMenuItemType.Default,
                  },
                ]}
              />
            ),
          }),
      ].filter(Boolean),
    [invitesList, rowData, bulkActionUserList],
  );

  const table = useReactTable({
    data: rowData,
    columns: columns,
    state: {
      globalFilter: searchText,
      pagination,
    },
    rowCount: hubInvitesQuery.data?.total,
    onGlobalFilterChange: setSearchText,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const loading =
    hubInvitesQuery.isPending || resendInviteMutation.isPending || cancelInviteMutation.isPending;

  return (
    <Stack
      gap={1}
      sx={{
        height: "100%",
      }}
    >
      {loading ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <Box
          sx={{
            minHeight: invitesList?.length > 4 ? 400 : "unset",
            overflowY: "auto",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderRadius: 2,
          }}
        >
          <table
            style={{
              width: "100%",
              boxShadow: Y_SHADOWS.dark_elevation,
              borderCollapse: "collapse",
              borderRadius: 2,
              borderSpacing: "0px",
            }}
          >
            <thead
              style={{
                backgroundColor: getDynamicColor("light1"),
                borderRadius: "8px",
                height: "40px",
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Box
                  component="tr"
                  key={headerGroup.id}
                  sx={{
                    width: "100%",
                    borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  }}
                >
                  {rowData.length > 0 && isDefaultOrgOwnerAdmin && (
                    <th
                      key="header-checkbox"
                      style={{
                        borderBottom: "1px solid lightgrey",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: getDynamicColor("light1"),
                        borderRadius: 2,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Checkbox
                          checked={allChecked}
                          indeterminate={!allChecked && someChecked}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newBulkActionUserList = [...bulkActionUserList];
                              for (const row of rowData) {
                                if (!bulkActionUserEmailSet.has(row.email)) {
                                  newBulkActionUserList.push({
                                    email: row.email,
                                  });
                                }
                              }
                              setBulkActionUserList(newBulkActionUserList);
                            } else {
                              const newBulkActionUserList = bulkActionUserList.filter(
                                (userList) => {
                                  return !rowData.map((row) => row.email).includes(userList.email);
                                },
                              );
                              setBulkActionUserList(newBulkActionUserList);
                            }
                          }}
                        />
                      </Box>
                    </th>
                  )}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        minHeight: "40px",
                        borderBottom: "1px solid lightgrey",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: getDynamicColor("light1"),
                        borderRadius: 2,
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                          color: getDynamicColor("dark5"),
                          fontFamily: "poppins",
                          fontSize: "12px",
                          fontWeight: 600,
                          mr: 1,
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {["email", "date_invited"].includes(header.id) && (
                          <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />
                        )}
                      </Stack>
                    </th>
                  ))}
                </Box>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Box
                    component="tr"
                    key={row.id}
                    sx={{
                      width: "max-content",
                      borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                      "&:last-of-type": {
                        borderBottomLeftRadius: 2,
                        borderBottomRightRadius: 2,
                        borderBottom: "unset",
                      },
                    }}
                  >
                    {isDefaultOrgOwnerAdmin && (
                      <td
                        key="row-checkbox"
                        style={{
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        <Checkbox
                          checked={bulkActionUserEmailSet.has(row.original.email)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBulkActionUserList([
                                ...bulkActionUserList,
                                {
                                  email: row.original.email,
                                },
                              ]);
                            } else {
                              setBulkActionUserList(
                                bulkActionUserList.filter(
                                  (user) => user.email !== row.original.email,
                                ),
                              );
                            }
                          }}
                        />
                      </td>
                    )}
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </Box>
                );
              })}
              {table.getRowCount() === 0 && !anyFilterEnabled && (
                <tr>
                  <td colSpan={columns.length}>
                    <Stack
                      direction="column"
                      sx={{
                        mx: "auto",
                        width: "100%",
                        color: getDynamicColor("dark4"),
                        minHeight: "250px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>No pending invites</Typography>
                      <Typography sx={{ textAlign: "center" }}>
                        Invite members to join this group using the buttons above
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      )}
      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <TablePagination
            component="div"
            sx={{ m: "auto", fontFamily: "poppins !important" }}
            count={table.getRowCount() ?? 0}
            rowsPerPageOptions={[5, 10, 20, 50]}
            page={pagination.pageIndex}
            onPageChange={(e, pageNum) => handlePageChange(pageNum)}
            rowsPerPage={pagination.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Stack>
    </Stack>
  );
};
