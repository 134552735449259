import React from "react";

// Components
import { SxProps } from "@mui/material";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

export type YoodliNotificationOptions = {
  duration?: number;
  variant?: YoodliNotificationBannerVariant;
  showCloseButton?: boolean;
  showIcon?: boolean;
  heightOffset?: number;
  sx?: SxProps;
};

type YoodliNotification = {
  id: string;
  message: string | JSX.Element;
  componentId: symbol;
  options?: YoodliNotificationOptions;
};

const MAX_DURATION = 60000;

type NotificationContextType = {
  showNotification: (message: string | JSX.Element, options?: YoodliNotificationOptions) => void;
  hideNotification: (instant: boolean) => void;
  notification: YoodliNotification | null;
  registerComponent: () => symbol;
  unregisterComponent: (componentId: symbol) => void;
  notifAnchorHeight: number;
  notifAnchorRef: React.RefObject<HTMLDivElement>;
};

const NotificationContext = React.createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notification, setNotification] = React.useState<YoodliNotification | null>(null);
  const [notifAnchorHeight, setNotifAnchorHeight] = React.useState<number>(0);
  const notifAnchorRef = React.useRef<HTMLDivElement>(null);
  const activeComponentsRef = React.useRef<Set<symbol>>(new Set());

  React.useEffect(() => {
    if (!notifAnchorRef.current) {
      return;
    }
    const updateBannerHeight = () => {
      if (notifAnchorRef.current) {
        const rect = notifAnchorRef.current.getBoundingClientRect();
        setNotifAnchorHeight(rect.top + rect.height + window.scrollY);
      }
    };

    updateBannerHeight(); // Initial position

    window.addEventListener("scroll", () => {
      updateBannerHeight();
      if (notification) {
        setNotification(null);
      }
    });
    const resizeObserver = new ResizeObserver(updateBannerHeight);
    resizeObserver.observe(notifAnchorRef.current);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("scroll", () => {
        updateBannerHeight();
        if (notification) {
          setNotification(null);
        }
      });
    };
  }, [notifAnchorRef.current]);

  const registerComponent = React.useCallback(() => {
    const componentId = Symbol();
    activeComponentsRef.current.add(componentId);
    return componentId;
  }, []);

  const unregisterComponent = React.useCallback((componentId: symbol) => {
    activeComponentsRef.current.delete(componentId);
    // Instantly hide notification if the unregistered component showed it
    setNotification((prev) => (prev && prev.componentId === componentId ? null : prev));
  }, []);

  const showNotification = React.useCallback(
    (message: string, options?: YoodliNotificationOptions) => {
      const componentId = activeComponentsRef.current.values().next().value;
      if (componentId) {
        setNotification({
          id: Date.now().toString(),
          message,
          componentId,
          options: {
            duration: Math.min(3000, MAX_DURATION),
            variant: YoodliNotificationBannerVariant.Success,
            showCloseButton: true,
            showIcon: true,
            ...options,
          },
        });
      }
    },
    [],
  );

  const hideNotification = React.useCallback((instant: boolean) => {
    if (instant) {
      setNotification(null);
    }
    // When not instant, the NotificationBanner component will handle the sliding animation
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        hideNotification,
        notification,
        registerComponent,
        unregisterComponent,
        notifAnchorHeight,
        notifAnchorRef,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = (): NotificationContextType => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};
