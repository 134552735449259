import React from "react";

// Components
import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { CoachBotCard } from "./CoachBotCard";
import { CoachBotWrapper } from "./CoachBotWrapper";
import { SelfServeCoachBotWizard } from "./SelfServeCoachBotWizard/SelfServeCoachBotWizard";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useContentUpload } from "hooks/useContentUpload";
import { useScrollToTop } from "hooks/useScrollToTop";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getAllCoachBots, deleteCoachbot } from "lib-frontend/modules/axiosOrgCoachBot";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { CoachBotWithScenarioInfo } from "lib-fullstack/api/coachBotApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { AnalyticProcessingState } from "lib-fullstack/utils/enums";
import { OrgCoachbotType } from "lib-fullstack/utils/productAnalyticEvents";
import { useAddContentToCoachbot } from "hooks/useAddContentToCoachbot";

type CoachBotLibraryProps = {
  setShouldBlockNav: (shouldBlock: boolean) => void;
};

export enum CoachBotSectionStatus {
  Library = "library",
  Details = "details",
  Wizard = "wizard",
}

export const CoachBotLibrary = ({ setShouldBlockNav }: CoachBotLibraryProps): JSX.Element => {
  const {
    defaultOrgId,
    defaultOrgName,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();

  useScrollToTop();
  const [selectedBot, setSelectedBot] = React.useState<CoachBotWithScenarioInfo>(undefined);
  const [sectionStatus, setSectionStatus] = React.useState<CoachBotSectionStatus>(
    CoachBotSectionStatus.Library,
  );
  const [deleteCoachBotOpen, setDeleteCoachBotOpen] = React.useState<boolean>(false);
  const [coachbotsProcessing, setCoachbotsProcessing] = React.useState<boolean>(false);
  const [scenarioCoachbotFilter, setScenarioCoachbotFilter] = React.useState<string>("");
  const [coachBotId, setCoachBotId] = React.useState<string>(undefined);

  const selectedBotId = selectedBot?.botId ?? coachBotId;

  const { handleAddContentToCoachbot } = useAddContentToCoachbot({ botId: selectedBotId });

  const { uploadingFiles, handleUploadContent, handleCancelFileUpload, handleClearUploadedFiles } =
    useContentUpload({ handleAddNewContent: handleAddContentToCoachbot });

  // Coachbots query
  const { data: coachbotsData, isPending: coachbotsLoading } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrgId, curSpaceId],
    queryFn: async () => {
      const bots = await getAllCoachBots(defaultOrgId, { space_id: curSpaceId });
      const processing = bots?.coachBots.some(
        (bot) =>
          bot.status === AnalyticProcessingState.PROCESSING ||
          bot.status === AnalyticProcessingState.PENDING,
      );
      setCoachbotsProcessing(processing);
      return bots;
    },
    enabled: !!defaultOrgId && !!curSpaceId,
    refetchInterval:
      coachbotsProcessing && sectionStatus !== CoachBotSectionStatus.Details ? 3000 : false,
    refetchOnWindowFocus: false,
  });

  // If an update has occurred and the query has been refetched, ensure that the
  // selected bot is updated to the latest version
  React.useEffect(() => {
    if (selectedBot) {
      setSelectedBot(
        (prev) => coachbotsData?.coachBots.find((bot) => bot.botId === prev.botId) || prev,
      );
    }
  }, [coachbotsData]);

  React.useEffect(() => {
    if (!coachbotsLoading && coachbotsData.coachBots.length === 0) {
      setSectionStatus(CoachBotSectionStatus.Wizard);
    }
  }, [coachbotsData, coachbotsLoading]);

  const defaultCoachbot = coachbotsData?.coachBots.find((bot) => bot.isDefault);
  const scenarioCoachbots = coachbotsData?.coachBots.filter(
    (bot) => !bot.isDefault && bot.name.includes(scenarioCoachbotFilter),
  );

  const { mutateAsync: handleCoachbotDeletion, isPending: coachbotDeletionLoading } = useMutation({
    mutationFn: async (botId: string) => {
      await deleteCoachbot(defaultOrgId, botId);
    },
    onSuccess: async (_, botId) => {
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrgId, curSpaceId],
      });
      if (sectionStatus !== CoachBotSectionStatus.Library) {
        setSectionStatus(CoachBotSectionStatus.Library);
      }
      Instrumentation.logOrgCoachbotDeleted(
        defaultOrgId,
        botId === defaultCoachbot?.botId ? OrgCoachbotType.DEFAULT : OrgCoachbotType.SCENARIO,
      );
    },
    onError: (error) => {
      console.error("Error deleting custom goal: ", error);
    },
  });

  const renderEmptyState = (title: string, subtitle: string) => (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight: 200,
        width: "100%",
        p: 4,
        border: `1px solid ${getDynamicColor("dark3")}`,
      }}
      gap={0.5}
    >
      <Typography sx={{ fontSize: 14, fontWeight: 700, color: getDynamicColor("dark4") }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: 14, color: getDynamicColor("dark4") }}>{subtitle}</Typography>
    </Stack>
  );

  const renderLibraryView = () => (
    <Stack sx={{ fontFamily: "Poppins", p: { xs: 4, md: 8 }, gap: { xs: 4, md: 8 } }}>
      <Stack
        direction="row"
        sx={{ gap: 2, justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}
      >
        <Stack>
          <Typography sx={{ fontWeight: 800, fontSize: { xs: 14, md: 16 } }}>
            Create a Coach Bot
          </Typography>
          <Typography sx={{ fontSize: 12, color: getDynamicColor("purple3"), fontWeight: 600 }}>
            Scale yourself with {defaultOrgName} AI coach!
          </Typography>
        </Stack>
        <Button
          size="xlarge"
          variant="contained"
          onClick={() => {
            setSelectedBot(undefined);
            setSectionStatus(CoachBotSectionStatus.Wizard);
          }}
          sx={{ px: 3 }}
        >
          Create Coach Bot
        </Button>
      </Stack>
      <Divider sx={{ mx: -8 }} />
      <Stack sx={{ gap: 8 }}>
        {curSpaceId === defaultOrg.default_space_id && (
          <Stack sx={{ gap: 3 }}>
            <Typography
              sx={{ fontSize: "16px", color: getDynamicColor("purple3"), fontWeight: 700 }}
            >
              Default Coach Bot
            </Typography>
            {defaultCoachbot ? (
              <CoachBotCard
                botName={defaultCoachbot?.name}
                dateBuilt={getHumanReadableDate(defaultCoachbot?.createdAt)}
                isProcessing={
                  defaultCoachbot?.status === "PROCESSING" || defaultCoachbot?.status === "PENDING"
                }
                isDefault
                handleDelete={() => {
                  setSelectedBot(defaultCoachbot);
                  setDeleteCoachBotOpen(true);
                }}
                handleEdit={() => {
                  setSelectedBot(defaultCoachbot);
                  setSectionStatus(CoachBotSectionStatus.Details);
                }}
              />
            ) : (
              <>
                {renderEmptyState(
                  "You have no default Coach Bot assigned",
                  "Create a default Coach Bot by clicking the “Create Coach Bot” button above",
                )}
              </>
            )}
          </Stack>
        )}
        <Stack sx={{ gap: 3 }}>
          <Stack
            direction="row"
            sx={{ gap: 3, justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}
          >
            <Typography
              sx={{ fontSize: "16px", color: getDynamicColor("purple3"), fontWeight: 700 }}
            >
              Scenario Coach Bots
            </Typography>
            <YoodliSearchBar
              value={scenarioCoachbotFilter}
              placeholder="Search"
              onChange={(e) => setScenarioCoachbotFilter(e.target.value)}
              clearSearch={() => setScenarioCoachbotFilter("")}
              sx={{ width: 300 }}
            />
          </Stack>
          {scenarioCoachbots?.map((bot) => (
            <CoachBotCard
              key={bot.botId}
              botName={bot.name}
              dateBuilt={getHumanReadableDate(bot.createdAt)}
              isProcessing={bot.status === AnalyticProcessingState.PROCESSING}
              scenarios={bot.scenarioInfo}
              handleDelete={() => {
                setSelectedBot(bot);
                setDeleteCoachBotOpen(true);
              }}
              handleEdit={() => {
                setSelectedBot(bot);
                setSectionStatus(CoachBotSectionStatus.Details);
              }}
            />
          ))}
          {scenarioCoachbots.length === 0 && scenarioCoachbotFilter.length == 0 && (
            <>
              {renderEmptyState(
                "You have not built any scenario Coach Bots",
                "Create a new Coach Bot by clicking the “Create Coach Bot” button above",
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );

  const renderWizardView = () => (
    <SelfServeCoachBotWizard
      uploadingFiles={uploadingFiles}
      handleUploadContent={handleUploadContent}
      coachbotsList={coachbotsData?.coachBots}
      setSectionStatus={setSectionStatus}
      setSelectedBot={setSelectedBot}
      coachBotId={coachBotId}
      setCoachBotId={setCoachBotId}
      handleCancelFileUpload={handleCancelFileUpload}
      handleClearUploadedFiles={handleClearUploadedFiles}
    />
  );

  const renderDetailsView = () => (
    <CoachBotWrapper
      selectedBot={selectedBot}
      setShouldBlockNav={setShouldBlockNav}
      setDeleteCoachBotOpen={setDeleteCoachBotOpen}
      setSectionStatus={setSectionStatus}
      uploadingFiles={uploadingFiles}
      handleUploadContent={handleUploadContent}
      handleCancelFileUpload={handleCancelFileUpload}
    />
  );

  const renderView = () => {
    switch (sectionStatus) {
      case CoachBotSectionStatus.Library:
        return renderLibraryView();
      case CoachBotSectionStatus.Details:
        if (!selectedBot) {
          break;
        }
        return renderDetailsView();
      case CoachBotSectionStatus.Wizard:
        return renderWizardView();
      default:
        return renderLibraryView();
    }
  };

  if (coachbotsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          minHeight: 300,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {renderView()}
      <YoodliCtaModal
        ctaBody={{
          title: (
            <Box component="span">
              {selectedBot?.isDefault ? (
                <Box>
                  Are you sure you want to delete the default Coach Bot{" "}
                  <strong style={{ color: getDynamicColor("primary") }}>{selectedBot?.name}</strong>
                  ?
                </Box>
              ) : (
                <Box>
                  Are you sure you want to delete{" "}
                  <strong style={{ color: getDynamicColor("primary") }}>{selectedBot?.name}</strong>
                  ?
                </Box>
              )}
            </Box>
          ),
          subtitle: `${
            selectedBot?.isDefault
              ? "All scenarios not assigned to another Coach Bot will use Yoodli's AI instead. "
              : "You will no longer be able to access this Coach Bot from any scenario. "
          }This action cannot be undone.`,
        }}
        open={deleteCoachBotOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        loading={coachbotDeletionLoading}
        close={() => {
          setDeleteCoachBotOpen(false);
        }}
        buttons={
          {
            primary: {
              text: "Yes, I'm sure",
              handler: async () => {
                await handleCoachbotDeletion(selectedBot?.botId).catch((e) => console.error(e));
              },
            },
            secondary: { text: "Cancel", handler: () => setDeleteCoachBotOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
