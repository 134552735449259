import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useLocation } from "react-router";

// Components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Button, CircularProgress, Divider, Stack, Typography, Box } from "@mui/material";
import { RouteLeavingGuard } from "components/RouteLeavingGuard";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { EditProgramStep } from "./EditProgramStep";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { ProgramStep } from "./ProgramStep";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getAllCustomGoals, updateOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgContentQueryParams, OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ProgramResponse, ProgramScenarioStep } from "lib-fullstack/api/programApiTypes";
import { ProgramState } from "lib-fullstack/utils/enums";
import { WebServerInternalPath } from "utils/paths";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";

type ProgramScenariosProps = {
  program: ProgramResponse;
  scenarios: GetScenarioResponse[];
  handleCopyPracticeLink: () => void;
};

export const ProgramScenarios = ({
  program,
  scenarios,
  handleCopyPracticeLink,
}: ProgramScenariosProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const qp = new URLSearchParams(location.search);

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const [isAddingScenario, setIsAddingScenario] = React.useState<boolean>(
    qp.has(OrgProgramsQueryParams.SCENARIO_ID),
  );
  const [isEditingScenario, setIsEditingScenario] = React.useState<boolean>(false);
  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  const customGoalsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.GoalList, defaultOrgId, curSpaceId],
    queryFn: async () => await getAllCustomGoals(defaultOrgId, { space_id: curSpaceId }),
    refetchOnWindowFocus: false,
  });

  const updateProgramStepsMutation = useMutation({
    mutationFn: (steps: ProgramScenarioStep[]) =>
      updateOrgProgram(defaultOrgId, program.id, { plan_steps: steps }),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
      });
    },
  });

  const handleStepDrag = (result) => {
    // if the item was dragged outside of the droppable area, do nothing
    if (!result.destination) {
      return;
    }
    // get the source and destination indices
    const sourceIdx = result.source.index;
    const destinationIdx = result.destination.index;
    // create a new array to avoid mutating state
    const newSteps = [
      ...(updateProgramStepsMutation.isPending
        ? updateProgramStepsMutation.variables
        : program.plan_steps),
    ];
    // remove the item from the source index
    const [removed] = newSteps.splice(sourceIdx, 1);
    // and insert it into the destination index
    newSteps.splice(destinationIdx, 0, removed);
    // update state
    updateProgramStepsMutation.mutate(newSteps);
  };

  const disableAction =
    isAddingScenario || isEditingScenario || program.state !== ProgramState.Draft;
  return customGoalsQuery.isPending ? (
    <CircularProgress />
  ) : (
    <Stack>
      <RouteLeavingGuard
        when={
          (isAddingScenario || isEditingScenario) && !qp.has(OrgProgramsQueryParams.SCENARIO_ID)
        }
        shouldBlockNavigation={() => {
          if (!routeLeavingModalVisible && (isAddingScenario || isEditingScenario)) {
            return true;
          }
          return false;
        }}
        title="You are leaving this page"
        body="Heads up! If you leave before saving the program your changes will be lost. Are you sure you want to leave?"
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
        okButtonSx={{ width: 90 }}
        cancelButtonSx={{ width: 90 }}
      />
      <Stack gap={2} direction="row" sx={{ alignItems: "center" }}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          Scenarios
        </Typography>
        {program.state !== ProgramState.Draft && (
          <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
            <DoNotDisturbIcon sx={{ width: 12, height: 12 }} />
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: "10px",
                fontFamily: "poppins",
                fontWeight: 600,
              }}
            >
              Programs steps are no longer editable
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack gap={1}>
        <DragDropContext onDragEnd={handleStepDrag}>
          <Droppable
            droppableId="droppable"
            style={{
              width: "100%",
            }}
          >
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef}>
                {(updateProgramStepsMutation.isPending
                  ? updateProgramStepsMutation.variables
                  : program.plan_steps
                ).map((step, index) => (
                  <Draggable
                    key={`${step.scenario_id}-${index}`}
                    draggableId={index.toString()} // According to docs, this needs to be a string
                    index={index}
                    isDragDisabled={disableAction}
                  >
                    {(provided) => (
                      <Box
                        component="div"
                        ref={provided.innerRef}
                        id={index}
                        {...provided.draggableProps}
                      >
                        <ProgramStep
                          step={step}
                          scenarios={scenarios}
                          customGoals={customGoalsQuery.data.goals}
                          disableAction={disableAction}
                          programState={program.state as ProgramState}
                          handleRemoveStep={() => {
                            updateProgramStepsMutation.mutate(
                              program.plan_steps.filter((_, i) => i !== index),
                            );
                            setIsEditingScenario(false);
                          }}
                          handleEditStep={() => setIsEditingScenario(true)}
                          handleSaveEdits={(newStep) => {
                            const newSteps = [...program.plan_steps];
                            newSteps[index] = newStep;
                            updateProgramStepsMutation.mutate(newSteps);
                            setIsEditingScenario(false);
                          }}
                          handleCopyPracticeLink={handleCopyPracticeLink}
                          showLine={
                            index !== program.plan_steps.length - 1 ||
                            program.state === ProgramState.Draft
                          }
                          dragHandleProps={provided.dragHandleProps}
                          programId={program.id}
                          stepIndex={index}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>

        {isAddingScenario && (
          <EditProgramStep
            scenarios={scenarios}
            customGoals={customGoalsQuery.data.goals}
            handleRemoveScenario={() => setIsAddingScenario(false)}
            handleSaveScenario={(step) => {
              updateProgramStepsMutation.mutate([...program.plan_steps, step]);
              Instrumentation.logProgramScenarioAdded(defaultOrgId, program.id);
              setIsAddingScenario(false);
            }}
          />
        )}
        {program.state === ProgramState.Draft && (
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: "3px",
              border: `1px solid ${getDynamicColor("dark3")}`,
              height: { xs: "200px", md: "85px" },
              py: 1,
            }}
          >
            <YoodliTooltip title={scenarios.length === 0 ? "No scenarios currently created" : ""}>
              <Button
                onClick={() => setIsAddingScenario(true)}
                disabled={disableAction || scenarios.length === 0}
                startIcon={<AddCircleIcon />}
                sx={{ fontSize: "14px" }}
              >
                Add existing scenario
              </Button>
            </YoodliTooltip>
            <Divider
              orientation={isSmallScreen ? "horizontal" : "vertical"}
              flexItem
              sx={{ borderColor: getDynamicColor("dark3") }}
            />
            <Button
              onClick={() => {
                const queryParams = new URLSearchParams({
                  [OrgContentQueryParams.TAB]: OrgSettingsTabs.CUSTOMIZE_PRACTICE,
                  [OrgContentQueryParams.PROGRAM_ID]: program.id,
                  [OrgContentQueryParams.SHOW_SCENARIO_TEMPLATES]: "true",
                });

                navigate({
                  pathname: WebServerInternalPath.ORG_CONTENT,
                  search: queryParams.toString(),
                });
              }}
              disabled={disableAction}
              startIcon={<AddCircleIcon />}
              sx={{ fontSize: "14px" }}
            >
              Create a scenario
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
