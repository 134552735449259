import React from "react";

// Utils
import { useQuery as useApiQuery, UseQueryResult } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgFiles } from "lib-frontend/modules/axiosOrgFiles";
import { GetOrgFilesResponse } from "lib-fullstack/api/orgFileApiTypes";

const ORG_FILES_QUERY_KEY = "orgFiles";

type OrgFileQueryResponse = {
  orgFilesQuery: UseQueryResult<GetOrgFilesResponse, Error>;
  refetchOrgFilesQuery: () => Promise<void>;
};

/**
 * Hook to query file library for the current organization and space
 * @returns orgFilesQuery: Query result for the org files
 * @returns refetchOrgFilesQuery: Function to refetch the org files query
 */
export const useOrgFilesQuery = (): OrgFileQueryResponse => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const orgFilesQuery = useApiQuery({
    queryKey: [ORG_FILES_QUERY_KEY, defaultOrgId, curSpaceId],
    queryFn: () =>
      listOrgFiles(defaultOrgId, {
        space_id: curSpaceId,
      }),
    enabled: !!defaultOrgId && !!curSpaceId,
    refetchOnWindowFocus: false,
  });

  const refetchOrgFilesQuery = React.useCallback(async () => {
    await orgFilesQuery.refetch();
  }, [orgFilesQuery]);

  return { orgFilesQuery, refetchOrgFilesQuery };
};
