import firebase from "firebase/app";
import React from "react";

// Components
import CheckIcon from "@mui/icons-material/Check";
import { Button, Stack, SxProps, Typography, CircularProgress } from "@mui/material";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import OrgSectionWrapper from "../OrgSectionWrapper";
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { useQuery as useApiQuery, useMutation } from "@tanstack/react-query";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getOrgProgramReminder,
  listOrgProgramUsers,
  sendOrgProgramReminder,
  upsetOrgProgramReminder,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ProgramResponse, SendProgramReminderAction } from "lib-fullstack/api/programApiTypes";
import { PROGRAM_REMINDER_MESSAGE_MAX_LENGTH } from "lib-fullstack/utils/constants";
import {
  ProgramRecordState,
  ProgramReminderEmailType,
  ProgramState,
} from "lib-fullstack/utils/enums";
import { getDefaultProgramReminderEmailMessages } from "lib-fullstack/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

const HEADER_SX: SxProps = {
  color: getDynamicColor("purple3"),
  fontFamily: "poppins",
  fontSize: 16,
  fontWeight: 700,
};

const REMINDER_EMAIL_TYPE_LABELS = {
  [ProgramReminderEmailType.NotStarted]: "Not started",
  [ProgramReminderEmailType.InProgress]: "In progress",
  [ProgramReminderEmailType.NotStartedOrInProgress]: "Not started & In progress",
  [ProgramReminderEmailType.Completed]: "Completed",
};

type CustomizeProgramReminderEmailProps = {
  program: ProgramResponse;
};

export const CustomizeProgramReminderEmail = ({
  program,
}: CustomizeProgramReminderEmailProps): JSX.Element => {
  const { showNotificationBanner } = useNotification();

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [reminderEmailType, setReminderEmailType] = React.useState<ProgramReminderEmailType>(
    ProgramReminderEmailType.NotStarted,
  );
  const [reminderEmailMessage, setReminderEmailMessage] = React.useState<string>("");
  const [reminderEmailSaved, setReminderEmailSaved] = React.useState<boolean>(false);
  const [testEmailSent, setTestEmailSent] = React.useState<boolean>(false);

  const defaultProgramMessages = getDefaultProgramReminderEmailMessages(program.name);

  const programReminderQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ProgramReminder, defaultOrgId, program.id],
    queryFn: () => getOrgProgramReminder(defaultOrgId, program.id),
    enabled: !!defaultOrgId && !!program,
    refetchOnWindowFocus: false,
  });

  const numProgramMembersQuery = useApiQuery({
    queryKey: [
      OrgProgramsQueryKeys.NumProgramUsersByStatus,
      defaultOrgId,
      program.id,
      reminderEmailType,
    ],
    queryFn: async () => {
      switch (reminderEmailType) {
        case ProgramReminderEmailType.NotStarted:
          return (
            await listOrgProgramUsers(defaultOrgId, program.id, {
              state: ProgramRecordState.NotStarted,
            })
          ).total;
        case ProgramReminderEmailType.InProgress:
          return (
            await listOrgProgramUsers(defaultOrgId, program.id, {
              state: ProgramRecordState.InProgress,
            })
          ).total;
        case ProgramReminderEmailType.NotStartedOrInProgress: {
          const [notStarted, inProgress] = await Promise.all([
            listOrgProgramUsers(defaultOrgId, program.id, { state: ProgramRecordState.NotStarted }),
            listOrgProgramUsers(defaultOrgId, program.id, { state: ProgramRecordState.InProgress }),
          ]);
          return notStarted.total + inProgress.total;
        }
        case ProgramReminderEmailType.Completed:
          return (
            await listOrgProgramUsers(defaultOrgId, program.id, {
              state: ProgramRecordState.Completed,
            })
          ).total;
        default:
          console.error(`Invalid reminder email type: ${reminderEmailType}`);
          return 0;
      }
    },
    enabled: !!defaultOrgId && !!program,
    refetchOnWindowFocus: false,
  });

  const sendProgramReminderMutation = useMutation({
    mutationFn: (action: SendProgramReminderAction) =>
      sendOrgProgramReminder(defaultOrgId, program.id, {
        action: action,
        reminder_type: reminderEmailType,
      }),
    onMutate: (action) => {
      if (action === SendProgramReminderAction.SendPreview) {
        setTestEmailSent(true);
        setTimeout(() => setTestEmailSent(false), 3000);
      } else if (action === SendProgramReminderAction.Send) {
        Instrumentation.logProgramReminderEmailSent(defaultOrgId, program.id, reminderEmailType);
        showNotificationBanner(
          `Emails have been sent to all members with ‘${REMINDER_EMAIL_TYPE_LABELS[reminderEmailType]}’ status`,
        );
      }
    },
  });

  const upsetProgramReminderMutation = useMutation({
    mutationFn: (_sendEmail: boolean) =>
      upsetOrgProgramReminder(defaultOrgId, program.id, {
        message: reminderEmailMessage,
        reminder_type: reminderEmailType,
      }),
    onMutate: (sendEmail) => {
      if (!sendEmail) {
        setReminderEmailSaved(true);
        setTimeout(() => setReminderEmailSaved(false), 3000);
      }
    },
    onSuccess: (_, sendEmail) => {
      if (sendEmail) {
        sendProgramReminderMutation.mutate(SendProgramReminderAction.Send);
      }
      return programReminderQuery.refetch();
    },
  });

  /**
   * Set the reminder email message in the textfield based on the reminder email type and the program reminder query data.
   * If the reminder email message is not found in the query data, use a default message.
   */
  React.useEffect(() => {
    setReminderEmailMessage(
      getProgramReminderMessage() ?? defaultProgramMessages[reminderEmailType],
    );
  }, [reminderEmailType, programReminderQuery?.data]);

  React.useEffect(() => {
    if (reminderEmailSaved) {
      setReminderEmailSaved(false);
    }
  }, [reminderEmailMessage]);

  const getProgramReminderMessage = () => {
    switch (reminderEmailType) {
      case ProgramReminderEmailType.NotStarted:
        return programReminderQuery?.data?.not_started_message;
      case ProgramReminderEmailType.InProgress:
        return programReminderQuery?.data?.in_progress_message;
      case ProgramReminderEmailType.NotStartedOrInProgress:
        return programReminderQuery?.data?.not_started_or_in_progress_message;
      case ProgramReminderEmailType.Completed:
        return programReminderQuery?.data?.completed_message;
    }
  };

  const renderExplainerText = () => {
    if (numProgramMembersQuery.isLoading) {
      return <CircularProgress />;
    } else if (program.state === ProgramState.Archived) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontFamily: "poppins",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          You cannot send a reminder email for an archived program
        </Typography>
      );
    } else {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontFamily: "poppins",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Send to {numProgramMembersQuery?.data ?? 0} member
          {numProgramMembersQuery?.data === 1 ? "" : "s"} & save message
        </Typography>
      );
    }
  };

  return (
    <OrgSectionWrapper
      loading={!programReminderQuery.data}
      ctas={
        <Stack
          direction="row"
          gap={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            px: { xs: 2, md: "60px" },
          }}
        >
          {renderExplainerText()}
          <Button
            disabled={reminderEmailMessage.length === 0 || program.state !== ProgramState.Published}
            onClick={() => {
              upsetProgramReminderMutation.mutate(true);
            }}
            variant="contained"
            sx={{ fontSize: 14, height: 40 }}
          >
            Send emails
          </Button>
        </Stack>
      }
    >
      <Stack
        gap={6}
        sx={{
          px: { xs: 2, md: 8 },
        }}
      >
        <Stack gap={1}>
          <Typography
            sx={{
              ...HEADER_SX,
              fontSize: 18,
            }}
          >
            Customize reminder email
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            Send an email to a select group of members for {program.name}.
          </Typography>
        </Stack>
        <Stack gap={1} sx={{ pt: 1 }}>
          <Typography sx={HEADER_SX}>Send to members with status:</Typography>
          <YoodliSelect
            options={Object.values(ProgramReminderEmailType).map((option) => ({
              label: REMINDER_EMAIL_TYPE_LABELS[option],
              value: option,
            }))}
            value={reminderEmailType}
            onChange={(e) => setReminderEmailType(e.target.value as ProgramReminderEmailType)}
          />
        </Stack>
        <Stack gap={1}>
          <Stack
            direction="row"
            gap={1}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Typography sx={HEADER_SX}>Customize message</Typography>
            {reminderEmailSaved ? (
              <Stack
                gap={0.5}
                direction="row"
                sx={{ alignItems: "center", color: getDynamicColor("greenSuccess"), height: 32 }}
              >
                <CheckIcon sx={{ width: 24, height: 24 }} />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Saved
                </Typography>
              </Stack>
            ) : (
              <Button
                disabled={
                  reminderEmailMessage.length === 0 ||
                  reminderEmailMessage === getProgramReminderMessage()
                }
                onClick={() => upsetProgramReminderMutation.mutate(false)}
                sx={{ fontSize: 14 }}
              >
                Save as draft
              </Button>
            )}
          </Stack>
          <YoodliTextfield
            value={reminderEmailMessage}
            onChange={(event) => {
              setReminderEmailMessage(event.target.value);
            }}
            multiline
            rows={4}
            maxChars={PROGRAM_REMINDER_MESSAGE_MAX_LENGTH}
          />
        </Stack>
        <Stack gap={1}>
          <Typography sx={HEADER_SX}>Preview email</Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            Send a copy of your last saved message to your email:{" "}
            {firebase.auth().currentUser?.email}
          </Typography>
          <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
            <Button
              onClick={() =>
                sendProgramReminderMutation.mutate(SendProgramReminderAction.SendPreview)
              }
              variant="outlined"
              size="small"
              sx={{ fontSize: 12 }}
            >
              Send test
            </Button>
            {testEmailSent && (
              <Stack
                gap={0.5}
                direction="row"
                sx={{ alignItems: "center", color: getDynamicColor("greenSuccess") }}
              >
                <CheckIcon sx={{ width: 24, height: 24 }} />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Test email sent
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </OrgSectionWrapper>
  );
};
