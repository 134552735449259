import React from "react";

// Components
import YoodliTooltip from "./YoodliComponents/YoodliTooltip";
import {
  Box,
  Checkbox,
  Divider,
  IconButton as MUIIconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
} from "@mui/material";

// Utils
import ConditionalWrapper from "./ConditionalWrapper";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export type MenuItemType = {
  title: string | JSX.Element;
  isCheckbox?: boolean;
  checked?: boolean;
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  disabledTooltip?: string;
  disableCloseOnClick?: boolean;
};

type IconMenuProps = {
  title: string;
  icon: JSX.Element;
  menuItems: MenuItemType[];
  staticMenuItems?: string[];
  menuItemSx?: SxProps;
  staticMenuItemSx?: SxProps;
  iconButtonSx?: SxProps;
  paperSx?: SxProps;
  minWidth?: string;
  hideCaret?: boolean;
  hideTooltip?: boolean;
  disableCloseOnClick?: boolean;
  disableDrag?: boolean;
  disabled?: boolean;
  openOverride?: boolean;
};

export default function IconMenu({
  icon,
  title,
  menuItems,
  staticMenuItems,
  menuItemSx,
  staticMenuItemSx,
  iconButtonSx,
  paperSx,
  minWidth,
  hideCaret,
  hideTooltip,
  disableCloseOnClick,
  disableDrag,
  disabled,
  openOverride,
}: IconMenuProps): JSX.Element {
  const menuItemRef = React.useRef(null);
  const iconButtonRef = React.useRef(null);
  const openOverrideRef = React.useRef(openOverride);
  const [staticMenuItemHovered, setStaticMenuItemHovered] = React.useState<{
    index: number;
  }>({ index: -1 });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  React.useEffect(() => {
    openOverrideRef.current = openOverride;
    if (openOverride) {
      setAnchorEl(iconButtonRef.current);
    } else {
      setAnchorEl(null);
    }
  }, [openOverride]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(iconButtonRef.current);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    if (!openOverrideRef.current) {
      setAnchorEl(null);
    }
  };
  const handleMenuItemClicked = async (e: React.MouseEvent<HTMLElement>, item: MenuItemType) => {
    await item?.onClick(e);
    if (!disableCloseOnClick && !item.disableCloseOnClick) {
      handleClose(e);
    }
  };
  // Static Menu Items Hover Handler (currently only email in profile menu)
  const [delayHandler, setDelayHandler] = React.useState(null);
  const handleStaticMenuItemMouseEnter = (index) => {
    setDelayHandler(
      setTimeout(() => {
        setStaticMenuItemHovered({ index });
      }, 500),
    );
  };

  const handleStaticMenuItemMouseLeave = () => {
    clearTimeout(delayHandler);
    setStaticMenuItemHovered({ index: -1 });
  };

  return (
    <>
      <span ref={iconButtonRef}>
        <IconButton
          handleClick={handleClick}
          title={title}
          icon={icon}
          open={open}
          sx={iconButtonSx}
          disabled={disabled}
          hideTooltip={hideTooltip}
        />
      </span>
      <Menu
        anchorEl={anchorEl}
        id={`${title}-menu`}
        open={open}
        elevation={0}
        onClose={handleClose}
        sx={{
          ".MuiList-root": {
            py: "0 !important",
          },
          ...(!disableDrag
            ? {
                ".MuiBackdrop-root": {
                  backgroundColor: getDynamicColor("dark5", 0.25),
                  // WebkitAppRegion: drag (set inside draggable/notdraggable classes) eats all click events when true
                  // making the menu not closable by clicking outside
                  WebkitAppRegion: "no-drag",
                },
              }
            : {}),
        }}
        slotProps={{
          paper: {
            elevation: 2,
            sx: {
              overflow: "visible",
              minWidth: minWidth ?? "180px",
              ...(hideCaret
                ? {}
                : {
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 18,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  }),
              ...paperSx,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {staticMenuItems && staticMenuItems.length
          ? staticMenuItems.map((item, i) => (
              <Box key={item} ref={menuItemRef}>
                <MenuItem
                  onMouseEnter={() =>
                    menuItemRef?.current?.clientWidth === 300
                      ? handleStaticMenuItemMouseEnter(i)
                      : null
                  }
                  onMouseLeave={handleStaticMenuItemMouseLeave}
                  disableRipple
                  sx={{
                    overflow: "hidden",
                    color: getDynamicColor("dark4"),
                    maxWidth: "300px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    ...staticMenuItemSx,
                  }}
                >
                  <Box
                    sx={{
                      ...(staticMenuItemHovered.index === i
                        ? {
                            transform: "translateX(calc(268px - 100%))",
                            transition: `transform ${(item.length + 20) / 50}s linear`,
                          }
                        : {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }),
                    }}
                  >
                    {item}
                  </Box>
                </MenuItem>
                <Box sx={{ px: 2 }}>
                  <Divider
                    sx={{
                      borderBottomWidth: "1px !important",
                      borderColor: getDynamicColor("dark2"),
                    }}
                  />
                </Box>
              </Box>
            ))
          : null}
        {menuItems.map((item) => (
          <YoodliTooltip
            title={item.disabled ? item.disabledTooltip : ""}
            key={item.title.toString()}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <MenuItem
                key={item.title.toString()}
                disabled={item.disabled}
                onClick={async (e) => {
                  if (!item.isCheckbox) {
                    await handleMenuItemClicked(e, item);
                  }
                }}
                sx={{
                  color: getDynamicColor("purple3"),
                  flexGrow: 1,
                  pointerEvents: item.isCheckbox ? "none" : "auto",
                  ...menuItemSx,
                }}
              >
                {item.title}
              </MenuItem>
              {item.isCheckbox && (
                <Checkbox
                  checked={item.checked && !item.disabled}
                  disabled={item.disabled}
                  onClick={() => {
                    item.onClick();
                  }}
                  sx={{
                    height: "fit-content",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                />
              )}
            </Stack>
          </YoodliTooltip>
        ))}
      </Menu>
    </>
  );
}

type IconButtonProps = {
  title: string;
  icon: JSX.Element;
  open: boolean;
  sx?: SxProps;
  hideTooltip?: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

const IconButton = ({
  title,
  icon,
  open,
  sx,
  hideTooltip,
  handleClick,
  disabled,
}: IconButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        textAlign: "center",
        height: "fit-content",
      }}
    >
      <ConditionalWrapper Wrapper={YoodliTooltip} wrapperProps={{ title }} condition={!hideTooltip}>
        <MUIIconButton
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
          disabled={disabled}
          sx={{
            p: "4px",
            ml: "0px !important",
            height: "46px",
            width: "46px",
            transition: "background-color 0.2s",
            svg: {
              transition: "color 0.2s",
              height: "28px",
              width: "28px",
              color: open ? getDynamicColor("purple3") : getDynamicColor("primary"),
            },
            ...(disabled && {
              svg: {
                height: "28px",
                width: "28px",
                color: getDynamicColor("dark4"),
              },
              borderColor: getDynamicColor("dark4"),
              color: getDynamicColor("dark4"),
            }),
            ...sx,
          }}
          aria-controls={open ? `${title}-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {icon}
        </MUIIconButton>
      </ConditionalWrapper>
    </Box>
  );
};
