import * as rt from "runtypes";
import { filterToIntegers } from "lib-fullstack/utils/helperFunctions";

/**
 * Create a new Runtype that decorates input Runtype as Optional(Union(T, Null)).
 */
export function NullableOptional<T extends rt.Runtype>(
  runtype: T,
): rt.Optional<rt.Union<[T, rt.Literal<null>]>> {
  return rt.Optional(rt.Union(runtype, rt.Null));
}

/**
 * Utility function to create a new Runtype from input Runtype
 * where all top level fields are made as optional. (i.e. not recursive)
 *
 * Note: there are two similar functions, but those are not the same.
 * - Partial<T> of Typescript: output is pure Typescript type, not Runtype type.
 * - Partial<T> of Runtype: input is Type script type, not Runtype type.
 *
 * @param runtype Source Runtype type
 * @returns A new Runtype type
 */
export function RTPartial<T extends { [key: string]: rt.Runtype }>(
  runtype: rt.Record<T, false>,
): rt.Record<{ [K in keyof T]: rt.Optional<T[K]> }, false> {
  const fields = runtype.fields;
  const partialFields: { [K in keyof T]?: rt.Optional<T[K]> } = {};

  for (const key in fields) {
    partialFields[key as keyof T] = rt.Optional(fields[key]) as rt.Optional<T[keyof T]>;
  }

  return rt.Record(partialFields as { [K in keyof T]: rt.Optional<T[K]> }) as rt.Record<
    { [K in keyof T]: rt.Optional<T[K]> },
    false
  >;
}

/**
 * Create a Runtype from a Typescript string enum.
 * It is basically https://github.com/runtypes/runtypes/issues/66#issuecomment-788129292
 * and has an additional getStringLiterals function for auto Python types generation.
 * Note that Static<> of this Runtype becomes the original Typescript enum type.
 */
export const RTStringEnum = <T>(stringEnum: Record<string, T>): rt.Runtype<T> => {
  const values = Object.values<unknown>(stringEnum);
  const isEnumValue = (input: unknown): input is T => values.includes(input);
  const errorMessage = (input: unknown): string =>
    `Failed constraint check. Expected one of ${JSON.stringify(
      values,
    )}, but received ${JSON.stringify(input)}`;

  const runtype = rt.Unknown.withConstraint<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (object: any) => isEnumValue(object) || errorMessage(object),
  );

  // @ts-expect-error
  // Ideally, this would be strongly typed by extending Constraint<A, T, K>.
  // However, it is not trivial because how withConstraint() is written.
  // Because this is used only by a specific purpose of Python type generation,
  // this dirty Javascript injection is used as a compromise.
  runtype.getStringLiterals = () => values as string[];

  return runtype;
};

/**
 * Create a Runtype from Typescript string enum,
 * specifically for Dictionary key type.
 * Dictionary key type can be string or number (or symbol) only.
 * RTStringEnum causes a type error in this case.
 */
export const RTStringEnumKey = (stringEnum: {
  [k: string]: string;
}): rt.Union<[rt.Literal<string>, ...rt.Literal<string>[]]> => {
  const values = Object.values(stringEnum);
  const literals = values.map(rt.Literal);
  return rt.Union(literals[0], ...literals.slice(1));
};

/**
 * Create Runtype from a Typescript number enum.
 * This is a simpler version than RTStringEnum where this becomes
 * just number type when it's converted to a static type.
 * It is OK for now because it's used by only one place so far.
 */
export const RTNumericEnum = (numericEnum: {
  [k: string]: string | number;
}): rt.Union<[rt.Literal<number>, ...rt.Literal<number>[]]> => {
  // when parsing an int enum, strip out the string keys
  const args = filterToIntegers(Object.keys(numericEnum));

  const literals = args.map(rt.Literal);
  return rt.Union(literals[0], ...literals.slice(1));
};
