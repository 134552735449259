import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import { Button, Stack, Typography } from "@mui/material";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";

// Assets
import { ReactComponent as PDFIcon } from "images/icons/pdf-icon.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isEmail } from "validator";
import { stringify as csvStringify } from "csv-stringify/sync";
import { CSV_STRINGIFY_DEFAULT_OPTIONS } from "lib-fullstack/utils/constants";
import { parse as csvParse } from "csv-parse/sync";
import { saveAs } from "file-saver";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";

const MAX_EMAILS = 1000;

type CSVUploadProps = {
  setEmails: (emails: string[]) => void;
  error: string;
  setError: (error: string) => void;
  onNext: () => void;
};

export const CSVUpload = ({ setEmails, onNext, error, setError }: CSVUploadProps): JSX.Element => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      await handleFileChange(acceptedFiles[0]);
    },
    accept: ".csv",
    maxFiles: 1,
  });
  const handleFileChange = async (file) => {
    if (!file) {
      setError("Please upload a CSV file");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result;
      if (text) {
        const records: { email: string }[] = csvParse(text.toString(), {
          columns: ["email"],
          relaxColumnCount: true,
          bom: true,
          cast: true,
          skipEmptyLines: true,
          skipRecordsWithError: true,
          trim: true,
        });

        // Convert records to email array.
        // Filter out any invalid emails, and remove dupes using Set
        const filteredEmails = Array.from(
          new Set(records.map((record) => record.email ?? "").filter((email) => isEmail(email))),
        );

        if (filteredEmails.length > MAX_EMAILS) {
          setError(
            `You can only upload up to ${MAX_EMAILS} emails at a time, you uploaded ${records.length}`,
          );
          return;
        }

        if (filteredEmails.length === 0) {
          setError("No valid emails found in the CSV file provided");
        } else {
          setEmails(filteredEmails);
          setError(null);
          onNext();
        }
      }
    };
    reader.readAsText(file);
  };

  const handleDownloadSampleCSV = () => () => {
    const rows = [
      { email: "employee1@business.com" },
      { email: "employee2@business.com" },
      { email: "employee3@business.com" },
      { email: "employee4@business.com" },
    ];
    const csvContent = csvStringify(rows, CSV_STRINGIFY_DEFAULT_OPTIONS);
    saveAs(new Blob([csvContent], { type: "text/csv;charset=utf-8" }), "sample_emails.csv");
  };

  const renderUploadDropzone = () => {
    return (
      <Stack
        gap={{ xs: 2, md: 4 }}
        alignItems="center"
        {...getRootProps()}
        onClick={undefined}
        sx={{
          borderRadius: "20px",
          border: {
            xs: "none",
            md: `2px dashed ${getDynamicColor("dark3")}`,
          },
          py: { xs: 2, md: 4 },
          width: "100%",
          transition: "all .5s ease",
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            transition: "all .7s ease",
          }}
        >
          <Stack
            direction="row"
            gap={4}
            sx={{
              color: getDynamicColor("primary"),
              svg: {
                height: 60,
                width: 60,
                transition: "all .7s ease",
              },
            }}
          >
            <PDFIcon />
          </Stack>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography
              color={getDynamicColor("dark4")}
              fontSize="20px"
              fontWeight={500}
              sx={{
                display: { xs: "none", md: "block" },
                height: 46,
                opacity: 1,
                transition:
                  "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease",
                overflow: "visible",
                pt: 2,
              }}
            >
              Drag your CSV file here
            </Typography>
            {error && (
              <Typography color={getDynamicColor("redError")} fontSize="14px" fontWeight={700}>
                {error}
              </Typography>
            )}
            <Button
              onClick={(e) => {
                getRootProps()?.onClick(e);
              }}
              variant="outlined"
              sx={{
                height: 44,
                px: 2.5,
                py: 1,
                mt: 3,
                opacity: 1,
                border: `2px solid ${getDynamicColor("primary")}`,
                borderRadius: "50px",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
                width: { xs: "100%", md: "max-content" },
                transition:
                  "margin .5s ease 0.2s, padding .5s ease 0.2s, height .5s ease 0.2s, opacity 0.4s ease, border .5s ease 0.2s",
              }}
            >
              Upload from device
            </Button>
          </Stack>
        </Stack>
        <input
          multiple={false}
          ref={fileInputRef}
          style={{ display: "none" }}
          {...getInputProps()}
        />
      </Stack>
    );
  };
  return (
    <Stack
      gap={{ xs: 2, md: 5 }}
      sx={{
        pb: WIZARD_FOOTER_HEIGHT,
      }}
    >
      <Stack
        direction="column"
        sx={{
          fontFamily: "Poppins",
        }}
        gap={1}
      >
        <WizardTitle>Upload a CSV</WizardTitle>
        <WizardSubTitle
          overrideSx={{
            maxWidth: 540,
          }}
        >
          Add a CSV file with the email addresses of all the members you would like to invite.
          Download a sample CSV file below to get started.
        </WizardSubTitle>
        <Button
          variant="text"
          onClick={handleDownloadSampleCSV()}
          disableRipple
          sx={{
            fontSize: 14,
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Download Sample CSV
        </Button>
      </Stack>
      {renderUploadDropzone()}
    </Stack>
  );
};
