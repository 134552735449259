// Components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Stack, Box, IconButton, Typography, TextField } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgFlexibleAndSeatsUneditable } from "lib-frontend/utils/orgUtils";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { MAX_ORG_SEATS } from "lib-fullstack/utils/constants";

type UpdateOrgSeatsControlsProps = {
  updatedOrgSeats: number;
  setUpdatedOrgSeats: React.Dispatch<React.SetStateAction<number>>;
  org: OrgV2Response;
  size: number;
  fontSize?: number;
  disabled?: boolean;
};

const BUTTON_SIZE = 20;

export const UpdateOrgSeatsControls = ({
  updatedOrgSeats,
  setUpdatedOrgSeats,
  org,
  size,
  fontSize,
  disabled,
}: UpdateOrgSeatsControlsProps): JSX.Element => {
  const controlsDisabled = disabled || isOrgFlexibleAndSeatsUneditable(org);

  const restrictValueToRange = (value: string, min: number, max: number) => {
    if (value) {
      if (Number(value) < min) {
        return min;
      }
      if (Number(value) > max) {
        return max;
      }
      return parseInt(value);
    }
    return null;
  };

  const getSeatCountColor = () => {
    if (updatedOrgSeats === org.license_count) {
      return getDynamicColor("purple3");
    } else if (updatedOrgSeats > org.license_count) {
      return getDynamicColor("greenSuccess");
    } else {
      return getDynamicColor("redError");
    }
  };

  return (
    <Stack gap={1} sx={{ alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          minWidth: size,
          borderRadius: "8px",
          border: `1px solid ${getSeatCountColor()}`,
        }}
      >
        {updatedOrgSeats > org.license_used && updatedOrgSeats > org.license_count_base && (
          <IconButton
            onClick={() => setUpdatedOrgSeats((prev) => prev - 1)}
            disabled={controlsDisabled}
            sx={{
              position: "absolute",
              top: `calc(50% - ${BUTTON_SIZE / 2}px)`,
              left: -(BUTTON_SIZE / 2),
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
              zIndex: 1,
              background: getDynamicColor("light1"),
              color: getDynamicColor("purple3"),
              "&:hover": {
                background: getDynamicColor("light1"),
              },
              "&.Mui-disabled": {
                color: getDynamicColor("dark3"),
                background: getDynamicColor("light1"),
              },
            }}
          >
            <RemoveCircleIcon sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }} />
          </IconButton>
        )}
        <TextField
          sx={{
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          }}
          inputProps={{
            sx: {
              position: "relative",
              textAlign: "center",
              color: getSeatCountColor(),
              width: updatedOrgSeats ? `${updatedOrgSeats?.toString()?.length}ch` : "1ch",
              fontFamily: "poppins",
              fontSize: fontSize ?? "20px",
              fontWeight: 700,
              letterSpacing: "0.2px",
            },
          }}
          InputProps={{
            sx: {
              // borderRadius: "8px",
              border: `none`,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
          disabled={controlsDisabled}
          type="number"
          value={updatedOrgSeats}
          onChange={(e) =>
            setUpdatedOrgSeats(
              restrictValueToRange(
                e.target.value,
                Math.max(org.license_used, org.license_count_base ?? 0),
                MAX_ORG_SEATS,
              ),
            )
          }
        />
        {updatedOrgSeats < MAX_ORG_SEATS && (
          <IconButton
            onClick={() => setUpdatedOrgSeats((prev) => prev + 1)}
            disabled={controlsDisabled}
            sx={{
              position: "absolute",
              zIndex: 1,
              top: `calc(50% - ${BUTTON_SIZE / 2}px)`,
              right: -(BUTTON_SIZE / 2),
              width: BUTTON_SIZE,
              height: BUTTON_SIZE,
              background: getDynamicColor("light1"),
              color: getDynamicColor("purple3"),
              "&:hover": {
                background: getDynamicColor("light1"),
              },
              "&.Mui-disabled": {
                color: getDynamicColor("dark3"),
                background: getDynamicColor("light1"),
              },
            }}
          >
            <AddCircleIcon sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }} />
          </IconButton>
        )}
      </Box>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 700,
          whiteSpace: "nowrap",
        }}
      >
        total seats
      </Typography>
    </Stack>
  );
};
