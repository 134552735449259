// Utils
import { ListReportsResponse, ListReportsResponseItem } from "lib-fullstack/api/reportApiTypes";
import { ReportState } from "lib-fullstack/utils/enums";

export enum ReportSubType {
  FeatureUse = "feature_use",
  GoalImprovement = "goal_improvement",
  UserEngagement = "user_engagement",
}

export enum ReportsTabType {
  Group = "group",
  Members = "members",
}

export const REPORT_FIELDS = {
  [ReportSubType.FeatureUse]: [
    "Email",
    "Name",
    "Sign up date",
    "Presentations Completed",
    "Interviews Completed",
    "Roleplays Completed",
    "Average minutes per practice",
    "Uploads",
    "Video calls (Zoodlis)",
    "Average minutes per video call",
    "Desktop App Recordings",
    "Average minutes per desktop app call",
  ],
  [ReportSubType.GoalImprovement]: [
    "Email",
    "Member name",
    "Goal name",
    "Sign up date",
    "Goal Type",
    "Last practice or upload date",
    "Practice count",
    "First goal score",
    "Max goal score",
    "Latest goal score",
  ],
  [ReportSubType.UserEngagement]: [
    "Email",
    "Name",
    "Sign up date",
    "Practices started",
    "Completed practices",
    "Shared practices",
    "Practices with comments",
    "Comments given",
    "Avg practice mins",
    "Last practice upload date",
  ],
};

export const getDownloadableReport = (data: ListReportsResponse): ListReportsResponseItem => {
  const r = data?.reports[0];
  if (!r) {
    return;
  }
  if (
    r.state === ReportState.Rendered &&
    !!r.downloadUrl &&
    new Date(r.expirationDate) > new Date()
  ) {
    return r;
  }
};

export const getReportProcessing = (data: ListReportsResponse): ListReportsResponseItem => {
  const r = data?.reports[0];
  if (!r) {
    return;
  }
  if (
    r.state === ReportState.Created &&
    !r.downloadUrl &&
    (!r.expirationDate || new Date(r.expirationDate) > new Date())
  ) {
    return r;
  }
};

export const getNoDataReport = (data: ListReportsResponse): ListReportsResponseItem => {
  const r = data?.reports[0];
  if (!r) {
    return;
  }
  if (
    r.state === ReportState.Rendered &&
    !r.downloadUrl &&
    new Date(r.expirationDate) > new Date()
  ) {
    return r;
  }
};
