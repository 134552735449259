import { Button, Box, SxProps } from "@mui/material";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

type BaseContentSpaceCardProps = {
  children?: React.ReactNode;
  onClick: () => void;
  sx?: SxProps;
  buttonSx?: SxProps;
  childrenContainerSx?: SxProps;
  disabled?: boolean;
};

export const BaseContentSpaceCard = ({
  children,
  sx,
  onClick,
  buttonSx,
  childrenContainerSx,
  disabled,
}: BaseContentSpaceCardProps): JSX.Element => {
  return (
    <Box
      sx={{
        position: "relative",
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "4px",
        height: "95px",
        overflow: "hidden",
        transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        "&:hover": !disabled && {
          borderColor: getDynamicColor("primary"),
          boxShadow: Y_SHADOWS.box_shadow_1,
        },
        ...sx,
      }}
    >
      <Button
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          padding: 0,
          backgroundColor: "inherit",
          "&:hover": { backgroundColor: "inherit" },
          ...buttonSx,
        }}
        disabled={disabled}
        onClick={onClick}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          ...childrenContainerSx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
