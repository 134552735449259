import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";
import { NextStepGoal, NextStepsType } from "components/Dashboard/DashboardTypes";

// Assets
import { ReactComponent as NextStepsIcon } from "images/icons/icon-lightbulb.svg";

// Utils
import { DetailTitle } from "./AnalyticDetail";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import seedrandom from "seedrandom";

type NextStepsProps = {
  nextSteps: NextStepsType;
  value: number;
  analyticThreshold?: number | number[];
};
const dailyRandomIndex = (max: number): number => {
  const rng = seedrandom(
    new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }),
  );
  return Math.floor(rng() * max);
};
const NextSteps = ({ nextSteps, value, analyticThreshold }: NextStepsProps): JSX.Element => {
  const [nextStep, setNextStep] = React.useState<string>("");
  React.useEffect(() => {
    if (nextSteps) {
      const { goal, positive, negative, random, threshold: _threshold } = nextSteps;
      const threshold = _threshold ?? (analyticThreshold as number) ?? 0;
      if (random) {
        const randomIndex = dailyRandomIndex(random.length);
        setNextStep(random[randomIndex]);
      } else if (positive && negative) {
        if (value <= threshold) {
          setNextStep(goal === NextStepGoal.HIGH ? negative : positive);
        } else {
          setNextStep(goal === NextStepGoal.HIGH ? positive : negative);
        }
      }
    }
  }, [nextSteps, value, analyticThreshold]);
  if (!nextStep) return null;
  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center">
        <Box
          sx={{
            width: 30,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 18,
              width: 18,
              backgroundColor: getDynamicColor("purple3"),
              borderRadius: "50%",
              svg: { fill: getDynamicColor("light1"), height: 10, width: 10 },
            }}
          >
            <NextStepsIcon />
          </Stack>
        </Box>
        <DetailTitle title="Next Step:" />
      </Stack>
      <Typography
        sx={{
          fontSize: 12,
        }}
      >
        {nextStep}
      </Typography>
    </Stack>
  );
};

export default NextSteps;
