import React from "react";

// Components
import { Drawer, IconButton, Stack, Link, Typography, Grid } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import CreateContentSpaceCard from "./CreateContentSpaceCard";
import ContentSpaceCard from "./ContentSpaceCard";
import YoodliDeleteModal from "webclient/src/components/Modals/YoodliDeleteModal";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

// Assets
import { ReactComponent as FileFolder } from "images/icons/icon-file-folder.svg";
import { ReactComponent as FileFolderAdmin } from "images/icons/icon-file-folder-admin.svg";

import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

// Utils
import { useLocation } from "react-router";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";

import { SupportPages } from "lib-frontend/utils/paths";

import { OrgSpaceItem } from "lib-fullstack/api/orgSpaceApiTypes";
import { isOrgOwnerAdmin, isUserMinimumEffectiveRole } from "lib-frontend/utils/orgUtils";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { EffectiveRole } from "lib-fullstack/utils/enums";

const PADDING_TOP = "18px";

enum ContentSpaceDrawerState {
  Manage = "manage",
  Copy = "copy",
}

type ManageContentSpacesDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleClose: () => void;
  handleCreate: () => void;
};

const ManageContentSpacesDrawer = ({
  open,
  handleClose,
  handleCreate,
}: ManageContentSpacesDrawerProps): JSX.Element => {
  const location = useLocation();

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [deleteTarget, setDeleteTarget] = React.useState<OrgSpaceItem | null>(null);
  const { effectiveRole, defaultOrgId } = React.useContext(UserOrgContext);

  React.useEffect(() => {
    if (open) {
      handleDrawerClose();
    }
  }, [location.pathname]);

  const { navDrawerOffset } = useNavDrawerOffset();

  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);

  const { spaces, curSpaceId, selectSpace, deleteSpace, copyContentInfo, copyContentToSpace } =
    React.useContext(ContentSpacesContext);

  const seenFTUXDictionary = getLiveUserDocMain()?.orgSpacesFTUXSeenDictionary;
  const showCreate = isUserMinimumEffectiveRole(effectiveRole, EffectiveRole.ORG_ADMIN);
  const showFTUX = showCreate && !seenFTUXDictionary?.[defaultOrgId];

  const drawerState = React.useMemo(() => {
    return copyContentInfo.isCopyDrawerOpen
      ? ContentSpaceDrawerState.Copy
      : ContentSpaceDrawerState.Manage;
  }, [copyContentInfo.isCopyDrawerOpen]);

  const onDeleteConfirmed = () => {
    deleteSpace(deleteTarget.id)
      .then(() => {
        setModalOpen(false);
      })
      .catch((e) => {
        console.error(e.toString());
      });
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onDeleteClick = (spaceId: string) => {
    const space = spaces.find((space) => space.id === spaceId);
    setDeleteTarget(space);
    setModalOpen(true);
  };

  const onSelectClick = (spaceId: string) => {
    // Manage drawer
    if (drawerState === ContentSpaceDrawerState.Manage) {
      selectSpace(spaceId)
        .then(() => {
          handleDrawerClose();
        })
        .catch((e) => {
          console.error(e.toString());
        });
    }
    // Drawer State is in Copy mode
    else {
      if (!copyContentInfo.requestPendingId) {
        copyContentToSpace(spaceId);
      }
    }
  };

  const handleDrawerClose = () => {
    // If we are closing the drawer and we've shown the user the FTUX, mark it in the user doc.
    if (showFTUX) {
      const prevEntry = getLiveUserDocMain()?.orgSpacesFTUXSeenDictionary ?? {};
      const newEntry = { ...prevEntry, [defaultOrgId]: true };

      try {
        void updateThisUserDocMain({ orgSpacesFTUXSeenDictionary: newEntry });
      } catch (e) {
        console.error(e.toString());
      }
    }

    handleClose();
  };

  const renderContentSpaceOptions = (spaceId: string, isDefault: boolean): JSX.Element => {
    return (
      <YoodliMenu
        menuContainerSx={{ mr: 2 }}
        buttonSx={{
          border: "none",
          mx: 0,
        }}
        type={YoodliMenuButtonType.Icon}
        menuItems={[
          {
            title: "Delete",
            onClick: () => onDeleteClick(spaceId),
            type: YoodliMenuItemType.Warning,
          },
        ]}
        menuDisabled={isDefault}
      />
    );
  };

  return (
    <Drawer
      open={
        drawerState === ContentSpaceDrawerState.Manage ? open : copyContentInfo.isCopyDrawerOpen
      }
      anchor="bottom"
      onClose={handleDrawerClose}
      hideBackdrop
      sx={{
        left: navDrawerOffset,
        zIndex: 1099,
      }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          position: "relative",
          top: PADDING_TOP,
          height: `calc(100% - ${PADDING_TOP})`,
          background: getDynamicColor("light1"),
          alignItems: "left",
          px: 4,
          py: 7,
        },
      }}
    >
      <IconButton
        onClick={handleDrawerClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 50,
          alignSelf: "top",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack gap={1}>
        <Typography
          sx={{
            textAlign: "left",
            fontFamily: "poppins",
            fontWeight: 700,
            fontSize: 18,
            color: getDynamicColor("purple3"),
            mt: 3,
            ml: { xs: 0, md: 2 },
          }}
        >
          {drawerState === ContentSpaceDrawerState.Manage
            ? "Your Content Spaces"
            : `Copy "${copyContentInfo?.copyRequest?.content?.name ?? ""}" to:`}
        </Typography>
        {drawerState === ContentSpaceDrawerState.Manage ? (
          <Typography
            sx={{
              ml: { xs: 0, md: 2 },
              width: "80%",
              fontSize: 12,
            }}
          >
            Want to learn more about content spaces? Check out this{" "}
            <Link href={SupportPages.CONTENT_SPACES} target="_blank">
              support article
            </Link>
            {"."}
          </Typography>
        ) : (
          <Typography
            sx={{
              ml: { xs: 0, md: 2 },
              width: "80%",
              fontSize: 12,
            }}
          >
            Copying will copy all associated customizations. To learn more, read this{" "}
            <Link href={SupportPages.SCENARIOS} target="_blank">
              support article
            </Link>
            {"."}
          </Typography>
        )}
      </Stack>
      <Grid
        container
        columns={{ xs: 1, md: 2 }}
        rowSpacing={2}
        columnSpacing={{ xs: 0, md: 1 }}
        maxWidth="lg"
        sx={{
          my: 1,
          mr: { xs: 1, md: 2 },
          pr: { xs: 1, md: 2 },
          ml: { xs: 0, md: 1 },
        }}
      >
        {isUserMinimumEffectiveRole(effectiveRole, EffectiveRole.ORG_ADMIN) && (
          <Grid item xs={1} sx={{ pl: 0 }}>
            <CreateContentSpaceCard
              onClick={handleCreate}
              disabled={drawerState === ContentSpaceDrawerState.Copy}
            />
          </Grid>
        )}
        {spaces.map((space, key) => {
          const isSelected = space.id === curSpaceId;

          return (
            <Grid item xs={1} key={key}>
              <ContentSpaceCard
                key={key}
                name={space.name}
                adminCount={space.admins.length}
                selected={isSelected}
                isDefault={space.is_default}
                isLoading={copyContentInfo.requestPendingId === space.id}
                additionalMenu={
                  isOrgOwnerAdmin(defaultOrg) &&
                  renderContentSpaceOptions(space.id, space.is_default)
                }
                onClick={() => onSelectClick(space.id)}
              />
            </Grid>
          );
        })}
        {showCreate && (
          <Grid item xs={1} sx={{ pl: 0 }}>
            <CreateContentSpaceCard
              onClick={handleCreate}
              disabled={drawerState === ContentSpaceDrawerState.Copy}
            />
          </Grid>
        )}
      </Grid>

      {showFTUX && (
        <Stack alignItems="center" justifyContent="center" spacing={6}>
          <AnimatedGradientText sx={{ textAlign: "center", fontSize: 30, fontWeight: 800 }}>
            Get organized with content spaces
          </AnimatedGradientText>
          <Stack spacing={6}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <FileFolder style={{ flexShrink: 0 }} />
              <Typography sx={{ fontSize: 16, fontWeight: 500, color: getDynamicColor("purple3") }}>
                Create and manage content within dedicated spaces
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={3}>
              <FileFolderAdmin style={{ flexShrink: 0 }} />
              <Typography sx={{ fontSize: 16, fontWeight: 500, color: getDynamicColor("purple3") }}>
                Assign content admins who can only manage their space
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
      {modalOpen && (
        <YoodliDeleteModal
          open={modalOpen}
          targetDesc={deleteTarget?.name}
          onClose={() => onModalClose()}
          onDelete={() => onDeleteConfirmed()}
        />
      )}
    </Drawer>
  );
};

export default ManageContentSpacesDrawer;
