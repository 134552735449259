import React from "react";

// Components
import { Button, Stack, Typography } from "@mui/material";
import { AddContentModal } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/AddContentModal";
import { UploadedFiles } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/UploadedFiles";

// Utils
import { useContentUpload } from "hooks/useContentUpload";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { getDynamicColor } from "lib-frontend/utils/Colors";

const MAX_FILES = 5;

type EditPrereadFilesProps = {
  prereadFiles: string[];
  updatePrereadFiles: (prereadFileIds: string[]) => void;
};
export const EditPrereadFiles = ({
  prereadFiles,
  updatePrereadFiles,
}: EditPrereadFilesProps): JSX.Element => {
  const [showAddContentModal, setShowAddContentModal] = React.useState<boolean>(false);

  const handleAddNewContent = (filesIds: string[]) => {
    const updatedPrereadFiles = [...prereadFiles, ...filesIds];
    updatePrereadFiles(updatedPrereadFiles);
  };

  const { uploadingFiles, handleUploadContent, handleCancelFileUpload, handleClearUploadedFiles } =
    useContentUpload({ handleAddNewContent: handleAddNewContent });
  const { orgFilesQuery } = useOrgFilesQuery();

  const content = React.useMemo(() => {
    if (!orgFilesQuery?.data?.files) return [];

    return (prereadFiles ?? []).map((fileId) => ({
      id: fileId,
      name: orgFilesQuery?.data?.files?.find((file) => file.id === fileId)?.name ?? "",
    }));
  }, [orgFilesQuery?.data?.files, prereadFiles]);

  const cancelFileUpload = async (fileId: string) => {
    const updatedPrereadFiles = prereadFiles.filter((id) => id !== fileId);
    updatePrereadFiles(updatedPrereadFiles);
    handleCancelFileUpload(fileId);
  };

  const handleClearAllFiles = () => {
    updatePrereadFiles([]);
    handleClearUploadedFiles();
  };

  return (
    <>
      <Stack gap={1}>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: 12,
            fontWeight: 600,
            color: getDynamicColor("purple3"),
          }}
        >
          (Optional) Add up to 5 files to give the AI more context.
        </Typography>
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setShowAddContentModal(true)}
            disableRipple
            sx={{ fontSize: 14, px: 0 }}
          >
            Add files
          </Button>
          {prereadFiles.length > 0 && (
            <Button
              onClick={handleClearAllFiles}
              sx={{
                fontSize: 12,
                color: getDynamicColor("purple3"),
              }}
            >
              Clear all
            </Button>
          )}
        </Stack>
        <UploadedFiles files={content} handleCancelFileUpload={cancelFileUpload} />
      </Stack>
      <AddContentModal
        uploadingFiles={uploadingFiles}
        handleUploadContent={handleUploadContent}
        amtOfFilesLeft={MAX_FILES - prereadFiles.length}
        handleCancelFileUpload={cancelFileUpload}
        showAddContentModal={showAddContentModal}
        setShowAddContentModal={setShowAddContentModal}
        content={content}
        handleAddNewContent={handleAddNewContent}
      />
    </>
  );
};
