// Utils
import { Record, Static, String, Boolean, Array as RTArray, Union, Null, Optional } from "runtypes";

export const CreateOrgSpaceRequest = Record({
  name: String,
});
export type CreateOrgSpaceRequest = Static<typeof CreateOrgSpaceRequest>;

export const CreateOrgSpaceResponse = Record({
  org_space_id: String,
});
export type CreateOrgSpaceResponse = Static<typeof CreateOrgSpaceResponse>;

export const OrgSpaceAdmin = Record({
  email: String,
  display_name: Union(String, Null),
  is_invited: Optional(Boolean),
});

export type OrgSpaceAdmin = Static<typeof OrgSpaceAdmin>;

export const OrgSpaceItem = Record({
  id: String,
  name: String,
  created_date: String,
  created_by_email: Union(String, Null),
  is_default: Boolean,
  admins: RTArray(OrgSpaceAdmin),
});

export type OrgSpaceItem = Static<typeof OrgSpaceItem>;

export const GetOrgSpacesResponse = Record({
  spaces: RTArray(OrgSpaceItem),
});
export type GetOrgSpacesResponse = Static<typeof GetOrgSpacesResponse>;

export const UpdateOrgSpaceRequest = Record({
  name: String,
});
export type UpdateOrgSpaceRequest = Static<typeof UpdateOrgSpaceRequest>;
