import { noop } from "lodash";
import React from "react";

// Components
import { DialogDrawer } from "lib-frontend/components/DialogDrawer";

// Utils
import DowngradeModalContent from "./DowngradeModalContent";
import GoAnnualModalContent from "./GoAnnualModalContent";
import PendingPlanChangeModalContent from "./PendingPlanChangeModalContent";
import PricingOptionsModalContent from "./PricingOptionsModalContent";
import { usePricingUsage } from "hooks";
import { CustomEventEnum, publish } from "lib-frontend/events";
import { useNavDrawerOffset, useShouldRenderPricingGate } from "lib-frontend/hooks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { userDocsService } from "lib-frontend/utils/LiveUserDocs";
import { PricingDurationOption } from "lib-frontend/utils/PricingData";
import { UserDocTypes } from "lib-fullstack/db";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type PricingModalProps = {
  open: boolean;
  hideBackdrop?: boolean;
  onClose: () => void;
  hasPendingPlan?: boolean;
  annualSavingsAmt?: number;
  handleGoAnnual?: () => void;
};

export default function PricingModal({
  open,
  hideBackdrop,
  onClose,
  hasPendingPlan = false,
  annualSavingsAmt = 0,
  handleGoAnnual = noop,
}: PricingModalProps): JSX.Element {
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();
  const shouldRenderPricingGate = useShouldRenderPricingGate();
  const {
    usagePlanType,
    usagePlanDuration,
    usagePlanRenewalDate,
    usedSpeeches,
    nextUsagePlanType,
    quota,
  } = usePricingUsage();
  const [loading, setLoading] = React.useState(false);

  const [showPendingPlanChange, setShowPendingPlanChange] = React.useState(hasPendingPlan);

  const [downgradeSelection, setDowngradeSelection] = React.useState<UsagePlanType | null>(null);
  const [durationChange, setDurationChange] = React.useState<boolean>(false);

  const [pricingDurationOption, setPricingDurationOption] = React.useState<PricingDurationOption>(
    PricingDurationOption.ANNUALLY,
  );

  React.useEffect(() => {
    if (hasPendingPlan) {
      setShowPendingPlanChange(true);
    }
  }, [hasPendingPlan]);

  React.useEffect(() => {
    if (open) {
      publish(CustomEventEnum.PRICING_MODAL_OPEN);
    }
  }, [open]);

  // TODO: 2024-05-02 - Because on mobile the "modal" is actually a drawer that is mounted right away
  // the site crashes unless we wait to render until the user docs are resolved and the pExpVersion is rea dy to be used/referenced
  if (
    !userDocsService.liveUserDocsAreResolved() ||
    !userDocsService.getUserDocs()[UserDocTypes.MAIN].pExpVersion
  ) {
    return null;
  }

  const handleSetPricingDurationOption = () => {
    setPricingDurationOption(
      pricingDurationOption === PricingDurationOption.ANNUALLY
        ? PricingDurationOption.MONTHLY
        : PricingDurationOption.ANNUALLY,
    );
  };

  const handleOptionSelected = () => {
    // show an error is there is already a pending plan change
    if (nextUsagePlanType) {
      setShowPendingPlanChange(true);
    }
  };

  const handleCloseModal = () => {
    onClose();
    // only reset the state after the close animation completes
    setTimeout(() => {
      handleResetState();
    }, 300);
  };

  const handleResetState = () => {
    setShowPendingPlanChange(hasPendingPlan);
    setDowngradeSelection(null);
    setDurationChange(false);
    setPricingDurationOption(PricingDurationOption.ANNUALLY);
    setLoading(false);
  };

  const renderModalContent = (): JSX.Element => {
    // must check before downgradeSelection because downgrades also fail when pending plans exist
    if (showPendingPlanChange) {
      return <PendingPlanChangeModalContent handleCloseModal={handleCloseModal} />;
    } else if (durationChange) {
      return (
        <GoAnnualModalContent
          open
          handleCloseModal={handleCloseModal}
          annualSavingsAmt={annualSavingsAmt}
          handleGoAnnual={handleGoAnnual}
        />
      );
    } else if (downgradeSelection) {
      return (
        <DowngradeModalContent
          usagePlanType={usagePlanType}
          usagePlanDuration={usagePlanDuration}
          usagePlanRenewalDate={usagePlanRenewalDate}
          downgradeSelection={downgradeSelection}
          setDowngradeSelection={setDowngradeSelection}
        />
      );
    }

    return (
      <PricingOptionsModalContent
        onClose={handleCloseModal}
        handleOptionSelected={handleOptionSelected}
        loading={loading}
        setLoading={setLoading}
        shouldRenderPricingGate={shouldRenderPricingGate}
        usedSpeeches={usedSpeeches}
        usagePlanType={usagePlanType}
        quota={quota}
        setDowngradeSelection={setDowngradeSelection}
        setDurationChange={setDurationChange}
        pricingDurationOption={pricingDurationOption}
        handleSetPricingDurationOption={handleSetPricingDurationOption}
      />
    );
  };

  const sharedStyles = {
    position: "relative",

    borderRadius: "16px",
    boxShadow: Y_SHADOWS.box_shadow_7,
    background: getDynamicColor("light1"),
    p: { xs: 3, md: 5 },
    pt: { xs: 4, md: 6 },
    zIndex: 12,
    // override modal styles if downgrade modal or pending plan change modals are showing
    ...(!!downgradeSelection || showPendingPlanChange
      ? {
          width: "min(600px, 100%)",
          maxWidth: `calc(100% - ${navDrawerOffset + 32}px) !important`,
          p: "0 !important",
        }
      : {}),
  };

  return (
    <DialogDrawer
      open={open}
      onOpen={noop}
      hideBackdrop={hideBackdrop}
      onClose={handleCloseModal}
      dialogProps={{
        sx: {
          ...modalStyles,
          left: navDrawerOffset,
          cursor: "default",
        },
        PaperProps: {
          sx: {
            ...sharedStyles,
            overflow: "auto",
            m: 2,
            width: `min(${durationChange ? 600 : 1300}px, 100%)`,
            maxWidth: {
              xs: "calc(100% - 32px) !important",
              md: `calc(100% - 32px) !important`,
            },
          },
        },
      }}
      drawerProps={{
        // come up from bottom
        anchor: "bottom",
        sx: {
          zIndex: "1203 !important",
        },
        PaperProps: {
          sx: {
            ...sharedStyles,
            position: "relative",
            width: "100%",
            mt: 4,
            pb: `24px !important`,
          },
        },
      }}
    >
      {renderModalContent()}
    </DialogDrawer>
  );
}
