// Components
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Stack, IconButton, Typography, Button } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type BulkActionDeleteChipProps = {
  bulkActionItems: string[];
  clearBulkActionItems: () => void;
  disableDeleteAction: boolean;
  handleDelete: () => void;
  tooltipText?: string;
};

export const BulkActionDeleteChip = ({
  bulkActionItems,
  clearBulkActionItems,
  disableDeleteAction,
  handleDelete,
  tooltipText,
}: BulkActionDeleteChipProps): JSX.Element => {
  return (
    bulkActionItems.length > 0 && (
      <Stack
        direction="row"
        gap={1}
        sx={{
          alignItems: "center",
          backgroundColor: getDynamicColor("programChart.blue"),
          borderRadius: "50px",
          height: 35,
        }}
      >
        <IconButton onClick={clearBulkActionItems}>
          <CloseIcon sx={{ width: 16, height: 16, color: getDynamicColor("purple3") }} />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: 500,
            fontSize: "12px",
            color: getDynamicColor("purple3"),
            whiteSpace: "nowrap",
          }}
        >
          {bulkActionItems.length} selected
        </Typography>
        <YoodliTooltip title={tooltipText}>
          <Button
            onClick={handleDelete}
            disabled={disableDeleteAction}
            color="error"
            endIcon={<DeleteOutlineIcon sx={{ width: 16, height: 16 }} />}
            sx={{ fontWeight: 500, fontSize: "12px" }}
          >
            Delete
          </Button>
        </YoodliTooltip>
      </Stack>
    )
  );
};
