import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSpeechShare } from "lib-frontend/modules/AxiosInstance";
import { markOnboardingTaskCompleteAndEmitEvent } from "lib-frontend/utils/onboardingUtils";
import { db } from "lib-fullstack";
import { AGGREGATE_PERMISSIONS } from "../ShareSpeechWindow";
import { SPEECH_QUERY_KEY } from "../useSpeechQuery";

export const useShareSpeechMutation = (): UseMutationResult<
  void,
  Error,
  {
    speechId: string;
    sharePermissions: string;
    shareWithEvaluators: boolean;
  }
> => {
  const queryClient = useQueryClient();
  const shareSpeechMutation = useMutation({
    mutationFn: async ({
      speechId,
      sharePermissions,
      shareWithEvaluators,
    }: {
      speechId: string;
      sharePermissions: string;
      shareWithEvaluators: boolean;
    }) => {
      if (
        sharePermissions === AGGREGATE_PERMISSIONS.ALL ||
        sharePermissions === AGGREGATE_PERMISSIONS.ORG
      ) {
        markOnboardingTaskCompleteAndEmitEvent(db.OnboardingChecklistTask.SHARE_SPEECH).catch(
          (err) =>
            console.error(
              `Error marking onboarding task SHARE_SPEECH completed by setting linkSharing public: ${err}`,
            ),
        );
      }
      await updateSpeechShare(speechId, {
        link_sharing: sharePermissions === AGGREGATE_PERMISSIONS.ALL,
        org_link_sharing: sharePermissions === AGGREGATE_PERMISSIONS.ORG,
        add_evaluators: shareWithEvaluators ? shareWithEvaluators : undefined,
      });
    },
    onSuccess: (_, params) => {
      return queryClient.invalidateQueries({ queryKey: [SPEECH_QUERY_KEY, params.speechId] });
    },
    onError: (error) => {
      console.error("Error sharing speech: ", error);
    },
  });
  return shareSpeechMutation;
};
