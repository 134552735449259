// Components
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { DisplayableSampleSpeechInfo } from "lib-fullstack/db/db_runtypes_sample_speech";

type WordsProps = {
  speech: DisplayableSampleSpeechInfo;
  index: number;
};

export const SampleSpeechItem = ({ speech, index }: WordsProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  const slugify = (str: string): string => {
    return encodeURIComponent(
      str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, ""),
    );
  };

  const getSpeechSummaryUrl = (speech: DisplayableSampleSpeechInfo) => {
    if (!speech?.slug) {
      return;
    }
    return `${WebServerExternalPath.SHARE}${speech.slug}/${slugify(speech.name)}`;
  };
  return (
    <Box key={index}>
      {index > 0 && (
        <Divider
          sx={{
            borderBottomWidth: "2px",
            borderRadius: 1,
            backgroundColor: getDynamicColor("dark4"),
          }}
        />
      )}
      <Stack
        sx={{
          pb: { xs: 4, md: 8 },
          pt: index === 0 ? 0 : { xs: 4, md: 8 },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box flex={1} sx={{ maxWidth: 380, mb: 2, mx: "auto" }}>
          <a href={getSpeechSummaryUrl(speech)} target="_blank" rel="noopener noreferrer">
            <LazyLoadImage
              style={{
                width: "100%",
                borderRadius: "16px",
                cursor: "pointer",
                boxShadow: isSmallScreen ? "-8px 8px 6px 0" : "-16px 16px 6px 0",
                color: getDynamicColor("purple1"),
              }}
              effect="blur"
              src={speech.thumbnailPath}
            />
          </a>
        </Box>
        <Stack
          sx={{
            flexDirection: "column",
            flex: 1,
            paddingX: { xs: 0, md: 4 },
            color: getDynamicColor("purple3"),
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "24px" },
              fontWeight: 600,
              pt: { xs: "16px", md: "0px" },
            }}
          >
            {speech.name}
          </Typography>
          {!isSmallScreen && (
            <Stack direction="row">
              <Stack direction="column" flex={1} paddingY={2}>
                <SampleSpeechAnalytic title="PACING" value={speech.wpm} label=" wpm" />
                <SampleSpeechAnalytic title="TOP KEY WORD" value={speech.topKeyWord} />
              </Stack>
              <Stack direction="column" flex={1} paddingY={2}>
                <SampleSpeechAnalytic
                  title="FILLER WORDS"
                  value={Math.round(speech.percentFillerWords)}
                  label="%"
                />
                <SampleSpeechAnalytic title="TOP WEAK WORD" value={speech.topWeakWord} />
              </Stack>
            </Stack>
          )}
          <Button
            variant="contained"
            sx={{
              marginTop: { xs: 2, md: 0 },
            }}
            href={getSpeechSummaryUrl(speech)}
            target="_blank"
            endIcon={<OpenInNewIcon />}
          >
            View Speech
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

const SampleSpeechAnalytic = (props: {
  title: string;
  value: string | number;
  label?: string;
}): JSX.Element => {
  return (
    <Box paddingY={2}>
      <Typography
        sx={{
          fontSize: "12px",
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        {props.value}
        {props.label}
      </Typography>
    </Box>
  );
};
