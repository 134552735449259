import React from "react";

// Components
import { Stack, Box, CircularProgress, Typography } from "@mui/material";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";

// Utils
import { OrgProgramsQueryKeys, ScenarioSubQueryKeys } from "./OrgPrograms";
import { ProgramStateIndicator } from "./ProgramStateIndicator";
import { ProgramStepProgress } from "./ProgramStepProgress";
import { ProgramUserDashboard } from "./ProgramUserDashboard";
import { ProgramUserStat, ProgramUserStatProps } from "./ProgramUserStat";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getAllCustomGoals,
  getOrgProgramUser,
  listScenarios,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parseProgramProgressDate } from "lib-frontend/utils/orgUtils";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { ProgramRecordState } from "lib-fullstack/utils/enums";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

type ProgramUserProps = {
  program: ProgramResponse;
  userId: string;
  handleBack: () => void;
};

export const ProgramUser = ({ program, userId }: ProgramUserProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const programUserQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ProgramUser, defaultOrgId, program.id, userId],
    queryFn: () => getOrgProgramUser(defaultOrgId, program.id, userId),
  });

  const scenariosQuery = useApiQuery({
    queryKey: [
      OrgProgramsQueryKeys.ScenarioList,
      defaultOrgId,
      ScenarioSubQueryKeys.Org,
      curSpaceId,
    ],
    queryFn: () => listScenarios(defaultOrgId, { space_id: curSpaceId }),
    enabled: !!defaultOrgId && !!curSpaceId,
  });
  const filteredScenarios = scenariosQuery.data?.contentArray.filter(
    (scenario) => !scenario.isTemplate && scenario.enabled,
  );

  const customGoalsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.GoalList, defaultOrgId, curSpaceId],
    queryFn: () => getAllCustomGoals(defaultOrgId, { space_id: curSpaceId }),
    enabled: !!defaultOrgId && !!curSpaceId,
  });

  const loading =
    programUserQuery.isLoading || scenariosQuery.isLoading || customGoalsQuery.isLoading;

  const programUserStats: ProgramUserStatProps[] = loading
    ? null
    : [
        {
          title: "ATTEMPTS STARTED",
          value: programUserQuery.data.num_attempt_started,
        },
        {
          title: "ATTEMPTS COMPLETED",
          value: programUserQuery.data.total_count_attempts,
        },
        {
          title: "MINUTES RECORDED",
          value: Math.round(programUserQuery.data.total_seconds_attempts / 60),
          valueDescription: "minutes",
        },
        {
          title: "SCENARIOS COMPLETED",
          value: programUserQuery.data.num_steps_completed,
          valueDescription: `out of ${programUserQuery.data.plan_step_results.length}`,
        },
      ];

  return (
    <Stack
      gap={{ xs: 3, md: 7 }}
      sx={{
        minHeight: { xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`, md: "100dvh" },
        background: getDynamicColor("light1"),
        pb: { xs: 2, md: 14 },
      }}
    >
      {loading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: 300,
            width: "100%",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack gap={7} sx={{ px: { xs: 2, md: 7 } }}>
          <Stack gap={2} direction="row" sx={{ alignItems: "center" }}>
            <YoodliAvatar name={programUserQuery.data.name} />
            <Stack gap={0.5}>
              <Stack gap={2} direction="row" sx={{ alignItems: "center" }}>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {programUserQuery.data.name}
                </Typography>
                <ProgramStateIndicator state={programUserQuery.data.state as ProgramRecordState} />
              </Stack>
              <Stack gap={0.5} direction="row" sx={{ alignItems: "center" }}>
                <CalendarIcon style={{ color: getDynamicColor("purple3") }} />
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "10px",
                    fontWeight: 400,
                  }}
                >
                  {parseProgramProgressDate(programUserQuery.data)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              Overall stats
            </Typography>
            <Stack
              gap={{ xs: 2, md: 3 }}
              direction="row"
              sx={{ flexWrap: "wrap", width: "100%", overflowX: "auto" }}
            >
              {programUserStats.map((stat) => (
                <Box
                  sx={{
                    flexBasis: {
                      xs: "calc(50% - 8px)",
                      md: "calc(50% - 12px)",
                      lg: "calc(25% - 18px)",
                    },
                  }}
                >
                  <ProgramUserStat key={stat.title} {...stat} />
                </Box>
              ))}
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              Program progress
            </Typography>
            <Stack gap={3}>
              {programUserQuery.data.plan_step_results.map((step, index) => (
                <ProgramStepProgress
                  key={`step-${index}`}
                  step={program.plan_steps[index]}
                  stepResult={step}
                  scenario={filteredScenarios.find(
                    (scenario) => scenario.id === program.plan_steps[index].scenario_id,
                  )}
                  customGoals={customGoalsQuery.data.goals}
                  userId={userId}
                />
              ))}
            </Stack>
          </Stack>
          <ProgramUserDashboard program={program} user={programUserQuery.data} />
        </Stack>
      )}
    </Stack>
  );
};
