import firebase from "firebase/app";
import { db } from "lib-fullstack";
import _ from "lodash";

// Utils
import { SPEECH_SUBTYPES, SPEECH_TYPES } from "../../../utils/Enums";
import { getEnumKey } from "../../../utils/Utilities";
import { Entry, stringToEntry } from "../ShareSpeechByEmail";
import { Speech } from "lib-fullstack/db";

export const ERROR_STATE_STRINGS = {
  NOT_SHARED: `This speech is currently private.
          Please login to view this speech or ask the speaker to grant you access and then try
          again!`,
  ACCESS_DENIED: `Oops, you don't have access to view this page`,
  COULD_NOT_LOAD: "Could not load speech details",
  DELETED: "This speech has been deleted",
};

export enum SummaryToggleViews {
  ANALYTICS = "Analytics",
  COMMENTS = "Comments",
}

// Select text inside DOM element
export function selectElementContents(el: HTMLSpanElement): void {
  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

// Maps speech to its type in string format
export function getSpeechTypeString(dbSpeech: db.Doc<db.Speech>): string {
  if (dbSpeech.data.type === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.ZOOM_RECALL)) {
    return "Zoom call";
  } else if (
    dbSpeech.data.type === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.LIVE) &&
    dbSpeech.data.subType === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.JOB_INTERVIEW)
  ) {
    return "interview";
  } else {
    return "speech";
  }
}

export const EVAL_STAR_LABELS = {
  1: "Developing",
  2: "Emerging",
  3: "Accomplished",
  4: "Excels",
  5: "Exemplary",
};

type Participants = Speech["speakers"] | Speech["attendees"];
type Participant = Speech["speakers"][number] | Speech["attendees"][number];
type SpeechParticipants = Pick<Speech, "attendees" | "collabs" | "speakers"> & {
  excludeMyEmail: string;
};

function extractEmails(excludeEmail: string, participants?: Participants): string[] {
  return Object.values<Participant>(participants ?? {})
    .filter((s) => !!s.emailAddress)
    .filter((s) => s.emailAddress.trim().toLowerCase() !== excludeEmail)
    .map((s) => s.emailAddress);
}

export function getEmailAddresses(props: SpeechParticipants): {
  calAttendees: string[];
  excludingCollabs: Entry[];
  collabs: _.Dictionary<{ email: string }>;
} {
  const { excludeMyEmail } = props;
  const speakers = extractEmails(excludeMyEmail, props.speakers);
  const calAttendees = extractEmails(excludeMyEmail, props.attendees);

  const collabs = _.pickBy(
    props.collabs ?? {},
    (c) => c.email.trim().toLowerCase() !== excludeMyEmail,
  );
  const collabEmails = Object.values(collabs).map((c) => c.email);

  const excludingCollabs = _.without(
    _.uniq([...speakers, ...calAttendees]),
    ...collabEmails, // exclude people that this speech has already been shared with
  )
    .sort()
    .map(stringToEntry);

  return { calAttendees, collabs, excludingCollabs };
}

export function hasAttendeesToShareWithStill(props: SpeechParticipants): boolean {
  const { calAttendees, excludingCollabs } = getEmailAddresses(props);
  return !_.isEmpty(calAttendees) && !_.isEmpty(excludingCollabs);
}

// note the hasCurrentUser param is used to suppress errors when the user is not resolved yet. It should only be used in situations where we are certain we won't suppress a real error.
export function handleSpeechPermissionsError(
  error: unknown,
  objectType: string,
  hasCurrentUser?: boolean,
): void {
  const typedError = error as firebase.FirebaseError;

  if (typedError) {
    if (typedError.code === "permission-denied") {
      // if we get this error because the user is not resolved yet, we can entirely suppress it in some cases
      if (hasCurrentUser !== false) {
        console.log(
          `Permission denied from server to read from ${objectType}. Private speech or cannot access`,
        );
      }
    } else {
      console.error(typedError);
    }
  }
}
