import React from "react";

// Components
import { Button, CircularProgress, InputLabel, Stack, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { OrgDetailCardItemLabel } from "./AdminConfigOrgDetails";
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import { useValidatePositiveInt } from "auth/utils/validator";
import { adminUpdateOrgData } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { AdminUpdateOrgRequest } from "lib-fullstack/api/adminApiTypes";

export const UpdateQuotasForm = ({
  orgId,
  card,
  setRerenderKey,
  close,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  // Hooks
  const [courseQuota, setCourseQuota] = React.useState<string>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.COURSE_QUOTA).value.toString(),
  );
  const [videoQuota, setVideoQuota] = React.useState<string>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.VIDEO_QUOTA).value.toString(),
  );
  const [demoQuota, setDemoQuota] = React.useState<string>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.DEMO_QUOTA).value.toString(),
  );
  const [hubQuota, setHubQuota] = React.useState<string>(
    card.items.find((item) => item.label === OrgDetailCardItemLabel.HUB_QUOTA).value.toString(),
  );

  const [loadingText, setLoadingText] = React.useState<string>("");
  const [errorText, setErrorText] = React.useState<string>("");

  const errors = {
    courseQuota: useValidatePositiveInt(courseQuota),
    videoQuota: useValidatePositiveInt(videoQuota),
    demoQuota: useValidatePositiveInt(demoQuota),
    hubQuota: useValidatePositiveInt(hubQuota),
  };

  const isFormValid = () => {
    return ![...Object.values(errors)].some((error) => !!error);
  };

  const handleUpdateOrg = async (e) => {
    e.preventDefault();

    setErrorText("");
    try {
      setLoadingText(`Updating ${card.label}`);
      const params: AdminUpdateOrgRequest = {
        quotas: {
          courseQuota: parseInt(courseQuota),
          courseVideoQuota: parseInt(videoQuota),
          demoVideoQuota: parseInt(demoQuota),
          hubQuota: parseInt(hubQuota),
        },
      };
      await adminUpdateOrgData(orgId, params);
      setRerenderKey((prevKey: number) => prevKey + 1);
      close();
    } catch (e) {
      console.log(`error updating ${card.label}: ${e}`);
      setErrorText(`Error Updating ${card.label}: ${e}`);
    }
    setLoadingText("");
  };

  return (
    <Stack width="100%" gap={2} p={3}>
      {errorText && (
        <Typography p={1} color="error" fontWeight={600} fontSize="12px">
          {errorText}
        </Typography>
      )}
      <form style={{ width: "100%" }} onSubmit={async (e) => await handleUpdateOrg(e)}>
        <Stack gap={3}>
          <Stack>
            <InputLabel
              htmlFor="course-quota-select"
              sx={{
                pb: 0.5,
                ml: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Course Quota
            </InputLabel>
            <YoodliTextfield
              id="course-quota-select"
              type="number"
              placeholder="Course quota"
              value={courseQuota}
              required={true}
              onChange={(e) => setCourseQuota(e.target.value)}
              error={!!errors.courseQuota}
              helperText={errors.courseQuota}
              fullWidth
              InputProps={{
                sx: {
                  fontSize: "16px",
                  fontWeight: 500,
                },
              }}
            />
          </Stack>
          <Stack>
            <InputLabel
              htmlFor="video-quota-select"
              sx={{
                pb: 0.5,
                ml: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Videos per course quota
            </InputLabel>
            <YoodliTextfield
              id="video-quota-select"
              type="number"
              placeholder="Videos per course quota"
              value={videoQuota}
              required={true}
              onChange={(e) => setVideoQuota(e.target.value)}
              error={!!errors.videoQuota}
              helperText={errors.videoQuota}
              fullWidth
              InputProps={{
                sx: {
                  fontSize: "16px",
                  fontWeight: 500,
                },
              }}
            />
          </Stack>
          <Stack>
            <InputLabel
              htmlFor="demo-quota-select"
              sx={{
                pb: 0.5,
                ml: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Demo videos quota
            </InputLabel>
            <YoodliTextfield
              id="demo-quota-select"
              type="number"
              placeholder="Number of seats to license"
              value={demoQuota}
              required={true}
              onChange={(e) => setDemoQuota(e.target.value)}
              error={!!errors.demoQuota}
              helperText={errors.demoQuota}
              fullWidth
              InputProps={{
                sx: {
                  fontSize: "16px",
                  fontWeight: 500,
                },
              }}
            />
          </Stack>
          <Stack>
            <InputLabel
              htmlFor="hub-quota-select"
              sx={{
                pb: 0.5,
                ml: 2,
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Group Quota
            </InputLabel>
            <YoodliTextfield
              id="hub-quota-select"
              type="number"
              placeholder="Group Quota"
              value={hubQuota}
              required={true}
              onChange={(e) => setHubQuota(e.target.value)}
              error={!!errors.hubQuota}
              helperText={errors.hubQuota}
              fullWidth
              InputProps={{
                sx: {
                  fontSize: "16px",
                  fontWeight: 500,
                },
              }}
            />
          </Stack>
        </Stack>

        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        startIcon={
          loadingText.length > 0 ? (
            <CircularProgress
              size={20}
              sx={{
                color: getDynamicColor("dark1"),
              }}
            />
          ) : (
            <></>
          )
        }
        onClick={async (e) => await handleUpdateOrg(e)}
        variant="gradient"
        disabled={!isFormValid() || !!loadingText}
        sx={{
          fontFamily: "poppins",
          fontSize: "16px",
          marginTop: 3,
          fontWeight: 700,
          "&:disabled": {
            color: getDynamicColor("light1"),
            background: getDynamicColor("dark4"),
          },
        }}
      >
        {loadingText.length == 0 ? `Update ${card.label}` : loadingText}
      </Button>
    </Stack>
  );
};
