import React from "react";

// Components
import { Stack } from "@mui/material";

// Utils
import { TrialEnded } from "../TrialEnded";
import { CreateProgram } from "./CreateProgram";
import { CustomizeProgramReminderEmail } from "./CustomizeProgramReminderEmail";
import { NoPrograms } from "./NoPrograms";
import { ProgramInfo } from "./ProgramInfo";
import { ProgramsLibrary } from "./ProgramsLibrary";
import { ProgramUser } from "./ProgramUser";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgPrograms } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { ProgramProvisioningState, ProgramState } from "lib-fullstack/utils/enums";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";

export enum OrgProgramsSectionStatus {
  Default = "Default",
  CreateProgram = "CreateProgram",
  ProgramInfo = "ProgramInfo",
  ProgramUser = "ProgramUser",
  SendProgramReminder = "SendProgramReminder",
}

export enum OrgProgramsQueryKeys {
  OrgPrograms = "OrgPrograms",
  HubData = "HubData",
  ScenarioList = "ScenarioList",
  ScenarioInfo = "ScenarioInfo",
  GoalList = "GoalList",
  ProgramUsers = "ProgramUsers",
  ProgramUser = "ProgramUser",
  ProgramDashboardReports = "ProgramDashboardReports",
  ProgramStep = "ProgramStep",
  MemberView = "MemberView",
  ProgramReminder = "ProgramReminder",
  NumProgramUsersByStatus = "NumProgramUsersByStatus",
}

export enum ScenarioSubQueryKeys {
  Member = "Member",
  Org = "Org",
}

type OrgProgramsProps = {
  setShowBackHeader: React.Dispatch<React.SetStateAction<boolean>>;
  sectionStatus: string;
  setSectionStatus: (qParam: string) => void;
};

export default function OrgPrograms({
  setShowBackHeader,
  sectionStatus,
  setSectionStatus,
}: OrgProgramsProps): JSX.Element {
  const {
    adminInfo: { defaultOrg, defaultOrgLoading },
    defaultOrgId,
  } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const [programId, setProgramId] = useQueryParamState(
    OrgProgramsQueryParams.PROGRAM_ID,
    undefined,
  );
  const [userId, setUserId] = useQueryParamState(OrgProgramsQueryParams.USER_ID, undefined);

  const orgProgramsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId, curSpaceId],
    queryFn: () => listOrgPrograms(defaultOrgId, { space_id: curSpaceId }),
    enabled: !!defaultOrg && !!curSpaceId,
    refetchInterval: (query) =>
      query?.state?.data?.some(
        (program) => program.provisioning_state === ProgramProvisioningState.InProgress,
      )
        ? 1000
        : 0,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!programId && sectionStatus === OrgProgramsSectionStatus.ProgramInfo) {
      setSectionStatus(OrgProgramsSectionStatus.Default);
    }
  }, [programId]);

  React.useEffect(() => {
    if (!userId && sectionStatus === OrgProgramsSectionStatus.ProgramUser) {
      setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
    }
  }, [userId]);

  const renderDefaultContent = () => {
    if (isOrgTrialEnded(defaultOrg)) {
      return <TrialEnded org={defaultOrg} />;
    } else if (orgProgramsQuery.data?.length > 0) {
      return (
        <ProgramsLibrary
          handleCreateProgram={() => {
            setSectionStatus(OrgProgramsSectionStatus.CreateProgram);
          }}
          orgPrograms={orgProgramsQuery.data}
          handleSelectProgram={(programId) => {
            setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
            setProgramId(programId);
          }}
        />
      );
    } else {
      return (
        <NoPrograms
          handleCreateProgram={() => setSectionStatus(OrgProgramsSectionStatus.CreateProgram)}
        />
      );
    }
  };

  React.useEffect(() => {
    setShowBackHeader(sectionStatus !== OrgProgramsSectionStatus.Default);
  }, [sectionStatus]);

  const renderLayout = () => {
    if (!defaultOrgLoading && !orgProgramsQuery.isPending) {
      switch (sectionStatus) {
        case OrgProgramsSectionStatus.CreateProgram:
          return (
            <CreateProgram handleBack={() => setSectionStatus(OrgProgramsSectionStatus.Default)} />
          );
        case OrgProgramsSectionStatus.ProgramInfo:
          return (
            <ProgramInfo
              program={orgProgramsQuery.data?.find((program) => program.id === programId)}
              handleBack={() => {
                setSectionStatus(OrgProgramsSectionStatus.Default);
                setProgramId(undefined);
              }}
              anyProgramPublished={orgProgramsQuery.data?.some(
                (program) => program.state === ProgramState.Published,
              )}
            />
          );
        case OrgProgramsSectionStatus.ProgramUser:
          return (
            <ProgramUser
              program={orgProgramsQuery.data?.find((program) => program.id === programId)}
              userId={userId}
              handleBack={() => {
                setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
                setUserId(undefined);
              }}
            />
          );
        case OrgProgramsSectionStatus.SendProgramReminder:
          return (
            <CustomizeProgramReminderEmail
              program={orgProgramsQuery.data?.find((program) => program.id === programId)}
            />
          );
        case OrgProgramsSectionStatus.Default:
        default:
          return (
            <Stack
              gap={{ xs: 3, md: 5 }}
              sx={{
                background: getDynamicColor("light1"),
                pb: 14,
                minHeight: { xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`, md: "100dvh" },
              }}
            >
              {renderDefaultContent()}
            </Stack>
          );
      }
    }
  };

  return renderLayout();
}
