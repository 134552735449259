import { Typography } from "@mui/material";
import { BaseContentSpaceCard } from "./BaseContentSpaceCard";

import { getDynamicColor } from "lib-frontend/utils/Colors";

type CreateContentSpaceCardProps = {
  onClick: () => void;
  disabled?: boolean;
};

const CreateContentSpaceCard = ({
  onClick,
  disabled,
}: CreateContentSpaceCardProps): JSX.Element => {
  return (
    <BaseContentSpaceCard
      onClick={onClick}
      disabled={disabled}
      childrenContainerSx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "16px",
          color: !disabled ? getDynamicColor("primary") : getDynamicColor("dark4"),
        }}
      >
        + Create new content space
      </Typography>
    </BaseContentSpaceCard>
  );
};

export default CreateContentSpaceCard;
