import React from "react";

// Utils
import { YoodliActivityContext } from "lib-frontend/contexts/YoodliActivityContext";
import { CustomEventEnum, subscribe, unsubscribe } from "lib-frontend/events";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { NAV_DRAWER_CLOSED_WIDTH, NAV_DRAWER_OPEN_WIDTH } from "lib-frontend/utils/Utilities";

export const DRAWER_MENU_TRANSITION_DURATION = "0.2s";
export const DRAWER_MENU_TRANSITION_DURATION_MS = 200;
export const DRAWER_MENU_TRANSITION_EASING = "ease-out";

export const useNavDrawerOffset = (): {
  navDrawerOffset: number;
  navDrawerOpen: boolean;
  modalStyles: {
    left: { xs: number; md: number };
    transition: string;
  };
} => {
  const isSmallScreen = useIsSmallScreen();
  const { isFocusedActivity } = React.useContext(YoodliActivityContext);
  const [navDrawerOffset, setNavDrawerOffset] = React.useState<number>(
    document?.getElementById(CustomEventEnum.NAV_DRAWER_OPEN)
      ? NAV_DRAWER_OPEN_WIDTH
      : NAV_DRAWER_CLOSED_WIDTH,
  );
  const navDrawerOpen = React.useMemo(
    () => !!document?.getElementById(CustomEventEnum.NAV_DRAWER_OPEN),
    [navDrawerOffset],
  );
  React.useEffect(() => {
    const setNavDrawerOpen = () => setNavDrawerOffset(NAV_DRAWER_OPEN_WIDTH);
    const setNavDrawerClosed = () => setNavDrawerOffset(NAV_DRAWER_CLOSED_WIDTH);
    subscribe(CustomEventEnum.NAV_DRAWER_OPEN, setNavDrawerOpen);
    subscribe(CustomEventEnum.NAV_DRAWER_CLOSE, setNavDrawerClosed);

    return () => {
      unsubscribe(CustomEventEnum.NAV_DRAWER_OPEN, setNavDrawerOpen);
      unsubscribe(CustomEventEnum.NAV_DRAWER_CLOSE, setNavDrawerClosed);
    };
  }, []);
  return {
    navDrawerOffset: isSmallScreen || isFocusedActivity ? 0 : navDrawerOffset,
    modalStyles: {
      left: { xs: 0, md: navDrawerOffset / 2 },
      transition: `left ${DRAWER_MENU_TRANSITION_DURATION} ${DRAWER_MENU_TRANSITION_EASING} !important`,
    },
    navDrawerOpen,
  };
};
