import React from "react";

// Components
import {
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  IconButton,
  Stack,
  SxProps,
  Button,
  ButtonProps,
  CircularProgress,
  MenuProps,
} from "@mui/material";

// Utils
import YoodliTooltip from "./YoodliTooltip";
import { DynamicColorType, getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

export enum YoodliMenuItemType {
  Default = "default",
  Warning = "warning",
  Primary = "primary",
}

export enum YoodliMenuButtonType {
  Text = "text",
  Icon = "icon",
}

const YoodliMenuItemTypeColorMap: Record<YoodliMenuItemType, DynamicColorType> = {
  [YoodliMenuItemType.Default]: "purple3",
  [YoodliMenuItemType.Warning]: "redError",
  [YoodliMenuItemType.Primary]: "primary",
};

export type YoodliMenuItem = {
  title: string | JSX.Element;
  onClick: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
  type?: YoodliMenuItemType;
  disabledTooltip?: string;
  keepOpen?: boolean;
  loading?: boolean;
};

export type YoodliMenuProps = {
  type: YoodliMenuButtonType;
  menuItems: YoodliMenuItem[];
  icon?: JSX.Element;
  buttonSx?: SxProps;
  buttonText?: string;
  buttonProps?: ButtonProps;
  showDropdownArrow?: boolean;
  fitWidth?: boolean;
  menuContainerSx?: SxProps;
  menuDisabled?: boolean;
};

export const YoodliMenu = ({
  type,
  menuItems,
  icon,
  buttonSx,
  buttonProps,
  buttonText,
  showDropdownArrow,
  fitWidth,
  menuContainerSx,
  menuDisabled,
  ...menuProps
}: YoodliMenuProps & Partial<MenuProps>): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuLoadingIndex, setMenuLoadingIndex] = React.useState<number>(undefined);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const renderDropdownArrow = () => {
    return (
      <ExpandMoreRoundedIcon
        sx={{
          transform: anchorEl ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease-in-out",
        }}
      />
    );
  };

  const handleMenuItemClick = async (e, item, index) => {
    e.stopPropagation();
    setMenuLoadingIndex(index);
    await item.onClick(e);
    setMenuLoadingIndex(undefined);
    if (!item.keepOpen) setAnchorEl(null);
  };

  const renderMenuButton = () => {
    switch (type) {
      case YoodliMenuButtonType.Icon:
        return (
          <IconButton
            disabled={menuDisabled}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            sx={{
              m: 0,
              p: 0.5,
              width: "32px",
              height: "32px",
              color: getDynamicColor("primary"),
              "& svg": {
                width: "24px",
                height: "24px",
              },
              ...buttonSx,
            }}
          >
            {icon ?? <MoreVertIcon />}
          </IconButton>
        );
      case YoodliMenuButtonType.Text:
        return (
          <Button
            disabled={menuDisabled}
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            endIcon={showDropdownArrow ? renderDropdownArrow() : null}
            {...buttonProps}
            sx={{
              ...buttonSx,
            }}
          >
            {buttonText ?? "More"}
          </Button>
        );
    }
  };

  return (
    <>
      {renderMenuButton()}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e: Event) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          sx: { width: buttonRef.current && fitWidth && buttonRef.current.offsetWidth },
        }}
        slotProps={{
          paper: {
            sx: {
              background: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
              zIndex: 1000,
            },
          },
        }}
        {...menuProps}
      >
        <Stack
          width={fitWidth ? "100%" : "fit-content"}
          minWidth="150px"
          mt={1.5}
          py={2}
          gap={1}
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{ ...menuContainerSx, background: getDynamicColor("light1") }}
        >
          {menuItems.map((item, index) => {
            const type = item.type ?? YoodliMenuItemType.Default;
            const color = getDynamicColor(YoodliMenuItemTypeColorMap[type]);
            return (
              <YoodliTooltip
                key={index}
                title={item.disabled && item.disabledTooltip}
                sx={{
                  width: "100%",
                }}
              >
                <MenuItem
                  onClick={async (e) => handleMenuItemClick(e, item, index)}
                  disabled={item.disabled || menuLoadingIndex !== undefined}
                  sx={{
                    fontFamily: "Poppins",
                    width: "100%",
                    gap: 1,
                    color: color,
                  }}
                >
                  {menuLoadingIndex === index && <CircularProgress size={12} />}
                  {item.title}
                </MenuItem>
              </YoodliTooltip>
            );
          })}
        </Stack>
      </Menu>
    </>
  );
};
