// Components
import PauseIcon from "@mui/icons-material/PauseCircleOutline";
import StopIcon from "@mui/icons-material/StopCircleOutlined";
import VideoCameraIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import { Button, ButtonProps, Stack } from "@mui/material";

// Utils
import { getDynamicColor } from "../utils/Colors";

type ControlConfig = {
  color: string;
  icon: JSX.Element;
  secondaryColor?: string;
};

const controlButtonConfig: { [k: string]: ControlConfig } = {
  start: {
    color: getDynamicColor("primary"),
    icon: <VideoCameraIcon sx={{ color: getDynamicColor("light1") }} />,
  },
  stop: {
    color: getDynamicColor("redError"),
    secondaryColor: "#FFEAE9",
    icon: (
      <StopIcon
        sx={{
          color: getDynamicColor("redError"),
        }}
      />
    ),
  },
  pause: {
    color: getDynamicColor("secondary"),
    icon: <PauseIcon sx={{ color: getDynamicColor("light1") }} />,
  },
};

// Start/Stop buttons
export default function ControlButton(
  props: ButtonProps & {
    status: keyof typeof controlButtonConfig;
  },
): JSX.Element {
  const icon = controlButtonConfig[props.status].icon;
  const color = controlButtonConfig[props.status].color;
  const secondaryColor = controlButtonConfig[props.status]?.secondaryColor;

  return (
    <Button
      {...props}
      sx={{
        py: 1,
        px: 3,
        borderRadius: "100px",
        "&:hover": {
          backgroundColor: secondaryColor ?? color,
          opacity: 0.8,
        },
        elevation: 0,
        color: secondaryColor ? color : "white",
        fontWeight: 700,
        backgroundColor: secondaryColor ?? color,
        ...props.sx,
      }}
      startIcon={
        icon && (
          <Stack
            sx={{
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            {icon}
          </Stack>
        )
      }
    >
      {props.children}
    </Button>
  );
}
