import firebase from "firebase/app";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";

// Components
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import MeetingsOnboardingMobile from "components/Onboarding/MeetingsOnboardingMobile";
import OpenYoodliNotifier from "components/Onboarding/OnboardingCarousel/OpenYoodliNotifier";
import PoodliDownloadInstructions from "components/PoodliDownload/PoodliDownloadInstructions";
import { WarningRounded as WarningIcon } from "@mui/icons-material";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { submitDownloadPoodliMobileNotificationJob } from "lib-frontend/utils/DbUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import {
  OnboardingAnalyticsEvents,
  PoodliAnalyticsEvents,
  PoodliDownloadedWheres,
} from "lib-fullstack/utils/productAnalyticEvents";
import { getPrivateYoodliDownloadPath } from "utils/Utilities";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { OrgLoading } from "components/Orgs/OrgLoading";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { AuthQueryParams } from "lib-fullstack/utils/queryParams";

export default function DownloadPoodli(): JSX.Element {
  const {
    defaultOrgName,
    loading,
    orgModuleAccess: { desktopAppEnabled },
  } = React.useContext(UserOrgContext);
  const { navDrawerOffset } = useNavDrawerOffset();
  const [showYoodliNotifier, setShowYoodliNotifier] = React.useState<boolean>(false);
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const authedViewer = firebase.auth()?.currentUser;
  const referrerLocation = React.useRef<PoodliDownloadedWheres | undefined>(undefined);

  const downloadPoodli = () => {
    getPrivateYoodliDownloadPath()
      .then((path) => {
        window.location.href = path;
        setShowYoodliNotifier(true);
      })
      .catch((err) => {
        console.warn(`Error getting Private Yoodli download path`, err);
      });
  };

  React.useEffect(() => {
    if (loading && authedViewer) {
      return;
    }
    if (desktopAppEnabled) {
      Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.POODLI_DOWNLOAD_PAGE_VIEWED);
      if (isMobile) {
        if (authedViewer) {
          submitDownloadPoodliMobileNotificationJob().catch((er) => {
            console.error(`Error submitting download poodli mobile notification job: ${er}`);
          });
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const referrerLocationString = urlParams.get(AuthQueryParams.WHERE);
        if (
          Object.values(PoodliDownloadedWheres).includes(
            referrerLocationString as PoodliDownloadedWheres,
          )
        ) {
          referrerLocation.current = referrerLocationString as PoodliDownloadedWheres;
          Instrumentation.logPoodliDownload(referrerLocation.current);
        } else {
          Instrumentation.logPoodliDownload();
        }

        downloadPoodli();
      }
    }
  }, [loading, authedViewer]);

  const onSetUpLaterClicked = () => {
    Instrumentation.logAmplitudeEvent(
      OnboardingAnalyticsEvents.POODLI_DOWNLOAD_PAGE_SET_UP_LATER_CLICKED,
    );
    navigate(WebServerExternalPath.SIGN_UP);
  };

  const renderContent = () => {
    if (loading && authedViewer) {
      return <OrgLoading prompts={["Loading..."]} />;
    }
    if (!desktopAppEnabled) {
      return (
        <Stack
          gap={2.5}
          sx={{
            border: `1px solid ${getDynamicColor("dark2")}`,
            boxShadow: Y_SHADOWS.dark_elevation,
            px: 6,
            pt: 6,
            pb: 5,
            mx: 4.5,
            backgroundColor: getDynamicColor("light1"),
            height: "fit-content",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "20px",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
            }}
          >
            <WarningIcon sx={{ color: getDynamicColor("orangeWarningDark"), mr: 1 }} />
            {defaultOrgName ?? "Your organization"} has disabled access to the desktop version of
            Yoodli
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            If you believe this to be in error, please contact an organization administator.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate(WebServerExternalPath.HOME_LOGGED_IN);
            }}
          >
            Back to Home
          </Button>
        </Stack>
      );
    }
    if (isMobile) {
      return (
        <Stack justifyContent={"center"} textAlign={"center"} gap={3} paddingTop="24px">
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: 500,
              color: getDynamicColor("purple3"),
              px: 1.5,
            }}
          >
            Downloading Private Yoodli is only available on desktop!
          </Typography>
          <MeetingsOnboardingMobile
            sendMobileDownloadEmail={!!authedViewer}
            setUpLater={onSetUpLaterClicked}
            sxOverrides={{ pt: 0 }}
          />
        </Stack>
      );
    }
    return (
      <Container
        maxWidth="xl"
        sx={{ m: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {showYoodliNotifier && !isSmallScreen && <OpenYoodliNotifier />}
        <PoodliDownloadInstructions
          downloadAgain={downloadPoodli}
          where={referrerLocation.current}
          onSetUpLaterClicked={!authedViewer ? onSetUpLaterClicked : null}
          cardSxProps={{
            ...(authedViewer && {
              margin: "unset",
            }),
          }}
        />
      </Container>
    );
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: `calc(100vw - ${navDrawerOffset}px)`,
        mr: `${navDrawerOffset}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...(isMobile && {
          position: "fixed",
        }),
      }}
    >
      {renderContent()}
    </Box>
  );
}
