import React from "react";

// Components
import { VIDEOS_AND_COURSES_QUERY_KEY } from "components/Orgs/ManageContent/OrgManageContent";

// Utils
import { UseMutateAsyncFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import {
  createOrgCourse,
  updateOrgCourse,
  createCourseVideo,
  createDemoVideo,
  deleteCourseVideo,
  deleteDemoVideo,
  updateCourseVideo,
  updateDemoVideo,
  deleteCourse,
} from "lib-frontend/modules/AxiosInstance";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { uploadBlobToResumableUploadUrl } from "lib-frontend/utils/resumableUpload";
import {
  ContentsViewResponse,
  CopyCourseRequest,
  CopyCourseVideoRequest,
  CopyDemoVideoRequest,
  CourseResponse,
  CourseVideoResponse,
  DemoVideoResponse,
  UpdateCourseVideoRequest,
  UpdateDemoVideoRequest,
} from "lib-fullstack/api/hubApiTypes";
import { CreateContentRequestKind } from "lib-fullstack/db";
import { ContentVideoState } from "lib-fullstack/utils/enums";

export type HubsCourseAndDemoVideosTypeV2 = {
  uploadingVideos: DemoVideoResponse[] | CourseVideoResponse[];
  uploading: boolean;
  handleDeleteDemoVideo: UseMutateAsyncFunction<void, Error, string>;
  handleUpdateDemoVideo: UseMutateAsyncFunction<
    void,
    Error,
    { videoId: string; updates: UpdateDemoVideoRequest }
  >;
  handleCreateDemoVideos: UseMutateAsyncFunction<void, Error, File[]>;
  handleCopyDemoVideo: UseMutateAsyncFunction<
    void,
    Error,
    { videoId: string; copyRequest: CopyDemoVideoRequest }
  >;
  clearUploadingVideos: () => void;
  selectedCourse: CourseResponse;
  setSelectedCourse: (course: CourseResponse) => void;
  contentView: ContentsViewResponse;
  setContentView: (content: ContentsViewResponse) => void;
  handleDeleteCourseVideo: UseMutateAsyncFunction<void, Error, string>;
  handleUpdateCourseVideos: UseMutateAsyncFunction<void, Error, string[]>;
  handleUpdateCourseVideo: UseMutateAsyncFunction<
    void,
    Error,
    {
      videoId: string;
      updates: UpdateDemoVideoRequest;
    }
  >;
  handleDeleteCourse: UseMutateAsyncFunction<void, Error, void>;
  handleUpdateCourse: UseMutateAsyncFunction<
    void,
    Error,
    {
      courseId: string;
      title: string;
      description: string;
      availableHubs: string[];
    }
  >;
  handleCopyCourse: UseMutateAsyncFunction<void, Error, { copyRequest: CopyCourseRequest }>;
  handleCreateCourse: UseMutateAsyncFunction<
    void,
    Error,
    {
      title: string;
      description: string;
      availableHubs: string[];
    }
  >;
  handleCreateCourseVideos: UseMutateAsyncFunction<void, Error, File[]>;
  handleCopyCourseVideo: UseMutateAsyncFunction<
    void,
    Error,
    { courseId: string; copyRequest: CopyCourseVideoRequest }
  >;
};

export default function useCourseContentAndDemoVideosV2(
  orgId: string,
  hubId: string,
): HubsCourseAndDemoVideosTypeV2 {
  const queryClient = useQueryClient();
  const [uploadingVideos, setUploadingVideos] = React.useState<
    DemoVideoResponse[] | CourseVideoResponse[]
  >([]);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [contentView, setContentView] = React.useState<ContentsViewResponse>();
  const [selectedCourse, setSelectedCourse] = React.useState<CourseResponse>();

  const clearUploadingVideos = () => {
    setUploadingVideos([]);
    setUploading(false);
  };

  const { mutateAsync: handleDeleteDemoVideo } = useMutation({
    mutationFn: async (videoId: string) => {
      await deleteDemoVideo(orgId, videoId);
      const newUploadingVideos = uploadingVideos.filter((video) => video.id !== videoId);
      setUploadingVideos(newUploadingVideos);
    },
    onSuccess: () => {
      Instrumentation.logOrgWelcomeVideoDeleted(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleUpdateDemoVideo } = useMutation({
    mutationFn: async ({
      videoId,
      updates,
    }: {
      videoId: string;
      updates: UpdateDemoVideoRequest;
    }) => {
      const newDemoVideo = await updateDemoVideo(orgId, videoId, updates);
      setUploadingVideos((uploadingVideos) =>
        uploadingVideos.map((video) => (video.id === videoId ? newDemoVideo : video)),
      );
    },
    onSuccess: () => {
      Instrumentation.logOrgWelcomeVideoUpdated(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleCopyDemoVideo } = useMutation({
    mutationFn: async ({ copyRequest }: { videoId: string; copyRequest: CopyDemoVideoRequest }) => {
      await createDemoVideo(orgId, copyRequest);
    },
    onSuccess: () => {
      Instrumentation.logOrgWelcomeVideoUpdated(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleCreateDemoVideos } = useMutation({
    mutationFn: async (files: File[]) => {
      setUploading(true);
      const responses = await Promise.all(
        files.map(async (file) =>
          createDemoVideo(orgId, {
            space_id: curSpaceId,
            title: file.name,
            description: "",
            extension: file.name.split(".").pop(),
            request_type: CreateContentRequestKind.New,
          }),
        ),
      );

      for (const response of responses) {
        await handleUpdateDemoVideo({
          videoId: response.id,
          updates: { available_hubs: [hubId] },
        });
        Instrumentation.logOrgWelcomeVideoCreated(orgId);
      }

      const newUploadingVideos = responses.map((res, idx) => ({
        videoId: res.id,
        uploadUrl: res.upload_url,
        file: files[idx],
      }));

      // this could be refactored to use a refetchQueries instead of invalidate + getQueryData
      await queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });

      const updatedVideosAndCourses: ContentsViewResponse = queryClient.getQueryData([
        VIDEOS_AND_COURSES_QUERY_KEY,
        orgId,
        curSpaceId,
      ]);
      setUploadingVideos(
        updatedVideosAndCourses?.demo_videos.filter(
          (video) => video.state === ContentVideoState.UPLOADING,
        ),
      );

      await Promise.all(
        newUploadingVideos.map(async (info) => {
          await uploadBlobToResumableUploadUrl(
            info.file,
            info.uploadUrl,
            null,
            `upload (${info.file.name})`,
          );
        }),
      );
      setUploading(false);
    },
  });

  const { mutateAsync: handleDeleteCourseVideo } = useMutation({
    mutationFn: async (videoId: string) => {
      await deleteCourseVideo(orgId, selectedCourse.id, videoId);
    },
    onSuccess: (_, videoId) => {
      const newUploadingVideos = uploadingVideos.filter((video) => video.id !== videoId);
      setUploadingVideos(newUploadingVideos);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleUpdateCourseVideo } = useMutation({
    mutationFn: async ({
      videoId,
      updates,
    }: {
      videoId: string;
      updates: UpdateCourseVideoRequest;
    }) => {
      const newCourseVideo = await updateCourseVideo(orgId, selectedCourse.id, videoId, updates);
      const newUploadingVideos = uploadingVideos.map((video) => {
        if (video.id === videoId) {
          return newCourseVideo;
        }
        return video;
      });
      setUploadingVideos(newUploadingVideos);
    },
    onSuccess: () => {
      Instrumentation.logOrgCourseCreated(orgId);
      void queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleCreateCourse } = useMutation({
    mutationFn: async ({
      title,
      description,
      availableHubs,
    }: {
      title: string;
      description: string;
      availableHubs: string[];
    }) => {
      await createOrgCourse(orgId, {
        title,
        space_id: curSpaceId,
        description,
        available_hubs: availableHubs,
      });
    },
    onSuccess: () => {
      Instrumentation.logOrgCourseCreated(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleCopyCourse } = useMutation({
    mutationFn: async ({ copyRequest }: { copyRequest: CopyCourseRequest }) => {
      await createOrgCourse(orgId, copyRequest);
    },
    onSuccess: () => {
      Instrumentation.logOrgCourseCreated(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleCopyCourseVideo } = useMutation({
    mutationFn: async ({
      courseId,
      copyRequest,
    }: {
      courseId: string;
      copyRequest: CopyCourseVideoRequest;
    }) => {
      await createCourseVideo(orgId, courseId, copyRequest);
    },
    onSuccess: () => {
      Instrumentation.logOrgWelcomeVideoUpdated(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleCreateCourseVideos } = useMutation({
    mutationFn: async (files: File[]) => {
      setUploading(true);
      const newUploadingVideos = await Promise.all(
        files.map(async (file) => {
          const courseVideo = await createCourseVideo(orgId, selectedCourse.id, {
            title: file.name,
            description: "",
            extension: file.name.split(".").pop(),
            space_id: curSpaceId,
          });
          return { videoId: courseVideo.id, uploadUrl: courseVideo.upload_url, file };
        }),
      );
      await queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });

      const updatedVideosAndCourses: ContentsViewResponse = queryClient.getQueryData([
        VIDEOS_AND_COURSES_QUERY_KEY,
        orgId,
        curSpaceId,
      ]);
      setUploadingVideos(
        updatedVideosAndCourses?.courses
          .find((course) => course.id === selectedCourse.id)
          .videos.filter((video) => video.state === ContentVideoState.UPLOADING),
      );

      await Promise.all(
        newUploadingVideos.map(async (info) => {
          await uploadBlobToResumableUploadUrl(
            info.file,
            info.uploadUrl,
            null,
            `upload (${info.file.name})`,
          );
        }),
      );
      setUploading(false);
    },
  });

  const { mutateAsync: handleUpdateCourseVideos } = useMutation({
    mutationFn: async (videoIds: string[]) => {
      await updateOrgCourse(
        orgId,
        selectedCourse.id,
        selectedCourse.title,
        selectedCourse.description,
        selectedCourse.available_hubs,
        videoIds,
      );
    },
    onSuccess: () => {
      Instrumentation.logOrgCourseUpdated(orgId);
      void queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleUpdateCourse } = useMutation({
    mutationFn: async ({
      courseId,
      title,
      description,
      availableHubs,
    }: {
      courseId: string;
      title: string;
      description: string;
      availableHubs: string[];
    }) => {
      await updateOrgCourse(orgId, courseId, title, description, availableHubs);
    },
    onSuccess: () => {
      Instrumentation.logOrgCourseUpdated(orgId);
      void queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  const { mutateAsync: handleDeleteCourse } = useMutation({
    mutationFn: async () => {
      await deleteCourse(orgId, selectedCourse.id);
    },
    onSuccess: () => {
      Instrumentation.logOrgCourseDeleted(orgId);
      return queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, orgId],
      });
    },
  });

  return {
    uploadingVideos,
    uploading,
    contentView,
    setContentView,
    selectedCourse,
    setSelectedCourse,
    clearUploadingVideos,
    handleCreateCourseVideos,
    handleCreateCourse,
    handleCopyCourse,
    handleCopyCourseVideo,
    handleUpdateCourseVideos,
    handleUpdateCourseVideo,
    handleDeleteCourseVideo,
    handleCreateDemoVideos,
    handleCopyDemoVideo,
    handleDeleteDemoVideo,
    handleDeleteCourse,
    handleUpdateDemoVideo,
    handleUpdateCourse,
  };
}
