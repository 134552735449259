import React from "react";

// Components
import { CreateConvoScenarioStepId, GoalHooks } from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";
import { RatedGoalDescriptionStep } from "./RatedGoalDescriptionStep";
import { RatedGoalEvaluationStep } from "./RatedGoalEvaluationStep";

// Utils
import {
  CreateCustomGoalRequest,
  CreateScoreGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";

export const CreateRatedGoalSteps = (
  ratedGoal: CreateScoreGoalRequest,
  setRatedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  inScenarioCreation?: boolean,
): WizardStep[] => {
  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <RatedGoalDescriptionStep
        ratedGoal={ratedGoal}
        setRatedGoal={setRatedGoal}
        goalEditId={goalEditId}
      />
    ),
    validate: () => ratedGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    skipPrevIndexUpdate: inScenarioCreation,
    prevCopy: "Discard",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  const evaluationStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <RatedGoalEvaluationStep
        ratedGoal={ratedGoal}
        setRatedGoal={setRatedGoal}
        goalEditId={goalEditId}
        goalHooks={goalHooks}
      />
    ),
    validate: () =>
      ratedGoal.aiDescription.length > 0 &&
      ratedGoal.highScoreDescription.length > 0 &&
      ratedGoal.lowScoreDescription.length > 0 &&
      ratedGoal.maxScore > 1,
    skipNextIndexUpdate: true,
    next: async () => {
      await createCustomGoal(ratedGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;
  return [descStep, evaluationStep];
};
