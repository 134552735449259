// Components
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton, Stack, Typography } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { truncateTextMiddle } from "utils/Utilities";

type UploadFileRowProps = {
  file: {
    id: string;
    name: string;
  };
  handleCancelFileUpload: (fileId: string) => Promise<void>;
};

export const UploadFileRow = ({
  file,
  handleCancelFileUpload,
}: UploadFileRowProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        borderRadius: 4,
        backgroundColor: getDynamicColor("dark1"),
        borderBottom: `0.5px solid ${getDynamicColor("dark2")}`,
      }}
    >
      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
        <UploadFileIcon sx={{ width: 20, height: 20 }} />
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Poppins",
          }}
        >
          {truncateTextMiddle(file.name, 32, 24)}
        </Typography>
      </Stack>
      <YoodliTooltip title="This will remove the file from this list but will not delete it from your library.">
        <IconButton onClick={() => handleCancelFileUpload(file.id)}>
          <CloseIcon sx={{ width: 20, height: 20, color: getDynamicColor("primary") }} />
        </IconButton>
      </YoodliTooltip>
    </Stack>
  );
};
