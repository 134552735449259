import React from "react";
import parse from "html-react-parser";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { Box, Button, Collapse, IconButton, Stack, Typography, keyframes } from "@mui/material";
import { GoalTalkTime, distributeWeightEvenly } from "components/ConvoScenarios/convoScenarioUtils";
import { GoalDescriptions, GoalLabels } from "lib-fullstack/utils/defaultGoals";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Assets
import { ReactComponent as LockIcon } from "images/icons/icon-lock-locked.svg";
import { ReactComponent as UnlockIcon } from "images/icons/icon-lock-unlocked.svg";

// Utils
import { GoalItem, getCustomGoalKindDescription } from "./GoalSelector";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { DefaultGoal, GoalKind } from "lib-fullstack/db";
import { reorder } from "utils/Utilities";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

type GoalWeightSelectorProps = {
  goals: (DefaultGoal | string)[];
  showGoalWeights: boolean;
  goalWeights: Record<string, number>;
  customGoals: CustomGoalItemWithUsage[];
  customGoalsLoading: boolean;
  goalOrder: (string | DefaultGoal)[];
  lockedWeightGoalIds: (string | DefaultGoal)[];
  handleUpdateLockedWeightGoalIds: (lockedWeightGoalIds: (string | DefaultGoal)[]) => void;
  handleUpdateGoalOrder: (goals: (DefaultGoal | string)[]) => void;
  handleUpdateGoalWeights: (weights: Record<string, number>) => void;
  handleRemoveGoal: (goal: string) => void;
  setCurrStepIndex: React.Dispatch<React.SetStateAction<number>>;
  handleEditCustomGoal: (goal: CustomGoalItemWithUsage) => Promise<void>;
  handleUpdateCustomGoalType: (type: GoalKind) => void;

  // talking points and target time goals
  talkingPoints: string[];
  handleUpdateTalkingPoints: (talkingPoints: string[]) => void;
  goalTalkTime: GoalTalkTime;
  handleUpdateGoalTalkTime: (goalTalkTime: GoalTalkTime) => void;
  specialGoalIdSelected: string;
  setSpecialGoalIdSelected: (goalId: string) => void;
  talkTimeMinutes: number;
  setTalkTimeMinutes: (minutes: number) => void;
  talkTimeSeconds: number;
  setTalkTimeSeconds: (seconds: number) => void;

  // extra details needed to edit talking points/target time
  goalsExtraDetails: Record<string, JSX.Element>;
  goalsExtraTitleInfo: Record<string, JSX.Element>;
};

type LockWeightButtonProps = {
  locked: boolean;
  toggleLock: (locked: boolean) => void;
  disableInputHover: (disabled: boolean) => void;
  canLock: boolean;
};

const restrictValueToRange = (value: string, min: number, max: number) => {
  if (value) {
    if (Number(value) < min) {
      return min;
    }
    if (Number(value) > max) {
      return max;
    }
    return value.split(".")[0];
  }
  return value;
};

const LockWeightButton = ({
  locked,
  toggleLock,
  disableInputHover,
  canLock,
}: LockWeightButtonProps): JSX.Element => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isShaking, setIsShaking] = React.useState(false);

  const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
`;

  return (
    <IconButton
      sx={{
        p: 0,
        position: "absolute",
        left: -14,
        zIndex: 1,
        animation: isShaking ? `${shake} 0.3s` : "none",
        transition: "background-color 0.3s ease",
      }}
      onClick={() => {
        if (canLock || locked) {
          toggleLock(!locked);
          disableInputHover(true);
        } else {
          setIsShaking(true);
          setTimeout(() => {
            setIsShaking(false);
          }, 500);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && locked ? (
        <UnlockIcon
          style={{
            height: 26,
            width: 26,
          }}
        />
      ) : (
        <LockIcon
          style={{
            color: !canLock ? getDynamicColor("redError") : getDynamicColor("primary"),
            transition: "color 0.3s ease",
            height: 26,
            width: 26,
          }}
        />
      )}
    </IconButton>
  );
};

type GoalWeightInputProps = {
  inputsMap: Record<string, HTMLInputElement | null>;
  setInputRef: (el: HTMLInputElement | null) => void;
  lockedWeightGoalIds: (string | DefaultGoal)[];
  goalWeights: Record<string, number>;
  goal: GoalItem;
  updateLockedWeight: (goalId: string, locked: boolean) => void;
  updateWeight: (goalId: string, weight: string) => void;
  error: boolean;
  remainingWeight: number;
  focusedGoalId: string;
  setFocusedGoalId: (goalId: string) => void;
  setHoveringGoalId: (goalId: string) => void;
};
const GoalWeightInput = ({
  setHoveringGoalId,
  inputsMap,
  setInputRef,
  lockedWeightGoalIds,
  goal,
  goalWeights,
  updateLockedWeight,
  updateWeight,
  remainingWeight,
  error,
  focusedGoalId,
  setFocusedGoalId,
}: GoalWeightInputProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoverDisabled, setHoverDisabled] = React.useState(false);

  const locked = React.useMemo(() => {
    return lockedWeightGoalIds.includes(goal.id);
  }, [lockedWeightGoalIds, goal]);

  return (
    <Stack direction="row">
      <YoodliTextfield
        onMouseEnter={() => {
          setIsHovered(true);
          setHoveringGoalId(goal.id);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          setHoverDisabled(false);
          setHoveringGoalId(undefined);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onBlur={() => {
          if (error && remainingWeight) {
            updateWeight(goal.id, (remainingWeight + goalWeights[goal.id]).toString());
          }
          if (focusedGoalId) {
            updateLockedWeight(goal.id, true);
          }
        }}
        error={error}
        sx={{
          "& .MuiOutlinedInput-root": {
            pl: "4px",
          },
          "& .Mui-disabled, .Mui-disabled > fieldset": {
            borderColor: `${getDynamicColor("dark4")} !important`,
            WebkitTextFillColor: getDynamicColor("dark4"),
          },
        }}
        inputProps={{
          className: "blockEnterToNavigate",
          sx: {
            width: "36px",
            textAlign: "right",
            fontWeight: 700,
            fontSize: 16,
            pl: 0,
            py: "10px",
            position: "relative",
          },
        }}
        InputProps={{
          inputRef: (el) => {
            setInputRef(el);
          },
          sx: {
            pr: "8px",
          },
          startAdornment: (locked || (isHovered && !hoverDisabled)) && (
            <LockWeightButton
              canLock={!error}
              disableInputHover={(disabled) => {
                setHoverDisabled(disabled);
              }}
              locked={locked}
              toggleLock={() => updateLockedWeight(goal.id, !locked)}
            />
          ),
          endAdornment: (
            <Box
              onClick={() => {
                // this is just to make it so the clicking on the percent
                // acts like its part of the input
                const inputEl = inputsMap[goal.id];
                if (inputEl) {
                  const length = inputEl.value.length;
                  inputEl.focus();
                  inputEl.setSelectionRange(length, length);
                }
              }}
              sx={{ fontWeight: 700, fontSize: 16 }}
            >
              %
            </Box>
          ),
        }}
        value={
          restrictValueToRange(goalWeights[goal.id]?.toString(), 0, 100) ?? (locked ? "0" : "")
        }
        onChange={(e) => {
          const regex = /^[0-9]*$/;
          if (!regex.test(e.target.value)) {
            return;
          }
          setFocusedGoalId(goal.id);
          updateWeight(goal.id, restrictValueToRange(e.target.value, 0, 100).toString());
        }}
      />
    </Stack>
  );
};

export const GoalWeightSelector = ({
  goals,
  customGoals,
  showGoalWeights,
  customGoalsLoading,
  goalWeights,
  lockedWeightGoalIds,
  handleUpdateLockedWeightGoalIds,
  handleUpdateGoalWeights,
  goalOrder,
  handleUpdateGoalOrder,
  handleRemoveGoal,
  handleEditCustomGoal,
  handleUpdateCustomGoalType,
  setCurrStepIndex,

  talkingPoints,
  goalTalkTime,
  handleUpdateGoalTalkTime,
  specialGoalIdSelected,
  setSpecialGoalIdSelected,
  talkTimeMinutes,
  talkTimeSeconds,
  goalsExtraDetails,
  goalsExtraTitleInfo,
}: GoalWeightSelectorProps): JSX.Element => {
  const [goalItems, setGoalItems] = React.useState<GoalItem[]>([]);
  const [errorGoalId, setErrorGoalId] = React.useState<string>();
  const [focusedGoalId, setFocusedGoalId] = React.useState<string>();
  const [hoveringGoalId, setHoveringGoalId] = React.useState<string>();

  const inputRefs = React.useRef<Record<string, HTMLInputElement | null>>({});

  const setRef = React.useCallback(
    (element: HTMLInputElement | null, id: string) => {
      if (element) {
        inputRefs.current[id] = element;
      }
    },
    [inputRefs],
  );

  const handleBlur = (goalId: string) => {
    if (inputRefs.current) {
      inputRefs.current[goalId]?.blur();
    }
  };

  React.useEffect(() => {
    if (customGoalsLoading) {
      return;
    }
    const goalItemsToSort = goals.map((goalId) => {
      if (GoalLabels[goalId]) {
        if (goalsExtraDetails[goalId]) {
          const specialGoalDescriptions = {
            [DefaultGoal.HitTalkingPoints]:
              talkingPoints.length > 0 ? `Rated goal: Scored 1-${talkingPoints.length}` : undefined,
            [DefaultGoal.HitTimeTarget]: "Binary goal: Achieved/Missed",
          };
          return {
            id: goalId,
            name: GoalLabels[goalId],
            goalKindDescription: specialGoalDescriptions[goalId],
            userDescription: GoalDescriptions[goalId],
          };
        }
        return {
          id: goalId,
          name: GoalLabels[goalId],
          userDescription: GoalDescriptions[goalId],
        };
      }
      const customGoal = customGoals?.find((goal) => goal.id === goalId);
      if (customGoal) {
        return {
          id: goalId,
          name: customGoal.name,
          goalKind: customGoal.goalKind,
          goalKindDescription: getCustomGoalKindDescription(customGoal),
          userDescription: customGoal?.userDescription,
        };
      }
    });
    // also removing undefined values i.e. goals that don't exist in either the predefined or custom goals
    const cleanedOrderedGoalItems = goalItemsToSort.filter(Boolean);
    cleanedOrderedGoalItems.sort((a, b) => {
      return goalOrder.indexOf(a.id) - goalOrder.indexOf(b.id);
    });
    setGoalItems(cleanedOrderedGoalItems);
  }, [goals, customGoalsLoading, goalOrder, talkingPoints, goalTalkTime]);

  const handleGoalDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedGoals = reorder(goalItems, result.source.index, result.destination.index);
    setGoalItems(reorderedGoals);
    handleUpdateGoalOrder(reorderedGoals.map((goal) => goal.id));
  };

  const handleWeightChange = (goalId: string, weight: string) => {
    const otherUnlockedWeights = [];
    let currRemainingWeight = 100;
    Object.keys(goalWeights).forEach((currGoalId) => {
      if (!lockedWeightGoalIds.includes(currGoalId)) {
        if (goalId !== currGoalId) {
          otherUnlockedWeights.push(currGoalId);
        }
      }
      // get remaining weight for even distribution amongst unlocked goals
      else {
        if (goalId !== currGoalId) {
          currRemainingWeight -= goalWeights[currGoalId];
        }
      }
    });
    const evenlyDistributedUnlockedWeights = distributeWeightEvenly(
      otherUnlockedWeights,
      currRemainingWeight - Number(weight),
    );
    const newGoalWeights = {
      ...goalWeights,
      ...evenlyDistributedUnlockedWeights,
      [goalId]: Number(weight),
    };
    handleUpdateGoalWeights(newGoalWeights);
    const totalRemainingWeight =
      100 - Object.values(newGoalWeights).reduce((acc, curr) => acc + curr, 0);
    if (totalRemainingWeight !== 0) {
      setErrorGoalId(goalId);
    } else {
      setErrorGoalId(undefined);
    }
  };

  const updateLockedWeight = (goalId: string, isLocked: boolean) => {
    if (isLocked) {
      handleUpdateLockedWeightGoalIds([...lockedWeightGoalIds, goalId]);
    } else {
      handleUpdateLockedWeightGoalIds(
        lockedWeightGoalIds.filter((lockedGoal) => lockedGoal !== goalId),
      );
    }
  };

  const remainingWeight = React.useMemo(() => {
    if (!goalWeights) {
      return 0;
    }
    const totalWeight = Object.values(goalWeights).reduce((acc, curr) => acc + curr, 0);
    return 100 - totalWeight;
  }, [goalWeights]);

  const getDraggableGoalStyles = (goalId: string) => {
    return {
      display: "flex",
      flexDirection: "row",
      gap: 3,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 1.5,
      backgroundColor: getDynamicColor("light1"),
      border: `1px solid ${getDynamicColor("dark4")}`,
      padding: 2,
      minHeight: 80,
      svg: {
        color: getDynamicColor("primary"),
      },
      "> p": {
        width: "100%",
      },
      // use mb not gap here so the placeholder while dragging renders correctly
      mb: 1,
      ...(specialGoalIdSelected &&
        specialGoalIdSelected !== goalId && {
          backgroundColor: getDynamicColor("dark1"),
          color: getDynamicColor("dark4"),
          filter: "grayscale(100%)",
          pointerEvents: "none",
        }),
      ...(specialGoalIdSelected === goalId && {
        borderColor: getDynamicColor("primary"),
        borderWidth: 2,
      }),
    };
  };

  return (
    <Stack sx={{ gap: 1 }}>
      <DragDropContext onDragEnd={handleGoalDrag}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Stack {...provided.droppableProps} ref={provided.innerRef} direction="column">
              {goalItems.map((goal, index) => {
                if (goal) {
                  return (
                    <Draggable
                      key={index}
                      draggableId={index.toString()} // According to docs, this needs to be a string
                      index={index}
                      isDragDisabled={goal?.id === hoveringGoalId || !!specialGoalIdSelected}
                    >
                      {(provided) => (
                        <Box
                          key={goal?.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...getDraggableGoalStyles(goal.id),
                          }}
                          onMouseDown={() => handleBlur(focusedGoalId)}
                        >
                          {provided.placeholder}
                          <DragIndicatorIcon
                            style={{
                              cursor: "grab",
                              height: "20px",
                              width: "20px",
                              visibility: specialGoalIdSelected ? "hidden" : "initial",
                            }}
                          />
                          <Stack direction="column" sx={{ width: "100%" }}>
                            <Stack
                              direction="row"
                              sx={{
                                width: "100%",
                                gap: 1,
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Stack
                                direction="column"
                                sx={{ gap: 0.5, overflow: "hidden", width: "100%" }}
                              >
                                <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
                                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                                    {goal.name}
                                  </Typography>
                                  {!!goalsExtraTitleInfo[goal.id] && goalsExtraTitleInfo[goal.id]}
                                </Stack>

                                {goal?.userDescription && (
                                  <Typography
                                    component="div"
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      mr: 1,
                                      maxWidth: "calc(100% - 120px)",
                                      maxHeight: "45px",
                                    }}
                                  >
                                    {parse(goal.userDescription)}
                                  </Typography>
                                )}
                                {goal?.goalKindDescription && (
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {goal?.goalKindDescription}
                                  </Typography>
                                )}
                              </Stack>
                              <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
                                {showGoalWeights && specialGoalIdSelected !== goal.id && (
                                  <GoalWeightInput
                                    setHoveringGoalId={setHoveringGoalId}
                                    inputsMap={inputRefs.current}
                                    setInputRef={(el) => setRef(el, goal.id)}
                                    focusedGoalId={focusedGoalId}
                                    setFocusedGoalId={setFocusedGoalId}
                                    lockedWeightGoalIds={lockedWeightGoalIds}
                                    goal={goal}
                                    goalWeights={goalWeights}
                                    updateLockedWeight={updateLockedWeight}
                                    updateWeight={handleWeightChange}
                                    error={errorGoalId === goal.id}
                                    remainingWeight={remainingWeight}
                                  />
                                )}
                                {specialGoalIdSelected === goal.id && (
                                  <Button
                                    variant="text_small"
                                    disabled={
                                      goal.id === DefaultGoal.HitTalkingPoints &&
                                      talkingPoints?.length === 0
                                    }
                                    onClick={() => {
                                      setSpecialGoalIdSelected(undefined);
                                      if (goal.id === DefaultGoal.HitTimeTarget) {
                                        handleUpdateGoalTalkTime({
                                          minutes: talkTimeMinutes,
                                          seconds: talkTimeSeconds,
                                        });
                                      }
                                    }}
                                  >
                                    {goal.id === DefaultGoal.HitTalkingPoints ? "Done" : "Save"}
                                  </Button>
                                )}
                                <YoodliMenu
                                  type={YoodliMenuButtonType.Icon}
                                  buttonSx={{
                                    m: 0,
                                    p: 0,
                                  }}
                                  menuItems={[
                                    (!!goalsExtraDetails[goal.id] ||
                                      customGoals?.find(
                                        (customGoal) => customGoal.id === goal.id,
                                      )) && {
                                      title: "Edit",
                                      type: YoodliMenuItemType.Primary,
                                      onClick: async () => {
                                        const customGoal = customGoals?.find(
                                          (customGoal) => customGoal.id === goal.id,
                                        );
                                        if (customGoal) {
                                          handleEditCustomGoal(goal as CustomGoalItemWithUsage)
                                            .then(() => {
                                              handleUpdateCustomGoalType(
                                                (goal as CustomGoalItemWithUsage)
                                                  .goalKind as GoalKind,
                                              );
                                              setCurrStepIndex((prev) => prev + 1);
                                            })
                                            .catch((e) =>
                                              console.error("Error editing custom goal: ", e),
                                            );
                                        } else {
                                          setSpecialGoalIdSelected(goal.id);
                                        }
                                      },
                                    },
                                    {
                                      title: "Remove",
                                      type: YoodliMenuItemType.Warning,
                                      onClick: () => {
                                        handleRemoveGoal(goal.id);
                                        if (specialGoalIdSelected === goal.id) {
                                          setSpecialGoalIdSelected(undefined);
                                        }
                                      },
                                    },
                                  ].filter(Boolean)}
                                />
                              </Stack>
                            </Stack>
                            {!!goalsExtraDetails[goal.id] && (
                              <Collapse
                                in={specialGoalIdSelected === goal.id}
                                timeout="auto"
                                unmountOnExit
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    pt: 1.5,
                                    width: "100%",
                                  }}
                                >
                                  {goalsExtraDetails[goal.id]}
                                </Box>
                              </Collapse>
                            )}
                          </Stack>
                        </Box>
                      )}
                    </Draggable>
                  );
                }
              })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      {goalItems.length === 1 && (errorGoalId || remainingWeight !== 0) && (
        <Typography sx={{ color: getDynamicColor("redError"), fontWeight: 600, fontSize: 14 }}>
          The sum of all weights must equal 100%
        </Typography>
      )}
    </Stack>
  );
};
