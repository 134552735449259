import { db } from "lib-fullstack";
import { AuthorizationAPIHeaders } from "lib-frontend/modules/AxiosInstance";
import { getSpeechThumbnails } from "lib-fullstack/utils/speechUtils";

/**
 * This is a module to retrieve thumbnail URL for speeches from the server
 * and cache them in memory to avoid redundant requests.
 * Note that the current implementation does *not* re-query the server
 * if URL has expired or after the server responds unavailable.
 * If re-query becomes needed by a new usage in the future, we need to adjust.
 */
/*/
/**
 * In-memory cache of thumbnail URL
 * key: speech ID
 * value: thumbnail URL or null if it's unavailable
 */
const cache = new Map<string, string | null>();

/**
 * Retrieve temporary URL (24 hours as of writing) of thumbnails for
 * passed set of speeches from server API, and save them into the memory.
 * This does not re-query the speeches which are already queried once.
 */
export async function queryThumbnails(speeches: db.Doc<db.Speech>[]): Promise<void> {
  const newSpeeches = speeches.filter((speech) => !cache.has(speech.ref.id));
  newSpeeches.sort((a: db.Doc<db.Speech>, b: db.Doc<db.Speech>) =>
    b.data.recordedDate > a.data.recordedDate ? 1 : -1,
  );

  const authHeaders = await AuthorizationAPIHeaders();
  if (newSpeeches.length > 0) {
    // break into batches of 20
    for (let speechIndex = 0; speechIndex < newSpeeches.length; speechIndex += 20) {
      const speechBatch = newSpeeches.slice(speechIndex, speechIndex + 20);
      const thumbnails = await getSpeechThumbnails(
        speechBatch.map((speech) => {
          return {
            speechId: speech.ref.id,
            ownerId: speech.ref.collection.path.split("/")[3],
          };
        }),
        authHeaders,
      );

      for (let i = 0; i < thumbnails.length; i++) {
        cache.set(speechBatch[i].ref.id, thumbnails[i]);
      }
    }
  }
}

/**
 * Return the thumbnail URL for the specified speech, or null if unavailable.
 */
export function getThumbnail(speechId: string): string | null {
  return cache.get(speechId) ?? null;
}
