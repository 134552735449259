// Components
import { LinearProgress, Stack, Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { PlanStepResult } from "lib-fullstack/api/programApiTypes";
import { PlanStepState } from "lib-fullstack/utils/enums";

type StepProgressTrackerProps = {
  planStepResults: PlanStepResult[];
};

export const StepProgressTracker = ({ planStepResults }: StepProgressTrackerProps): JSX.Element => {
  const numCompletedSteps = planStepResults.filter(
    (step) => step.state === PlanStepState.Completed,
  ).length;

  return (
    <Stack gap={0.5} sx={{ minWidth: "160px" }}>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 500,
        }}
      >
        {numCompletedSteps}/{planStepResults.length} scenarios complete
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(numCompletedSteps / planStepResults.length) * 100}
        color="inherit"
        sx={{
          color: getDynamicColor(
            numCompletedSteps === planStepResults.length ? "chartGreen" : "chartPurple",
          ),
          borderRadius: "100px",
          "&::before": {
            backgroundColor: getDynamicColor("dark2"),
          },
        }}
      />
    </Stack>
  );
};
