import React from "react";
import { NavigateFunction, useNavigate, useLocation } from "react-router";

// Components
import { Box, Stack } from "@mui/material";

// Utils
import { BulkInviteByCSVWizard } from "../BulkInviteByCSVWizard/BulkInviteByCSVWizard";
import { InviteMembers, MAX_EMAIL_INVITE_COUNT } from "../InviteMembers";
import { OrgLoading } from "../OrgLoading";
import AvailableContent from "../Overview/AvailableContent";
import { TrialEnded } from "../TrialEnded";
import { Activity } from "./Activity";
import DeleteHubWizard from "./DeleteHubWizard";
import { HubsHeader } from "./HubsHeader";
import { useQueryParamState } from "hooks/useQueryParamState";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  isHubAdmin,
  isOrgTrialEnded,
  isUserMinimumEffectiveRole,
} from "lib-frontend/utils/orgUtils";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { HubV2Response, OrgMemberResponse, OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { ReportsTab } from "../Reports/ReportsTab";
import { ReportsTabType } from "../Reports/reportsUtils";
import { MemberInfo } from "../Members/MembersTable";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { YoodliNotificationOptions } from "lib-frontend/contexts/NotificationContext";
import ReassignGroupsWizard from "../Members/ReassignGroupsWizard";
import { MemberDashboard } from "../Members/MemberDashboard";
import { ManageRole } from "../Members/ManageRole";
import { EffectiveRole } from "lib-fullstack/utils/enums";

export enum OrgHubsSectionStatus {
  Default = "Default",
  Dashboard = "Dashboard",
  ManageRole = "ManageRole",
  InviteMembers = "InviteEmail",
  DeleteHub = "DeleteHub",
  BulkInviteByCsv = "InviteCSV",
  ReassignGroups = "ReassignGroups",
}

export enum HubsTab {
  Activity = "Activity",
  AvailableContent = "Available content",
  Reports = "Reports",
}

export const HubsTabLabels = {
  [HubsTab.Activity]: "Activity",
  [HubsTab.AvailableContent]: "Available content",
  [HubsTab.Reports]: "Reports",
};

export enum OrgHubsQueryKeys {
  HUB_USERS = "hubUsers",
  HUB_INVITES = "hubInvites",
}

// helper function to make sure we have a hub to display for a user, otherwise
// redirect them back to home
export const findUserFallbackHub = (
  defaultOrg: OrgV2Response | null,
  hubId: string = undefined,
  navigate: NavigateFunction,
): HubV2Response => {
  let selectedHub: HubV2Response = undefined;
  const newSearchParams = new URLSearchParams(window.location.search);
  if (hubId) {
    selectedHub = defaultOrg?.hubs?.find(
      (hub) =>
        hub.id === hubId &&
        (isHubAdmin(hub) ||
          isUserMinimumEffectiveRole(defaultOrg?.effective_role, EffectiveRole.SPACE_ADMIN)),
    );
  }
  if (!selectedHub) {
    selectedHub = defaultOrg?.hubs?.find(
      (hub) =>
        hub.org_default &&
        (isHubAdmin(hub) ||
          isUserMinimumEffectiveRole(defaultOrg?.effective_role, EffectiveRole.SPACE_ADMIN)),
    );
  }
  // ex. hub admin who is not in default hub will be shown their first hub that they're an admin of
  if (!selectedHub && defaultOrg?.hubs?.length > 0) {
    selectedHub = defaultOrg?.hubs?.find((hub) => isHubAdmin(hub));
  }

  // just return the first hub if the org is in trial ended state (it won't be shown anyways)
  if (!selectedHub && defaultOrg?.hubs?.length > 0 && isOrgTrialEnded(defaultOrg)) {
    selectedHub = defaultOrg.hubs[0];
  }

  // if somehow no hubs are found, redirect to home (shouldn't happen currently)
  // but in case there are scenarios where we want to support a hub admin leaving
  // their last hub or hub owner deleting the default hub
  if (!selectedHub) {
    navigate(WebServerExternalPath.HOME_LOGGED_IN, { replace: true });
    return;
  }
  newSearchParams.set(OrgHubsQueryParams.GROUP_ID, selectedHub.id);
  navigate({ search: newSearchParams.toString() }, { replace: true });
  return selectedHub;
};

export default function OrgHubs(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);

  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const [selectedHub, setSelectedHub] = React.useState<HubV2Response>(undefined);
  const [bulkActionUserList, setBulkActionUserList] = React.useState<MemberInfo[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<OrgMemberResponse>(null);

  const [selectedTab, setSelectedTab] = useQueryParamState(
    OrgHubsQueryParams.TAB,
    HubsTab.Activity,
  );
  const [sectionStatus, setSectionStatus] = useQueryParamState(
    OrgHubsQueryParams.SECTION,
    OrgHubsSectionStatus.Default,
  );
  const [notificationData, setNotificationData] = React.useState<{
    message: string | JSX.Element;
    options: YoodliNotificationOptions;
  }>(null);

  const { showNotificationBanner } = useNotification();

  React.useEffect(() => {
    if (defaultOrg?.id) {
      const selectedHub = findUserFallbackHub(
        defaultOrg,
        qp.get(OrgHubsQueryParams.GROUP_ID),
        navigate,
      );
      setSelectedHub(selectedHub);

      // If the query parameter is not set, set it to the default hub id
      if (!qp.has(OrgHubsQueryParams.GROUP_ID) && selectedHub) {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set(OrgHubsQueryParams.GROUP_ID, selectedHub.id);
        navigate({ search: newSearchParams.toString() }, { replace: true });
      }
    } else {
      setSelectedHub(undefined);
    }
  }, [defaultOrg?.id]);

  React.useEffect(() => {
    if (notificationData) {
      showNotificationBanner(notificationData.message, notificationData.options);
    }
  }, [notificationData, showNotificationBanner]);

  // Handle reverting to Default if the state doesnt match the page requirements
  React.useEffect(() => {
    if (
      (sectionStatus === OrgHubsSectionStatus.Dashboard && !selectedUser) ||
      (sectionStatus === OrgHubsSectionStatus.ReassignGroups && bulkActionUserList?.length === 0)
    ) {
      setSectionStatus(OrgHubsSectionStatus.Default);
    }
  }, [sectionStatus, selectedUser]);

  const handleOpenDashboard = (user: OrgMemberResponse) => {
    setSelectedUser(user);
    setSectionStatus(OrgHubsSectionStatus.Dashboard);
  };

  const renderTab = () => {
    if (isOrgTrialEnded(defaultOrg)) {
      return (
        <Box sx={{ pt: 4 }}>
          <TrialEnded org={defaultOrg} />
        </Box>
      );
    }
    switch (selectedTab) {
      case HubsTab.Activity:
        return (
          <Activity
            orgId={defaultOrg.id}
            hubId={selectedHub.id}
            setSectionStatus={setSectionStatus}
            handleOpenDashboard={handleOpenDashboard}
            bulkActionUserList={bulkActionUserList}
            setBulkActionUserList={setBulkActionUserList}
          />
        );
      case HubsTab.AvailableContent:
        return <AvailableContent hubId={selectedHub.id} />;
      case HubsTab.Reports:
        return (
          <ReportsTab
            type={ReportsTabType.Group}
            hubName={selectedHub?.name}
            hubId={selectedHub?.id}
          />
        );
    }
  };

  const loading = !defaultOrg || !selectedHub;

  if (loading) {
    return <OrgLoading prompts={["Loading groups..."]} />;
  }

  const renderContent = () => {
    switch (sectionStatus) {
      case OrgHubsSectionStatus.ManageRole:
        return (
          <ManageRole
            handleShowNotificationBanner={setNotificationData}
            org={defaultOrg}
            handleBack={() => {
              setSectionStatus(OrgHubsSectionStatus.Dashboard);
            }}
            backCopy="User Dashboard"
            user={selectedUser}
          />
        );
      case OrgHubsSectionStatus.Dashboard:
        if (!selectedUser) {
          return <OrgLoading prompts={["Loading user dashboard..."]} />;
        }
        return (
          <MemberDashboard
            handleBack={() => setSectionStatus(OrgHubsSectionStatus.Default)}
            backCopy="Groups"
            handleOpenManageRole={() => setSectionStatus(OrgHubsSectionStatus.ManageRole)}
            user={selectedUser}
          />
        );
      case OrgHubsSectionStatus.InviteMembers:
        return (
          <InviteMembers
            title={`Invite people to join ${selectedHub.name}`}
            subtitle={`Add up to ${MAX_EMAIL_INVITE_COUNT} emails separated by commas and select one role for all new users. Yoodli will send invitations to all new users and exclude any already-existing members. All new members will be automatically added to the organization.`}
            backCopy="Groups"
            handleBack={() => {
              setSectionStatus(OrgHubsSectionStatus.Default);
            }}
            org={defaultOrg}
            selectedHubId={selectedHub.id}
          />
        );
      case OrgHubsSectionStatus.ReassignGroups:
        return (
          <ReassignGroupsWizard
            origin="group"
            bulkActionUserList={bulkActionUserList}
            setBulkActionUserList={setBulkActionUserList}
            backCopy="Members"
            setSectionStatus={setSectionStatus}
            handleShowNotificationBanner={setNotificationData}
          />
        );
      case OrgHubsSectionStatus.DeleteHub:
        return (
          <DeleteHubWizard
            hubId={selectedHub.id}
            backCopy={selectedHub.name}
            setSelectedHub={setSelectedHub}
            setSectionStatus={setSectionStatus}
          />
        );
      case OrgHubsSectionStatus.BulkInviteByCsv:
        return (
          <BulkInviteByCSVWizard
            org={defaultOrg}
            currentHub={selectedHub}
            handleBack={() => setSectionStatus(OrgHubsSectionStatus.Default)}
          />
        );
      case OrgHubsSectionStatus.Default:
      default:
        return (
          <Stack sx={{ minHeight: "100vh", backgroundColor: getDynamicColor("light1") }}>
            <HubsHeader
              hubs={defaultOrg.hubs.filter(
                (hub) =>
                  isHubAdmin(hub) ||
                  isUserMinimumEffectiveRole(defaultOrg.effective_role, EffectiveRole.SPACE_ADMIN),
              )}
              selectedHub={selectedHub}
              setSelectedHub={(hub) => {
                setSelectedHub(hub);
                qp.set(OrgHubsQueryParams.GROUP_ID, hub.id);
                setBulkActionUserList([]);
                navigate({ search: qp.toString() }, { replace: true });
              }}
              selectedTab={selectedTab as HubsTab}
              setSelectedTab={setSelectedTab}
              setSectionStatus={setSectionStatus}
            />
            <Box sx={{ mx: "auto", maxWidth: "xxl", width: "100%" }}>{renderTab()}</Box>
          </Stack>
        );
    }
  };

  return renderContent();
}
