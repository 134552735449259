import React from "react";

// Components
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { HubTableRowData } from "./ManageRole";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { HubRole } from "lib-fullstack/utils/enums";

type HubsTableProps = {
  rowData: HubTableRowData[];
  setRowData: (data: HubTableRowData[]) => void;
  searchText: string;
  setSearchText: (text: string) => void;
};

export const HubsTable = ({
  rowData,
  setRowData,
  searchText,
  setSearchText,
}: HubsTableProps): JSX.Element => {
  const columnHelper = createColumnHelper<HubTableRowData>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Group name</Typography>,
        cell: (info) => (
          <Stack direction="row" gap={2} sx={{ alignItems: "center", ml: { xs: 1, md: 3 } }}>
            <Checkbox
              checked={!!info.row.original.role}
              onChange={() =>
                setRowData(
                  rowData.map((hub) =>
                    hub.id === info.row.original.id
                      ? { ...hub, role: hub.role ? undefined : HubRole.MEMBER }
                      : hub,
                  ),
                )
              }
            />
            <Typography>{info.getValue()}</Typography>
          </Stack>
        ),
      }),
      columnHelper.accessor("role", {
        id: "role",
        header: () => <Typography>Role</Typography>,
        cell: (info) => {
          if (!info.getValue()) {
            return null;
          } else {
            return (
              <YoodliSelect
                sx={{ mr: 1 }}
                value={info.getValue()}
                options={[
                  { label: "Admin", value: HubRole.ADMIN },
                  { label: "Member", value: HubRole.MEMBER },
                ]}
                onChange={(e) =>
                  setRowData(
                    rowData.map((hub) =>
                      hub.id === info.row.original.id
                        ? { ...hub, role: e.target.value as HubRole }
                        : hub,
                    ),
                  )
                }
              />
            );
          }
        },
      }),
    ],
    [rowData],
  );

  const filteredData = React.useMemo(() => {
    if (!searchText) return rowData;
    return rowData.filter((row) => row.name.toLowerCase().includes(searchText.toLowerCase()));
  }, [rowData, searchText]);

  const table = useReactTable({
    data: filteredData,
    columns: columns,
    state: {
      globalFilter: searchText,
    },
    onGlobalFilterChange: setSearchText,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box
      sx={{
        maxHeight: "300px",
        overflowY: "auto",
        border: `1px solid ${getDynamicColor("dark2")}`,
        borderRadius: 2,
      }}
    >
      <table
        style={{
          width: "100%",
          boxShadow: Y_SHADOWS.dark_elevation,
          borderCollapse: "collapse",
          borderRadius: 2,
          borderSpacing: "0px",
        }}
      >
        <thead
          style={{
            backgroundColor: getDynamicColor("light1"),
            borderRadius: "8px",
            height: "40px",
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Box
              component="tr"
              key={headerGroup.id}
              sx={{
                width: "100%",
                borderBottom: `1px solid ${getDynamicColor("dark2")}`,
              }}
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    borderBottom: "1px solid lightgrey",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: getDynamicColor("light1"),
                    borderRadius: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      color: getDynamicColor("dark5"),
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      mr: 1,
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Stack>
                </th>
              ))}
            </Box>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Box
                component="tr"
                key={row.id}
                sx={{
                  width: "max-content",
                  height: "70px",
                  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  "&:last-of-type": {
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                    borderBottom: "unset",
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </Box>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};
