// Components
import { Grow, Stack } from "@mui/material";

// Utils
import { TEXTFIELD_WIDTH } from "./CoachBotUploadContent";
import { UploadFileRow } from "./UploadFileRow";

type UploadedFilesProps = {
  files: {
    id: string;
    name: string;
  }[];
  handleCancelFileUpload: (fileId: string) => Promise<void>;
};

export const UploadedFiles = ({
  files,
  handleCancelFileUpload,
}: UploadedFilesProps): JSX.Element => {
  return (
    <Grow in={files?.length > 0} unmountOnExit timeout={300}>
      <Stack
        sx={{
          width: "100%",
          maxWidth: {
            xs: "unset",
            md: TEXTFIELD_WIDTH,
            lg: TEXTFIELD_WIDTH + 100,
            xl: TEXTFIELD_WIDTH + 200,
          },
        }}
        gap="10px"
      >
        {files.map((file, index) => {
          return (
            <UploadFileRow
              key={index}
              file={file}
              handleCancelFileUpload={handleCancelFileUpload}
            />
          );
        })}
      </Stack>
    </Grow>
  );
};
