import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import { InfoOutlined as InfoIcon, DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { Avatar, Box, Button, Chip, FormControlLabel, Stack, Typography } from "@mui/material";
import { DEFAULT_PERSONA, getDemeanorColor } from "components/ConvoScenarios/convoScenarioUtils";
import {
  StackedMenu,
  StackedMenuItem,
} from "components/Orgs/ManageContent/CustomGoals/StackedMenu";
import { CreatePersonaForm } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreatePersonaForm";
import { TextOverflowTooltip } from "lib-frontend/components/YoodliComponents/TextOverflowTooltip";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { SELECT_VALUE_STYLES } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { YoodliSwitch } from "lib-frontend/components/YoodliSwitch";

// Utils
import { WizardType } from "./CreateConvoScenarioWizard";
import { PersonaList } from "./PersonaList";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { DraggableStyles } from "lib-frontend/utils/orgUtils";
import {
  CreatePersonaRequest,
  PersonaMemberViewResponse,
  UpdatePersonaRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { ScenarioTypeIdEnum } from "lib-fullstack/db";
import { reorder } from "utils/Utilities";

type PersonaSelectorProps = {
  showCreatePersona: boolean;
  setShowCreatePersona: (value: boolean) => void;
  personas: PersonaMemberViewResponse[];
  personaIds: string[];
  handleUpdatePersonaIds: React.Dispatch<React.SetStateAction<string[]>>;
  currStepIndex: number;
  setPersonaToCreate: React.Dispatch<React.SetStateAction<UpdatePersonaRequest>>;
  wizardType: WizardType;
  scenarioTypeId: ScenarioTypeIdEnum;
  autoEndEnabled: boolean;
  setAutoEndEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  aiTimeLimitS: number;
  setAiTimeLimitS: React.Dispatch<React.SetStateAction<number>>;
  autoEndPrompt: string;
  setAutoEndPrompt: React.Dispatch<React.SetStateAction<string>>;
};

export const PersonaSelector = ({
  showCreatePersona,
  setShowCreatePersona,
  personas,
  personaIds,
  handleUpdatePersonaIds,
  currStepIndex,
  setPersonaToCreate,
  wizardType,
  scenarioTypeId,
  autoEndEnabled,
  setAutoEndEnabled,
  aiTimeLimitS,
  setAiTimeLimitS,
  autoEndPrompt,
  setAutoEndPrompt,
}: PersonaSelectorProps): JSX.Element => {
  const [newPersona, setNewPersona] = React.useState<UpdatePersonaRequest>(DEFAULT_PERSONA);
  const [personaItems, setPersonaItems] = React.useState<PersonaMemberViewResponse[]>([]);
  const [editAIEndBehavior, setEditAIEndBehavior] = React.useState<boolean>(false);

  React.useEffect(() => {
    setNewPersona(DEFAULT_PERSONA);
  }, [currStepIndex]);

  React.useEffect(() => {
    const newPersonaItems = [];
    personaIds.forEach((personaId) => {
      const persona = personas.find((p) => p.id === personaId);
      if (persona) {
        newPersonaItems.push(persona);
      }
    });
    setPersonaItems(newPersonaItems);
    // filtering out persona ids that don't exist for the user for whatever reason
    if (newPersonaItems.length !== personaIds.length) {
      handleUpdatePersonaIds(newPersonaItems.map((persona) => persona.id));
    }
  }, [personaIds, personas]);

  React.useEffect(() => {
    // if newPersona is valid, set personaToCreate in parent, so it can validate the step
    if (newPersona.name && newPersona.job_title && newPersona.voice_id && newPersona.demeanor) {
      // cast as CreatePersonaRequest since it sometimes has an id, which fails typing
      setPersonaToCreate(newPersona as CreatePersonaRequest);
    }
  }, [newPersona]);

  React.useEffect(() => {
    if (!showCreatePersona) {
      setNewPersona(DEFAULT_PERSONA);
    }
  }, [showCreatePersona]);

  const handleCreatePersonaClicked = () => {
    setShowCreatePersona(true);
  };

  const handleEditPersona = (persona: PersonaMemberViewResponse) => {
    setNewPersona(persona);
    setShowCreatePersona(true);
  };

  const handleUpdateNewPersona = (key: keyof UpdatePersonaRequest, value: string | string[]) => {
    setNewPersona((prev) => ({ ...prev, [key]: value }));
  };

  const handlePersonaSelectionChange = (personaId: string, action: "add" | "remove") => {
    if (action === "remove") {
      handleUpdatePersonaIds((personaIds) => personaIds.filter((p) => p !== personaId));
    } else if (!personaIds.includes(personaId)) {
      handleUpdatePersonaIds((personaIds) => [...personaIds, personaId]);
    }
  };

  const handleDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedPersonaItems = reorder(
      personaItems,
      result.source.index,
      result.destination.index,
    );
    setPersonaItems(reorderedPersonaItems);
    handleUpdatePersonaIds(reorderedPersonaItems.map((persona) => persona.id));
  };

  const renderPersonaValue = (persona?: PersonaMemberViewResponse) => {
    return (
      <Stack
        direction="row"
        sx={{ overflow: "hidden", alignItems: "center", gap: 1, width: "100%" }}
      >
        {persona && (
          <Avatar
            alt={persona?.name}
            src={persona?.profile_picture_signed_url}
            key={persona?.profile_picture_signed_url}
            sx={{
              height: 36,
              width: 36,
              svg: {
                color: `${getDynamicColor("light1")} !important`,
              },
            }}
          />
        )}
        <Stack
          direction="row"
          columnGap={1}
          rowGap={0.5}
          alignItems="center"
          flexWrap="wrap"
          sx={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Typography
            component="div"
            sx={{
              ...SELECT_VALUE_STYLES,
              width: "fit-content",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            <TextOverflowTooltip
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              title={`${persona.name}, ${persona.job_title}${
                persona.company ? ` at ${persona.company}` : ""
              }`}
              textValue={`${persona.name}, ${persona.job_title}${
                persona.company ? ` at ${persona.company}` : ""
              }`}
            />
          </Typography>
          {persona.demeanor && (
            <Chip
              className="subLabel"
              sx={{
                fontSize: 10,
                fontWeight: 400,
                color: getDynamicColor("purple3"),
                backgroundColor: getDemeanorColor(persona.demeanor),
                textTransform: "uppercase",
                letterSpacing: "1px",
                p: 0,
                height: "unset",
                span: {
                  lineHeight: 1,
                  py: 1,
                },
              }}
              label={persona.demeanor}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const renderAIControls = () => {
    if (scenarioTypeId !== ScenarioTypeIdEnum.PITCH && !showCreatePersona) {
      if (editAIEndBehavior) {
        return (
          <Stack
            gap={2}
            sx={{
              borderRadius: "8px",
              border: `1px solid ${getDynamicColor("dark3")}`,
              boxShadow: Y_SHADOWS.box_shadow_1,
              pt: 5,
              px: 3,
              pb: 2,
              mx: 1,
            }}
          >
            <FormControlLabel
              label={
                <Stack direction="row" gap={1} sx={{ width: "100%", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: getDynamicColor("purple3"),
                      fontWeight: 600,
                      fontFamily: "poppins",
                    }}
                  >
                    Allow the AI to end the conversation
                  </Typography>
                  <YoodliTooltip
                    placement="bottom-start"
                    title="The AI will end the call when the conversation reaches a natural conclusion."
                  >
                    <InfoIcon
                      sx={{
                        height: 20,
                        width: 20,
                        color: getDynamicColor("purple3"),
                      }}
                    />
                  </YoodliTooltip>
                </Stack>
              }
              labelPlacement="start"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                gap: { xs: 2, md: 4 },
                mx: 0,
              }}
              control={
                <YoodliSwitch
                  checked={autoEndEnabled}
                  onClick={(e) => setAutoEndEnabled(e.target.checked)}
                  color={getDynamicColor("primary")}
                />
              }
            />
            <YoodliTextfield
              value={autoEndPrompt}
              onChange={(e) => setAutoEndPrompt(e.target.value)}
              multiline
              rows={3}
              placeholder="(Optional) You can describe when the AI should end the call here. By default, it will use its best judgement to end the call when the scenario is complete."
            />
            <FormControlLabel
              label={
                <Typography
                  sx={{
                    fontSize: 14,
                    color: getDynamicColor("purple3"),
                    fontWeight: 600,
                    fontFamily: "poppins",
                  }}
                >
                  AI will try to end this conversation after:
                </Typography>
              }
              labelPlacement="start"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                gap: { xs: 2, md: 4 },
                mx: 0,
              }}
              control={
                <Stack direction="row" alignItems="center" gap={1}>
                  <YoodliTextfield
                    type="number"
                    placeholder="e.g 15"
                    inputProps={{
                      min: 0,
                    }}
                    value={Math.floor(aiTimeLimitS / 60) === 0 ? "" : Math.floor(aiTimeLimitS / 60)}
                    onChange={(e) => {
                      const val = Number(e.target.value);
                      setAiTimeLimitS(val * 60);
                    }}
                    sx={{
                      width: 84,
                    }}
                  />
                  <Typography
                    sx={{
                      color: getDynamicColor("dark4"),
                      fontSize: 14,
                      fontFamily: "poppins",
                    }}
                  >
                    mins
                  </Typography>
                </Stack>
              }
            />
            <Button onClick={() => setEditAIEndBehavior(false)} sx={{ ml: "auto", p: 0 }}>
              Done
            </Button>
          </Stack>
        );
      } else {
        return (
          <Stack gap="10px">
            <Stack
              direction="row"
              gap={1}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontSize: 14,
                  fontWeight: 600,
                  fontFamily: "poppins",
                }}
              >
                AI conversation end behavior
              </Typography>
              <Button onClick={() => setEditAIEndBehavior(true)} sx={{ fontSize: 12 }}>
                Change setting
              </Button>
            </Stack>
            <Stack gap="6px">
              <Stack direction="row" gap={5} sx={{ alignItems: "center" }}>
                <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: getDynamicColor("dark4"),
                      fontSize: 12,
                      fontFamily: "poppins",
                    }}
                  >
                    Allow the AI to end the conversation
                  </Typography>
                  <YoodliTooltip
                    placement="bottom-start"
                    title="The AI will end the call when the conversation reaches a natural conclusion."
                  >
                    <InfoIcon
                      sx={{
                        height: 20,
                        width: 20,
                        color: getDynamicColor("dark4"),
                      }}
                    />
                  </YoodliTooltip>
                </Stack>
                <Typography
                  sx={{
                    color: getDynamicColor("dark4"),
                    fontSize: 12,
                    fontFamily: "poppins",
                    fontWeight: 600,
                  }}
                >
                  {autoEndEnabled ? "ON" : "OFF"}
                </Typography>
              </Stack>
              <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
                <Typography
                  sx={{
                    color: getDynamicColor("dark4"),
                    fontSize: 12,
                    fontFamily: "poppins",
                  }}
                >
                  Allow the AI to time limit the conversation
                </Typography>
                <Typography
                  sx={{
                    color: getDynamicColor("dark4"),
                    fontSize: 12,
                    fontFamily: "poppins",
                    fontWeight: 600,
                  }}
                >
                  {aiTimeLimitS ? `${aiTimeLimitS / 60} min` : "Not set"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      }
    }
  };

  const nestedMenuItems = React.useMemo(() => {
    return [
      {
        title: "Create a persona",
        subtitle: "Build a customized persona",
        onClick: handleCreatePersonaClicked,
      },
      {
        title: "From your library",
        subtitle: "Choose personas created by you or members of your organization",
        subMenuContent: (
          <PersonaList
            allPersonas={personas}
            selectedPersonaIds={personaIds}
            handlePersonaSelectionChange={handlePersonaSelectionChange}
            showSearchBar
            renderPersonaValue={renderPersonaValue}
            handleEditPersona={handleEditPersona}
          />
        ),
      },
    ].filter(Boolean) as StackedMenuItem[];
  }, [personas, personaIds]);

  return (
    <Stack justifyContent="flex-start" gap={2}>
      {showCreatePersona ? (
        <CreatePersonaForm
          newPersona={newPersona}
          handleUpdateNewPersona={handleUpdateNewPersona}
          currStepIndex={currStepIndex}
          wizardType={wizardType}
        />
      ) : (
        <Stack gap={6}>
          <StackedMenu
            menuItems={nestedMenuItems}
            buttonContent={
              <Stack direction="row" sx={{ gap: 1.5, alignItems: "center" }}>
                <Typography>Add a persona</Typography>
                <Typography sx={{ fontSize: 22, fontWeight: 500 }}>+</Typography>
              </Stack>
            }
            buttonSx={{
              alignItems: "center",
              justifyContent: "center",
              px: { xs: 3, md: 6 },
            }}
          />
          {renderAIControls()}
          <Stack gap={1}>
            {personaItems.length > 0 && (
              <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
                <Button
                  variant="text"
                  sx={{ fontSize: "12px" }}
                  onClick={() => {
                    handleUpdatePersonaIds([]);
                  }}
                >
                  Clear all
                </Button>
              </Stack>
            )}
            <Stack sx={{ maxHeight: "calc(100vh - 85px)", overflow: "auto", px: 1 }}>
              {personaItems.length === 0 && (
                <Typography
                  sx={{
                    color: getDynamicColor("dark4"),
                    fontSize: 14,
                    fontWeight: 600,
                    fontFamily: "poppins",
                    mt: 2,
                  }}
                >
                  No personas selected. Click the "+" button above to add a persona
                </Typography>
              )}
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} direction="column">
                      {personaItems.map((persona, index) => {
                        if (persona) {
                          return (
                            <Draggable
                              key={index}
                              draggableId={index.toString()} // According to docs, this needs to be a string
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  key={persona?.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    ...DraggableStyles,
                                  }}
                                >
                                  {provided.placeholder}
                                  <DragIndicatorIcon
                                    style={{
                                      cursor: "grab",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <Stack direction="column" sx={{ width: "100%" }}>
                                    <Stack
                                      direction="row"
                                      sx={{
                                        width: "100%",
                                        gap: 1,
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {renderPersonaValue(persona)}
                                      <YoodliMenu
                                        type={YoodliMenuButtonType.Icon}
                                        menuItems={[
                                          {
                                            title: "Edit",
                                            type: YoodliMenuItemType.Primary,
                                            onClick: () => {
                                              handleEditPersona(persona);
                                            },
                                          },
                                          {
                                            title: "Remove",
                                            type: YoodliMenuItemType.Warning,
                                            onClick: () => {
                                              handlePersonaSelectionChange(persona.id, "remove");
                                            },
                                          },
                                        ]}
                                      />
                                    </Stack>
                                  </Stack>
                                </Box>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
