import firebase from "firebase/app";
import { db } from "lib-fullstack";

// Utils
import { getSiteId } from "./LiveSiteDocs";
import { Instrumentation } from "./ProductAnalyticsUtils";
import { KeyActionAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";

// remark.isRead is not a key action, but still` update the db
// use the interaction as the key action, as that includes isRead + more info
export async function logRemarkRead(remarkRef: db.Ref<db.Remark>): Promise<void> {
  const remark = await db.get(remarkRef);
  db.update(remarkRef, {
    isRead: true,
  }).catch(console.error);

  db.upset(db.userDocs([getSiteId(), firebase.auth().currentUser?.uid]), db.UserDocTypes.REMARKS, {
    type: db.UserDocTypes.REMARKS,
    remarkCounts: {
      [remark.data.remarkId]: {
        count: db.value("increment", 1),
        lastUpdated: new Date().toISOString(),
      },
    },
  }).catch(console.error);
}

export async function logRemark(
  remarkRef: db.Ref<db.Remark>,
  how: string,
  _params?: {
    [key: string]: any; // eslint-disable-line
  },
  keyActionParams?: {
    [key: string]: any; // eslint-disable-line
  },
): Promise<void> {
  if (!keyActionParams) {
    keyActionParams = {};
  }
  keyActionParams.remark_id = (await db.get(remarkRef)).data.remarkId;
  Instrumentation.logKeyAction(KeyActionAnalyticsEvents.AUTOREMARK, {
    how,
    remarkId: remarkRef.id,
    userId: firebase.auth().currentUser?.uid,
    ...keyActionParams,
  });
}
