import React from "react";

// Components
import { Button, Stack, SxProps } from "@mui/material";
import { UploadingFile } from "components/Orgs/ManageContent/OrgFileLibrary/OrgFileLibrary";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";

// Utils
import { AddContentModal } from "./AddContentModal";
import { UploadedFiles } from "./UploadedFiles";
import { useMutation } from "@tanstack/react-query";
import { useCoachBotContentQuery } from "hooks/useCoachBotContentQuery";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteCoachBotContentV2 } from "lib-frontend/modules/axiosOrgCoachBot";
import { deleteOrgFile } from "lib-frontend/modules/axiosOrgFiles";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { BotContentV2 } from "lib-fullstack/api/coachBotApiTypes";
import { OrgCoachbotType } from "lib-fullstack/utils/productAnalyticEvents";
import { useAddContentToCoachbot } from "hooks/useAddContentToCoachbot";

type CoachBotUploadContentProps = {
  coachBotName: string;
  setLoading: (loading: boolean) => void;
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  botId: string;
  // the below function is used inside of the onProgress callback, so need to be ref getter functions rathe than actual references
  // that way the onProgress callback always has the correct value. If we were to just use the ref, it wouldnt trigger a re render when it changes
  overrideSx?: SxProps;
  hideTitle?: boolean;
  handleCancelFileUpload: (fileId: string) => void;
  coachBotType?: OrgCoachbotType;
};

export const TEXTFIELD_WIDTH = 540;
export const UPLOAD_WIDTH = {
  xs: "unset",
  md: TEXTFIELD_WIDTH,
  lg: TEXTFIELD_WIDTH + 100,
  xl: TEXTFIELD_WIDTH + 200,
};
export const MAX_FILES = 15;

export const CoachBotUploadContent = ({
  coachBotName,
  uploadingFiles,
  handleUploadContent,
  botId,
  overrideSx,
  hideTitle,
  handleCancelFileUpload,
  coachBotType,
}: CoachBotUploadContentProps): JSX.Element => {
  const [showAddContentModal, setShowAddContentModal] = React.useState<boolean>(false);

  const { coachBotContentQuery, refetchCoachBotContentQuery } = useCoachBotContentQuery(botId);
  const { refetchOrgFilesQuery } = useOrgFilesQuery();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const maxFilesReached = coachBotContentQuery.data?.files?.length >= MAX_FILES;

  const cancelFileUploadMutation = useMutation({
    mutationFn: (fileId: string) => {
      const content = coachBotContentQuery.data?.files.find((file) => file.file_id === fileId);
      return deleteCoachBotContentV2(defaultOrgId, botId, content.id, { space_id: curSpaceId });
    },
    onMutate: (fileId) => {
      handleCancelFileUpload(fileId);
      Instrumentation.logOrgCoachbotUpdated(defaultOrgId, coachBotType);
    },
    onSuccess: async (_, fileId) => {
      await deleteOrgFile(defaultOrgId, fileId, { space_id: curSpaceId });
      return Promise.all([refetchOrgFilesQuery(), refetchCoachBotContentQuery()]);
    },
  });

  const clearAllFileUploadsMutation = useMutation({
    mutationFn: (content: BotContentV2[]) => {
      return Promise.all(
        content.map((file) =>
          deleteCoachBotContentV2(defaultOrgId, botId, file.id, { space_id: curSpaceId }),
        ),
      );
    },
    onMutate: (content) => {
      content.forEach((file) => handleCancelFileUpload(file.file_id));
      Instrumentation.logOrgCoachbotUpdated(defaultOrgId, coachBotType);
    },
    onSuccess: async () => {
      return refetchCoachBotContentQuery();
    },
  });

  const { handleAddContentToCoachbot } = useAddContentToCoachbot({ botId });

  const content =
    coachBotContentQuery.data?.files?.map((file) => ({
      id: file.file_id,
      name: file.name,
    })) ?? [];

  return (
    <>
      <Stack
        direction="column"
        sx={{
          position: "relative",
          px: { xs: 2, md: 0 },
          py: { xs: 3, md: 6 },
          ...overrideSx,
        }}
        gap={{ xs: 1, md: 2.5 }}
      >
        <Stack
          sx={{
            width: UPLOAD_WIDTH,
          }}
          gap={1}
        >
          {!hideTitle && (
            <WizardTitle
              overrideSx={{
                px: { xs: 1, md: 2 },
              }}
            >
              Add content to {coachBotName}
            </WizardTitle>
          )}
          {maxFilesReached && (
            <WizardSubTitle
              overrideSx={{
                color: maxFilesReached ? getDynamicColor("redError") : getDynamicColor("dark5"),
                px: { xs: 1, md: 2 },
              }}
            >
              You've reached the max upload limit ({MAX_FILES}).
            </WizardSubTitle>
          )}
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: {
              xs: "unset",
              md: TEXTFIELD_WIDTH,
              lg: TEXTFIELD_WIDTH + 100,
              xl: TEXTFIELD_WIDTH + 200,
            },
          }}
        >
          <Button
            disabled={maxFilesReached}
            onClick={() => setShowAddContentModal(true)}
            sx={{ fontSize: 14 }}
          >
            Add files
          </Button>
          {coachBotContentQuery.data?.files.length > 0 && (
            <Button
              onClick={() => clearAllFileUploadsMutation.mutate(coachBotContentQuery.data.files)}
              sx={{
                fontSize: 12,
                color: getDynamicColor("purple3"),
              }}
            >
              Clear all
            </Button>
          )}
        </Stack>
        <UploadedFiles
          files={content}
          handleCancelFileUpload={(file) => cancelFileUploadMutation.mutateAsync(file)}
        />
      </Stack>
      <AddContentModal
        uploadingFiles={uploadingFiles}
        handleUploadContent={handleUploadContent}
        handleCancelFileUpload={(fileId) => cancelFileUploadMutation.mutateAsync(fileId)}
        amtOfFilesLeft={MAX_FILES - (coachBotContentQuery.data?.files?.length ?? 0)}
        showAddContentModal={showAddContentModal}
        setShowAddContentModal={setShowAddContentModal}
        handleAddNewContent={handleAddContentToCoachbot}
        content={content}
      />
    </>
  );
};
