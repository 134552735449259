import { db } from "lib-fullstack";
import React from "react";

// Components
import { Info as InfoIcon } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Link,
  Button,
} from "@mui/material";
import {
  DataRedactionSelect,
  DataRedactionSelectOption,
} from "components/Orgs/OrgSettings/DataRedactionSelect";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";

// Assets
import { ReactComponent as NoSpeechIcon } from "images/icons/NoSpeechIcon.svg";

// Utils
import { IOSSwitch } from "../Settings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { usePricingUsage } from "lib-frontend/hooks";
import {
  getUserDataRedactionConfig,
  updateUserDataRedactionConfig,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { updateThisUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { LandingPageExternalPath, getLandingPageExternalUrl } from "lib-fullstack/utils/paths";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

const DATA_REDACTION_CONFIG_QUERY_KEY = "dataRedactionConfig";

export const PrivacySection = (): JSX.Element => {
  const userDoc = useUserDocsState()[db.UserDocTypes.MAIN];
  const queryClient = useQueryClient();

  const { usagePlanType } = usePricingUsage();

  const [dataRedactionModalOpen, setDataRedactionModalOpen] = React.useState<boolean>(false);
  const [dataRedactionDays, setDataRedactionDays] = React.useState<number>(undefined);
  const [selectedDataRedactionOption, setSelectedDataRedactionOption] =
    React.useState<DataRedactionSelectOption>(undefined);
  const updatedDataRedactionDays =
    selectedDataRedactionOption === DataRedactionSelectOption.SelectDays ? dataRedactionDays : 0;

  const dataRedactionConfigQuery = useApiQuery({
    queryKey: [DATA_REDACTION_CONFIG_QUERY_KEY],
    queryFn: () => getUserDataRedactionConfig(),
  });

  const handleDataCollection = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateThisUserDocMain({
      dataCollectionConsent: e.target.checked,
    }).catch(console.error);
  };

  const updateDataRedactionMutation = useMutation({
    mutationFn: () => updateUserDataRedactionConfig({ user_config_days: updatedDataRedactionDays }),
    onSuccess: async () => {
      Instrumentation.logUserDataRedactionDaysUpdated(updatedDataRedactionDays);
      await queryClient.invalidateQueries({ queryKey: [DATA_REDACTION_CONFIG_QUERY_KEY] });
    },
    onSettled: () => {
      setDataRedactionDays(undefined);
      setSelectedDataRedactionOption(undefined);
    },
  });

  const updateDataRedaction = async () => {
    if (updatedDataRedactionDays === dataRedactionConfigQuery.data.user_config_days) {
      setDataRedactionDays(undefined);
      setSelectedDataRedactionOption(undefined);
    } else if (
      // Only show confirmation modal when moving to a more stringent data retention settings
      updatedDataRedactionDays === 0 ||
      (updatedDataRedactionDays > dataRedactionConfigQuery.data.user_config_days &&
        dataRedactionConfigQuery.data.user_config_days !== 0)
    ) {
      updateDataRedactionMutation.mutate();
    } else {
      setDataRedactionModalOpen(true);
    }
  };

  const dataDeletionEnabledIcon = () => {
    if (usagePlanType === UsagePlanType.ENTERPRISE) {
      if (dataRedactionConfigQuery.data.org_config_days === 0) {
        return (
          <Tooltip title="Your organization has enabled data retention" placement="right">
            <InfoIcon sx={{ color: getDynamicColor("dark3") }} />
          </Tooltip>
        );
      } else {
        return (
          <Box
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "30px",
              background: getDynamicColor("dark2"),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0.5,
            }}
          >
            <NoSpeechIcon />
          </Box>
        );
      }
    }
  };

  const renderDataDeletionControls = () => {
    const getDataRedactionText = () => {
      if (dataRedactionConfigQuery.data.user_config_days === 0) {
        return "Disabled";
      }

      return `Every ${dataRedactionConfigQuery.data.user_config_days} day${
        dataRedactionConfigQuery.data.user_config_days > 1 ? "s" : ""
      }`;
    };

    if (selectedDataRedactionOption) {
      return (
        <Stack gap={3}>
          <DataRedactionSelect
            dataRedactionDays={dataRedactionDays}
            setDataRedactionDays={setDataRedactionDays}
            selectedDataRedactionOption={selectedDataRedactionOption}
            setSelectedDataRedactionOption={setSelectedDataRedactionOption}
            otherLabel="Disable"
          />
          <Button onClick={updateDataRedaction} variant="outlined" sx={{ fontSize: "12px" }}>
            Save changes
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack gap={2}>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: "16px",
              textAlign: { xs: "start", md: "end" },
              fontFamily: "poppins",
              fontWeight: 500,
            }}
          >
            {getDataRedactionText()}
          </Typography>
          <Button
            onClick={() => {
              setDataRedactionDays(dataRedactionConfigQuery.data.user_config_days);
              setSelectedDataRedactionOption(
                dataRedactionConfigQuery.data.user_config_days
                  ? DataRedactionSelectOption.SelectDays
                  : DataRedactionSelectOption.Other,
              );
            }}
            sx={{
              fontSize: 14,
              whiteSpace: "nowrap",
              px: { xs: 0, md: "auto" },
            }}
          >
            Manage data
          </Button>
        </Stack>
      );
    }
  };

  const renderDataDeletionSelector = () => {
    return (
      dataRedactionConfigQuery.data?.available && (
        <Stack pt={5} gap={2.5}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Data retention
            </Typography>
            {dataDeletionEnabledIcon()}
          </Stack>
          <Stack
            gap={2}
            direction={{ xs: "column", md: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography
              sx={{
                width: { xs: "unset", lg: "49%" },
                color: getDynamicColor("purple3"),
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "160%",
                pr: { xs: 2, lg: 0 },
              }}
            >
              {dataRedactionConfigQuery.data.org_config_days > 0
                ? `Your organization has enabled data retention. This means that your recordings and transcripts will be stored for ${
                    dataRedactionConfigQuery.data.org_config_days
                  } day${
                    dataRedactionConfigQuery.data.org_config_days !== 1 ? "s" : ""
                  }. Your analytics and progress will be kept after deletion.`
                : "Yoodli will automatically delete recordings and transcripts after selected time frame while keeping analytics and progress"}
            </Typography>
            {dataRedactionConfigQuery.data.org_config_days === 0 && renderDataDeletionControls()}
          </Stack>
        </Stack>
      )
    );
  };

  return (
    <>
      <div style={{ zIndex: 1 }}>
        <Typography component="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
          Privacy
        </Typography>
        {renderDataDeletionSelector()}
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mt: { xs: 4, md: 2 } }}
        >
          <Grid item flexWrap={"wrap"} xs={10} lg={6}>
            <Grid container direction="row" alignItems="center" wrap="nowrap">
              <Grid item sx={{ paddingRight: "0.5em" }}>
                <Typography className="font-size-label">
                  Use my data to improve Yoodli AI Speech Coach
                </Typography>
              </Grid>
              <Grid item sx={{ height: "24px" }}>
                <Tooltip title="We never share your data externally, and will never use your likeness in model training. Leaving this checked lets us use your data to improve machine learning features in Yoodli. This applies to past and future recordings.">
                  <InfoIcon sx={{ color: getDynamicColor("dark3") }} />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={userDoc.dataCollectionConsent}
                    onChange={handleDataCollection}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
          <Link
            sx={{
              color: getDynamicColor("primary"),
              fontWeight: 600,
              fontSize: { xs: 14, md: 16 },
              mt: 2,
            }}
            href={getLandingPageExternalUrl(getClientEnvConfig(), LandingPageExternalPath.PRIVACY)}
          >
            View privacy policy
          </Link>
        </Grid>
      </div>
      <YoodliCtaModal
        hideCloseButton
        open={dataRedactionModalOpen}
        close={() => setDataRedactionModalOpen(false)}
        ctaBody={{
          title: `Set Data Retention to ${updatedDataRedactionDays} day${
            updatedDataRedactionDays === 1 ? "" : "s"
          }?`,
          subtitle: (
            <Typography sx={{ fontSize: "14px" }}>
              All video recordings and transcripts will be{" "}
              <strong>
                kept for {updatedDataRedactionDays} day
                {updatedDataRedactionDays === 1 ? "" : "s"}.
              </strong>{" "}
              Analytics will be kept and continue to shown on dashboards after deletion.
            </Typography>
          ),
        }}
        buttons={
          {
            primary: {
              text: "Set Data Retention",
              handler: () => updateDataRedactionMutation.mutate(),
            },
            secondary: { text: "Cancel", handler: () => setDataRedactionModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
