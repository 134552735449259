import React from "react";

// Utils
import { useOrgFilesQuery } from "./useOrgFilesQuery";
import { useMutation } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useStateRef } from "lib-frontend/hooks";
import { createOrgFile, patchOrgFile } from "lib-frontend/modules/axiosOrgFiles";
import { uploadBlobToResumableUploadUrl, UploadProgress } from "lib-frontend/utils/resumableUpload";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import { OrgFileSourceType, OrgFileState } from "lib-fullstack/utils/enums";
import { getAcceptedFileTypes } from "utils/orgContentUtils";
import { CreateContentRequestKind } from "lib-fullstack/db";

type UploadingFile = {
  id: string;
  progress: number;
  state: OrgFileState;
};

type ContentUploadResponse = {
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  handleCancelFileUpload: (fileId: string) => void;
  handleClearUploadedFiles: () => void;
};

type ContentUploadRequest = {
  handleAddNewContent?: (fileIds: string[]) => void;
};

/**
 * Hook to upload content to the current organization and space
 * @param handleAddNewContent: Optional callback function to handle newly added content file IDs
 * @returns uploadingFiles: Array of files currently being uploaded
 * @returns handleUploadContent: Function to upload content
 * @returns handleCancelFileUpload: Function to cancel an ongoing file upload
 */
export const useContentUpload = ({
  handleAddNewContent,
}: ContentUploadRequest): ContentUploadResponse => {
  const { refetchOrgFilesQuery } = useOrgFilesQuery();

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const acceptedFileTypes = getAcceptedFileTypes();

  const [uploadingFiles, setUploadingFiles, uploadingFilesRef] = useStateRef<UploadingFile[]>([]);

  const createOrgFileMutation = useMutation({
    mutationFn: (files: File[]) =>
      createOrgFile(defaultOrgId, {
        request_type: CreateContentRequestKind.New,
        space_id: curSpaceId,
        files: files.map((file) => ({
          source_type: OrgFileSourceType.Upload,
          name: file.name,
          mime_type: file.type,
        })),
      }),
    onSuccess: async (createFilesResponse, files) => {
      handleAddNewContent?.(createFilesResponse.files.map((file) => file.id));
      return Promise.all([
        refetchOrgFilesQuery(),
        asyncMap(
          createFilesResponse.files,
          async (fileResponse, i) => {
            await uploadBlobToResumableUploadUrl(
              files[i],
              fileResponse.upload_url,
              (progress: UploadProgress) => onProgress(fileResponse.id, progress),
              `upload (${files[i].name})`,
            );
          },
          5,
          1,
        ),
      ]);
    },
  });

  const updateOrgFileStateMutation = useMutation({
    mutationFn: (fileId: string) =>
      patchOrgFile(defaultOrgId, fileId, {
        space_id: curSpaceId,
        state: OrgFileState.Pending,
      }),
    onSuccess: () => {
      return refetchOrgFilesQuery();
    },
  });

  const onProgress = (fileId: string, progress: UploadProgress) => {
    const progressPct = Math.floor((progress.uploadedBytes / progress.totalBytes) * 100);
    handleUpdateFileProgress(fileId, progressPct);
    return true;
  };

  const handleUpdateFileProgress = (fileId: string, progress: number) => {
    const isComplete = progress >= 100;

    // Update uploadedFiles state to reflect the progress of the file upload
    let newFiles: UploadingFile[];
    const fileExists = uploadingFilesRef.current.some((file) => file.id === fileId);
    if (fileExists) {
      newFiles = uploadingFilesRef.current.map((file) =>
        file.id === fileId ? { ...file, progress } : file,
      );
    } else {
      newFiles = [
        ...uploadingFilesRef.current,
        { id: fileId, progress, state: OrgFileState.Uploading },
      ];
    }
    setUploadingFiles(newFiles);
    if (
      isComplete &&
      uploadingFilesRef.current.find((x) => x.id === fileId).state === OrgFileState.Uploading
    ) {
      setUploadingFiles(
        uploadingFilesRef.current.map((file) =>
          file.id === fileId ? { ...file, state: OrgFileState.Pending } : file,
        ),
      );
      updateOrgFileStateMutation.mutate(fileId);
    }
  };

  const handleCancelFileUpload = (fileId: string) => {
    // Update the file state to finished so the progress callback doesn't complete the upload
    setUploadingFiles((oldFiles) =>
      oldFiles.map((file) =>
        file.id === fileId ? { ...file, state: OrgFileState.Finished } : file,
      ),
    );
  };

  // Return how much the file is over the limit in bytes
  // Returning 0 if it's not over the limit
  const getBytesOver = (file: File): number => {
    for (const { mime, regexp, maxSize } of acceptedFileTypes) {
      if ((regexp && regexp.test(file.type)) || (!regexp && file.type === mime)) {
        return Math.max(file.size - maxSize, 0);
      }
    }
    // It is unexpected to reach here. Return 1 to block this file anyway.
    console.warn(`Unexpected file type: ${file.type}`);
    return 1;
  };

  const isValidFile = (file: File): boolean => {
    return getBytesOver(file) === 0;
  };

  const handleUploadContent = (files: File[]) => {
    const validFiles = files.filter(isValidFile);
    if (validFiles.length === 0) {
      return;
    }

    createOrgFileMutation.mutate(validFiles);
  };

  return {
    uploadingFiles: uploadingFiles,
    handleUploadContent: handleUploadContent,
    handleCancelFileUpload,
    handleClearUploadedFiles: () => setUploadingFiles([]),
  };
};
