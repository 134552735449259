// Utils
import { getUserId } from "./AccountUtils";
import { getSiteId } from "./LiveSiteDocs";
import { getLiveUserDocMain } from "./LiveUserDocs";
import { Instrumentation } from "./ProductAnalyticsUtils";
import {
  OnboardingChecklist,
  OnboardingChecklistItem,
  OnboardingChecklistTask,
} from "lib-fullstack/db";
import { CalendarAction } from "lib-fullstack/utils/calendar";
import { OBQ1Option } from "lib-fullstack/utils/enums";
import {
  markOnboardingTaskComplete,
  markOnboardingTaskDismissed,
  onboardingChecklists,
} from "lib-fullstack/utils/onboardingChecklistUtils";
import { HomePageChecklistEvents } from "lib-fullstack/utils/productAnalyticEvents";

export type OnboardingState = {
  eventsFocus: OBQ1Option;
  calendarAction?: CalendarAction;
};

export type OnboardingChecklistDoc = {
  checklistId: OnboardingChecklist;
  completedTimestamp: string;
  viewedCompletedAnimationTimestamp: string;
  completedTasks: OnboardingChecklistItem[];
  dismissedTasks: OnboardingChecklistItem[];
};

export const markAllOnboardingTasksComplete = async (): Promise<void> => {
  const onboardingChecklistDoc = getLiveUserDocMain()?.onboardingChecklist;
  if (onboardingChecklistDoc?.checklistId) {
    const availableTasks = onboardingChecklists[onboardingChecklistDoc.checklistId];
    Promise.all(
      availableTasks.map(async (task) => {
        markOnboardingTaskComplete(task, getSiteId(), getUserId()).catch((er) => {
          console.error(`failed to mark onboarding task ${task} complete`, er);
        });
      }),
    ).catch((er) => {
      console.error("failed to mark all onboarding tasks complete", er);
    });
  }
};

export const markOnboardingTaskCompleteAndEmitEvent = async (
  completedTask: OnboardingChecklistTask,
): Promise<void> => {
  try {
    const onboardingChecklistDoc = getLiveUserDocMain()?.onboardingChecklist;
    if (onboardingChecklistDoc?.checklistId) {
      if (onboardingChecklistDoc.completedTasks?.some((task) => task.task === completedTask)) {
        return; // Do nothing since task already completed.
      }

      const availableTasks = onboardingChecklists[onboardingChecklistDoc.checklistId];
      const completedTaskIdx = availableTasks.indexOf(completedTask);

      if (completedTaskIdx !== -1) {
        // Only emit event if it is in the user's checklist.
        Instrumentation.logYoodliOnboardingChecklistItemCompleted(completedTask, completedTaskIdx);
      }
      const isChecklistCompletedWithThisTask = await markOnboardingTaskComplete(
        completedTask,
        getSiteId(),
        getUserId(),
      );
      if (isChecklistCompletedWithThisTask) {
        Instrumentation.logAmplitudeEvent(
          HomePageChecklistEvents.YOODLI_CHECKLIST_ALL_ITEMS_COMPLETED,
        );
      }
    } else {
      console.log(
        `Cannot mark onboarding task ${completedTask} complete since no onboarding checklist found.`,
      );
    }
  } catch (er) {
    throw new Error(
      `onboardingUtils.markOnboardingTaskCompleteAndEmitEvent: Error marking onboarding task ${completedTask} complete: ${er}`,
    );
  }
};
export const markOnboardingTaskDismissedAndEmitEvent = async (
  dismissedTask: OnboardingChecklistTask,
): Promise<void> => {
  try {
    const onboardingChecklistDoc = getLiveUserDocMain()?.onboardingChecklist;
    if (onboardingChecklistDoc?.checklistId) {
      if (onboardingChecklistDoc.dismissedTasks?.some((task) => task.task === dismissedTask)) {
        return; // Do nothing since task already completed.
      }

      const availableTasks = onboardingChecklists[onboardingChecklistDoc.checklistId];
      const dismissedTaskIdx = availableTasks.indexOf(dismissedTask);

      if (dismissedTaskIdx !== -1) {
        // Only emit event if it is in the user's checklist.
        Instrumentation.logYoodliOnboardingChecklistItemDismissed(dismissedTask, dismissedTaskIdx);
      }
      const isChecklistCompletedWithThisTask = await markOnboardingTaskDismissed(
        dismissedTask,
        getSiteId(),
        getUserId(),
      );
      if (isChecklistCompletedWithThisTask) {
        Instrumentation.logAmplitudeEvent(
          HomePageChecklistEvents.YOODLI_CHECKLIST_ALL_ITEMS_COMPLETED,
        );
      }
    } else {
      console.log(
        `Cannot mark onboarding task ${dismissedTask} dismissed since no onboarding checklist found.`,
      );
    }
  } catch (er) {
    throw new Error(
      `onboardingUtils.markOnboardingTaskDismissedAndEmitEvent: Error marking onboarding task ${dismissedTask} dismissed: ${er}`,
    );
  }
};
