import { db } from "lib-fullstack";
import React from "react";

// Components
import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { IOSSwitch } from "../Settings";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { updateThisUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import { OrgSharingVisibilityEnum } from "lib-fullstack/db";

enum SpeechVisibility {
  PUBLIC = "Public",
  PRIVATE = "Private",
  ORG_ONLY = "Organization only",
}

export const WhenRecordSpeech = (): JSX.Element => {
  const userDoc = useUserDocsState()[db.UserDocTypes.MAIN];

  const { defaultOrgId, defaultOrgSharingVisibility } = React.useContext(UserOrgContext);

  const [defaultSpeechVisibility, setDefaultSpeechVisibility] = React.useState<SpeechVisibility>(
    userDoc.defaultLinkSharingPublic ? SpeechVisibility.PUBLIC : SpeechVisibility.PRIVATE,
  );

  /**
   * If user has not set a default sharing visibility, and they are in an organization, indicate that the org setting overrrides
   */
  React.useEffect(() => {
    if (defaultOrgId && userDoc.defaultLinkSharingPublic === undefined) {
      switch (defaultOrgSharingVisibility) {
        case OrgSharingVisibilityEnum.OrgOnly:
          setDefaultSpeechVisibility(SpeechVisibility.ORG_ONLY);
          break;
        case OrgSharingVisibilityEnum.Public:
          setDefaultSpeechVisibility(SpeechVisibility.PUBLIC);
          break;
        case OrgSharingVisibilityEnum.Private:
        default:
          setDefaultSpeechVisibility(SpeechVisibility.PRIVATE);
          break;
      }
    }
  }, [defaultOrgId, userDoc?.defaultLinkSharingPublic]);

  // Only show org option in the menu if the user is in an org with "org only" sharing preferences and the user has not set a default sharing visibility
  const defaultSharingMenuItems = React.useMemo(() => {
    let items = Object.values(SpeechVisibility);
    if (
      defaultOrgSharingVisibility !== OrgSharingVisibilityEnum.OrgOnly ||
      userDoc.defaultLinkSharingPublic !== undefined
    ) {
      items = items.filter((visibility) => visibility !== SpeechVisibility.ORG_ONLY);
    }
    return items;
  }, [userDoc?.defaultLinkSharingPublic, defaultOrgSharingVisibility]);

  const handleRealTimeAlert = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      realTimeAlertsOn: e.target.checked,
    }).catch(console.error);

  const handleMirror = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      mirrorVideo: e.target.checked,
    }).catch(console.error);

  const handleLanguageLearnerFeedback = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateThisUserDocMain({
      languageLearnerFeedback: e.target.checked,
    }).catch(console.error);

  const handleDefaultSpeechVisibility = (newVisibility: SpeechVisibility) => {
    setDefaultSpeechVisibility(newVisibility);
    const visibilityIsPublic = newVisibility === SpeechVisibility.PUBLIC;
    updateThisUserDocMain({
      defaultLinkSharingPublic: visibilityIsPublic,
    }).catch(console.error);
  };

  const renderSwitches = () => (
    <Grid container>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mt: { xs: 4, md: 2 } }}
      >
        <Grid item flexWrap={"wrap"} xs={10} lg={6}>
          <Typography className="font-size-label">Show real-time alerts</Typography>
        </Grid>
        <Grid item xs={1} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch checked={userDoc.realTimeAlertsOn} onChange={handleRealTimeAlert} />
              }
              label=""
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mt: { xs: 4, md: 2 } }}
      >
        <Grid item flexWrap={"wrap"} xs={10} lg={6}>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item sx={{ paddingRight: "0.5em" }}>
              <Typography className="font-size-label">Mirror my video</Typography>
            </Grid>
            <Grid item sx={{ height: "24px" }}>
              <Tooltip title="Mirroring video gives you the view of yourself you're used to seeing in, well, a mirror">
                <InfoIcon sx={{ color: getDynamicColor("dark3") }} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch checked={userDoc.mirrorVideo} onChange={handleMirror} />}
              label=""
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mt: { xs: 4, md: 2 } }}
      >
        <Grid item flexWrap={"wrap"} xs={10} lg={6}>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item sx={{ paddingRight: "0.5em" }}>
              <Typography className="font-size-label">Pronunciation feedback</Typography>
            </Grid>
            <Grid item sx={{ height: "24px" }}>
              <Tooltip title="Provide pronunciation feedback for language learners">
                <InfoIcon sx={{ color: getDynamicColor("dark3") }} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={userDoc.languageLearnerFeedback ?? true}
                  onChange={handleLanguageLearnerFeedback}
                />
              }
              label=""
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderDefaultSpeechVisibilitySelector = () => (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      rowGap={1}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", lg: "center" }}
      sx={{
        mt: { xs: 2, md: 3 },
        width: "100%",
      }}
    >
      <Typography
        sx={{
          width: { xs: "unset", lg: "49%" },
        }}
      >
        Default sharing visibility (applies to new Yoodlis)
      </Typography>
      <Stack
        direction="row"
        gap={4}
        sx={{
          width: { xs: "unset", lg: "51%" },
          alignItems: "center",
        }}
      >
        <YoodliSelect
          value={defaultSpeechVisibility}
          onChange={(e) => handleDefaultSpeechVisibility(e.target.value as SpeechVisibility)}
          MenuProps={{
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
            sx: {
              ".MuiPaper-root": {
                maxHeight: { xs: "50vh", md: "min(800px, 90vh)" },
              },
            },
          }}
          sx={{
            minWidth: "auto",
            height: 44,
            px: 2,
          }}
        >
          {defaultSharingMenuItems.map((visibility) => (
            <MenuItem key={visibility} value={visibility}>
              {visibility}
            </MenuItem>
          ))}
        </YoodliSelect>
        {defaultOrgId && userDoc.defaultLinkSharingPublic === undefined && (
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: 14,
            }}
          >
            This has been set by your organization’s administrator.
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  return (
    <div>
      <Typography component="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
        When I Record a Speech...
      </Typography>
      {renderSwitches()}
      {renderDefaultSpeechVisibilitySelector()}
    </div>
  );
};
