import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useNavigate } from "react-router";

// Components
import {
  demoVideoTask,
  practiceTask,
  sampleSpeechTask,
  customizeInterviewQuestionsTask,
  uploadTask,
  createOrgTask,
  inviteToHubTask,
  shareSpeechTask,
  ChecklistDataElement,
  viewSpeechSummaryTask,
  salesNudgeTask,
} from "components/Home/OnboardingChecklist/tasks";

// Utils
import { useOnQuery } from "@typesaurus/react";
import { updateSpeechShare } from "lib-frontend/modules/AxiosInstance";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import {
  markOnboardingTaskCompleteAndEmitEvent,
  markOnboardingTaskDismissedAndEmitEvent,
} from "lib-frontend/utils/onboardingUtils";
import { PracticeTypeId } from "lib-frontend/utils/practiceRecorderUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { PracticeRecorderQueryParams } from "lib-fullstack/utils/queryParams";
import { copyToMyClipboard, generateSpeechSharePath } from "lib-frontend/utils/Utilities";
import { OnboardingChecklistTask } from "lib-fullstack/db";
import { onboardingChecklists } from "lib-fullstack/utils/onboardingChecklistUtils";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import {
  EventsKeyActionHows,
  KeyActionAnalyticsEvents,
} from "lib-fullstack/utils/productAnalyticEvents";
import { WebServerInternalPath } from "utils/paths";
import { generateSpeechShareFullLink } from "utils/Utilities";
import { useShowB2BNudgeBanner } from "./useShowB2BNudgeBanner";

type PracticeClickHandler = (
  path: string,
  task: OnboardingChecklistTask,
  name: string,
  buttonText: string,
) => void;

type ChecklistOnClickHandlers = {
  [OnboardingChecklistTask.DEMO_VIDEO]: () => void;
  [OnboardingChecklistTask.VIEW_SAMPLE_SPEECH]: () => void;
  [OnboardingChecklistTask.UPLOAD_SPEECH]: () => void;
  [OnboardingChecklistTask.FPR_CONVERSATION]: PracticeClickHandler;
  [OnboardingChecklistTask.FPR_INTERVIEW]: PracticeClickHandler;
  [OnboardingChecklistTask.FPR_SPEECH]: PracticeClickHandler;
};

type Tasks = { [key: string]: ChecklistDataElement };
type TaskList = ChecklistDataElement[];

type UseOnboardingChecklistTasksReturn = {
  taskLists: { [key: string]: TaskList };
  checkIfCompleted: (task: OnboardingChecklistTask) => boolean;
  checkIfDismissed: (task: OnboardingChecklistTask) => boolean;
};

export const useOnboardingChecklistTasks = (
  allTasks: OnboardingChecklistTask[],
  completedTasks: OnboardingChecklistTask[],
  dismissedTasks: OnboardingChecklistTask[],
  inviteUrl: string,
  onClickHandlers: ChecklistOnClickHandlers,
): UseOnboardingChecklistTasksReturn => {
  const { onTalkToSalesPressed, onDismiss } = useShowB2BNudgeBanner();
  const navigate = useNavigate();
  const [latestSpeeches, _latestSpeechMeta] = useOnQuery<db.Speech>(
    // For anonymous access, this query ends up with setting latestSpeech as 'undefined'
    db.userSpeeches([getSiteId(), firebase.auth().currentUser?.uid]),
    [db.limit(1), db.order("recordingStartDate", "desc")],
  );
  const latestSpeech = latestSpeeches?.[0];
  const checkIfCompleted = (task: OnboardingChecklistTask) => {
    return completedTasks?.includes(task);
  };
  const checkIfDismissed = (task: OnboardingChecklistTask) => {
    return dismissedTasks?.includes(task);
  };
  const tasks = React.useMemo((): Tasks => {
    return {
      [OnboardingChecklistTask.DEMO_VIDEO]: demoVideoTask(() => {
        onClickHandlers[OnboardingChecklistTask.DEMO_VIDEO]();
      }),
      [OnboardingChecklistTask.VIEW_SAMPLE_SPEECH]: sampleSpeechTask(
        (name: string, buttonText: string) => {
          Instrumentation.logYoodliOnboardingChecklistCTAClicked(
            name,
            OnboardingChecklistTask.VIEW_SAMPLE_SPEECH,
            allTasks.indexOf(OnboardingChecklistTask.VIEW_SAMPLE_SPEECH),
            checkIfCompleted(OnboardingChecklistTask.VIEW_SAMPLE_SPEECH),
            buttonText,
          );
          onClickHandlers[OnboardingChecklistTask.VIEW_SAMPLE_SPEECH]();
        },
      ),
      [OnboardingChecklistTask.VIEW_SPEECH_SUMMARY]: viewSpeechSummaryTask(
        (name: string, buttonText: string) => {
          Instrumentation.logYoodliOnboardingChecklistCTAClicked(
            name,
            OnboardingChecklistTask.VIEW_SPEECH_SUMMARY,
            allTasks.indexOf(OnboardingChecklistTask.VIEW_SPEECH_SUMMARY),
            checkIfCompleted(OnboardingChecklistTask.VIEW_SPEECH_SUMMARY),
            buttonText,
          );
          const link = generateSpeechSharePath(latestSpeech.data);
          navigate(link);
        },
        !latestSpeech,
      ),
      [OnboardingChecklistTask.FPR_INTERVIEW]: practiceTask(
        PracticeTypeId.INTERVIEW,
        (name, buttonText) =>
          onClickHandlers[OnboardingChecklistTask.FPR_INTERVIEW](
            WebServerExternalPath.PRACTICE_INTERVIEW,
            OnboardingChecklistTask.FPR_INTERVIEW,
            name,
            buttonText,
          ),
        checkIfCompleted(OnboardingChecklistTask.FPR_INTERVIEW),
      ),
      [OnboardingChecklistTask.FPR_SPEECH]: practiceTask(
        PracticeTypeId.SPEECH,
        (name, buttonText) =>
          onClickHandlers[OnboardingChecklistTask.FPR_SPEECH](
            WebServerExternalPath.PRACTICE_SPEECH,
            OnboardingChecklistTask.FPR_SPEECH,
            name,
            buttonText,
          ),
        checkIfCompleted(OnboardingChecklistTask.FPR_SPEECH),
      ),
      [OnboardingChecklistTask.FPR_CONVERSATION]: practiceTask(
        PracticeTypeId.CONVERSATION,
        (name, buttonText) =>
          onClickHandlers[OnboardingChecklistTask.FPR_CONVERSATION](
            WebServerExternalPath.PRACTICE_CONVERSATION,
            OnboardingChecklistTask.FPR_CONVERSATION,
            name,
            buttonText,
          ),
        checkIfCompleted(OnboardingChecklistTask.FPR_CONVERSATION),
      ),
      [OnboardingChecklistTask.CUSTOMIZE_QUESTIONS_AND_PRACTICE]: customizeInterviewQuestionsTask(
        (name, buttonText) => {
          const ctaUrl =
            WebServerExternalPath.PRACTICE_INTERVIEW +
            `?${PracticeRecorderQueryParams.CUSTOMIZE_QUESTIONS}=true`;
          Instrumentation.logYoodliOnboardingChecklistCTAClicked(
            name,
            OnboardingChecklistTask.CUSTOMIZE_QUESTIONS_AND_PRACTICE,
            allTasks.indexOf(OnboardingChecklistTask.CUSTOMIZE_QUESTIONS_AND_PRACTICE),
            checkIfCompleted(OnboardingChecklistTask.CUSTOMIZE_QUESTIONS_AND_PRACTICE),
            buttonText,
            ctaUrl,
          );
          navigate(ctaUrl);
        },
        checkIfCompleted(OnboardingChecklistTask.CUSTOMIZE_QUESTIONS_AND_PRACTICE),
      ),
      [OnboardingChecklistTask.UPLOAD_SPEECH]: uploadTask((name, buttonText) => {
        onClickHandlers[OnboardingChecklistTask.UPLOAD_SPEECH]();
        Instrumentation.logYoodliOnboardingChecklistCTAClicked(
          name,
          OnboardingChecklistTask.UPLOAD_SPEECH,
          allTasks.indexOf(OnboardingChecklistTask.UPLOAD_SPEECH),
          checkIfCompleted(OnboardingChecklistTask.UPLOAD_SPEECH),
          buttonText,
        );
      }, checkIfCompleted(OnboardingChecklistTask.UPLOAD_SPEECH)),
      [OnboardingChecklistTask.CREATE_ORG]: createOrgTask((name, buttonText) => {
        const ctaUrl = checkIfCompleted(OnboardingChecklistTask.CREATE_ORG)
          ? WebServerInternalPath.ORG_SETTINGS
          : WebServerInternalPath.CREATE_ORG;
        Instrumentation.logYoodliOnboardingChecklistCTAClicked(
          name,
          OnboardingChecklistTask.CREATE_ORG,
          allTasks.indexOf(OnboardingChecklistTask.CREATE_ORG),
          checkIfCompleted(OnboardingChecklistTask.CREATE_ORG),
          buttonText,
          ctaUrl,
        );
        navigate(ctaUrl);
      }, checkIfCompleted(OnboardingChecklistTask.CREATE_ORG)),
      [OnboardingChecklistTask.SALES_NUDGE]: salesNudgeTask(
        (name, buttonText) => {
          Instrumentation.logYoodliOnboardingChecklistCTAClicked(
            name,
            OnboardingChecklistTask.SALES_NUDGE,
            allTasks.indexOf(OnboardingChecklistTask.SALES_NUDGE),
            checkIfCompleted(OnboardingChecklistTask.SALES_NUDGE),
            buttonText,
          );
          markOnboardingTaskCompleteAndEmitEvent(db.OnboardingChecklistTask.SALES_NUDGE).catch(
            (err) =>
              console.error(
                `Error marking invite-client-to-group onboarding task complete: ${err}`,
              ),
          );
          onTalkToSalesPressed("checklist");
        },
        checkIfCompleted(OnboardingChecklistTask.SALES_NUDGE),
        (name) => {
          Instrumentation.logYoodliOnboardingChecklistCTAClicked(
            name,
            OnboardingChecklistTask.SALES_NUDGE,
            allTasks.indexOf(OnboardingChecklistTask.SALES_NUDGE),
            checkIfCompleted(OnboardingChecklistTask.SALES_NUDGE),
            "Dismiss",
          );
          markOnboardingTaskDismissedAndEmitEvent(db.OnboardingChecklistTask.SALES_NUDGE).catch(
            (err) => console.error(`Error marking sales-nudge onboarding task dismissed: ${err}`),
          );
          onDismiss();
        },
      ),
      [OnboardingChecklistTask.INVITE_CLIENT_TO_HUB]: inviteToHubTask(
        (name, buttonText) => {
          Instrumentation.logYoodliOnboardingChecklistCTAClicked(
            name,
            OnboardingChecklistTask.INVITE_CLIENT_TO_HUB,
            allTasks.indexOf(OnboardingChecklistTask.INVITE_CLIENT_TO_HUB),
            checkIfCompleted(OnboardingChecklistTask.INVITE_CLIENT_TO_HUB),
            buttonText,
          );
          if (inviteUrl) {
            copyToMyClipboard(
              inviteUrl,
              "Copied the link to your group to your clipboard! Share: " + inviteUrl,
            );

            markOnboardingTaskCompleteAndEmitEvent(
              db.OnboardingChecklistTask.INVITE_CLIENT_TO_HUB,
            ).catch((err) =>
              console.error(
                `Error marking invite-client-to-group onboarding task complete: ${err}`,
              ),
            );
          }
        },
        checkIfCompleted(OnboardingChecklistTask.INVITE_CLIENT_TO_HUB),
        inviteUrl,
      ),
      [OnboardingChecklistTask.SHARE_SPEECH]: shareSpeechTask((name, buttonText) => {
        const link = generateSpeechShareFullLink(latestSpeech.data);
        copyToMyClipboard(link, "Copied your latest Yoodli to the clipboard! Share: " + link);

        // Set to public visibility
        updateSpeechShare(latestSpeech.ref.id, {
          link_sharing: true,
        }).catch((err) => console.error(`Error updating speech linkSharing: ${err}`));

        Instrumentation.logKeyAction(KeyActionAnalyticsEvents.SHARE_SPEECH, {
          link,
          userId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
          slug: latestSpeech.data.slug,
          how: EventsKeyActionHows.SHARE_LINK,
        });
        markOnboardingTaskCompleteAndEmitEvent(db.OnboardingChecklistTask.SHARE_SPEECH).catch(
          (err) => console.error(`Error marking onboarding task complete: ${err}`),
        );
        Instrumentation.logYoodliOnboardingChecklistCTAClicked(
          name,
          OnboardingChecklistTask.SHARE_SPEECH,
          allTasks.indexOf(OnboardingChecklistTask.SHARE_SPEECH),
          checkIfCompleted(OnboardingChecklistTask.SHARE_SPEECH),
          buttonText,
        );
      }, !latestSpeech),
    };
  }, [allTasks, history, completedTasks, inviteUrl, latestSpeech]);
  const taskLists = React.useMemo(() => {
    const _taskLists: { [key: string]: TaskList } = {};
    Object.keys(onboardingChecklists).forEach((checklistId) => {
      _taskLists[checklistId] = onboardingChecklists[checklistId].map((task) => tasks[task]);
    });
    return _taskLists;
  }, [tasks]);
  return { taskLists, checkIfCompleted, checkIfDismissed };
};
