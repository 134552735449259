import { db } from "lib-fullstack";

export const updateDailyStreak = async (
  siteId: string,
  userId: string,
  useDocHomeTimezone: boolean,
): Promise<void> => {
  // Update daily streak if applicable
  const userDocPublic = await db.getUserDocPublic(siteId, userId);
  const lastStreakActionDate = userDocPublic.lastStreakActionDate;
  let yesterday = new Date();
  let today = new Date();

  // If this function is called from the backend, we need to convert the dates to the user's home timezone instead of using default UTC.
  if (useDocHomeTimezone) {
    const userDocMain = await db.getUserDocMain(siteId, userId);
    if (userDocMain?.homeTimezone) {
      const homeTimeZone = userDocMain.homeTimezone;
      yesterday = new Date(yesterday.toLocaleString("en-US", { timeZone: homeTimeZone }));
      today = new Date(today.toLocaleString("en-US", { timeZone: homeTimeZone }));
    }
  }

  yesterday.setDate(today.getDate() - 1);

  // last streak action was yesterday, so streak += 1
  if (lastStreakActionDate === yesterday.toDateString()) {
    const newStreak = userDocPublic.dailyStreak + 1;
    await db
      .updateUserDocPublic(siteId, userId, {
        dailyStreak: newStreak,
        lastStreakActionDate: today.toDateString(),
      })
      .catch(console.error);
  }
  // last streak action was not 0 or 1 days ago, so streak = 1
  else if (lastStreakActionDate !== today.toDateString()) {
    await db
      .updateUserDocPublic(siteId, userId, {
        dailyStreak: 1,
        lastStreakActionDate: today.toDateString(),
      })
      .catch(console.error);
  }
};
