// Components
import { Divider, Stack, Typography, Button } from "@mui/material";
import { YoodliTabSelector } from "lib-frontend/components/YoodliComponents/YoodliTabSelector";

// Utils
import { ProgramLibraryItem } from "./ProgramLibraryItem";
import { useQueryParamState } from "hooks/useQueryParamState";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { ProgramState } from "lib-fullstack/utils/enums";

type ProgramsLibraryProps = {
  handleCreateProgram: () => void;
  orgPrograms: ProgramResponse[];
  handleSelectProgram: (programId: string) => void;
};

export const ProgramsLibrary = ({
  handleCreateProgram,
  orgPrograms,
  handleSelectProgram,
}: ProgramsLibraryProps): JSX.Element => {
  const [activeTab, setActiveTab] = useQueryParamState(
    OrgProgramsQueryParams.SUBTAB,
    ProgramState.Published,
  );
  const filteredPrograms = orgPrograms.filter((p) => p.state === activeTab);
  return (
    <Stack gap={{ xs: 4, md: 8 }} sx={{ pt: { xs: 0, md: 2 }, maxWidth: "xxl" }}>
      <Stack
        gap={1}
        direction={{ xs: "column", md: "row" }}
        sx={{
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
          px: { xs: 2, md: 7 },
          mt: 3,
        }}
      >
        <Stack gap={1}>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 700,
              color: getDynamicColor("purple3"),
              fontSize: { xs: 14, md: 16 },
            }}
          >
            Create a program
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: "12px",
              fontWeight: 600,
              fontFamily: "poppins",
            }}
          >
            Create programs tailored to your teams’ learning needs and goals.
          </Typography>
        </Stack>
        <Button
          onClick={handleCreateProgram}
          variant="contained"
          size="xlarge"
          sx={{ whiteSpace: "nowrap" }}
        >
          Create Program
        </Button>
      </Stack>
      <Divider />
      <Stack gap={{ xs: 4, md: 6 }} sx={{ pl: { xs: 2, md: 7 }, pr: { xs: 2, md: 6 } }}>
        <Stack gap={{ xs: 2, md: 3 }}>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Program library
          </Typography>
          <YoodliTabSelector
            tabs={[
              {
                label: "Published",
                value: ProgramState.Published,
                count: orgPrograms.filter((p) => p.state === ProgramState.Published).length,
              },
              {
                label: "Drafts",
                value: ProgramState.Draft,
                count: orgPrograms.filter((p) => p.state === ProgramState.Draft).length,
              },
              {
                label: "Archived",
                value: ProgramState.Archived,
                count: orgPrograms.filter((p) => p.state === ProgramState.Archived).length,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={(tab) => setActiveTab(tab as ProgramState)}
          />
        </Stack>
        {filteredPrograms.length ? (
          <Stack gap={{ xs: 4, md: 5 }}>
            {filteredPrograms.map((program) => (
              <ProgramLibraryItem
                key={program.id}
                program={program}
                handleSelectProgram={handleSelectProgram}
              />
            ))}
          </Stack>
        ) : (
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: "14px",
              fontWeight: 600,
              fontFamily: "poppins",
            }}
          >
            You have no {activeTab} programs
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
