import React from "react";

// Components
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Stack, Box, Button } from "@mui/material";
import { CSVUpload } from "components/Orgs/BulkInviteByCSVWizard/CSVUpload";
import { ImportComplete } from "components/Orgs/BulkInviteByCSVWizard/ImportComplete";
import { InvitesSent } from "components/Orgs/BulkInviteByCSVWizard/InvitesSent";
import { SendInvitations } from "components/Orgs/BulkInviteByCSVWizard/SendInvitations";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { InvitedEmailResponse } from "../InviteMembers";
import { OrgMembersQueryKeys } from "../Members/OrgMembers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useNavDrawerOffset, usePrevious } from "lib-frontend/hooks";
import { createOrgUserV2 } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgOwnerAdmin } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  AddOrgUserResult,
  AddOrgUsersResponse,
  HubV2Response,
  OrgV2Response,
} from "lib-fullstack/api/orgApiTypes";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { HubRole, OrgRole } from "lib-fullstack/utils/enums";
import { OrgInviteTypes } from "lib-fullstack/utils/productAnalyticEvents";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";

export const BULK_INVITE_ROLE_OPTIONS = [
  { value: HubRole.MEMBER, label: "Group member" },
  { value: HubRole.ADMIN, label: "Group admin" },
  { value: OrgRole.ADMIN, label: "Org admin" },
];

export enum BulkInviteRoleOptionEnum {
  HUB_MEMBER = HubRole.MEMBER,
  HUB_ADMIN = HubRole.ADMIN,
  ORG_ADMIN = OrgRole.ADMIN,
}

export type BulkInviteRoleOption = (typeof BULK_INVITE_ROLE_OPTIONS)[number];

type BulkInviteByCSVWizardProps = {
  org: OrgV2Response;
  handleBack: () => void;
  currentHub?: HubV2Response;
};

enum BulkInviteStepId {
  UPLOAD = "UPLOAD",
  IMPORT_COMPLETE = "IMPORT_COMPLETE",
  SELECT_ROLE = "SELECT_ROLE",
  SELECT_HUB = "SELECT_HUB",
  SEND_INVITES = "SEND_INVITES",
  INVITES_SENT = "INVITES_SENT",
}

export const BulkInviteByCSVWizard = ({
  org,
  handleBack,
  currentHub,
}: BulkInviteByCSVWizardProps): JSX.Element => {
  const { invalidateDefaultOrgQuery } = React.useContext(UserOrgContext);
  const { modalStyles, navDrawerOffset } = useNavDrawerOffset();
  const queryClient = useQueryClient();
  const timer = React.useRef<NodeJS.Timeout | null>(null);

  // State
  const [error, setError] = React.useState<string | null>(null);
  const [currStepIndex, setCurrStepIndex] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [emails, setEmails] = React.useState<string[]>([]);
  const [emailResponses, setEmailResponses] = React.useState<InvitedEmailResponse>({
    addedEmails: [],
    invitedEmails: [],
    failedEmails: [],
    noLicenseEmails: [],
    emailSentEmails: [],
  });

  const [selectedRole, setSelectedRole] = React.useState<BulkInviteRoleOption>(
    BULK_INVITE_ROLE_OPTIONS[0],
  );

  const [sendEmailInvite, setSendEmailInvite] = React.useState<boolean>(false);
  const [pctInvitesSent, setPctInvitesSent] = React.useState(0);

  const [selectedHubIds, setSelectedHubIds] = React.useState<string[]>(
    currentHub ? [currentHub.id] : [],
  );
  const selectedHubs = isOrgOwnerAdmin(org)
    ? org.hubs.filter((hub) => selectedHubIds.includes(hub.id))
    : [currentHub];

  const totalTime = 100 * emails.length;

  const prevEmails = usePrevious(emails);
  React.useEffect(() => {
    if (!error) {
      // if i go from having emails to not, return to first slide to upload more emails
      if (prevEmails?.length && !emails?.length) {
        setCurrStepIndex(0);
      }
    }
  }, [emails, error]);

  const prevCurrStepIndex = usePrevious(currStepIndex);
  React.useEffect(() => {
    const shouldSkip = BULK_CSV_INVITE_STEPS[currStepIndex]?.shouldSkipStep?.();
    if (shouldSkip) {
      if (prevCurrStepIndex < currStepIndex) {
        setCurrStepIndex((prev) => prev + 1);
      } else if (prevCurrStepIndex > currStepIndex) {
        setCurrStepIndex((prev) => prev - 1);
      }
    }
  }, [currStepIndex]);

  const handleRemoveEmail = (email: string) => {
    setEmails((prevEmails) => prevEmails.filter((e) => e !== email));
  };

  const inviteMembersMutation = useMutation({
    mutationFn: async () => {
      let resp = null;
      if (Object.values(HubRole).includes(selectedRole.value as HubRole)) {
        resp = await createOrgUserV2(org.id, {
          emails: emails,
          org_role: null,
          hub_role: selectedRole.value as HubRole,
          hub_ids: selectedHubs.map((hub) => hub.id),
          send_invite_email: sendEmailInvite,
        });
      } else if (Object.values(OrgRole).includes(selectedRole.value as OrgRole)) {
        resp = await createOrgUserV2(org.id, {
          emails: emails,
          org_role: OrgRole.ADMIN,
          hub_role: HubRole.ADMIN,
          hub_ids: ["default"],
          send_invite_email: sendEmailInvite,
        });
      }
      return resp;
    },
    onSuccess: async (response: AddOrgUsersResponse) => {
      void invalidateDefaultOrgQuery();
      Instrumentation.logOrgInvitationSent(org.id, OrgInviteTypes.CSV);
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgUserCounts, org.id],
      });
      clearInterval(timer.current);
      setPctInvitesSent(100);
      // making sure the 100% loader is shown for a bit
      await new Promise((resolve) => setTimeout(resolve, 500));
      setCurrStepIndex((prev) => prev + 1);
      const newEmailResponses = {
        addedEmails: [],
        invitedEmails: [],
        failedEmails: [],
        noLicenseEmails: [],
        emailSentEmails: [],
      };
      response.results.forEach((resp) => {
        switch (resp.result) {
          case AddOrgUserResult.ADDED:
            newEmailResponses.addedEmails.push(resp.email);
            break;
          case AddOrgUserResult.INVITE_WITHOUT_EMAIL:
            newEmailResponses.invitedEmails.push(resp.email);
            break;
          case AddOrgUserResult.INTERNAL_ERROR:
            newEmailResponses.failedEmails.push(resp.email);
            break;
          case AddOrgUserResult.NO_MORE_LICENSE:
            newEmailResponses.noLicenseEmails.push(resp.email);
            break;
          case AddOrgUserResult.INVITE_WITH_EMAIL:
            newEmailResponses.emailSentEmails.push(resp.email);
        }
      });
      setEmailResponses(newEmailResponses);
    },
    onError: () => {
      setError("Something went wrong. Hit ‘Send’ to retry.");
    },
  });

  React.useEffect(() => {
    if (inviteMembersMutation.isPending) {
      const startTime = Date.now();

      timer.current = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = (elapsedTime / totalTime) * 100;
        setPctInvitesSent(Math.min(95, newProgress));
      }, 500);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [inviteMembersMutation.isPending]);

  const BULK_CSV_INVITE_STEPS: Array<WizardStep> = React.useMemo(() => {
    return [
      {
        label: "Upload",
        id: BulkInviteStepId.UPLOAD,
        component: (
          <CSVUpload
            onNext={() => {
              setCurrStepIndex(1);
            }}
            setEmails={setEmails}
            error={error}
            setError={setError}
          />
        ),
        validate: () => emails?.length > 0 && !error,
        enterToNavigate: true,
      },
      {
        label: "Import Complete",
        id: BulkInviteStepId.IMPORT_COMPLETE,
        component: (
          <ImportComplete
            emails={emails}
            handleRemoveEmail={handleRemoveEmail}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            selectedHubIds={selectedHubIds}
            setSelectedHubIds={setSelectedHubIds}
          />
        ),
        validate: () => !!selectedRole && !!selectedHubIds?.length && !error,
      },

      {
        label: "Review and send invitations",
        id: BulkInviteStepId.SEND_INVITES,
        component: (
          <SendInvitations
            selectedHubs={selectedHubs}
            selectedRole={selectedRole}
            emails={emails}
            pctInvitesSent={pctInvitesSent}
            error={error}
            sendEmailInvite={sendEmailInvite}
            setSendEmailInvite={setSendEmailInvite}
          />
        ),
        next: async () => {
          setError(null);
          await inviteMembersMutation.mutateAsync();
        },
        nextCopy: "Send",
        nextButtonProps: {
          sx: { minWidth: 160 },
        },
        nextButtonVariant: "gradient",
        validate: () => {
          return (
            !!selectedRole &&
            (selectedHubIds?.length > 0 || selectedRole?.value === OrgRole.ADMIN) &&
            emails?.length > 0
          );
        },
        enterToNavigate: true,
        skipNextIndexUpdate: true,
      },
      {
        label: "Invites sent",
        id: BulkInviteStepId.INVITES_SENT,
        component: (
          <InvitesSent
            emailResponses={emailResponses}
            roleStr={selectedRole.label}
            onDone={handleBack}
            hubNames={selectedHubs.map((hub) => hub.name)}
          />
        ),
        validate: () => true,
        hideFooter: true,
      },
    ].map((x, i) => ({ ...x, stepIdx: i, hidePrev: i === 0 }));
  }, [
    currStepIndex,
    selectedRole,
    selectedHubIds,
    org,
    emails,
    error,
    pctInvitesSent,
    sendEmailInvite,
  ]);

  return (
    <Stack
      sx={{
        background: getDynamicColor("light1"),
        height: {
          xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`,
          md: `calc(100vh - ${WIZARD_FOOTER_HEIGHT})`,
        },
        position: "relative",
      }}
    >
      <Box
        sx={{
          ...defaultHeaderSx,
          display: "flex",
          alignItems: "center",
          pl: { xs: 0, md: 4 },
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <Button
          variant="text"
          sx={{
            color: getDynamicColor("light1"),
          }}
          startIcon={<ChevronLeftIcon />}
          onClick={handleBack}
        >
          Members
        </Button>
      </Box>
      <Wizard
        routeLeavingGuardUIProps={{
          title: "Exit without sending",
          body: "Are you sure you want to leave this page? Your invites have not been sent yet!",
          when: emails?.length > 0 && currStepIndex < BULK_CSV_INVITE_STEPS.length - 1,
        }}
        controlsSx={{
          left: navDrawerOffset,
          transition: modalStyles.transition,
        }}
        containerSx={{
          py: { xs: 3, md: 8 },
        }}
        steps={BULK_CSV_INVITE_STEPS}
        currStepIndex={currStepIndex}
        setCurrStepIndex={setCurrStepIndex}
        loading={loading}
        setLoading={setLoading}
      />
    </Stack>
  );
};
