// Utils
import { PracticeTypeId } from "lib-frontend/utils/practiceRecorderUtils";
import { OnboardingChecklistTask } from "lib-fullstack/db";

export type ChecklistDataElement = {
  taskId: OnboardingChecklistTask;
  name: string;
  description: string | JSX.Element;
  isDisabled: boolean;
  buttonText: string;
  disabledTipText?: string;
  onClick: () => void;
  onDismiss?: () => void;
};

export const demoVideoTask = (onClick: () => void): ChecklistDataElement => ({
  taskId: OnboardingChecklistTask.DEMO_VIDEO,
  name: "Check out a demo video",
  description: "Learn more about Yoodli's coaching capabilities",
  isDisabled: false,
  buttonText: "Play video",
  onClick,
});

export const sampleSpeechTask = (
  onClick: (name: string, buttonText: string) => void,
): ChecklistDataElement => {
  const name = "See a sample analytics report";
  const buttonText = "See sample reports";
  const description =
    "See Yoodli at work! Analyze what some of the most seasoned speakers get right and wrong";
  return {
    taskId: OnboardingChecklistTask.VIEW_SAMPLE_SPEECH,
    name,
    description,
    isDisabled: false,
    buttonText,
    onClick: () => onClick(name, buttonText),
  };
};

export const customizeInterviewQuestionsTask = (
  onClick: (name: string, buttonText: string) => void,
  isCompleted: boolean,
): ChecklistDataElement => {
  const name = "Input custom questions";
  const buttonText = isCompleted
    ? "Practice with custom questions again"
    : "Practice with custom questions";
  const description = "Customize your practice by inputting questions you care about";
  return {
    taskId: OnboardingChecklistTask.CUSTOMIZE_QUESTIONS_AND_PRACTICE,
    name,
    description,
    isDisabled: false,
    buttonText,
    onClick: () => onClick(name, buttonText),
  };
};

export const practiceTask = (
  practiceType: PracticeTypeId,
  onClick: (name: string, buttonText: string) => void,
  isCompleted: boolean,
): ChecklistDataElement => {
  const copyData = {
    [PracticeTypeId.SPEECH]: {
      taskId: OnboardingChecklistTask.FPR_SPEECH,
      name: "Practice a presentation",
      description: "Give your presentation and receive judgment-free feedback",
    },
    [PracticeTypeId.CONVERSATION]: {
      taskId: OnboardingChecklistTask.FPR_CONVERSATION,
      name: "Practice a roleplay",
      description: "Set some context and customize an AI conversation partner ",
    },
    [PracticeTypeId.INTERVIEW]: {
      taskId: OnboardingChecklistTask.FPR_INTERVIEW,
      name: "Practice a mock interview",
      description: "Add custom questions, a company, and customize your AI interviewer",
    },
  };
  const buttonText = isCompleted ? "Practice again" : "Practice";
  const { taskId, name, description } = copyData[practiceType];

  return {
    taskId,
    name,
    description,
    buttonText,
    onClick: () => onClick(name, buttonText),
    isDisabled: false,
  };
};

export const viewSpeechSummaryTask = (
  onClick: (name: string, buttonText: string) => void,
  isDisabled: boolean,
): ChecklistDataElement => {
  const taskId = OnboardingChecklistTask.VIEW_SPEECH_SUMMARY;
  const name = "Check out your speech summary";
  const description = "See the magic of Yoodli's AI coaching feedback";
  return {
    taskId,
    name,
    description,
    isDisabled,
    disabledTipText: isDisabled && "Please practice first",
    buttonText: "View Analytics",
    onClick: () => onClick(name, "View Analytics"),
  };
};

export const uploadTask = (
  onClick: (name: string, buttonText: string) => void,
  isCompleted: boolean,
): ChecklistDataElement => {
  const taskId = OnboardingChecklistTask.UPLOAD_SPEECH;
  const name = "Upload a speech";
  const buttonText = isCompleted ? "Upload again" : "Upload";
  const description = "See the magic of Yoodli's AI coaching feedback";
  return {
    taskId,
    name,
    description,
    isDisabled: false,
    buttonText,
    onClick: () => onClick(name, buttonText),
  };
};

export const createOrgTask = (
  onClick: (name: string, buttonText: string) => void,
  isCompleted: boolean,
): ChecklistDataElement => {
  const taskId = OnboardingChecklistTask.CREATE_ORG;
  const name = "Coach with a branded version of Yoodli";
  const buttonText = isCompleted ? "View org" : "Create";
  const description = (
    <span>
      <p>Create a Yoodli organization with your branding (7 day free trial)</p>
      <ul style={{ marginBottom: 0 }}>
        <li>Add your logo and company name</li>
        <li>Invite your clients to manage their progress</li>
        <li>Upload custom course content</li>
      </ul>
    </span>
  );
  return {
    taskId,
    name,
    description,
    isDisabled: false,
    buttonText,
    onClick: () => onClick(name, buttonText),
  };
};

export const salesNudgeTask = (
  onClick: (name: string, buttonText: string) => void,
  isCompleted: boolean,
  onDismiss: (name) => void,
): ChecklistDataElement => {
  const taskId = OnboardingChecklistTask.SALES_NUDGE;
  const name = "Learn more about Yoodli organizations";
  let buttonText = "Talk to sales";
  let description =
    "Talk to our sales team to find out how you can coach with a branded version of Yoodli";
  if (isCompleted) {
    buttonText = "";
    description = "We'll reach out to you shortly to answer any questions you have!";
  }
  return {
    taskId,
    name,
    description,
    isDisabled: false,
    buttonText,
    onClick: () => onClick(name, buttonText),
    onDismiss: () => onDismiss(name),
  };
};

export const inviteToHubTask = (
  onClick: (name: string, buttonText: string) => void,
  isCompleted: boolean,
  inviteUrl: string,
): ChecklistDataElement => {
  const taskId = OnboardingChecklistTask.INVITE_CLIENT_TO_HUB;
  const name = "Invite a client";
  const buttonText = isCompleted ? "Invite more clients" : "Copy group invite link";
  const description = "Manage your clients and track their progress in a single place";
  return {
    taskId,
    name,
    description,
    isDisabled: !inviteUrl,
    disabledTipText: !inviteUrl && "Please create an organization first",
    buttonText,
    onClick: () => onClick(name, buttonText),
  };
};

export const shareSpeechTask = (
  onClick: (name: string, buttonText: string) => void,
  isDisabled: boolean,
): ChecklistDataElement => {
  const taskId = OnboardingChecklistTask.SHARE_SPEECH;
  const name = "Share your latest practice with a coach";
  const buttonText = "Copy last practice link";
  const description =
    "Get timestamped feedback from coaches and peers you trust. Try sharing your last practice.";

  return {
    taskId,
    name,
    description,
    isDisabled,
    disabledTipText: isDisabled && "Please practice first",
    buttonText,
    onClick: () => onClick(name, buttonText),
  };
};
