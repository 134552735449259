import { db } from "lib-fullstack";

// Utils
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { PoodliQueryParams, PoodliToggleViews } from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "webclient/src/utils/paths";

// webclient nav
export const NAV_DRAWER_OPEN_WIDTH = 214;
export const NAV_DRAWER_CLOSED_WIDTH = 74;

export const lineClampStyles = (
  lineCount: number,
): {
  display: string;
  WebkitLineClamp: string;
  WebkitBoxOrient: string;
  overflow: string;
  textOverflow: string;
} => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lineCount.toString(),
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
};

export const areRealTimeAlertsEnabled = (userDocMain: db.UserDocMain): boolean => {
  return userDocMain?.poodliStatuses?.realTimeAlertsEnabled === undefined
    ? true
    : userDocMain.poodliStatuses.realTimeAlertsEnabled;
};

export function isWhiteLabel(): boolean {
  return getSiteId() !== "yoodli";
}

/**
 * Returns only the unique instances of an array of objects based on a predicate
 * @param {*} arr
 * @param {*} predicate
 * @returns array of unique objects
 * @example uniqBy([{id: 1, name: 'a'}, {id: 2, name: 'b'}, {id: 1, name: 'c'}], 'id')
 *   return [{id: 1, name: 'a'}, {id: 2, name: 'b'}]
 */
// Fixme: add return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === "function" ? predicate : (o) => o[predicate];
  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

/**
 * This function removes all non-digit characters and then parses the remaining string
 *
 * This exists because we sometimes can't use parseInt directly because the string begins with letters, e.g. parseInt("Start: 100") === NaN
 * @param str - The string to convert to an integer
 * @returns The integer value of the string
 */
export const getIntFromString = (str: string): number => {
  return parseInt(str.replace(/\D/g, ""), 10);
};

/**
 * Returns sorted array of objects by key
 * @param {*} arr
 * @param {*} key
 * @returns sorted array of objects
 * @example
 * const arr = [{a: 1}, {a: 2}, {a: 0}]
 * sortByKey(arr, 'a') // [{a: 0}, {a: 1}, {a: 2}]
 */
export const sortByKey = (arr: object[], key: string): object[] => {
  return arr.sort((a, b) => {
    if (!a[key] && b[key]) return -1;
    if (!b[key] && a[key]) return 1;
    if (a[key] === b[key]) return 0;
    return a[key] > b[key] ? 1 : -1;
  });
};

export const convertHexToRGBA = (hexCode: string, opacity = 1): string => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const toTitleCase = (str: string): string => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return str
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ":" &&
        array[index + 1] !== ":" &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== "-" || (array[index - 1] === "-" && array[index + 1] === "-"))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substring(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ":" && array[index + 2] !== "") {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join("");
};

// the following isEqual is a replacement for lodash's isEqual function. This doesnt have the ability to compare things
// like Buffers etc. while lodash does, but we dont need that functionality
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isEqual = (first: any, second: any): boolean => {
  if (first === second) {
    return true;
  }
  if (
    (first === undefined || second === undefined || first === null || second === null) &&
    (first || second)
  ) {
    return false;
  }
  const firstType = first?.constructor.name;
  const secondType = second?.constructor.name;
  if (firstType !== secondType) {
    return false;
  }
  if (firstType === "Array") {
    if (first.length !== second.length) {
      return false;
    }
    let equal = true;
    for (let i = 0; i < first.length; i++) {
      if (!isEqual(first[i], second[i])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  if (firstType === "Object") {
    let equal = true;
    const fKeys = Object.keys(first);
    const sKeys = Object.keys(second);
    if (fKeys.length !== sKeys.length) {
      return false;
    }
    for (let i = 0; i < fKeys.length; i++) {
      if (first[fKeys[i]] && second[fKeys[i]]) {
        if (first[fKeys[i]] === second[fKeys[i]]) {
          continue;
        }
        if (
          first[fKeys[i]] &&
          (first[fKeys[i]].constructor.name === "Array" ||
            first[fKeys[i]].constructor.name === "Object")
        ) {
          equal = isEqual(first[fKeys[i]], second[fKeys[i]]);
          if (!equal) {
            break;
          }
        } else if (first[fKeys[i]] !== second[fKeys[i]]) {
          equal = false;
          break;
        }
      } else if ((first[fKeys[i]] && !second[fKeys[i]]) || (!first[fKeys[i]] && second[fKeys[i]])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  return first === second;
};

/**
 * Copies text to clipboard
 */
export const copyToMyClipboard = (text: string, windowAlert = undefined): void => {
  setTimeout(() => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log(`${text} Text copied!`);

        if (windowAlert !== undefined) {
          window.alert(windowAlert);
        }
      },
      function (err) {
        console.log(`Async: Could not copy text: ${err}`);
      },
    );
  }, 0);
};

export const generateSpeechSharePath = (speech: db.Speech & { documentId?: string }): string => {
  if (speech.slug) {
    return WebServerInternalPath.SHARE_SLUG.replace(":slug", speech.slug || "");
  }

  throw Error("Could not generate share link for speech (no slug/user/doc IDs)");
};

export const POODLI_CAL_CONNECT_QUERY_PARAM = "privateYoodliCalendar";

export function getRandomListItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

export function precedingAOrAn(word: string): string {
  return word.match("^[aieouAIEOU].*") ? "an" : "a";
}

// get cute speech name, based on time of day
export function getCuteNameForSpeech(): string {
  const time = new Date().getHours();
  if (time < 4 || time > 21) {
    return "🌙 Nighttime Yoodli";
  } else if (time < 11) {
    return "☕ Breakfast Yoodli";
  } else if (time < 14) {
    return "☀️  Lunchtime Yoodli";
  } else if (time < 18) {
    return "🍵 Afternoon Yoodli";
  } else {
    return "🍷 Evening Yoodli";
  }
}

/**
 * launch poodli from webclient
 */
export const POODLI_LAUNCH_URL = "yoodli://";
export const launchPoodli = (): void => {
  window.location.href = POODLI_LAUNCH_URL;
};

export const launchPoodliToSpecificTab = (tab: PoodliToggleViews): void => {
  window.location.href = `${POODLI_LAUNCH_URL}?${PoodliQueryParams.TAB}=${tab}`;
};

export const launchPoodliToSettingsOpen = (): void => {
  window.location.href = `${POODLI_LAUNCH_URL}?${PoodliQueryParams.SETTINGS}=true`;
};

export const launchPoodliWithCalConnectError = (error: string): void => {
  window.location.href = `${POODLI_LAUNCH_URL}?skipCalConnectOnboarding=true&error=${encodeURIComponent(
    error,
  )}`;
};

// Generates a color code based on a string
// Generates a vibrant color code based on a string
// Ensures the color is not too light or dark
export function stringToColor(string: string): string {
  let hash = 0;
  let i;

  if (!string || string.length === 0) return "#000000";

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use hash to generate HSL values
  const h = hash % 360; // Hue between 0 and 360
  const s = 85 + (hash % 15); // Saturation between 85% and 100%
  const l = 30 + (hash % 25); // Lightness between 45% and 60%

  // Convert HSL to RGB
  const hslToRgb = (h: number, s: number, l: number) => {
    s /= 100;
    l /= 100;
    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [f(0), f(8), f(4)].map((v) => Math.round(v * 255));
  };

  const [r, g, b] = hslToRgb(h, s, l);

  // Ensure each RGB value is a two-digit hex
  const toHex = (value: number) => `0${value.toString(16)}`.slice(-2);

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

/**
 * @param {*} a
 * @param {*} b
 * @returns string of the difference between a and b
 *
 */
export const getStringDiff = (a: string, b: string): string => {
  let i = 0;
  let j = 0;
  let result = "";
  if (a === b) return result;
  if (a && !b) return a;
  if (b && !a) return b;
  while (j < b.length) {
    if (a[i] != b[j] || i == a.length) result += b[j];
    else i++;
    j++;
  }
  return result;
};
// Returns the suffix for a number (e.g. 1st, 2nd, 3rd, 4th, etc.)
export function getNumberSuffix(num: number): string {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return "th";
  }

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
