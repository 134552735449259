import React from "react";

// Components
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { CoachBotDone } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotDone/CoachBotDone";
import { CoachBotGetStarted } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotGetStarted/CoachBotGetStarted";
import { CoachBotNaming } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotNaming/CoachBotNaming";
import { CoachBotUploadContent } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotUploadContent/CoachBotUploadContent";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { UploadingFile } from "../../OrgFileLibrary/OrgFileLibrary";
import { CoachBotSectionStatus } from "../CoachBotLibrary";
import { BotProcessingState, CoachBotStepId } from "../CoachBotTypes";
import { useUpdateCoachBot } from "../useUpdateCoachBot";
import { CoachBotScenarios } from "./CoachBotScenarios/CoachBotScenarios";
import { CoachBotSetDefault } from "./CoachBotSetDefault/CoachBotSetDefault";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCoachBotContentQuery } from "hooks/useCoachBotContentQuery";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { createCoachBot, updateCoachBot } from "lib-frontend/modules/axiosOrgCoachBot";
import { currentUserEmail as getCurrentUserEmail } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { CoachBotWithScenarioInfo, GetCoachBotResponse } from "lib-fullstack/api/coachBotApiTypes";
import { OrgFileState } from "lib-fullstack/utils/enums";
import { OrgCoachbotType } from "lib-fullstack/utils/productAnalyticEvents";

export const FOOTER_HEIGHT = 86;

type SelfServeCoachBotWizardProps = {
  coachbotsList: CoachBotWithScenarioInfo[];
  setSectionStatus: (status: CoachBotSectionStatus) => void;
  setSelectedBot: (bot: CoachBotWithScenarioInfo) => void;
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  coachBotId: string;
  setCoachBotId: (botId: string) => void;
  handleCancelFileUpload: (fileId: string) => void;
  handleClearUploadedFiles: () => void;
};

export const HEADER_HEIGHT_SX = { xs: 116, md: 132 };

export const SelfServeCoachBotWizard = ({
  coachbotsList,
  setSectionStatus,
  setSelectedBot,
  uploadingFiles,
  handleUploadContent,
  coachBotId,
  setCoachBotId,
  handleCancelFileUpload,
  handleClearUploadedFiles,
}: SelfServeCoachBotWizardProps): JSX.Element => {
  const {
    defaultOrgId,
    defaultOrgName,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const { coachBotContentQuery } = useCoachBotContentQuery(coachBotId);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();
  const { handleUpdateCoachBot } = useUpdateCoachBot();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [coachBotName, setCoachBotName] = React.useState<string>("");
  const [coachBotNameCollision, setCoachBotNameCollision] = React.useState<boolean>(false);
  const [currStepIndex, setCurrStepIndex] = React.useState<number>(0);
  const [selectedScenarioIds, setSelectedScenarioIds] = React.useState<string[]>([]);
  const [makeDefault, setMakeDefault] = React.useState<boolean>(true);

  const showIntroStep = React.useMemo(() => coachbotsList.length === 0, []);

  const { navDrawerOffset, modalStyles } = useNavDrawerOffset();

  const filesUploading = React.useMemo(() => {
    return (
      uploadingFiles?.length &&
      uploadingFiles?.filter((file) => file.state === OrgFileState.Uploading)
    );
  }, [uploadingFiles]);

  const [botProcessingState, setBotProcessingState] =
    React.useState<BotProcessingState>("readyToProcess");

  const hasDefault = coachbotsList.some((bot) => bot.isDefault);

  const createCoachBotMutation = useMutation({
    mutationFn: () =>
      createCoachBot(defaultOrgId, curSpaceId, coachBotName, getCurrentUserEmail(), true),
    onMutate: () => setLoading(true),
    onSuccess: (res) => {
      if (!res?.botId) {
        throw new Error(
          `Error creating coachbot for org: ${defaultOrgId}. No botId returned from API.`,
        );
      }
      setCoachBotId(res.botId);
      setLoading(false);
      handleClearUploadedFiles();
      return queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrgId, curSpaceId],
      });
    },
  });

  const introStep = React.useMemo(
    () => ({
      label: "Get Started",
      id: CoachBotStepId.GET_STARTED,
      component: (
        <CoachBotGetStarted
          entityName={defaultOrgName}
          isDefaultSpace={curSpaceId === defaultOrg.default_space_id}
        />
      ),
      validate: () => true,
      enterToNavigate: true,
      hideProgressMarker: true,
      nextCopy: "Get started",
    }),
    [defaultOrgName],
  );

  const nameStep = React.useMemo(
    () => ({
      label: "Name",
      id: CoachBotStepId.NAMING,
      component: (
        <CoachBotNaming
          coachBotNameCollision={coachBotNameCollision}
          coachBotName={coachBotName}
          setCoachBotName={setCoachBotName}
          setCoachBotNameCollision={setCoachBotNameCollision}
          coachbotsList={coachbotsList}
        />
      ),
      validate: () => {
        return coachBotName?.length > 0 && !coachBotNameCollision;
      },
      next: async () => {
        try {
          createCoachBotMutation.mutate();
        } catch (er) {
          console.error(
            `Error creating coachbot ${coachBotName} for org ${defaultOrgId} by user email ${getCurrentUserEmail()}: ${er}`,
          );
        }
      },
      prev: () => {
        setSectionStatus(CoachBotSectionStatus.Library);
      },
      enterToNavigate: true,
      showProgressTracker: true,
    }),
    [defaultOrgId, coachBotName, coachBotNameCollision, coachbotsList],
  );

  const uploadStep = React.useMemo(
    () => ({
      label: "Add Content",
      id: CoachBotStepId.UPLOAD_CONTENT,
      component: (
        <CoachBotUploadContent
          coachBotName={coachBotName}
          setLoading={setLoading}
          uploadingFiles={uploadingFiles}
          handleUploadContent={handleUploadContent}
          botId={coachBotId}
          handleCancelFileUpload={handleCancelFileUpload}
        />
      ),
      // TODO: potentially check file sizes/types/other validation steps on files here
      validate: () => {
        return (
          coachBotContentQuery?.data?.files.length > 0 &&
          uploadingFiles.every((file) => file.state !== OrgFileState.Uploading)
        );
      },
      next: () => {
        if (botProcessingState === "readyToProcess") {
          setBotProcessingState("processing");
        }
      },
      showProgressTracker: true,
    }),
    [
      uploadingFiles,
      botProcessingState,
      coachBotId,
      coachBotName,
      defaultOrgId,
      coachBotContentQuery.data?.files,
    ],
  );

  const setAsDefaultStep = React.useMemo(
    () => ({
      label: "Review",
      id: CoachBotStepId.SET_AS_DEFAULT,
      component: <CoachBotSetDefault makeDefault={makeDefault} setMakeDefault={setMakeDefault} />,
      initialStep: true,
      validate: () => true,
      next: async () => {
        try {
          setLoading(true);
          const updates: Partial<GetCoachBotResponse> = {};
          if (makeDefault) {
            updates.isDefault = true;
          }
          if (updates.isDefault) {
            await updateCoachBot(defaultOrgId, coachBotId, updates);
          }
        } catch (er) {
          console.error(`Error updating coachbot ${coachBotId} for org ${defaultOrgId}: ${er}`);
        }
        setLoading(false);
      },
      showProgressTracker: true,
    }),
    [makeDefault, coachBotId, defaultOrgId],
  );

  const assignScenariosStep = React.useMemo(
    () => ({
      label: "Review",
      id: CoachBotStepId.ASSIGN_SCENARIOS,
      component: (
        <CoachBotScenarios
          coachBotList={coachbotsList}
          selectedScenarioIds={selectedScenarioIds}
          setSelectedScenarioIds={setSelectedScenarioIds}
          orgId={defaultOrgId}
        />
      ),
      initialStep: true,
      validate: () => true,
      next: async () => {
        try {
          setLoading(true);
          const updates: Partial<GetCoachBotResponse> = {};
          if (selectedScenarioIds?.length) {
            updates.scenarioIds = selectedScenarioIds;
          }
          if (updates.scenarioIds) {
            await handleUpdateCoachBot({
              orgId: defaultOrgId,
              botId: coachBotId,
              updateOptions: { scenarioIds: updates.scenarioIds },
              isDefault: false,
            });
          }
        } catch (er) {
          console.error(`Error updating coachbot ${coachBotId} for org ${defaultOrgId}: ${er}`);
        }
        setLoading(false);
      },
      showProgressTracker: true,
    }),
    [defaultOrgId, coachBotId, selectedScenarioIds, coachbotsList],
  );

  const reviewStep = React.useMemo(
    () => ({
      label: "Review",
      id: CoachBotStepId.DONE,
      component: (
        <CoachBotDone coachBotName={coachBotName} botProcessingState={botProcessingState} />
      ),
      validate: () => {
        return true;
      },
      next: async () => {
        try {
          setLoading(true);
          await updateCoachBot(defaultOrgId, coachBotId, { isActive: true });
          await queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrgId, curSpaceId],
          });
          const createdBot = coachbotsList.find((bot) => bot.botId === coachBotId);
          Instrumentation.logOrgCoachbotCreated(
            defaultOrgId,
            createdBot.isDefault ? OrgCoachbotType.DEFAULT : OrgCoachbotType.SCENARIO,
          );
          setSelectedBot(createdBot);
          setSectionStatus(CoachBotSectionStatus.Details);
        } catch (er) {
          console.error(`Error updating coachbot ${coachBotId} for org ${defaultOrgId}: ${er}`);
          setBotProcessingState("error");
        } finally {
          setLoading(false);
        }
      },
      prev: () => null,
      showProgressTracker: true,
    }),
    [coachBotName, botProcessingState, defaultOrgId, coachBotId, coachbotsList],
  );

  const COACHBOT_STEPS: Array<WizardStep> = [
    showIntroStep ? introStep : null,
    nameStep,
    uploadStep,
    !hasDefault && curSpaceId === defaultOrg.default_space_id ? setAsDefaultStep : null,
    hasDefault || !makeDefault ? assignScenariosStep : null,
    reviewStep,
  ]
    .filter(Boolean)
    .map((x, i) => ({ ...x, stepIdx: i, hidePrev: showIntroStep && i === 0 }));

  let routeLeavingGuardBody = `${
    uploadingFiles?.length
      ? "You have uploaded content that we are still processing!"
      : "Are you sure you want to close this window?"
  } You can pick up where you left off later, or choose cancel if you want to continue building your bot now`;
  if (filesUploading) {
    routeLeavingGuardBody =
      "Are you sure you want to close this window? Uploads in progress will not be saved!";
  }

  const renderHeaderComponent = () => (
    <Stack
      direction="row"
      sx={{
        ...defaultHeaderSx,
        alignItems: "center",
        px: { xs: 2, md: 3, lg: 4, xl: 5 },
        position: "fixed",
        top: 0,
        left: navDrawerOffset,
        right: 0,
        transition: modalStyles.transition,
      }}
    >
      <Button
        variant="text"
        sx={{
          color: getDynamicColor("light1"),
          fontSize: 14,
        }}
        startIcon={<ChevronLeftIcon />}
        onClick={() => {
          setSectionStatus(CoachBotSectionStatus.Library);
        }}
      >
        Back to Coach Bot Library
      </Button>
    </Stack>
  );

  return (
    <Wizard
      containerSx={{
        position: "absolute",
        zIndex: 50,
        backgroundColor: getDynamicColor("light1"),
        top: 80,
        left: 0,
        width: "100%",
      }}
      controlsSx={{
        left: navDrawerOffset,
        transition: modalStyles.transition,
      }}
      steps={COACHBOT_STEPS}
      routeLeavingGuardUIProps={{
        title: filesUploading ? "Stop uploads" : "Exit bot creation",
        body: routeLeavingGuardBody,
        // When should shouldBlockNavigation be invoked, simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        when: !!coachBotId && currStepIndex !== COACHBOT_STEPS.length - 1,
      }}
      loading={loading}
      setLoading={setLoading}
      currStepIndex={currStepIndex}
      setCurrStepIndex={setCurrStepIndex}
      headerComponent={renderHeaderComponent()}
    />
  );
};
