import firebase from "firebase/app";
import { initializeFirebase } from "lib-frontend/modules/FirebaseModule";
import { db } from "lib-fullstack";
import _ from "lodash";
/**
 * React-friendly interface to User Docs.
 * IMPORTANT: This is a bit complicated but has very important efficiency gains.
 * Please consider using it if you need to read or write user docs.
 *
 * tldr: This subscribes to a copy of the user's docs (main, readonly, public,
 *  etc.) so that we always have one up-to-date copy of them in memory. It also
 *  lets you subscribe to those docs with `useState` so that you can update the
 *  UI (if you want to).
 *
 * These docs are guaranteed to be resolved once the app *renders*... however,
 *   they likely are not resolved during module import. You will want to
 *   use `pLiveUserDocsReady.then(...)` or `await pLiveUserDocsReady` if
 *   you need them to be resolved.
 *
 * So, this lets you read the docs without a DB read, and it lets you
 * subscribe to them without adding new Firebase listeners.
 *
 * This means you can, e.g., pull up user preferences 100's of times per page
 * (e.g., inside every comment in a huge list of comments), and the perf cost
 * will be negligible.
 *
 * Usage for reading:
 * ```
 * const userDoc = getLiveUserDocMain();
 * ```
 * Usage for writing:
 * ```
 * updateUserDocMain({key: value})
 * ```
 * Usage for subscribing:
 * ```
 * function myReactComponent() {
 *   const userDoc = useUserDocsState()[db.UserDocTypes.MAIN];
 *
 *   if( userDoc.someKey1 !== 'watermelon') return null;
 *
 *   return <SomeTag value={userDoc.someKey2}/>
 * }
 * ```
 * This "subscribing" example will re-render whn the user doc changes.
 *
 */
import React from "react";

// Utils
import { getSiteId, pLiveSiteDocsReady } from "lib-frontend/utils/LiveSiteDocs";
import { UserDocTypes } from "lib-fullstack/db";
import { updateUserDocMain, updateUserDocPublic, mergeDefault } from "lib-fullstack/db/user_docs";
import { UpdateModel } from "typesaurus/update";

interface IUserDocs {
  [db.UserDocTypes.PUBLIC]: db.UserDocPublic;
  [db.UserDocTypes.MAIN]: db.UserDocMain;
  [db.UserDocTypes.READONLY]: db.UserDocReadonly;
  [db.UserDocTypes.PLAN]: db.UserDocPlan;
}
type UserDocsChangeListener = (userDocs: IUserDocs) => void;
type RemoveUserDocsChangeListener = () => void;

class LiveUserDocsService {
  private userDocs: IUserDocs | null = null;
  private userDocUserId: string | null;

  private liveUserDocsReadyPromise: Promise<void>;
  private liveUserDocsReadyResolver: () => void = null;
  private userDocChangeListeners: UserDocsChangeListener[] = [];
  private userDocDbListenerUnsub: (() => void) | null = null;

  constructor() {
    this.liveUserDocsReadyPromise = new Promise((resolve) => {
      this.liveUserDocsReadyResolver = () => {
        resolve();
      };
    });

    // ideally we should always proactively use resetLiveUserDocsOnAuthChange and awaitUserDocsResolved
    // on user auth changes, but use this as a backup to avoid potential for data leaks
    // setupUserDocListeners must handle re-entrancy with the same user id and ignore it
    firebase.auth().onAuthStateChanged(() => {
      this.resetLiveUserDocsOnAuthChange();
    });
  }

  public getUserDocs(): IUserDocs {
    return this.userDocs;
  }

  public liveUserDocsAreResolved(): boolean {
    return !!this.userDocs;
  }

  public awaitUserDocsResolved(): Promise<void> {
    return this.liveUserDocsReadyPromise;
  }

  public registerUserDocsChangeListener(
    callback: UserDocsChangeListener,
  ): RemoveUserDocsChangeListener {
    this.userDocChangeListeners.push(callback);
    return () => {
      _.remove(this.userDocChangeListeners, (e) => e === callback);
    };
  }

  public assertResolved(): void {
    // If you see this error, you either need to `await awaitUserDocsResolved`
    //  or move your logic so it is not called until <App> renders.
    // Note that on auth changes, awaitUserDocsResolved will reset and need to be reawaited
    if (!this.liveUserDocsAreResolved()) {
      throw new Error("User docs are not yet resolved.");
    }
  }

  public resetLiveUserDocsOnAuthChange() {
    // detect no-ops where we have the same user id, in case a race condition comes up during sign-in
    if (this.userDocUserId && firebase.auth().currentUser?.uid === this.userDocUserId) {
      return;
    }
    console.log(
      "Resetting live user docs on auth change. Old user id:",
      this.userDocUserId,
      "New user id:",
      firebase.auth().currentUser?.uid,
    );

    this.liveUserDocsReadyPromise = new Promise((resolve) => {
      const lastResolver = this.liveUserDocsReadyResolver;
      this.liveUserDocsReadyResolver = () => {
        if (lastResolver) {
          lastResolver();
        }
        resolve();
      };
    });

    void this.setupUserDocListeners(firebase.auth().currentUser);
  }

  //
  // private helpers
  //

  private callUserDocsChangeListeners() {
    this.userDocChangeListeners.forEach((callback) => callback(this.userDocs));
  }

  private setUserDocsWithDefaults(
    userDocPublic?: Partial<db.UserDocPublic>,
    userDocMain?: Partial<db.UserDocMain>,
    userDocReadonly?: Partial<db.UserDocReadonly>,
    userDocPlan?: Partial<db.UserDocPlan>,
  ) {
    this.userDocs = {
      [db.UserDocTypes.PUBLIC]: mergeDefault(userDocPublic, db.UserDocTypes.PUBLIC),
      [db.UserDocTypes.MAIN]: mergeDefault(userDocMain, db.UserDocTypes.MAIN),
      [db.UserDocTypes.READONLY]: mergeDefault(userDocReadonly, db.UserDocTypes.READONLY),
      [db.UserDocTypes.PLAN]: mergeDefault(userDocPlan, db.UserDocTypes.PLAN),
    };
  }

  private async setupUserDocListeners(user: firebase.User | undefined) {
    await pLiveSiteDocsReady;

    // if we haven't yet, let the world know we're ready
    // note this has to come before setUserDocsWithDefaults as it tests if that is undefined or not
    if (this.liveUserDocsReadyResolver) {
      let unregisterCallback: RemoveUserDocsChangeListener = null;
      const firstTimeReadyCallback = () => {
        this.liveUserDocsReadyResolver?.();
        this.liveUserDocsReadyResolver = null;
        unregisterCallback();
      };

      unregisterCallback = this.registerUserDocsChangeListener(firstTimeReadyCallback);
    }

    try {
      if (user && user.uid !== this.userDocUserId) {
        // if we have a user and the user id has changed since last time, rewire our listeners
        if (this.userDocDbListenerUnsub) {
          this.userDocDbListenerUnsub();
          this.userDocDbListenerUnsub = null;
        }

        const docNames = [
          db.UserDocTypes.PUBLIC,
          db.UserDocTypes.MAIN,
          db.UserDocTypes.READONLY,
          db.UserDocTypes.PLAN,
        ];

        this.userDocDbListenerUnsub = db.onGetMany(
          db.userDocs([getSiteId(), user.uid]),
          docNames,
          (userDocPayload) => {
            const [userDocPublic, userDocMain, userDocReadonly, userDocPlan] = userDocPayload;

            this.setUserDocsWithDefaults(
              userDocPublic?.data as db.UserDocPublic | undefined,
              userDocMain?.data as db.UserDocMain | undefined,
              userDocReadonly?.data as db.UserDocReadonly | undefined,
              userDocPlan?.data as db.UserDocPlan | undefined,
            );

            this.callUserDocsChangeListeners();
          },
          console.error,
        );
      } else {
        // User signed out or anon, and our last update was from a signed-in user
        if (this.userDocDbListenerUnsub) {
          this.userDocDbListenerUnsub();
          this.userDocDbListenerUnsub = null;
        }

        this.setUserDocsWithDefaults();
        this.callUserDocsChangeListeners();
      }
    } catch (err) {
      console.error("Error setting up user doc listeners", err);
    }

    this.userDocUserId = user?.uid;
  }
}

initializeFirebase();
export const userDocsService = new LiveUserDocsService();

/**
 * Get current user docs. This is fast and cheap and does not
 * cause a new Firestore DB read.
 */
// export function getLiveUserDocs(): IUserDocs {
//   return userDocs;
// }

/**
 * Get specified user doc (fast/cheap).
 */
export function getLiveUserDocPublic(): db.UserDocPublic {
  userDocsService.assertResolved();
  return userDocsService.getUserDocs()[UserDocTypes.PUBLIC];
}

/**
 * Get specified user doc (fast/cheap).
 */
export function getLiveUserDocPlan(): db.UserDocPlan {
  userDocsService.assertResolved();
  return userDocsService.getUserDocs()[UserDocTypes.PLAN];
}

/**
 * Update specified user doc
 */
export async function updateThisUserDocPublic(
  updates: UpdateModel<db.UserDocPublic>,
): Promise<void> {
  return updateUserDocPublic(getSiteId(), firebase.auth().currentUser?.uid, updates);
}

/**
 * Get specified user doc (fast/cheap).
 */
export function getLiveUserDocMain(): db.UserDocMain {
  userDocsService.assertResolved();
  return userDocsService.getUserDocs()[UserDocTypes.MAIN];
}

/**
 * Update specified user doc
 */
export async function updateThisUserDocMain(updates: UpdateModel<db.UserDocMain>): Promise<void> {
  return updateUserDocMain(getSiteId(), firebase.auth().currentUser?.uid, updates);
}

/**
 * Get specified user doc (fast/cheap).
 */
export function getLiveUserDocReadonly(): db.UserDocReadonly {
  userDocsService.assertResolved();
  return userDocsService.getUserDocs()[UserDocTypes.READONLY];
}

/**
 * Set up React hooks so that an update will trigger whenever the user
 * docs change. This is fast and cheap and does not create a new
 * Firestore subscription or cause a new DB read.
 *
 * Usage (in a React component function):
 * ```
 * userDocs = useUserDocsState();
 * ```
 */
export function useUserDocsState(): IUserDocs {
  userDocsService.assertResolved();
  const [userDocsState, setUserDocsState] = React.useState(userDocsService.getUserDocs());
  React.useEffect(() => {
    return userDocsService.registerUserDocsChangeListener(setUserDocsState);
  }, []);
  return userDocsState;
}

export const updateUserDocsForPoodli = (): void => {
  updateUserDocMain(getSiteId(), firebase.auth().currentUser?.uid, {
    poodliStatuses: {
      poodliDownloadDate: new Date().toISOString(),
    },
  }).catch(console.error);
};
