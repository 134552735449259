import React from "react";

// Components
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { UploadingFile } from "components/Orgs/ManageContent/OrgFileLibrary/OrgFileLibrary";

// Utils
import { CoachBotUploadDropzone } from "./CoachBotUploadDropzone";
import { FileUploadProgress } from "./FileUploadProgress";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgFileState } from "lib-fullstack/utils/enums";
import { truncateTextMiddle } from "utils/Utilities";

type AddContentModalUploadTabProps = {
  content: {
    id: string;
    name: string;
  }[];
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  handleCancelFileUpload: (contentId: string) => Promise<void>;
  amtOfFilesLeft: number;
};

export const AddContentModalUploadTab = ({
  content,
  uploadingFiles,
  handleUploadContent,
  handleCancelFileUpload,
  amtOfFilesLeft,
}: AddContentModalUploadTabProps): JSX.Element => {
  // Only show files that are still uploading
  const filteredUploadingFiles = uploadingFiles.filter(
    (file) => file.state !== OrgFileState.Finished,
  );

  let subTitleCopy = `Choose up to ${amtOfFilesLeft} files. Documents, audio, and video files only. 50 MB max size per document, 5 GB max size per audio/video.`;
  if (amtOfFilesLeft === 0) {
    subTitleCopy = `You've reached the max upload limit.`;
  }

  return (
    <Stack gap={3}>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: 500,
          color: getDynamicColor("purple3"),
          fontSize: "12px",
        }}
      >
        Any files uploaded here will also appear in your library
      </Typography>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: 500,
          color: amtOfFilesLeft === 0 ? getDynamicColor("redError") : getDynamicColor("purple3"),
          fontSize: "12px",
        }}
      >
        {subTitleCopy}
      </Typography>
      <CoachBotUploadDropzone
        numFilesLeft={amtOfFilesLeft}
        handleUploadContent={handleUploadContent}
      />
      {filteredUploadingFiles.map((file) => {
        const contentFile = content.find((content) => content.id === file.id);
        return (
          <React.Fragment key={file.id}>
            <Stack
              direction="row"
              gap={1}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              {contentFile ? (
                <Stack direction="row" gap="20px" sx={{ alignItems: "center" }}>
                  <UploadFileIcon sx={{ width: 20, height: 20 }} />
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: getDynamicColor("purple3"),
                      width: 270,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {truncateTextMiddle(contentFile.name)}
                  </Typography>
                </Stack>
              ) : (
                <CircularProgress />
              )}
              <Box sx={{ width: 150 }}>
                <FileUploadProgress
                  progress={file.progress}
                  fileId={file.id}
                  status={file.state}
                  hideEdit
                  hideCancel={false}
                  handleRemoveFile={() => handleCancelFileUpload(file.id)}
                />
              </Box>
            </Stack>
            <Divider />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
