import { getAxiosInstance, AuthorizationAPIHeaders } from "./AxiosInstance";
import * as api from "lib-fullstack/api/integrationsApiTypes";
import * as secretsApi from "lib-fullstack/api/integrationSecretsApiTypes";

/**
 * Create a new integration for an organization.
 * @param orgId The ID of the organization.
 * @param body The request body.
 * @returns The response containing the ID of the created integration.
 * @throws Error if the request fails.
 */
export async function createOrgIntegration(
  orgId: string,
  body: api.CreateOrgIntegrationRequest,
): Promise<api.CreateOrgIntegrationResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().post(`/v2/orgs/${orgId}/integrations`, body, {
      headers: headers,
    });
    return api.CreateOrgIntegrationResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad createOrgIntegration request, status " + err.response?.status);
  }
}

/**
 * Get a single organization integration by its ID.
 * @param orgId The ID of the organization.
 * @param integrationId The ID of the integration to retrieve.
 * @returns The integration data.
 * @throws Error if the request fails.
 */
export async function getOrgIntegration(
  orgId: string,
  integrationId: string,
): Promise<api.GetOrgIntegrationResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(`/v2/orgs/${orgId}/integrations/${integrationId}`, {
      headers: headers,
    });
    return api.GetOrgIntegrationResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad getOrgIntegration request, status " + err.response?.status);
  }
}

/**
 * Get all integrations for an organization.
 * @param orgId The ID of the organization.
 * @returns The integrations.
 * @throws Error if the request fails.
 */
export async function listOrgIntegrations(orgId: string): Promise<api.ListOrgIntegrationsResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(`/v2/orgs/${orgId}/integrations`, {
      headers: headers,
    });
    return api.ListOrgIntegrationsResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad listOrgIntegrations request, status " + err.response?.status);
  }
}

/**
 * Update an organization integration.
 * @param orgId The ID of the organization.
 * @param integrationId The ID of the integration to update.
 * @param body The request body.
 * @throws Error if the request fails.
 */
export async function updateOrgIntegration(
  orgId: string,
  integrationId: string,
  body: api.UpdateOrgIntegrationRequest,
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().patch(`/v2/orgs/${orgId}/integrations/${integrationId}`, body, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad updateOrgIntegration request, status " + err.response?.status);
  }
}

/**
 * Delete an organization integration.
 * @param orgId The ID of the organization.
 * @param integrationId The ID of the integration to delete.
 * @throws Error if the request fails.
 */
export async function deleteOrgIntegration(orgId: string, integrationId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().delete(`/v2/orgs/${orgId}/integrations/${integrationId}`, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad deleteOrgIntegration request, status " + err.response?.status);
  }
}

/**
 * Create secrets for an org integration.
 * @param orgId Organization ID.
 * @param integrationId Integration ID.
 * @throws Error if the request fails.
 */
export async function createOrgIntegrationSecrets(
  orgId: string,
  integrationId: string,
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().post(
      `/v2/orgs/${orgId}/integrations/${integrationId}/secrets`,
      {},
      {
        headers: headers,
      },
    );
  } catch (err) {
    throw Error("Bad createOrgIntegrationSecrets request, status " + err.response?.status);
  }
}

/**
 * Get the secrets for an org integration.
 * @param orgId Organization ID.
 * @param integrationId Integration ID.
 * @returns Secrets object for the specified integration.
 * @throws Error if the request fails.
 */
export async function getOrgIntegrationSecrets(
  orgId: string,
  integrationId: string,
): Promise<secretsApi.GetOrgIntegrationSecretsResponseBase> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(
      `/v2/orgs/${orgId}/integrations/${integrationId}/secrets`,
      {
        headers: headers,
      },
    );
    return secretsApi.GetOrgIntegrationSecretsResponseBase.check(ret.data);
  } catch (err) {
    throw Error("Bad getOrgIntegrationSecrets request, status " + err.response?.status);
  }
}
