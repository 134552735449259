import retry from "async-retry";

// 8MB. GCS doc recommends at minimum 8MB for efficient uploads.
// This size must be multiple of 256KiB (256 * 1024).
const BATCH_SIZE = 8388608;

// Progress callback and its data
export type UploadProgress = {
  uploadedBytes: number;
  totalBytes: number;
};
export type UploadProgressCallback = (progress: UploadProgress) => boolean;

/**
 * Uploads a blob to GCS using resumable upload, dividing it into chunks.
 * Individual chunks may be retried up to 5 times each.
 * Throws an error if the upload fails or is canceled by callback.
 * @param blob a blob to upload
 * @param uploadUrl resumable upload URL
 * @param callback progress callback (null if not used)
 * @param logPrepend string prepended to all log messages
 * @param batchSize size of each chunk (default: 8MB)
 * @param offset starting byte offset (default: 0)
 * @returns total number of bytes uploaded
 */
export async function uploadBlobToResumableUploadUrl(
  blob: Blob | Uint8Array,
  uploadUrl: string,
  callback: UploadProgressCallback | null,
  logPrepend: string,
  batchSize?: number | null,
  offset?: number,
  isFinal?: boolean,
): Promise<number> {
  const total =
    blob instanceof Blob
      ? (blob as Blob).size
      : blob instanceof Uint8Array
        ? (blob as Uint8Array).length
        : 0;
  let start = 0;
  offset = offset ?? 0;
  isFinal = isFinal ?? true;
  console.log(`${logPrepend} Start ${total} with offset ${offset}`);
  callback?.({ uploadedBytes: 0, totalBytes: total });

  let finalResponseRange = "";
  while (start < total) {
    const end = Math.min(start + (batchSize ?? BATCH_SIZE), total);
    const blobData = blob.slice(start, end);
    let rangeHeader = `bytes ${start + offset}-${end + offset - 1}/${total + offset}`;
    if (!isFinal) {
      rangeHeader = `bytes ${start + offset}-${end + offset - 1}/*`;
    }
    await retry(
      async () => {
        const response = await fetch(uploadUrl, {
          method: "PUT",
          headers: {
            "Content-Range": rangeHeader,
          },
          body: blobData,
          // @ts-ignore (not in TS lib yet, but MDN shows most browsers support it--Firefox excluded)
          // priority: "low",
        });
        // 308 is expected for chunks other than the last one
        if (!response.ok && response.status !== 308) {
          throw new Error(`PUT response ${response.status} ${response.statusText}`);
        }
        finalResponseRange = response.headers.get("Range");
      },
      {
        retries: 150,
        minTimeout: 1000,
        maxTimeout: 30000,
        factor: 2,
        onRetry: async (error) => {
          console.log(
            `${logPrepend} Error ${start}-${
              end - 1
            }/${total} with offset ${offset}. Will retry. ${error}`,
          );
        },
      },
    );

    console.log(`${logPrepend} Success ${start}-${end - 1}/${total}.`);
    const response = callback?.({ uploadedBytes: end, totalBytes: total });
    if (response === false) {
      throw new Error("Canceled by callback");
    }
    start = end;
  }
  callback?.({ uploadedBytes: total, totalBytes: total });

  if (!isFinal) {
    const reportedUploadedBytes = parseInt(finalResponseRange.split("-")[1]) + 1;

    console.log(
      `${logPrepend} Completed ${total} with offset ${offset}. GCS reports ${reportedUploadedBytes}`,
    );
    return reportedUploadedBytes;
  } else {
    console.log(`${logPrepend} Completed ${total} with offset ${offset}.`);
    return total;
  }
}
