// Utils
import {
  getPublicPersonaProfilePicture,
  listPersonaProfilePictures,
  listUserPersonaProfilePictures,
} from "lib-frontend/modules/AxiosInstance";
import type {
  PersonaProfilePicturesResponse,
  ListPersonaProfilePicturesQueryParams,
} from "lib-fullstack/api/scenarioApiTypes";
import { VIEW_LOGO_URL_EXIPRATION_MS } from "lib-fullstack/utils/constants";
/**
 * Cache entry type that includes the URL and its expiration timestamp
 */
export interface CacheEntry {
  url: string | null;
  expiresAt: number; // Unix timestamp in milliseconds
}

/**
 * In-memory cache of profile picture URL
 * key: profile picture ID
 * value: CacheEntry containing URL and expiration
 */
const cache = new Map<string, CacheEntry>();

/**
 * Helper function to check if a cache entry is expired
 */
function isExpired(entry: CacheEntry): boolean {
  return Date.now() > entry.expiresAt;
}

/**
 * Return the profile picture URL for the specified ID, or null if unavailable.
 */
export async function getProfilePicture(profilePictureId: string): Promise<string | null> {
  const entry = cache.get(profilePictureId);
  if (!entry || isExpired(entry)) {
    // Refetch if expired or not in cache
    try {
      const newUrl = await getPublicPersonaProfilePicture(profilePictureId);
      cacheProfilePicture(profilePictureId, newUrl);
      return newUrl;
    } catch (error) {
      console.error(`Error refetching expired profile picture for ID ${profilePictureId}:`, error);
      return null;
    }
  }
  return entry.url;
}

/**
 * Helper function to cache a profile picture URL with expiration
 */
function cacheProfilePicture(id: string, url: string | null): void {
  cache.set(id, {
    url,
    expiresAt: Date.now() + VIEW_LOGO_URL_EXIPRATION_MS,
  });
}

/**
 * Retrieve temporary URL (7 days as of writing) of profile pictures for
 * passed set of profile picture IDs from server API, and save them into the memory.
 * This does not re-query the IDs which are already queried once.
 */
export async function queryProfilePictures(profilePictureIds: string[]): Promise<void> {
  const newIds = profilePictureIds.filter((id) => {
    const entry = cache.get(id);
    return !entry || isExpired(entry);
  });

  if (newIds.length > 0) {
    // Process in batches of 20 to avoid overwhelming the server
    for (let i = 0; i < newIds.length; i += 20) {
      const idBatch = newIds.slice(i, i + 20);
      const urls = await Promise.all(
        idBatch.map(async (id) => {
          try {
            return await getPublicPersonaProfilePicture(id);
          } catch (error) {
            console.error(`Error fetching profile picture for ID ${id}:`, error);
            return null;
          }
        }),
      );

      for (let j = 0; j < idBatch.length; j++) {
        cacheProfilePicture(idBatch[j], urls[j]);
      }
    }
  }
}

/**
 * Helper function to get a profile picture URL, querying if not available in cache.
 * Returns null if the picture is unavailable.
 */
export async function getProfilePictureWithQuery(profilePictureId: string): Promise<string | null> {
  const cached = getProfilePicture(profilePictureId);
  if (cached !== null) {
    return cached;
  }

  await queryProfilePictures([profilePictureId]);
  return getProfilePicture(profilePictureId);
}

/**
 * Wrapper for listPersonaProfilePictures that caches results
 */
export async function listPersonaProfilePicturesWithCache(
  orgId: string,
  params: ListPersonaProfilePicturesQueryParams,
): Promise<PersonaProfilePicturesResponse> {
  const response = await listPersonaProfilePictures(orgId, params);

  // Cache all profile picture URLs
  response.profilePictures.forEach((p) => {
    cacheProfilePicture(p.id, p.signedUrl);
  });

  return response;
}

/**
 * Wrapper for listUserPersonaProfilePictures that caches results
 */
export async function listUserPersonaProfilePicturesWithCache(): Promise<PersonaProfilePicturesResponse> {
  const response = await listUserPersonaProfilePictures();

  // Cache all profile picture URLs
  response.profilePictures.forEach((p) => {
    cacheProfilePicture(p.id, p.signedUrl);
  });

  return response;
}
