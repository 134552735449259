import { noop } from "lodash";
import React from "react";

// Components
import { Button, Stack } from "@mui/material";
import { UploadingFile } from "components/Orgs/ManageContent/OrgFileLibrary/OrgFileLibrary";
import { DialogDrawer } from "lib-frontend/components/DialogDrawer";
import YoodliHeaderTabs from "lib-frontend/components/YoodliComponents/YoodliHeaderTabs";

// Utils
import { AddContentModalFileLibraryTab } from "./AddContentModalFileLibraryTab";
import { AddContentModalUploadTab } from "./AddContentModalUploadTab";
import { getDynamicColor } from "lib-frontend/utils/Colors";

enum AddContentModalTab {
  ChooseFiles = "ChooseFiles",
  Upload = "Upload",
}

const AddContentModalTabLabels = {
  [AddContentModalTab.ChooseFiles]: "Choose files",
  [AddContentModalTab.Upload]: "Upload",
};

type AddContentModalProps = {
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  amtOfFilesLeft: number;
  handleCancelFileUpload: (contentId: string) => Promise<void>;
  showAddContentModal: boolean;
  setShowAddContentModal: (show: boolean) => void;
  handleAddNewContent: (fileIds: string[]) => void;
  content: {
    id: string;
    name: string;
  }[];
};

export const AddContentModal = ({
  uploadingFiles,
  handleUploadContent,
  amtOfFilesLeft,
  handleCancelFileUpload,
  showAddContentModal,
  setShowAddContentModal,
  handleAddNewContent,
  content,
}: AddContentModalProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState<AddContentModalTab>(
    AddContentModalTab.ChooseFiles,
  );
  const [selectedLibraryFiles, setSelectedLibraryFiles] = React.useState<string[]>([]);

  const renderTab = () => {
    switch (selectedTab) {
      case AddContentModalTab.ChooseFiles:
        return (
          <AddContentModalFileLibraryTab
            selectedLibraryFiles={selectedLibraryFiles}
            setSelectedLibraryFiles={setSelectedLibraryFiles}
            content={content}
            amtOfFilesLeft={amtOfFilesLeft}
          />
        );
      case AddContentModalTab.Upload:
        return (
          <AddContentModalUploadTab
            content={content}
            uploadingFiles={uploadingFiles ?? []}
            handleUploadContent={handleUploadContent}
            handleCancelFileUpload={handleCancelFileUpload}
            amtOfFilesLeft={amtOfFilesLeft}
          />
        );
    }
  };

  const handleAddContent = () => {
    if (selectedTab === AddContentModalTab.ChooseFiles && selectedLibraryFiles.length > 0) {
      handleAddNewContent(selectedLibraryFiles);
    }

    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSelectedLibraryFiles([]);
    setShowAddContentModal(false);
  };

  return (
    <DialogDrawer
      open={showAddContentModal}
      onOpen={noop}
      onClose={handleCloseModal}
      dialogProps={{
        PaperProps: {
          sx: {
            width: 580,
            overflowX: "hidden",
          },
        },
      }}
      drawerProps={{
        PaperProps: {
          sx: {
            mt: 4,
            pt: { xs: 6 },
          },
        },
      }}
    >
      <Stack
        gap={4}
        sx={{
          pt: 3,
          px: 4,
          pb: 5,
        }}
      >
        <YoodliHeaderTabs
          allTabs={Object.values(AddContentModalTab)}
          selectedTab={selectedTab}
          handleTabChange={(tab) => setSelectedTab(tab as AddContentModalTab)}
          tabLabels={AddContentModalTabLabels}
          color={getDynamicColor("purple3")}
          containerSx={{ backgroundColor: getDynamicColor("light1"), px: 0 }}
          getButtonSx={(isSelected) => ({
            fontSize: "16px",
            fontWeight: 600,
            color: isSelected ? getDynamicColor("purple3") : getDynamicColor("dark4"),
          })}
        />
        {renderTab()}
        <Button
          variant="contained"
          size="medium"
          onClick={handleAddContent}
          sx={{ fontSize: 14, fontWeight: 600, width: 120, height: 40, ml: "auto" }}
        >
          Done
        </Button>
      </Stack>
    </DialogDrawer>
  );
};
