import React from "react";

// Components
import { CreateConvoScenarioStepId, GoalHooks } from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";

// Utils
import { CompoundGoalDescriptionStep } from "./CompoundGoalDescriptionStep";
import { CreateCompoundGoalSubGoalStep } from "./CreateCompoundGoalSubGoalStep";
import {
  CreateCompoundGoalRequest,
  CreateCustomGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";

export const CreateCompoundGoalSteps = (
  compoundGoal: CreateCompoundGoalRequest,
  setCompoundGoal: React.Dispatch<React.SetStateAction<CreateCompoundGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  inScenarioCreation?: boolean,
): WizardStep[] => {
  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <CompoundGoalDescriptionStep
        compoundGoal={compoundGoal}
        setCompoundGoal={setCompoundGoal}
        goalEditId={goalEditId}
      />
    ),
    validate: () => compoundGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    prevCopy: "Discard",
    skipPrevIndexUpdate: inScenarioCreation,
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  const subgoalStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <CreateCompoundGoalSubGoalStep
        compoundGoal={compoundGoal}
        setCompoundGoal={setCompoundGoal}
        goalEditId={goalEditId}
        goalHooks={goalHooks}
      />
    ),
    skipNextIndexUpdate: true,
    validate: () => compoundGoal.subGoals?.length > 1,
    next: async () => {
      await createCustomGoal(compoundGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    hidePrev: !compoundGoal.subGoals,
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    nextHelperText: compoundGoal.subGoals?.length === 1 && "Add at least one more subgoal",
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;
  return [descStep, subgoalStep];
};
