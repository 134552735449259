import React from "react";
import { useBlocker, useNavigate } from "react-router-dom";

// Components
import { WarningAmberRounded as WarningIcon } from "@mui/icons-material";
import { Button, Dialog, Stack, SxProps, Typography } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GlobalQueryParams } from "lib-fullstack/utils/queryParams";

export type RouteLeavingGuardProps = {
  when: boolean;
  shouldBlockNavigation: (nextLocation) => boolean;
  title: string;
  body: string;
  cancelText?: string;
  cancelButtonSx?: SxProps;
  okText?: string;
  okButtonSx?: SxProps;
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  onNavigate?: () => void;
};
export function RouteLeavingGuard({
  when,
  shouldBlockNavigation,
  title,
  body,
  cancelText,
  cancelButtonSx,
  okText,
  okButtonSx,
  modalVisible,
  setModalVisible,
  onNavigate,
}: RouteLeavingGuardProps): JSX.Element {
  const { modalStyles } = useNavDrawerOffset();
  const navigate = useNavigate();

  const blocker = useBlocker(({ nextLocation }) => when && shouldBlockNavigation(nextLocation));

  React.useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Enter") {
        closeModal();
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = (callback = () => null) => {
    setModalVisible(false);
    callback?.();
  };

  React.useEffect(() => {
    if (blocker.state === "blocked") {
      showModal();
    }
  }, [blocker, blocker.state, blocker.location]);

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      onNavigate?.();
      if (blocker && blocker.proceed) {
        const nextPath = blocker.location.pathname;

        // Check if navigating to the same route
        if (nextPath === blocker.location.pathname) {
          const urlParams = new URLSearchParams(decodeURIComponent(blocker.location.search));
          urlParams.set(GlobalQueryParams.TIMESTAMP, Date.now().toString());
          const newSearch = urlParams.toString();
          const newPath = `${blocker.location.pathname}?${newSearch}`;
          // Then immediately force a refresh with navigate, passing along any state from the
          // navigation that triggered this confirmation.
          navigate(newPath, { replace: true, state: { ...blocker?.location?.state } });
        } else {
          // Normal proceed for different routes
          blocker.proceed();
        }
      }
    });
  };

  return (
    <>
      <Dialog
        open={modalVisible}
        onClose={() => closeModal()}
        PaperProps={{
          sx: {
            ...modalStyles,
            borderRadius: "12px",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            backgroundColor: getDynamicColor("light1"),
            width: "min(100%, 600px)",
            p: 4,
            px: { xs: 3, lg: 4 },
            gap: 2,
          },
        }}
      >
        <Stack direction="row" gap={1}>
          <WarningIcon />
          <Typography
            component="h2"
            sx={{
              fontFamily: "poppins",
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: { xs: 14, lg: 16 },
            fontFamily: "poppins",
            fontWeight: 400,
            lineHeight: 1.3,
            maxWidth: 500,
          }}
        >
          {body}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 2,
            width: "100%",
          }}
        >
          <Button onClick={handleConfirmNavigationClick} variant="outlined" sx={okButtonSx}>
            {okText ?? "Ok"}
          </Button>
          <Button onClick={() => closeModal()} variant="contained" sx={cancelButtonSx}>
            {cancelText ?? "Cancel"}
          </Button>
        </Stack>
      </Dialog>
    </>
  );
}
