import React from "react";

// Components
import { styled } from "@mui/system";

// Utils
import ConfettiAnimation from "../images/animations/confetti.json";
import SmallConfettiAnimation from "../images/animations/small-confetti.json";
import Lottie, { LottieComponentProps } from "lottie-react";

const Wrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default function Confetti(props: Omit<LottieComponentProps, "animationData">): JSX.Element {
  return (
    <Wrapper className={props.className}>
      <Lottie animationData={ConfettiAnimation} {...props} />
    </Wrapper>
  );
}

export const SmallConfetti = React.forwardRef(
  (
    props: Omit<LottieComponentProps, "animationData"> & {
      lottieRef: React.RefObject<HTMLDivElement>;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return <Lottie animationData={SmallConfettiAnimation} ref={ref} {...props} />;
  },
);
