import React from "react";

// Utils
import { YoodliNotificationOptions, useNotificationContext } from "./NotificationContext";

export const useNotification = (): {
  showNotificationBanner: (
    message: string | JSX.Element,
    options?: YoodliNotificationOptions,
  ) => void;
  notifAnchorRef: React.RefObject<HTMLDivElement>;
} => {
  const { showNotification, registerComponent, unregisterComponent, notifAnchorRef } =
    useNotificationContext();

  React.useEffect(() => {
    const componentId = registerComponent();
    return () => {
      unregisterComponent(componentId);
    };
  }, [registerComponent, unregisterComponent]);

  return { showNotificationBanner: showNotification, notifAnchorRef };
};
