// Utils
import { WebServerInternalPath } from "./paths";
import { checkoutPlan, getBillingSettingsUrl } from "lib-frontend/modules/AxiosInstance";
import { AppSource } from "lib-frontend/utils/Enums";
import { getLiveUserDocMain, getLiveUserDocReadonly } from "lib-frontend/utils/LiveUserDocs";
import { PricingCardType, SettingsTab, PlanChangeType } from "lib-frontend/utils/PricingData";
import { PRICING_EXPERIMENT_CONFIG } from "lib-frontend/utils/PricingData";
import {
  Billing,
  PlanCheckoutResult,
  PlanSettingsActionResult,
} from "lib-fullstack/api/planApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import {
  PricingExperiment,
  UsagePlanDuration,
  UsagePlanType,
} from "lib-fullstack/utils/pricingTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";

const clientEnv = getClientEnvConfig();

const PRICING_PLANS = Object.values(UsagePlanType);

export const isPricingPlanRecommended = (
  usagePlanType: UsagePlanType,
  cardData: PricingCardType,
): boolean => {
  if (usagePlanType === UsagePlanType.PRO_V1) {
    return cardData.type === UsagePlanType.UNLIMITED;
  } else if (usagePlanType === UsagePlanType.UNLIMITED) {
    return false;
  }
  return cardData?.isRecommended;
};

const isUpgrade = (currentPlan: UsagePlanType, newPlan: UsagePlanType): boolean => {
  const currentPlanIndex = PRICING_PLANS.indexOf(currentPlan);
  const newPlanIndex = PRICING_PLANS.indexOf(newPlan);
  return currentPlanIndex <= newPlanIndex;
};

export async function handleGetBillingSettingsUrl(): Promise<void> {
  const returnUrl = `${clientEnv.url.WEB_SERVER}${WebServerExternalPath.ACCOUNT}?tab=${SettingsTab.BILLING}`;
  const checkoutResponse = await getBillingSettingsUrl(returnUrl, returnUrl);

  if (
    checkoutResponse.redirect_url &&
    checkoutResponse.result === PlanSettingsActionResult.REDIRECT
  ) {
    console.log("redirecting to change payment settings on stripe");
    window.location.href = checkoutResponse.redirect_url;
  }
}

export async function handlePlanCheckout(
  selectedPlanType: UsagePlanType,
  selectedPlanDuration: UsagePlanDuration,
  isConfirmation: boolean,
): Promise<Billing> {
  const userDocReadonly = getLiveUserDocReadonly();
  const userDocMain = getLiveUserDocMain();

  // either open poodli or redirect to the location the user came from when they clicked "upgrade"
  const openPoodli =
    new URLSearchParams(window.location.search).get("redirect") === AppSource.DESKTOP_APP;
  const redirectPath = openPoodli ? AppSource.DESKTOP_APP : window.location.pathname;

  const successUrl = `${clientEnv.url.WEB_SERVER}${
    WebServerInternalPath.CHECKOUT_COMPLETE
  }?planChange=${
    isUpgrade(userDocReadonly.usagePlanType as UsagePlanType, selectedPlanType)
      ? PlanChangeType.UPGRADE
      : PlanChangeType.DOWNGRADE
  }${`&redirect=${redirectPath}`}`;

  const cancelUrl = `${clientEnv.url.WEB_SERVER}${WebServerExternalPath.ACCOUNT}?tab=${
    SettingsTab.BILLING
  }${`&redirect=${redirectPath}`}`;

  const checkoutResponse = await checkoutPlan(
    selectedPlanType,
    selectedPlanType === UsagePlanType.FREE ? UsagePlanDuration.NONE : selectedPlanDuration,
    isConfirmation,
    userDocMain.pExpVersion as PricingExperiment,
    successUrl,
    cancelUrl,
  );

  if (checkoutResponse.result === PlanCheckoutResult.REDIRECT) {
    console.log("redirecting to checkout page");
    window.location.href = checkoutResponse.redirect_url;
  } else if (
    [PlanCheckoutResult.SCHEDULED, PlanCheckoutResult.NOT_SCHEDULED].includes(
      checkoutResponse.result as PlanCheckoutResult,
    )
  ) {
    console.log(
      `checkout ${
        checkoutResponse.result === PlanCheckoutResult.NOT_SCHEDULED ? "not " : ""
      }scheduled`,
    );
    return checkoutResponse.pending_billing;
  } else if (checkoutResponse.result === PlanCheckoutResult.FAIL_SCHEDULE_EXIST) {
    throw new Error(checkoutResponse.error_info);
  } else {
    throw new Error("Unexpected checkout response: " + checkoutResponse.error_info);
  }
}

export function getPlanData(planType: UsagePlanType, isAnnual: boolean): PricingCardType {
  return PRICING_EXPERIMENT_CONFIG[getLiveUserDocMain().pExpVersion].cards.find(
    (plan) =>
      plan.type === planType &&
      ([PricingExperiment.PRO_ADV].includes(
        getLiveUserDocMain().pExpVersion as PricingExperiment,
      ) ||
        isAnnual === !!plan.cost.annually ||
        [UsagePlanType.FREE, UsagePlanType.ENTERPRISE].includes(planType)),
  );
}

/**
 * Returns if the specified org is waiting for the input of a payment method.
 * This info is needed before charging fees via Stripe with proper tax calculation.
 * @param org (default) org info returned by API
 */
export function paymentMethodNeeded(org: OrgV2Response): boolean {
  return (
    ((org.subscription_type === OrgSubscriptionType.PAYG && org.is_trial) ||
      org.subscription_type === OrgSubscriptionType.FLEXIBLE) &&
    !org.payment_method_added
  );
}
