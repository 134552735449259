import React from "react";

// Components
import { Box, Button, Card, CircularProgress, Stack, Typography } from "@mui/material";
import { PricingFeatureBullet } from "components/Pricing/PricingCard";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import GoAnnualBanner from "./GoAnnualBanner";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { PricingCardType } from "lib-frontend/utils/PricingData";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getIntFromString } from "lib-frontend/utils/Utilities";
import { PlanCheckoutResult } from "lib-fullstack/api/planApiTypes";
import { handlePlanCheckout, isPricingPlanRecommended } from "utils/PricingUtils";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type PlanCardProps = {
  data: PricingCardType;
  usagePlanType: UsagePlanType;
  nextUsagePlanType: UsagePlanType | null;
  nextUsagePlanDuration: UsagePlanDuration;
  currentDuration: UsagePlanDuration;
  handleGoAnnual: () => void;
  goAnnualError: PlanCheckoutResult | null;
  annualSavingsAmt: number;
  hasPendingPlan?: boolean;
  handleTogglePricingModal: (open?: boolean, copy?: string, hasPendingPlan?: boolean) => void;
  isAnnual?: boolean;
};

const PlanCard = ({
  data,
  isAnnual,
  usagePlanType,
  nextUsagePlanType,
  nextUsagePlanDuration,
  currentDuration,
  handleGoAnnual,
  goAnnualError,
  annualSavingsAmt,
  hasPendingPlan,
  handleTogglePricingModal,
}: PlanCardProps): JSX.Element => {
  const isCurrentPlan = data.type === usagePlanType;
  const isRecommended = isPricingPlanRecommended(usagePlanType, data);

  // State
  const [hoveringButton, setHoveringButton] = React.useState(false);
  const [loadingCheckout, setLoadingCheckout] = React.useState(false);
  let buttonCopy = "UPGRADE";
  if (isCurrentPlan) {
    buttonCopy = hoveringButton ? "See all plans" : "Your plan";
  }
  const handleGoAnnualClicked = async () => {
    try {
      setLoadingCheckout(true);
      await handleGoAnnual();
    } catch (er) {
      console.error(`Error clicking "Go Annual" from PlanCard.tsx: ${er}`);
    } finally {
      setLoadingCheckout(false);
    }
  };
  return (
    <Card
      sx={{
        boxShadow: Y_SHADOWS.box_shadow_1,
        p: { xs: 3, md: 5 },
        pt: { xs: 4, md: 6 },
        flexGrow: 1,
        flexBasis: { xs: "100%", sm: "50%" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 1,
        position: "relative",
        overflow: "hidden",
        borderRadius: "12px",
        background: getDynamicColor(isRecommended ? "gradient" : "light1"),
        ...(isRecommended && {
          pt: 0,
          p: 0,
        }),
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: loadingCheckout ? "auto" : "none",
          opacity: loadingCheckout ? 1 : 0,
          backgroundColor: getDynamicColor("dark4", 0.25),
          transition: "opacity 0.2s ease-in-out",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
      <Box
        sx={
          isRecommended
            ? {
                position: "relative",
                top: { xs: -1, sm: 0 },
                left: 1,
                width: "calc(100% - 2px)",
                height: "calc(100% - 1px)",
                borderRadius: "11px",
                backgroundColor: getDynamicColor("light1"),
                p: { xs: 3, md: 5 },
                pt: { xs: 6, md: 6 },
              }
            : {}
        }
      >
        {isRecommended && (
          <Typography
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              letterSpacing: "1px",
              background: getDynamicColor("gradient.default"),
              fontSize: 12,
              color: getDynamicColor("light1"),
              fontWeight: 500,
              py: 0.75,
              px: 1.5,
            }}
          >
            RECOMMENDED
          </Typography>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          sx={{
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "poppins",
              }}
            >
              {data.title}
            </Typography>
            <Stack
              direction="row"
              alignItems="baseline"
              gap={0.5}
              sx={{
                color: getDynamicColor("purple3"),
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "24px", sm: "28px" },
                  fontFamily: "Poppins",
                  maxWidth: 220,
                  mr: "2px",
                }}
              >
                {isAnnual ? data?.cost?.annually : data?.cost?.monthly}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px" },
                  fontWeight: 700,
                  maxWidth: 220,
                  mb: 1,
                }}
              >
                {data?.pricingSubtext}, billed {isAnnual ? "annually" : "monthly"}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant={isCurrentPlan ? "contained" : "gradient"}
            startIcon={
              !isCurrentPlan && (
                <Box>
                  <UpgradeIcon />
                </Box>
              )
            }
            onMouseEnter={() => setHoveringButton(true)}
            onMouseLeave={() => setHoveringButton(false)}
            onClick={async () => {
              try {
                const currentIsAnnual =
                  currentDuration === UsagePlanDuration.YEARLY && !!data.cost.annually;
                Instrumentation.logSettingsPricingUpgradeButtonClicked(
                  data.type,
                  getIntFromString(currentIsAnnual ? data.cost?.annually : data.cost?.monthly),
                  currentIsAnnual,
                );
                setLoadingCheckout(true);
                if (isCurrentPlan || !!nextUsagePlanType) {
                  handleTogglePricingModal(true, buttonCopy, !!nextUsagePlanType && !isCurrentPlan);
                } else {
                  await handlePlanCheckout(
                    data.type,
                    isAnnual ? UsagePlanDuration.YEARLY : UsagePlanDuration.MONTHLY,
                    true,
                  );
                }
              } catch (er) {
                console.error(`Error clicking "${buttonCopy}" from PlanCard.tsx: ${er}`);
              } finally {
                setLoadingCheckout(false);
              }
            }}
            sx={{
              fontSize: 14,
              px: 3,
              width: "fit-content",
              height: "fit-content",
              svg: {
                height: 16,
                width: 16,
              },
              ...(isCurrentPlan && {
                minWidth: 138,
                color: getDynamicColor("primary"),
                backgroundColor: getDynamicColor("light2"),
                "&:hover": {
                  backgroundColor: getDynamicColor("purple1"),
                },
              }),
            }}
          >
            {buttonCopy}
          </Button>
        </Stack>
        <Stack
          gap={2.5}
          sx={{
            mt: 2.5,
            maxWidth: 320,
          }}
        >
          {data?.bulletPoints?.map((info) => {
            return (
              <PricingFeatureBullet
                key={info.toString()}
                info={info}
                overrideSx={{
                  fontSize: 14,
                }}
              />
            );
          })}
        </Stack>
        {!isAnnual && isCurrentPlan && nextUsagePlanDuration !== UsagePlanDuration.YEARLY && (
          <GoAnnualBanner
            isCard
            annualSavingsAmt={annualSavingsAmt}
            handleGoAnnual={handleGoAnnualClicked}
            goAnnualError={goAnnualError}
            hasPendingPlan={hasPendingPlan}
            handleTogglePricingModal={handleTogglePricingModal}
          />
        )}
      </Box>
    </Card>
  );
};

export default PlanCard;
