import { useLocation } from "react-router-dom";
import React from "react";
import firebase from "firebase/app";
import { useQuery as useApiQuery } from "@tanstack/react-query";

import { FeatureUpdateVisibility } from "lib-fullstack/db/db_runtypes";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { FeatureUpdateResponse, getMostRecentFeatureUpdate } from "lib-frontend/api/wordpress";
import { usePrevious } from "lib-frontend/hooks";
import {
  WhatsNewAnnouncementEvents,
  WhatsNewAnnouncementType,
} from "lib-fullstack/utils/productAnalyticEvents";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { UserOrgContext, UserOrgProfile } from "lib-frontend/contexts/UserOrgContext";
import { omitSidebarPaths } from "components/Layout";
import { isToastmasters } from "lib-frontend/utils/subdomain";
export const YOODLI_FEATURE_UPDATES_CONTEXT_QUERY_KEY = "yoodliFeatureUpdatesContext";

const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000;

export const useFeatureUpdates = (): {
  featureUpdateVisibility: FeatureUpdateVisibility | null;
  handleCloseFeatureUpdate: (type?: WhatsNewAnnouncementType) => Promise<void>;
  featureUpdate: FeatureUpdateResponse | null;
} => {
  const { pathname } = useLocation();
  const { userOrgProfile } = React.useContext(UserOrgContext);
  const isAdminView = userOrgProfile === UserOrgProfile.ORGANIZATION;
  const userDocMain = getLiveUserDocMain();

  // State
  const featureUpdateContext = userDocMain?.featureUpdates?.[isAdminView ? "orgAdmin" : "endUser"];

  const [featureUpdate, setFeatureUpdate] = React.useState<FeatureUpdateResponse | null>(null);

  const [visibility, setVisibility] = React.useState<FeatureUpdateVisibility | null>(
    featureUpdateContext?.visibility ?? null,
  );

  // Update visibility state when featureUpdateContext changes
  React.useEffect(() => {
    setVisibility(featureUpdateContext?.visibility ?? null);
  }, [featureUpdateContext?.visibility]);

  const currentUser = firebase.auth()?.currentUser;

  const shouldCheckForFeatureUpdate = React.useMemo(() => {
    // make sure user and userDocMain exist, and that we're not on a sidebar-less route before ever checking for updates
    if (
      isToastmasters() ||
      !currentUser ||
      !userDocMain ||
      (omitSidebarPaths as string[]).includes(pathname)
    ) {
      return false;
    }

    // check if the feature update has been checked in the last 6 hours (or if it's never been checked)
    const lastCheckedTimestamp = featureUpdateContext?.lastCheckedTimestamp;
    const lastCheckedTimestampDate = new Date(lastCheckedTimestamp ?? "");
    const sixHoursAgo = new Date(Date.now() - 6 * 60 * 60 * 1000);

    // if im already showing the update, check for changes to it so its always fresh (account for post-publish edits)
    const currentVisibility = featureUpdateContext?.visibility;
    const currentlyShowingUpdate =
      !currentVisibility ||
      (!!currentVisibility && currentVisibility !== FeatureUpdateVisibility.Hide);

    return (
      !lastCheckedTimestamp ||
      // <= since the timestamp is smaller, even though we want MORE than 6 hours ago
      lastCheckedTimestampDate <= sixHoursAgo ||
      currentlyShowingUpdate
    );
    // only update this value when we first acquire the userDoc,
    // so it doesnt update when we mutate the userDoc below
    // basically, only check this on mount, after we have the initial userDoc value
  }, [!!userDocMain, currentUser, pathname]);

  const featureUpdateQuery = useApiQuery({
    queryKey: [YOODLI_FEATURE_UPDATES_CONTEXT_QUERY_KEY],
    queryFn: async () => {
      // safety check to prevent accidentally refetching when we shouldn't, as caused pw failures
      if (!shouldCheckForFeatureUpdate) {
        return null;
      }
      // whenever fetching, set null first so theres no flashing
      setFeatureUpdate(null);
      const update: FeatureUpdateResponse | null = await getMostRecentFeatureUpdate(
        isAdminView,
      ).catch(() => {
        console.log("Error fetching feature update from wordpress");
        return null;
      });
      if (update) {
        // make sure account is older than 2 weeks to do popups
        const accountCreationTime = currentUser?.metadata?.creationTime;
        const twoWeeksAgo = new Date(Date.now() - twoWeeksInMs);
        const isAccountOlderThanTwoWeeks = accountCreationTime
          ? new Date(accountCreationTime) <= twoWeeksAgo
          : false;

        let userDocUpdate = {
          ...featureUpdateContext,
          lastCheckedTimestamp: new Date(Date.now()).toISOString(),
        };

        // if this is a new post, update the user doc with the new slug and reset visibility
        if (
          !featureUpdateContext?.lastSeenSlug ||
          (featureUpdateContext?.lastSeenSlug && update.slug !== featureUpdateContext?.lastSeenSlug)
        ) {
          userDocUpdate = {
            ...userDocUpdate,
            lastSeenSlug: update.slug,
            visibility:
              isAdminView || !isAccountOlderThanTwoWeeks
                ? FeatureUpdateVisibility.Banner
                : FeatureUpdateVisibility.Popup,
          };
        }
        await updateThisUserDocMain({
          featureUpdates: {
            ...userDocMain?.featureUpdates,
            [isAdminView ? "orgAdmin" : "endUser"]: userDocUpdate,
          },
        });
      }
      // manage my own state so i can reset it instantly when changing from org to non org route, or vice versa
      setFeatureUpdate(update);
      return update;
    },
    enabled: shouldCheckForFeatureUpdate && !!firebase.auth().currentUser,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const prevIsAdminView = usePrevious(isAdminView);
  React.useEffect(() => {
    (async () => {
      // if the route ever changes from org to non-org or vice versa, invalidate and refetch the feature update
      if (
        shouldCheckForFeatureUpdate &&
        prevIsAdminView !== undefined &&
        prevIsAdminView !== isAdminView
      ) {
        await featureUpdateQuery.refetch();
      }
    })().catch((err) => {
      console.error("Error refetching feature update on isAdminView changed", err);
    });
  }, [isAdminView, shouldCheckForFeatureUpdate]);

  const handleCloseFeatureUpdate = async (type?: WhatsNewAnnouncementType) => {
    const currentVisibility = featureUpdateContext?.visibility;
    let newVisibility = FeatureUpdateVisibility.Banner;
    let event: WhatsNewAnnouncementEvents;
    let payload;

    // dismissing the popup
    if (!currentVisibility || currentVisibility === FeatureUpdateVisibility.Popup) {
      newVisibility = FeatureUpdateVisibility.Banner;
      event = WhatsNewAnnouncementEvents.POPUP_DISMISSED;
    }
    // dismissing the banner
    else if (currentVisibility === FeatureUpdateVisibility.Banner) {
      newVisibility = FeatureUpdateVisibility.Hide;
      event = WhatsNewAnnouncementEvents.BANNER_DISMISSED;
      payload = {
        type,
      };
    }

    if (event) {
      Instrumentation.logAmplitudeEvent(event, payload);
    }

    // Update local state immediately
    setVisibility(newVisibility);

    await updateThisUserDocMain({
      featureUpdates: {
        ...userDocMain?.featureUpdates,
        [isAdminView ? "orgAdmin" : "endUser"]: {
          ...featureUpdateContext,
          visibility: newVisibility,
        },
      },
    });
  };

  return {
    featureUpdate,
    // only show the update if the data has already been fetched and has slug, to prevent content pop-in
    featureUpdateVisibility: featureUpdate?.slug ? visibility : null,
    handleCloseFeatureUpdate,
  };
};
