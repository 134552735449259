import React from "react";

// Utils
import { useQuery as useApiQuery, UseQueryResult } from "@tanstack/react-query";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getCoachBotContentV2 } from "lib-frontend/modules/axiosOrgCoachBot";
import { GetAllBotContentsV2Response } from "lib-fullstack/api/coachBotApiTypes";

const COACH_BOT_FILES_QUERY_KEY = "coachBotFiles";

type CoachBotContentQueryResponse = {
  coachBotContentQuery: UseQueryResult<GetAllBotContentsV2Response, Error>;
  refetchCoachBotContentQuery: () => Promise<void>;
};

/**
 * Hook to query coach bot content for the current organization and space
 * @returns coachBotContentQuery: Query result for the coach bot content
 * @returns refetchCoachBotFilesQuery: Function to refetch the org files query
 */
export const useCoachBotContentQuery = (botId: string): CoachBotContentQueryResponse => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const coachBotContentQuery = useApiQuery({
    queryKey: [COACH_BOT_FILES_QUERY_KEY, defaultOrgId, botId, curSpaceId],
    queryFn: () => getCoachBotContentV2(defaultOrgId, botId, { space_id: curSpaceId }),
    enabled: !!defaultOrgId && !!botId,
    refetchOnWindowFocus: false,
  });

  const refetchCoachBotContentQuery = React.useCallback(async () => {
    await coachBotContentQuery.refetch();
  }, [coachBotContentQuery]);

  return { coachBotContentQuery, refetchCoachBotContentQuery };
};
