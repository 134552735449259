import firebase from "firebase/app";

// Utils
import { UseQueryResult, useQuery as useApiQuery } from "@tanstack/react-query";
import { getSpeech, getUserSpeech } from "lib-frontend/modules/AxiosInstance";
import { GetSpeechField, GetSpeechResponse } from "lib-fullstack/api/apiTypes";

export const SPEECH_QUERY_KEY = "speech";
export const MIN_SPEECH_TIME_S = 30;
const refetchInterval = (query) => {
  return query?.state?.data?.program?.pending_score || !query?.state?.data?.score ? 1000 : 0;
};
export const useSpeechQuery = (
  speechId: string,
  hasSpeechDocPermissions: boolean,
  isSpeechOwner: boolean,
  isConvoScenario: boolean,
  speechAuthorId?: string,
  speechTooShort?: boolean,
): UseQueryResult<GetSpeechResponse> => {
  const authedUser = firebase.auth()?.currentUser;
  let speechQuery: UseQueryResult<GetSpeechResponse>;
  const queryObj = {
    refetchInterval: speechTooShort ? 0 : refetchInterval,
    refetchOnWindowFocus: false,
  };
  if (isSpeechOwner) {
    speechQuery = useApiQuery({
      ...queryObj,
      queryKey: [SPEECH_QUERY_KEY, speechId],
      queryFn: () => {
        return getSpeech(speechId, { fields: GetSpeechField.PROGRAM });
      },
      enabled:
        hasSpeechDocPermissions &&
        isSpeechOwner &&
        !!authedUser &&
        isConvoScenario &&
        !speechTooShort,
    });
  } else {
    speechQuery = useApiQuery({
      ...queryObj,
      queryKey: [SPEECH_QUERY_KEY, speechAuthorId, speechId],
      queryFn: () => getUserSpeech(speechAuthorId, speechId, { fields: GetSpeechField.PROGRAM }),
      enabled:
        hasSpeechDocPermissions &&
        !!speechAuthorId &&
        !!authedUser &&
        isConvoScenario &&
        !speechTooShort,
    });
  }
  return speechQuery;
};
