import React from "react";

import { isSafari } from "react-device-detect";

// Components
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  getDemeanorColor,
} from "components/ConvoScenarios/convoScenarioUtils";
import IconMenu from "lib-frontend/components/IconMenu";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";

// Utils
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CopyPersonaRequest, PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";
import { CreateContentRequestKind } from "lib-fullstack/db";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { createPersona } from "lib-frontend/modules/AxiosInstance";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

type PersonaCardProps = {
  persona: PersonaResponse;
  handleEditPersona: (persona: PersonaResponse) => void;
  handleDeletePersona: (personaId: string) => void;
  isUserPersona?: boolean;
};

export const PersonaCard = ({
  persona,
  handleEditPersona,
  handleDeletePersona,
  isUserPersona,
}: PersonaCardProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const { curSpaceId, copyContentInfo } = React.useContext(ContentSpacesContext);
  const [deletingPersona, setDeletingPersona] = React.useState<PersonaResponse>(null);

  const deletePersonaMutation = useMutation({
    mutationFn: () => void handleDeletePersona(persona.id),
    onSuccess: () => setDeletingPersona(null),
    onError: (e) => console.error(`Error deleting persona with id ${persona.id}: ${e}`),
  });

  const renderScenarioOptionsMenu = (persona: PersonaResponse) => {
    const menuItems = [
      {
        title: "Edit",
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          handleEditPersona(persona);
        },
      },
      !isUserPersona && {
        title: "Copy to",
        onClick: async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          copyContentInfo.handleIsCopyDrawerOpen(true);
          copyContentInfo.handleSetCopyContentRequest({
            copyHandler: async (destinationSpaceId: string) => {
              const personaRequest: CopyPersonaRequest = {
                request_type: CreateContentRequestKind.Copy,
                space_id: curSpaceId,
                source_persona_id: persona.id,
                destination_space_id: destinationSpaceId,
              };
              await createPersona(defaultOrgId, personaRequest);
              if (curSpaceId === destinationSpaceId) {
                void queryClient.invalidateQueries({
                  queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId, curSpaceId],
                });
              }
            },
            content: {
              name: persona.name,
              type: "Persona",
            },
          });
        },
      },
      {
        title: "Delete",
        onClick: () => {
          setDeletingPersona(persona);
        },
        disabled:
          deletingPersona?.id === persona.id ||
          persona.used_by_n_scenarios > 0 ||
          (persona.is_user_persona && persona.is_template),
        disabledTooltip: persona.used_by_n_scenarios > 0 ? "Persona is in use" : "",
      },
    ].filter(Boolean);
    return (
      <Box
        sx={{
          ml: "auto",
        }}
      >
        <IconMenu
          title="Manage File"
          hideCaret
          hideTooltip
          disableDrag
          menuItems={menuItems}
          iconButtonSx={{
            height: 24,
            width: 24,
            svg: {
              color: getDynamicColor(deletingPersona?.id === persona.id ? "dark3" : "primary"),
            },
          }}
          menuItemSx={{
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "poppins",
            px: 2,
            py: 1.5,
            color: getDynamicColor("primary"),
          }}
          minWidth={"140px"}
          paperSx={{
            borderRadius: "12px",
            minWidth: 180,
          }}
          icon={<MoreVertIcon />}
        />
      </Box>
    );
  };
  return (
    <>
      <Card
        sx={{
          borderRadius: "4px",
          border: `1px solid ${getDynamicColor("dark3")}`,
          boxShadow: "none",
          p: { xs: 2, sm: 3 },
          pb: { xs: 1.5, sm: 2.5 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={1.5}
          sx={{
            // was making the cards real funky on Safari
            display: !isSafari && "-webkit-box",
          }}
        >
          <Avatar
            alt={persona.name}
            src={persona.profile_picture_signed_url}
            key={persona.profile_picture_signed_url}
            sx={{
              height: 40,
              width: 40,
              img: { objectFit: "contain" },
            }}
          />
          <Stack
            direction="column"
            sx={{
              color: getDynamicColor("purple3"),
              flexGrow: 1,
            }}
          >
            <Typography
              onClick={() => handleEditPersona(persona)}
              sx={{
                fontWeight: 700,
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {persona.name}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 12,
              }}
            >
              {persona.job_title}
            </Typography>
          </Stack>
          {renderScenarioOptionsMenu(persona)}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box
            sx={{
              fontSize: 10,
              letterSpacing: "1px",
              color: getDynamicColor("purple3"),
              backgroundColor: getDemeanorColor(persona.demeanor),
              borderRadius: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: 1,
              py: 0.5,
              width: "fit-content",
            }}
          >
            {persona.demeanor.toUpperCase()}
          </Box>
          {persona.used_by_n_scenarios > 0 && (
            <Typography
              sx={{
                fontSize: 10,
                letterSpacing: "1px",
                fontWeight: 400,
                fontFamily: "inter",
                textAlign: "right",
              }}
            >
              USED BY {persona.used_by_n_scenarios} SCENARIOS
            </Typography>
          )}
        </Stack>
      </Card>
      <YoodliCtaModal
        open={!!deletingPersona}
        ctaBody={{
          title: `Are you sure you want to delete the persona "${persona.name}"?`,
          subtitle: "This action cannot be undone.",
        }}
        theme={YoodliCtaModalTheme.Danger}
        close={() => setDeletingPersona(null)}
        buttons={
          {
            primary: { text: "Delete", handler: deletePersonaMutation.mutate },
            secondary: { text: "Cancel", handler: () => setDeletingPersona(null) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
