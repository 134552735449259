// Components
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

export enum DataRedactionSelectOption {
  SelectDays = "SelectDays",
  Other = "Other",
}

type DataRedactionSelectProps = {
  selectedDataRedactionOption: DataRedactionSelectOption;
  setSelectedDataRedactionOption: (option: DataRedactionSelectOption) => void;
  dataRedactionDays: number;
  setDataRedactionDays: (days: number) => void;
  otherLabel: string;
};

export const DataRedactionSelect = ({
  selectedDataRedactionOption,
  setSelectedDataRedactionOption,
  dataRedactionDays,
  setDataRedactionDays,
  otherLabel,
}: DataRedactionSelectProps): JSX.Element => {
  return (
    <RadioGroup
      row
      value={selectedDataRedactionOption}
      onChange={(e) => {
        setDataRedactionDays(
          e.target.value === DataRedactionSelectOption.SelectDays ? 1 : undefined,
        );

        setSelectedDataRedactionOption(e.target.value as DataRedactionSelectOption);
      }}
    >
      <FormControlLabel
        value={DataRedactionSelectOption.SelectDays}
        control={<Radio />}
        label={
          <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
            <FormControl sx={{ mb: "20px" }}>
              <FormHelperText sx={{ height: "20px", mx: 0 }}>Every</FormHelperText>
              <YoodliTextfield
                value={
                  selectedDataRedactionOption === DataRedactionSelectOption.SelectDays
                    ? dataRedactionDays
                    : ""
                }
                disabled={selectedDataRedactionOption !== DataRedactionSelectOption.SelectDays}
                onChange={(e) => setDataRedactionDays(Number(e.target.value))}
                type="number"
                inputProps={{
                  min: "1",
                  max: "365",
                }}
                InputProps={{
                  sx: {
                    width: 60,
                    height: 40,
                  },
                }}
              />
            </FormControl>
            <Typography>days</Typography>
          </Stack>
        }
      />
      <FormControlLabel
        value={DataRedactionSelectOption.Other}
        control={<Radio />}
        label={otherLabel}
      />
    </RadioGroup>
  );
};
