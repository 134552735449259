// Components
import {
  HomeOutlined as HomeIcon,
  Addchart as DashboardIcon,
  SettingsOutlined as SettingsIcon,
  VideoLibraryOutlined as VideoLibraryOutlinedIcon,
} from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { SHARED } from "components/VideoJournal/VideoJournalUtils";

// Assets
import { ReactComponent as BuilderIcon } from "images/icons/icon-builder.svg";
import { ReactComponent as ContentIcon } from "images/icons/icon-content.svg";
import { ReactComponent as HubsIcon } from "images/icons/icon-hubs.svg";
import { ReactComponent as MembersIcon } from "images/icons/icon-members.svg";
import { ReactComponent as OverviewIcon } from "images/icons/icon-overview.svg";
import { ReactComponent as ProgramsIcon } from "images/icons/icon-programs.svg";

// Utils
import { WebServerInternalPath } from "./paths";
import { OrgSettingsQueryParams, VideoLibraryQueryParams } from "lib-fullstack/utils/queryParams";
import { EffectiveRole, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";

enum NavItemLabel {
  HOME = "Home",
  OVERVIEW = "Overview",
  MEMBERS = "Members",
  DASHBOARD = "Dashboard",
  HUBS = "Groups",
  SHARED_WITH_ME = "Shared with me",
  CONTENT = "Content",
  MY_YOODLIS = "My Yoodlis",
  MY_RECORDINGS = "My Recordings",
  MY_LEARNING = "My Learning",
  BUILDER = "Builder",
  ORG_SETTINGS = "Org settings",
  EXERCISES = "Exercises",
  PROGRAMS = "Programs",
}

type NavItem = {
  text: NavItemLabel;
  icon: React.ReactElement;
  path: string;
  dataTestId: string;
  pageTitle?: string;
  tooltip?: string;
  iconOverrideSx?: SxProps;
};

type NavRole =
  | EffectiveRole.ORG_ADMIN
  | EffectiveRole.SPACE_ADMIN
  | EffectiveRole.HUB_ADMIN
  | EffectiveRole.HUB_MEMBER
  | "none";

export const hideTmiPaths = [WebServerInternalPath.BUILDER];

export const navItems: Record<NavRole, NavItem[]> = {
  [EffectiveRole.ORG_ADMIN]: [
    {
      text: NavItemLabel.OVERVIEW,
      icon: <OverviewIcon />,
      path: WebServerInternalPath.ORG_OVERVIEW,
      dataTestId: UITestId.NavItemOrgOverview,
    },
    {
      text: NavItemLabel.CONTENT,
      icon: <ContentIcon />,
      path: `${WebServerInternalPath.ORG_CONTENT}?${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.CUSTOMIZE_PRACTICE}`,
      dataTestId: UITestId.NavItemOrgContent,
    },
    {
      text: NavItemLabel.MEMBERS,
      icon: <MembersIcon />,
      path: WebServerInternalPath.ORG_MEMBERS,
      dataTestId: UITestId.NavItemOrgMembers,
    },
    {
      text: NavItemLabel.HUBS,
      icon: <HubsIcon />,
      path: WebServerInternalPath.ORG_GROUPS,
      dataTestId: UITestId.NavItemOrgGroups,
    },
    {
      text: NavItemLabel.SHARED_WITH_ME,
      icon: <VideoLibraryOutlinedIcon />,
      path: `${WebServerInternalPath.LIBRARY}?${VideoLibraryQueryParams.TAB}=${SHARED}&${VideoLibraryQueryParams.PAGE}=1`,
      dataTestId: UITestId.NavItemSharedWithMe,
    },
    {
      text: NavItemLabel.ORG_SETTINGS,
      icon: <SettingsIcon />,
      path: WebServerInternalPath.ORG_SETTINGS,
      dataTestId: UITestId.NavItemOrgSettings,
    },
  ],
  [EffectiveRole.SPACE_ADMIN]: [
    {
      text: NavItemLabel.CONTENT,
      icon: <ContentIcon />,
      path: `${WebServerInternalPath.ORG_CONTENT}?${OrgSettingsQueryParams.TAB}=${OrgSettingsTabs.CUSTOMIZE_PRACTICE}`,
      dataTestId: UITestId.NavItemOrgContent,
    },
    {
      text: NavItemLabel.HUBS,
      icon: <HubsIcon />,
      path: WebServerInternalPath.ORG_GROUPS,
      dataTestId: UITestId.NavItemOrgGroups,
    },
    {
      text: NavItemLabel.SHARED_WITH_ME,
      icon: <VideoLibraryOutlinedIcon />,
      path: `${WebServerInternalPath.LIBRARY}?${VideoLibraryQueryParams.TAB}=${SHARED}`,
      dataTestId: UITestId.NavItemSharedWithMe,
    },
  ],
  [EffectiveRole.HUB_ADMIN]: [
    {
      text: NavItemLabel.HUBS,
      icon: <HubsIcon />,
      path: WebServerInternalPath.ORG_GROUPS,
      dataTestId: UITestId.NavItemOrgGroups,
    },
    {
      text: NavItemLabel.SHARED_WITH_ME,
      icon: <VideoLibraryOutlinedIcon />,
      path: `${WebServerInternalPath.LIBRARY}?${VideoLibraryQueryParams.TAB}=${SHARED}`,
      dataTestId: UITestId.NavItemSharedWithMe,
    },
  ],
  [EffectiveRole.HUB_MEMBER]: [
    {
      text: NavItemLabel.HOME,
      icon: <HomeIcon />,
      path: WebServerExternalPath.HOME_LOGGED_IN,
      dataTestId: UITestId.NavItemMemberHome,
    },
    {
      text: NavItemLabel.BUILDER,
      icon: <BuilderIcon />,
      path: WebServerInternalPath.BUILDER,
      dataTestId: UITestId.NavItemMemberBuilder,
      iconOverrideSx: {
        svg: {
          mx: "auto",
          path: {
            fill: "transparent",
          },
        },
      },
    },
    {
      text: NavItemLabel.DASHBOARD,
      icon: <DashboardIcon />,
      path: WebServerExternalPath.DASHBOARD,
      dataTestId: UITestId.NavItemMemberDashboard,
    },
    {
      text: NavItemLabel.MY_RECORDINGS,
      icon: <VideoLibraryOutlinedIcon />,
      path: WebServerInternalPath.LIBRARY,
      dataTestId: UITestId.NavItemMemberLibrary,
    },
    {
      text: NavItemLabel.MY_LEARNING,
      icon: <ProgramsIcon />,
      path: WebServerExternalPath.MY_LEARNING,
      dataTestId: UITestId.NavItemMemberLearning,
    },
  ],
  ["none"]: [
    {
      text: NavItemLabel.HOME,
      icon: <HomeIcon />,
      path: WebServerExternalPath.HOME_LOGGED_IN,
      dataTestId: UITestId.NavItemHome,
    },
    {
      text: NavItemLabel.BUILDER,
      icon: <BuilderIcon />,
      path: WebServerInternalPath.BUILDER,
      dataTestId: UITestId.NavItemBuilder,
      iconOverrideSx: {
        svg: {
          mx: "auto",
          path: {
            fill: "transparent",
          },
        },
      },
    },
    {
      text: NavItemLabel.DASHBOARD,
      icon: <DashboardIcon />,
      path: WebServerExternalPath.DASHBOARD,
      dataTestId: UITestId.NavItemDashboard,
    },
    {
      text: NavItemLabel.MY_YOODLIS,
      icon: <VideoLibraryOutlinedIcon />,
      path: WebServerInternalPath.LIBRARY,
      dataTestId: UITestId.NavItemLibrary,
    },
    {
      text: NavItemLabel.MY_LEARNING,
      icon: <ProgramsIcon />,
      path: WebServerExternalPath.MY_LEARNING,
      dataTestId: UITestId.NavItemLearning,
    },
  ],
};

export const isValidUrl = (url: string): boolean => {
  const urlRegex =
    /^(https?:\/\/)?((([a-zA-Z0-9$_.+!*'(),;?&=-]|%[0-9a-fA-F]{2})+:)*([a-zA-Z0-9$_.+!*'(),;?&=-]|%[0-9a-fA-F]{2})+@)?(((\d{1,3}\.){3}\d{1,3})|((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})))(:\d+)?(\/([a-zA-Z0-9$_.+!*'(),;:@&=-]|%[0-9a-fA-F]{2})*)*(\?([a-zA-Z0-9$_.+!*'(),;:@&=-]|%[0-9a-fA-F]{2})*)?(#([a-zA-Z0-9$_.+!*'(),;:@&=-]|%[0-9a-fA-F]{2})*)?$/;
  return urlRegex.test(url);
};
