import React from "react";

// Components
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  WarningAmberRounded as WarningAmberRoundedIcon,
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import PdfExtractor from "components/PdfExtractor";
import AnimatedEllipsis from "lib-frontend/components/AnimatedEllipsis";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalState,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliTable } from "lib-frontend/components/YoodliComponents/YoodliTable";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import videoLightbox from "ui/VideoLightbox";

// Utils
import { UploadingFile } from "../OrgFileLibrary/OrgFileLibrary";
import { BulkActionDeleteChip } from "./BulkActionDeleteChip";
import { AddContentModal } from "./SelfServeCoachBotWizard/CoachBotUploadContent/AddContentModal";
import { MAX_FILES } from "./SelfServeCoachBotWizard/CoachBotUploadContent/CoachBotUploadContent";
import { FileUploadProgress } from "./SelfServeCoachBotWizard/CoachBotUploadContent/FileUploadProgress";
import { useMutation } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { useCoachBotContentQuery } from "hooks/useCoachBotContentQuery";
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteCoachBotContentV2 } from "lib-frontend/modules/axiosOrgCoachBot";
import { deleteOrgFile, patchOrgFile } from "lib-frontend/modules/axiosOrgFiles";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getTimeDifference } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { CoachBotFileTableRow, TableRow } from "lib-frontend/utils/sharedTableUtils";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { BotContentV2 } from "lib-fullstack/api/coachBotApiTypes";
import { AnalyticProcessingState, OrgFileState } from "lib-fullstack/utils/enums";
import { OrgCoachbotType } from "lib-fullstack/utils/productAnalyticEvents";
import { downloadFile, isPreviewableFile } from "utils/orgContentUtils";
import { truncateTextMiddle } from "utils/Utilities";
import { useAddContentToCoachbot } from "hooks/useAddContentToCoachbot";

enum CoachBotContentSortOptions {
  NameAsc = "name",
  NameDesc = "-name",
  LastUpdatedAsc = "last_updated",
  LastUpdatedDesc = "-last_updated",
}

type CoachBotContentProps = {
  botId: string;
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  handleCancelFileUpload: (contentId: string) => void;
  coachBotType?: OrgCoachbotType;
};
export const CoachBotContent = ({
  botId,
  uploadingFiles,
  handleUploadContent,
  handleCancelFileUpload,
  coachBotType,
}: CoachBotContentProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const [editingFileId, setEditingFileId] = React.useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = React.useState<string>("");
  const [newFileName, setNewFileName] = React.useState<string>("");
  const [filesToDelete, setFilesToDelete] = React.useState<string[]>([]);
  const [bulkActionFiles, setBulkActionFiles] = React.useState<string[]>([]);
  const [sortBy, setSortBy] = React.useState<string>(CoachBotContentSortOptions.LastUpdatedDesc);
  const [showAddContentModal, setShowAddContentModal] = React.useState<boolean>(false);

  const columnHelper = createColumnHelper<CoachBotFileTableRow>();

  const { refetchOrgFilesQuery } = useOrgFilesQuery();
  const { coachBotContentQuery, refetchCoachBotContentQuery } = useCoachBotContentQuery(botId);

  const tableData: CoachBotFileTableRow[] = React.useMemo(() => {
    // Sort files based on filter
    const rows = (coachBotContentQuery?.data?.files ?? []).sort((a, b) => {
      if (sortBy === CoachBotContentSortOptions.NameAsc) {
        return a.name.localeCompare(b.name);
      } else if (sortBy === CoachBotContentSortOptions.NameDesc) {
        return b.name.localeCompare(a.name);
      } else if (sortBy === CoachBotContentSortOptions.LastUpdatedAsc) {
        return a.updated_at.localeCompare(b.updated_at);
      } else if (sortBy === CoachBotContentSortOptions.LastUpdatedDesc) {
        return b.updated_at.localeCompare(a.updated_at);
      }
      return 0;
    });

    return rows.map((file) => {
      const fileUploadState = uploadingFiles.find((f) => f.id === file.id);
      return {
        id: file.id,
        file_id: file.file_id,
        name: file.name,
        type: file.mime_type,
        last_updated: file.updated_at,
        signed_url: file.signed_url,
        processing_status: file.status,
        file_processing_status: file.file_state,
        upload_state: fileUploadState?.state,
        upload_pct: fileUploadState?.progress,
      };
    });
  }, [coachBotContentQuery?.data?.files, uploadingFiles, sortBy]);

  // set refs so we can access data inside the table without remounting
  const tableDataRef = React.useRef(tableData);
  React.useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  const removeContentMutation = useMutation({
    mutationFn: () =>
      Promise.all(
        filesToDelete.map((contentId) =>
          deleteCoachBotContentV2(defaultOrgId, botId, contentId, { space_id: curSpaceId }),
        ),
      ),
    onSuccess: () => {
      setBulkActionFiles(bulkActionFiles.filter((fileId) => !filesToDelete.includes(fileId)));
      setFilesToDelete([]);
      return refetchCoachBotContentQuery();
    },
  });

  const renameContentMutation = useMutation({
    mutationFn: (fileId: string) =>
      patchOrgFile(defaultOrgId, fileId, { space_id: curSpaceId, name: newFileName }),
    onSuccess: () => {
      setEditingFileId(null);
      return refetchCoachBotContentQuery();
    },
  });

  const cancelFileUploadMutation = useMutation({
    mutationFn: (content: BotContentV2) =>
      deleteCoachBotContentV2(defaultOrgId, botId, content.id, { space_id: curSpaceId }),
    onMutate: (content) => {
      handleCancelFileUpload(content.file_id);
      Instrumentation.logOrgCoachbotUpdated(defaultOrgId, coachBotType);
    },
    onSuccess: async (_, content) => {
      await deleteOrgFile(defaultOrgId, content.file_id, { space_id: curSpaceId });
      return Promise.all([refetchOrgFilesQuery(), refetchCoachBotContentQuery()]);
    },
  });

  const { handleAddContentToCoachbot } = useAddContentToCoachbot({ botId });

  const handleResetViewFile = () => {
    setPdfUrl("");
    setLightboxURL("");
  };

  const renderFileNameCell = (info) => {
    if (editingFileId === info.row.original.id) {
      return (
        <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
          <YoodliTextfield
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
            autoFocus
            InputProps={{
              sx: {
                height: 36,
                width: { xs: 175, lg: 250 },
              },
            }}
            sx={{
              width: { xs: 175, lg: 250 },
            }}
          />
          <IconButton
            onClick={() => renameContentMutation.mutate(info.row.original.file_id)}
            sx={{ color: getDynamicColor("primary") }}
          >
            <CheckIcon />
          </IconButton>
        </Stack>
      );
    }
    return (
      <Stack gap="6px">
        <Stack
          gap={1}
          direction="row"
          onClick={() =>
            isPreviewableFile(info.row.original.type) && handleViewFile(info.row.original.id)
          }
          sx={{
            alignItems: "center",
            color: getDynamicColor(
              isPreviewableFile(info.row.original.type) ? "primary" : "purple3",
            ),
            cursor: isPreviewableFile(info.row.original.type) ? "pointer" : "default",
          }}
        >
          <UploadFileIcon sx={{ width: 20, height: 20 }} />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 600,
              maxWidth: { xs: 70, sm: 175, lg: 250 },
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {truncateTextMiddle(
              info.getValue(),
              isMediumScreen ? 20 : 25,
              isMediumScreen ? 20 : 17,
            )}
          </Typography>
        </Stack>
        {isMediumScreen && (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "10px",
              fontWeight: 400,
              color: getDynamicColor("dark5"),
            }}
          >
            Updated: {getTimeDifference(info.row.original.last_updated)}
          </Typography>
        )}
      </Stack>
    );
  };

  const handleViewFile = (fileId: string) => {
    const file = tableDataRef.current.find((x) => x.id === fileId);
    if (file) {
      if (file.type?.includes("video") || file.type?.includes("audio")) {
        setLightboxURL(file.signed_url);
      } else if (file.type.includes("pdf")) {
        setPdfUrl(file.signed_url);
      } else {
        console.error(`Unsupported file type for viewing: ${file.type}`);
        return;
      }
    } else {
      console.error(`Could not find file with id: ${fileId}`);
      return;
    }
  };

  const handleSelectAllTableItems = () => {
    if (bulkActionFiles.length > 0) {
      setBulkActionFiles([]);
    } else {
      setBulkActionFiles(tableData.map((row) => row.id));
    }
  };

  const handleSelectTableItem = (rowId: string) => {
    setBulkActionFiles((oldBulkActionFiles) => {
      if (oldBulkActionFiles.includes(rowId)) {
        return oldBulkActionFiles.filter((fileId) => fileId !== rowId);
      } else {
        return [...oldBulkActionFiles, rowId];
      }
    });
  };

  const handleSetEditingFileId = (fileId: string) => {
    // if this is called while already editing, save the current file name
    if (editingFileId) {
      setEditingFileId(null);
      renameContentMutation.mutate(editingFileId);
    } else {
      setEditingFileId(fileId);
      setNewFileName(tableDataRef.current.find((x) => x.id === fileId)?.name ?? "");
    }
  };

  const cancelFileUpload = (fileId: string) => {
    const content = coachBotContentQuery?.data?.files.find((f) => f.file_id === fileId);
    cancelFileUploadMutation.mutate(content);
  };

  const renderStatusCell = (info) => {
    if (info.row.original.uploadStatus) {
      // render the loader here showing the GCS upload progress
      return (
        <Box
          sx={{
            minWidth: "200%",
          }}
        >
          <FileUploadProgress
            progress={info.row.original.uploadProgress}
            status={info.row.original.uploadStatus}
            handleRemoveFile={async (fileId) => cancelFileUpload(fileId)}
            fileId={info.row.original.id}
            editingFileId={editingFileId}
            handleSetEditingFileId={handleSetEditingFileId}
            // hide edit and remove form this context since the user already has the
            // dropdown menu to do these actions, and the buttons are removed when content is re-fetched
            hideEdit
            hideRemove
          />
        </Box>
      );
    } else if (
      info.row.original.processing_status === AnalyticProcessingState.ERROR ||
      info.row.original.file_processing_status === OrgFileState.Error
    ) {
      return (
        <WarningAmberRoundedIcon
          sx={{
            color: getDynamicColor("redError"),
            height: 20,
            width: 20,
          }}
        />
      );
    } else if (
      info.row.original.processing_status === AnalyticProcessingState.PROCESSING ||
      info.row.original.processing_status === AnalyticProcessingState.PENDING
    ) {
      return (
        <Stack direction="row" alignItems="flex-end">
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontFamily: "poppins",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Processing
          </Typography>
          <AnimatedEllipsis size={2} top={-6} marginLeft={6} />
        </Stack>
      );
    } else if (info.row.original.processing_status === AnalyticProcessingState.FINISHED) {
      return (
        <CheckCircleRoundedIcon
          sx={{
            color: getDynamicColor("greenSuccess"),
            height: 20,
            width: 20,
          }}
        />
      );
    }
  };

  const renderActionsCell = (info) => {
    // // if the row has an uploadStatus tht means it's a newlyUploaded file, and i shouldnt show the actions until it completes
    // // (besides the cancel button, which is always available for newly uploaded files)
    // if (info.row.original.uploadStatus) {
    //   return null;
    // }

    const menuItems = [
      {
        title: (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Remove
          </Typography>
        ),
        type: YoodliMenuItemType.Primary,
        onClick: () => {
          setFilesToDelete([info.getValue()]);
        },
      },
      {
        title: (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Preview
          </Typography>
        ),
        type: YoodliMenuItemType.Primary,
        onClick: () => handleViewFile(info.getValue()),
        disabled: !isPreviewableFile(info.row.original.type),
      },
      {
        title: (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Rename
          </Typography>
        ),
        type: YoodliMenuItemType.Primary,
        onClick: () => {
          setEditingFileId(info.row.original.id);
          setNewFileName(info.row.original.name);
        },
      },
      {
        title: (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Download
          </Typography>
        ),
        type: YoodliMenuItemType.Primary,
        onClick: () => downloadFile(info.row.original.signed_url),
      },
    ];

    return (
      <YoodliMenu
        type={YoodliMenuButtonType.Icon}
        icon={<MoreVertIcon />}
        buttonSx={{ border: "none", color: getDynamicColor("dark4") }}
        menuDisabled={!!editingFileId}
        menuItems={menuItems}
      />
    );
  };

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        header: () => "File name",
        cell: renderFileNameCell,
      }),
      isMediumScreen
        ? null
        : columnHelper.accessor("last_updated", {
            id: "last_updated",
            header: () => "Last updated",
            cell: (info) => (
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontFamily: "poppins",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {getTimeDifference(info.getValue())}
              </Typography>
            ),
          }),
      columnHelper.accessor("processing_status", {
        id: "status",
        header: () => "Status",
        cell: renderStatusCell,
      }),
      columnHelper.accessor("id", {
        id: "actions",
        header: () => <></>,
        cell: renderActionsCell,
      }),
    ].filter(Boolean);
  }, [editingFileId, newFileName, isMediumScreen]);
  const { lightbox, setLightboxURL } = videoLightbox("");

  const renderViewFile = () => {
    return (
      <>
        {lightbox}
        <PdfExtractor pdfUrl={pdfUrl} useDialog onDialogClose={handleResetViewFile} />
      </>
    );
  };

  const table = useReactTable({
    data: tableData,
    columns: columns.filter(Boolean),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 2,
        }}
      >
        <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              fontWeight: 700,
              fontFamily: "poppins",
            }}
          >
            Content
          </Typography>
          <BulkActionDeleteChip
            bulkActionItems={bulkActionFiles}
            clearBulkActionItems={() => setBulkActionFiles([])}
            handleDelete={() => setFilesToDelete(bulkActionFiles)}
            disableDeleteAction={!!editingFileId}
          />
        </Stack>
        <Button
          disabled={coachBotContentQuery.data?.files.length >= MAX_FILES}
          variant="outlined"
          sx={{
            width: 140,
            py: 0.5,
            fontSize: 14,
          }}
          onClick={() => setShowAddContentModal(true)}
        >
          Add files
        </Button>
      </Stack>
      <YoodliTable
        table={table as Table<TableRow>}
        numRows={tableData.length}
        emptyState={
          <Typography
            sx={{
              p: 2,
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 600,
              color: getDynamicColor("dark5"),
            }}
          >
            No content found
          </Typography>
        }
        dataRowSxOverride={{
          height: { xs: 75, lg: 55 },
        }}
        sortProps={{
          sortAvailableColumns: ["name", "last_updated"],
          setSortFilter: setSortBy,
        }}
        bulkActionProps={{
          showBulkAction: true,
          allChecked: bulkActionFiles.length === tableData.length,
          someChecked: bulkActionFiles.length > 0,
          onHeaderChecked: handleSelectAllTableItems,
          handleRowItemChecked: handleSelectTableItem,
          isRowItemChecked: (row) => bulkActionFiles.includes(row),
        }}
      />
      {renderViewFile()}
      <YoodliCtaModal
        open={!!filesToDelete.length}
        ctaBody={{
          title: `Are you sure you want to remove ${
            filesToDelete.length === 1
              ? tableData.find((file) => file.id === filesToDelete[0]).name
              : "the selected files"
          }?`,
        }}
        theme={YoodliCtaModalTheme.Danger}
        state={YoodliCtaModalState.Cta}
        close={() => setFilesToDelete([])}
        buttons={
          {
            primary: { text: "Delete", handler: removeContentMutation.mutate },
            secondary: { text: "Cancel", handler: () => setFilesToDelete([]) },
          } as CtaButtonHandlers
        }
      />
      <AddContentModal
        uploadingFiles={uploadingFiles}
        handleUploadContent={handleUploadContent}
        amtOfFilesLeft={MAX_FILES - (coachBotContentQuery?.data?.files?.length ?? 0)}
        handleCancelFileUpload={async (fileId) => cancelFileUpload(fileId)}
        showAddContentModal={showAddContentModal}
        setShowAddContentModal={setShowAddContentModal}
        handleAddNewContent={handleAddContentToCoachbot}
        content={coachBotContentQuery?.data?.files?.map((file) => ({
          id: file.file_id,
          name: file.name,
        }))}
      />
    </Box>
  );
};
