/**
 * Functions to handle speech sessions via server API.
 * Forwarding to direct database handlers during pricing migration.
 *
 * This function set does not handle updating Speech object at this time.
 * It would be required when we block direct access to database
 * in order to enhance the security to prevent fraudrant speech creation.
 */
import retry from "async-retry";
import { db } from "lib-fullstack";

// Utils
import { InitiateAsyncTranscription, createSpeech } from "lib-frontend/modules/AxiosInstance";
import { updateSpeechStates } from "lib-frontend/modules/AxiosInstance";
import * as axiosInstance from "lib-frontend/modules/AxiosInstance";
import { getAppVersion, getClientEnvConfig } from "lib-fullstack/client_env";
import { UploadState } from "lib-fullstack/db";
import { Language, RecordingSupportedLanguage } from "lib-fullstack/utils/enums";
import { getWebServerExternalUrl, WebServerExternalPath } from "lib-fullstack/utils/paths";

/**
 * Create a new speech for upload
 * @param fileName File name to be uploaded.
 * @param fileType MIME file type.
 * @param appVersion Application version.
 * @param shouldDiarize Whether to diarize the speech.
 * @param scenarioPath Optional scenario path to apply a scenario to an upload.
 * @returns ref to speech if succeed. null if failed due to limit of speeches. Throws on other errors.
 */
export const createSpeechForUpload = async (
  fileName: string,
  fileType: string,
  shouldDiarize: boolean,
  scenarioPath?: string,
  language?: RecordingSupportedLanguage,
): Promise<string> => {
  let audioOnly = false;
  if (fileType.includes("audio")) {
    audioOnly = true;
  }

  const recordingResult = await createSpeech({
    recordedByVersion: getAppVersion(),
    name: fileName,
    type: "UPLOADED",
    audioOnly: audioOnly,
    shouldDiarize: shouldDiarize,
    scenarioPath: scenarioPath,
    language: language ?? Language.English,
  });

  if (!recordingResult) {
    throw new Error("Speech creation for upload blocked - likely paywall for upgrade");
  }

  console.log(`Upload File - Created a new speech object: ${recordingResult.path}`);

  return recordingResult.id;
};

/**
 * Creates a new speech object by making the POST /speech API
 */
export async function reportMediaUpload(documentId: string, gsUrl: string): Promise<void> {
  // This is in turn set the lifecycleState as created and make it visible.
  await retry(
    async () => {
      await updateSpeechStates(documentId, {
        upload_state: UploadState.UPLOADED,
        gcs_uploaded: gsUrl,
      });
    },
    {
      retries: 3,
      // This parameter means waiting for 1 sec before the first retry, not actually a timeout of 5 seconds
      minTimeout: 1000,
      onRetry: async (error) => {
        console.warn(`updateSpeechStates(uploadState=UPLOADED) failed, retrying`, error);
      },
    },
  );

  // Once a record of the recording has been made, now inform the server to kick off transcription event
  await InitiateAsyncTranscription(documentId);
}

/**
 * Delete a speech in the DB
 */
export const deleteSpeech = async (
  speechRef: db.Ref<db.Speech>,
  reason: db.DeletedReason,
): Promise<void> => {
  if (!speechRef) {
    console.log("deleteSpeech: speechRef is invalid. no-op.");
  } else {
    await axiosInstance.deleteSpeech(speechRef.id, reason);
  }
};

/**
 * Construct the URL path to the speech summary page for the given speech slug
 */
export const getSpeechSummaryPath = (slug: string, returnFullPath: boolean): string => {
  let practiceLink: string = WebServerExternalPath.SHARE;
  if (returnFullPath) {
    practiceLink = getWebServerExternalUrl(
      getClientEnvConfig(),
      practiceLink as WebServerExternalPath,
    );
  }
  practiceLink += slug;
  return practiceLink;
};
