import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import {
  YoodliCtaModal,
  YoodliCtaModalTheme,
  CtaButtonHandlers,
  YoodliCtaModalState,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import YoodliHeaderTabs from "lib-frontend/components/YoodliComponents/YoodliHeaderTabs";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as StarIcon } from "images/icons/icon-star.svg";

// Utils
import { TrialEndedBanner } from "../TrialEndedBanner";
import { HubsTab, HubsTabLabels, OrgHubsSectionStatus } from "./OrgHubs";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteHubUserV2, updateHubV2 } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isHubAdmin, isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "utils/paths";
import { EffectiveRole } from "lib-fullstack/utils/enums";

export const ORG_HUBS_HEADER_HEIGHT = 125;

enum MenuAction {
  RenameHub = "rename_hub",
  LeaveHub = "leave_hub",
  DeleteHub = "delete_hub",
}

type HubsHeaderProps = {
  hubs: HubV2Response[];
  selectedHub: HubV2Response;
  setSelectedHub: (hub: HubV2Response) => void;
  selectedTab: HubsTab;
  setSelectedTab: (tab: HubsTab) => void;
  setSectionStatus: (status: OrgHubsSectionStatus) => void;
};

export const HubsHeader = ({
  hubs,
  selectedHub,
  setSelectedHub,
  selectedTab,
  setSelectedTab,
  setSectionStatus,
}: HubsHeaderProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const { notifAnchorRef } = useNotification();

  const {
    defaultOrgId,
    effectiveRole,
    adminInfo: { defaultOrg },
    isDefaultOrgOwnerAdmin,
    invalidateDefaultOrgQuery,
    orgListLoading,
  } = React.useContext(UserOrgContext);
  const qp = new URLSearchParams(location.search);
  const [name, setName] = React.useState<string>(selectedHub.name);
  const [openLeaveHubModal, setOpenLeaveHubModal] = React.useState<boolean>(false);
  const [leaveHubModalState, setLeaveHubModalState] = React.useState<YoodliCtaModalState>(
    YoodliCtaModalState.Cta,
  );
  const [openRenameHubModal, setOpenRenameHubModal] = React.useState<boolean>(false);

  const handleMenuItemClick = async (action: MenuAction) => {
    switch (action) {
      case MenuAction.RenameHub:
        setOpenRenameHubModal(true);
        break;
      case MenuAction.LeaveHub:
        setOpenLeaveHubModal(true);
        break;
      case MenuAction.DeleteHub:
        setSectionStatus(OrgHubsSectionStatus.DeleteHub);
        break;
    }
  };

  const { mutate: handleLeaveHub, isPending: leaveHubLoading } = useMutation({
    mutationFn: async () => {
      // check if this is the user's last hub
      if (defaultOrg.hubs.filter((hub) => !!hub.hub_role).length <= 1) {
        setLeaveHubModalState(YoodliCtaModalState.Error);
      } else {
        await deleteHubUserV2(defaultOrgId, selectedHub.id, "me", false);
      }
    },
    onSuccess: async () => {
      if (defaultOrg.hubs.filter((hub) => !!hub.hub_role).length <= 1) {
        return;
      }
      qp.delete(OrgHubsQueryParams.GROUP_ID);
      navigate({ search: qp.toString() }, { replace: true });
      await invalidateDefaultOrgQuery();
    },
    onError: (e) => {
      console.error("error leaving group: ", e);
    },
  });

  const { mutate: handleRenameHub, isPending: renameHubLoading } = useMutation({
    mutationFn: async () => {
      await updateHubV2(defaultOrgId, selectedHub.id, { name: name });
    },
    onSuccess: async () => {
      setOpenRenameHubModal(false);
      await invalidateDefaultOrgQuery();
    },
    onError: (e) => {
      console.error("error renaming group: ", e);
    },
  });

  const loading = leaveHubLoading || renameHubLoading || orgListLoading;

  const renderDefaultHubInfo = (label: string) => {
    const tooltipText = (
      <Stack gap={0.5} sx={{ zIndex: 99999 }}>
        <Typography sx={{ fontWeight: 600 }}>This is the default group</Typography>
        <Typography>
          If members are not assigned to a group upon joining the organization, they will
          automatically be placed in this default group. All members must be part of at least one
          group. This default group cannot be deleted.
        </Typography>
      </Stack>
    );
    return (
      <Stack direction="row" sx={{ width: "100%", alignItems: "center" }}>
        <Stack
          direction="row"
          alignItems="flex-start"
          gap={1}
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography className="label">{label}</Typography>
          <YoodliTooltip
            title={tooltipText}
            sx={{
              color: "inherit",
            }}
          >
            <StarIcon />
          </YoodliTooltip>
        </Stack>
      </Stack>
    );
  };

  const getEnabledTabs = React.useCallback(() => {
    let tabs = Object.values(HubsTab);
    if (!isDefaultOrgOwnerAdmin) {
      tabs = tabs.filter((tab) => tab !== HubsTab.Reports);
    }
    return tabs;
  }, [defaultOrg]);

  const hubOptions = React.useMemo(() => {
    return hubs
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((hub) => {
        const isDefault = hub.org_default;
        return {
          value: hub.id,
          label: hub.name,
          JSXOverride: isDefault ? renderDefaultHubInfo(hub.name) : null,
        };
      });
  }, [hubs]);

  return (
    <Stack
      sx={{
        ...defaultHeaderSx,
        minHeight: ORG_HUBS_HEADER_HEIGHT,
        justifyContent: isOrgTrialEnded(defaultOrg) ? "center" : "space-between",
        pt: isOrgTrialEnded(defaultOrg) ? 0 : 3,
        pl: { xs: 3, md: 4 },
        pr: { xs: 3, md: 5 },
      }}
      ref={notifAnchorRef}
    >
      {isOrgTrialEnded(defaultOrg) ? (
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: 600,
                color: getDynamicColor("light1"),
              }}
            >
              Organization Groups
            </Typography>
            <TrialEndedBanner />
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", md: "center" },
              rowGap: 1,
            }}
          >
            <Stack
              direction="row"
              gap={3}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <YoodliSelect
                MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
                value={selectedHub?.id}
                onChange={(e) => {
                  const hub = hubs.find((hub) => hub.id === e.target.value);
                  setSelectedHub(hub);
                  setName(hub.name);
                }}
                options={hubOptions}
                sx={{
                  background: getDynamicColor("light1"),
                  border: `3px solid ${getDynamicColor("primary")}`,
                  minWidth: { xs: "fit-content", md: 200 },
                }}
                fullWidth={isSmallScreen}
                inputProps={{
                  sx: {
                    ".selected": {
                      p: 0,
                      fontWeight: 700,
                      ".label": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      },
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                    p: 1.5,
                  },
                }}
              />
            </Stack>
            {(effectiveRole !== EffectiveRole.SPACE_ADMIN || isHubAdmin(selectedHub)) && (
              <Stack
                direction={{ xs: "column", md: "row" }}
                gap={1}
                sx={{
                  alignItems: { xs: "flex-start", md: "center" },
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Stack
                  direction="row"
                  gap={1}
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    mb: { xs: 0.5, md: 0 },
                  }}
                >
                  <YoodliMenu
                    type={YoodliMenuButtonType.Icon}
                    menuItems={[
                      {
                        title: "Rename group",
                        onClick: () => handleMenuItemClick(MenuAction.RenameHub),

                        type: YoodliMenuItemType.Default,
                      },
                      // if at least Org Admin but also not the hub owner
                      isDefaultOrgOwnerAdmin && {
                        title: "Leave group",
                        onClick: () => handleMenuItemClick(MenuAction.LeaveHub),

                        type: YoodliMenuItemType.Default,
                      },
                      // if at least Org Admin
                      isDefaultOrgOwnerAdmin &&
                        !selectedHub.org_default && {
                          title: "Delete group",
                          onClick: () => handleMenuItemClick(MenuAction.DeleteHub),
                          type: YoodliMenuItemType.Warning,
                        },
                    ].filter(Boolean)}
                    buttonSx={{
                      mx: 1,
                      color: getDynamicColor("light1"),
                      borderColor: getDynamicColor("light1"),
                    }}
                  />
                  {isDefaultOrgOwnerAdmin && defaultOrg.hubs.length < defaultOrg.hub_quota && (
                    <YoodliTooltip title="Create a new group">
                      <IconButton
                        sx={{ color: getDynamicColor("light1"), p: 0 }}
                        onClick={() => {
                          navigate(WebServerInternalPath.CREATE_GROUP, {
                            state: { from: "groups" },
                          });
                        }}
                      >
                        <AddIcon sx={{ width: "32px", height: "32px" }} />
                      </IconButton>
                    </YoodliTooltip>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
          <YoodliHeaderTabs
            selectedTab={selectedTab}
            handleTabChange={setSelectedTab}
            allTabs={getEnabledTabs()}
            tabLabels={HubsTabLabels}
            containerSx={{ px: 0 }}
          />
          <YoodliCtaModal
            hideCloseButton={true}
            ctaBody={{
              title: "Are you sure you want to leave this group?",
              subtitle:
                "If you leave, you will lose access to all content associated with this group.",
            }}
            errorBody={{
              title: "There was a problem leaving the group",
              subtitle:
                "You must be in at least one group. If you wish to leave the organization, go to Settings.",
            }}
            loading={loading}
            state={leaveHubModalState}
            setState={setLeaveHubModalState}
            open={openLeaveHubModal}
            theme={YoodliCtaModalTheme.Danger}
            close={() => setOpenLeaveHubModal(false)}
            buttons={
              {
                primary: { text: "Leave Group", handler: handleLeaveHub },
                secondary: { text: "Cancel", handler: () => setOpenLeaveHubModal(false) },
              } as CtaButtonHandlers
            }
          />
          <YoodliCtaModal
            hideCloseButton={false}
            ctaBody={{
              title: `Rename ${selectedHub.name}`,
            }}
            loading={loading}
            open={openRenameHubModal}
            theme={YoodliCtaModalTheme.Primary}
            close={() => setOpenRenameHubModal(false)}
            bodyComponent={
              <YoodliTextfield
                value={name}
                sx={{ width: "100%", maxWidth: "unset" }}
                InputProps={{ sx: { py: 0.5 } }}
                onChange={(e) => setName(e.target.value)}
                hideCharCount
                maxChars={30}
              />
            }
            buttonsComponent={
              <Stack
                sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                <Button
                  startIcon={
                    loading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: getDynamicColor("dark1"),
                        }}
                      />
                    )
                  }
                  onClick={() => handleRenameHub()}
                  disabled={loading || name.length > 30 || !name}
                  variant="contained"
                  sx={{
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    fontFamily: "poppins",
                    fontWeight: 700,
                    px: 4,
                  }}
                >
                  Save
                </Button>
                <Typography
                  sx={{ color: name.length > 30 || !name ? getDynamicColor("redError") : "" }}
                >
                  {name.length}/30 characters
                </Typography>
              </Stack>
            }
          />
        </>
      )}
    </Stack>
  );
};
