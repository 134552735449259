import React from "react";

// Components
import { RouteLeavingGuard } from "webclient/src/components/RouteLeavingGuard";
import { InviteMembers } from "../InviteMembers";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

type OrgContentPermissionsAddAdminProps = {
  handleBack: () => void;
};

export const OrgContentPermissionsAddAdmin = ({
  handleBack,
}: OrgContentPermissionsAddAdminProps): JSX.Element => {
  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const renderRouteLeavingGuard = () => {
    return (
      <RouteLeavingGuard
        title="Exit add content space admin?"
        body="Any progress you have made so far will not be saved. Are you sure you want to exit?"
        okText="Yes, I'm sure"
        when={true}
        shouldBlockNavigation={() => !routeLeavingModalVisible}
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
      />
    );
  };

  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);

  return (
    <>
      {renderRouteLeavingGuard()}
      <InviteMembers
        title={"Add content space admin"}
        subtitle={
          "Add emails separated by commas. Yoodli will send admin invitations to all new members, and directly add existing members as admin."
        }
        backCopy={null}
        handleBack={handleBack}
        showHeader={false}
        org={defaultOrg}
        selectedHubId={null}
        spaceIds={[curSpaceId]}
      />
    </>
  );
};
