import firebase from "firebase/app";
import React from "react";

// Components
import { Stack, Grid, Typography, CircularProgress } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { ScenarioInfo } from "components/Orgs/ManageContent/ManageCoachBotTab/CoachBotSettings";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { WizardSubTitle, WizardTitle } from "../../../../../Wizard/WizardTitles";
import { TEXTFIELD_WIDTH } from "../CoachBotUploadContent/CoachBotUploadContent";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { listScenarios } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { CoachBotWithScenarioInfo } from "lib-fullstack/api/coachBotApiTypes";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

type CoachBotScenariosProps = {
  coachBotList: CoachBotWithScenarioInfo[];
  selectedScenarioIds: string[];
  setSelectedScenarioIds: React.Dispatch<React.SetStateAction<string[]>>;
  orgId: string;
};

export const CoachBotScenarios = ({
  coachBotList,
  selectedScenarioIds,
  setSelectedScenarioIds,
  orgId,
}: CoachBotScenariosProps): JSX.Element => {
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const { data: scenariosData, isPending: scenariosLoading } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Scenarios, orgId, curSpaceId],
    queryFn: async () => await listScenarios(orgId, { space_id: curSpaceId }),
    enabled: !!firebase.auth().currentUser?.uid && !!orgId && !!curSpaceId,
    refetchOnWindowFocus: false,
  });

  const enabledScenarios = React.useMemo(() => {
    return (scenariosData?.contentArray as GetScenarioResponse[])
      ?.filter((scenario) => {
        return !scenario.isTemplate;
      })
      .map((scenario) => {
        return {
          scenarioId: scenario.id,
          scenarioName: scenario.title,
          coachBotName:
            coachBotList.find((bot) => bot.botId === scenario.botId)?.name ?? "None assigned",
        } as ScenarioInfo;
      });
  }, [scenariosData]);

  const renderScenarioSelector = () => {
    if (scenariosLoading) {
      return <CircularProgress />;
    }
    return (
      <YoodliMultiSelect
        isCheckboxSelect
        sx={{
          ".MuiChip-root": {
            borderRadius: "4px",
            backgroundColor: getDynamicColor("dark2"),
            color: getDynamicColor("dark5"),
            fontWeight: 500,
            fontSize: 14,
            maxWidth: 200,
            ".MuiChip-deleteIcon": {
              color: getDynamicColor("dark5"),
            },
          },
        }}
        value={selectedScenarioIds.map((id) => ({
          label: enabledScenarios.find((scen) => scen.scenarioId === id).scenarioName ?? "",
          value: id,
        }))}
        onChange={(e, vals: YoodliSelectOption[], _reason, detail) => {
          if (vals?.length === 0) {
            setSelectedScenarioIds([]);
            return;
          }
          if (selectedScenarioIds.includes(detail?.option?.value)) {
            setSelectedScenarioIds(
              selectedScenarioIds.filter((id) => id !== detail?.option?.value),
            );
          } else {
            setSelectedScenarioIds([...selectedScenarioIds, detail?.option?.value]);
          }
        }}
        placeholder="Select scenario(s)"
        disablePortal={false}
        options={(enabledScenarios ?? []).map((scen) => ({
          label: scen.scenarioName,
          value: scen.scenarioId,
          subLabel: scen.coachBotName,
          JSXOverride: (
            <Grid
              container
              spacing={2}
              sx={{
                color: selectedScenarioIds.includes(scen.scenarioId)
                  ? getDynamicColor("primary")
                  : getDynamicColor("purple3"),
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Poppins",
              }}
            >
              <Grid item xs={8}>
                {scen.scenarioName}
              </Grid>
              <Grid item xs={4} sx={{ fontSize: 12 }}>
                {scen.coachBotName ?? "None assigned"}
              </Grid>
            </Grid>
          ),
        }))}
      />
    );
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: "relative",
        px: { xs: 2, md: 0 },
        pt: { xs: 3, md: 6 },
        pb: { xs: 6, md: 10 },
      }}
      gap={2}
    >
      <Stack
        sx={{
          width: { xs: "unset", md: TEXTFIELD_WIDTH },
        }}
        gap={0.5}
      >
        <WizardTitle
          overrideSx={{
            px: { xs: 1, md: 2 },
          }}
        >
          Assign Coach Bot
        </WizardTitle>
        <WizardSubTitle
          overrideSx={{
            px: { xs: 1, md: 2 },
          }}
        >
          Choose the scenarios you want the Coach Bot to be active for members of your organization.
          You can do this later in the settings page
        </WizardSubTitle>
        <Stack sx={{ px: { xs: 1, md: 2 }, py: 3, gap: 1 }}>
          <Typography sx={{ color: getDynamicColor("purple3"), fontWeight: 600 }}>
            Assign to specific scenarios
          </Typography>
          {renderScenarioSelector()}
        </Stack>
      </Stack>
    </Stack>
  );
};
