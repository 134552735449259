// Components
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { BaseContentSpaceCard } from "./BaseContentSpaceCard";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ReactComponent as StarIcon } from "images/icons/icon-star.svg";

type ContentSpaceCardProps = {
  name: string;
  adminCount: number;
  selected: boolean;
  isDefault: boolean;
  onClick: () => void;
  additionalMenu?: JSX.Element;
  isLoading?: boolean;
};

const ContentSpaceCard = ({
  name,
  adminCount,
  selected,
  isDefault,
  onClick,
  additionalMenu,
  isLoading,
}: ContentSpaceCardProps): JSX.Element => {
  return (
    <BaseContentSpaceCard
      onClick={onClick}
      sx={{
        ...(selected && { borderColor: getDynamicColor("primary") }),
        overflow: "hidden",
      }}
      buttonSx={{
        color: "inherit",
        textAlign: "left",
        alignItems: "stretch",
        backgroundColor: selected ? getDynamicColor("light2") : "inherit",
        "&:hover": { backgroundColor: selected ? getDynamicColor("light2") : "inherit" },
      }}
    >
      <Stack direction="column" spacing={1} sx={{ p: 2, width: "100%", alignItems: "flex-start" }}>
        <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              minWidth: 0,
              flexGrow: 1,
              overflow: "hidden",
              justifyContent: "flex-start",
              mr: 1,
            }}
          >
            {isDefault && (
              <Box sx={{ flexShrink: 0, display: "flex", alignItems: "center" }}>
                <StarIcon />
              </Box>
            )}
            <Box sx={{ minWidth: 0, flexGrow: 1, overflow: "hidden" }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {name}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              justifyContent: "flex-end",
              flexShrink: 0,
              flexBasis: "auto",
              minWidth: "fit-content",
            }}
          >
            {selected && (
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontWeight: 500,
                  fontSize: 12,
                  whiteSpace: "nowrap",
                }}
              >
                Current
              </Typography>
            )}
            {additionalMenu && (
              <Box
                sx={{
                  pointerEvents: "all",
                }}
              >
                {additionalMenu}
              </Box>
            )}
            {isLoading && <CircularProgress size={20} />}
          </Stack>
        </Stack>
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontWeight: 500,
            fontSize: 12,
          }}
        >
          {`${adminCount} admin${adminCount === 1 ? "" : "s"}`}
        </Typography>
      </Stack>
    </BaseContentSpaceCard>
  );
};

export default ContentSpaceCard;
