// Components
import { styled } from "@mui/system";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

const drawerWidth = 200;

export const classes = {
  root: "root",
  icon: "icon",
  drawer: "drawer",
  appBarParent: "appBarParent",
  backgrounded: "backgrounded",
  navBarOptionsContainer: "navBarOptionsContainer",
  innerNav: "innerNav",
  bottomBar: "bottomBar",
  appBarTitle: "appBarTitle",
  menuButton: "menuButton",
  innerNavIcons: "innerNavIcons",
  toolbar: "toolbar",
  drawerPaper: "drawerPaper",
  content: "content",
  search: "search",
  searchIcon: "searchIcon",
  inputRoot: "inputRoot",
  toastErrorMessage: "toastErrorMessage",
};

export const RootStylesWrapper = styled("div")(({ theme }) => ({
  background: "white",
  color: getDynamicColor("purple3"),
  ".icon": {
    fontSize: "14px",
    fontWeight: 600,
    color: "#495057 !important",
    fontStyle: "normal",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
  },
  ".drawer": {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      left: 0,
      top: 80,
      flexShrink: 0,
    },
  },
  ".appBarParent": {
    "& #mainAppBar": {
      color: "#212529",
      background: getDynamicColor("light1"),
      position: "fixed",
    },
  },
  ".backgrounded": {
    "& #mainAppBar": {
      zIndex: -20,
    },
  },
  ".innerNav": {
    boxShadow: "0px 0px 0px 0px",
    color: "#212529",
    background: "#FFFFFF ",
  },
  ".bottomBar": {
    boxShadow: `0px -0.5px 0px ${getDynamicColor("dark3")}`,
    color: "#212529",
    background: "#123456 !important",
  },
  ".menuButton": {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
    color: "#343A40",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  ".innerNavIcons": {
    width: "20px",
    height: "20px",
    fontSize: "20px",
  },
  ".toolbar": {
    background: "white",
    minHeight: "56px !important",
  },
  ".drawerPaper": {
    width: drawerWidth,
    color: getDynamicColor("dark5"),
    background: getDynamicColor("dark1"),
    border: "0 !important",
  },
  ".content": {
    flexGrow: 1,
    padding: theme.spacing(0.5, 3),
    background: getDynamicColor("light1"),
  },
  ".search": {
    boxShadow: "0px 0px 0px 0px",
    position: "relative",
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  ".searchIcon": {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: getDynamicColor("dark5"),
  },
  ".inputRoot": {
    color: "inherit",
  },
  ".toastErrorMessage": {
    position: "absolute",
    top: "10%",
    height: "5vw",
    minWidth: "15px",
    width: "auto",
  },
}));
