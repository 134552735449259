import React from "react";

// Components
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Checkbox, Chip, Divider, IconButton, Stack, Typography } from "@mui/material";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { useOrgFilesQuery } from "hooks/useOrgFilesQuery";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { GetOrgFilesResponse } from "lib-fullstack/api/orgFileApiTypes";
import { truncateTextMiddle } from "utils/Utilities";

type AddContentModalFileLibraryTabProps = {
  selectedLibraryFiles: string[];
  setSelectedLibraryFiles: (files: string[]) => void;
  content: {
    id: string;
    name: string;
  }[];
  amtOfFilesLeft: number;
};

export const AddContentModalFileLibraryTab = ({
  selectedLibraryFiles,
  setSelectedLibraryFiles,
  content,
  amtOfFilesLeft,
}: AddContentModalFileLibraryTabProps): JSX.Element => {
  const [searchFilter, setSearchFilter] = React.useState<string>("");

  const { orgFilesQuery } = useOrgFilesQuery();

  const orgFiles = React.useMemo(() => {
    const files = (orgFilesQuery?.data as GetOrgFilesResponse)?.files ?? [];
    return files.filter((file) => file.name.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [searchFilter, orgFilesQuery?.data]);

  const handleCheckboxChange = (fileId: string) => {
    if (selectedLibraryFiles.includes(fileId)) {
      setSelectedLibraryFiles(selectedLibraryFiles.filter((id) => id !== fileId));
    } else if (selectedLibraryFiles.length < amtOfFilesLeft) {
      setSelectedLibraryFiles([...selectedLibraryFiles, fileId]);
    }
  };

  return (
    <Stack gap={4}>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: 500,
          color: getDynamicColor("purple3"),
          fontSize: "12px",
        }}
      >
        Add files from library. You can add up to: {amtOfFilesLeft} files
      </Typography>
      <Stack
        direction="row"
        gap={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {selectedLibraryFiles.length > 0 && (
          <Chip
            label={`${selectedLibraryFiles.length} selected`}
            avatar={
              <IconButton onClick={() => setSelectedLibraryFiles([])}>
                <CloseIcon sx={{ color: getDynamicColor("purple3"), width: 16, height: 16 }} />
              </IconButton>
            }
            sx={{
              color: getDynamicColor("purple3"),
              backgroundColor: getDynamicColor("secondaryLight"),
              fontWeight: 500,
              fontSize: "12px",
            }}
          />
        )}
        <YoodliSearchBar
          value={searchFilter}
          placeholder="Search Files"
          onChange={(e) => setSearchFilter(e.target.value)}
          clearSearch={() => setSearchFilter("")}
          sx={{
            width: 210,
            height: 40,
            ml: "auto",
          }}
        />
      </Stack>
      <Stack gap={3} sx={{ maxHeight: 350, overflowY: "auto" }}>
        {orgFiles.map((file) => {
          return (
            <React.Fragment key={file.id}>
              <Divider />
              <Stack
                onClick={() => {
                  if (!content.some((c) => c.id === file.id)) {
                    handleCheckboxChange(file.id);
                  }
                }}
                direction="row"
                gap={1}
                sx={{ alignItems: "center", cursor: "pointer" }}
              >
                <Checkbox
                  checked={
                    selectedLibraryFiles.includes(file.id) || content.some((c) => c.id === file.id)
                  }
                  disabled={
                    content.some((c) => c.id === file.id) ||
                    (selectedLibraryFiles.length >= amtOfFilesLeft &&
                      !selectedLibraryFiles.includes(file.id))
                  }
                />
                <UploadFileIcon sx={{ width: 20, height: 20 }} />
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontWeight: 500,
                    fontSize: "14px",
                    maxWidth: 270,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncateTextMiddle(file.name)}
                </Typography>
              </Stack>
            </React.Fragment>
          );
        })}
        <Divider />
      </Stack>
    </Stack>
  );
};
