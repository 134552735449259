import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as SharedUsersIcon } from "../../../../images/icons/users-connected-group.svg";

// Utils
import SectionOptionsMenu from "../SectionOptionsMenu";
import VideoCollection from "../VideoCollection";
import EmptyVideoView from "./EmptyVideoView";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CopyCourseRequest, CourseResponse } from "lib-fullstack/api/hubApiTypes";
import { CreateContentRequestKind } from "lib-fullstack/db";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { HubModalStatus } from "utils/Enums";

type CourseContentProps = {
  course: CourseResponse;
  setModalStatus: (modalStatus: HubModalStatus) => void;
  setSelectedCourse: (course: CourseResponse) => void;
  selectedOrgId: string;
  isHubAdmin?: boolean;
  handleCopyCourse?: (params: { copyRequest: CopyCourseRequest }) => Promise<void>;
};

export default function CourseContent({
  course,
  setModalStatus,
  setSelectedCourse,
  selectedOrgId,
  isHubAdmin,
  handleCopyCourse,
}: CourseContentProps): JSX.Element {
  const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState<HTMLElement | null>(null);
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const { copyContentInfo, curSpaceId } = React.useContext(ContentSpacesContext);
  const videos = course.videos.filter((video) =>
    [
      ContentVideoState.READY,
      isHubAdmin && ContentVideoState.PROCESSING,
      isHubAdmin && ContentVideoState.ERROR,
    ].includes(video.state as ContentVideoState),
  );

  const handleSetCourseModal = (modalStatus: HubModalStatus) => {
    setDropdownAnchorEl(null);
    setSelectedCourse(course);
    setModalStatus(modalStatus);
  };
  const menuItems = React.useMemo(() => {
    const options = [
      {
        title: "Edit course details",
        onClick: () => handleSetCourseModal(HubModalStatus.EDIT_COURSE_DETAILS),
      },
      {
        title: "Copy to",
        onClick: () => {
          setDropdownAnchorEl(null);
          copyContentInfo.handleIsCopyDrawerOpen(true);
          copyContentInfo.handleSetCopyContentRequest({
            copyHandler: async (destinationSpaceId: string) => {
              await handleCopyCourse({
                copyRequest: {
                  source_course_id: course.id,
                  space_id: curSpaceId,
                  destination_space_id: destinationSpaceId,
                  request_type: CreateContentRequestKind.Copy,
                },
              });
            },
            content: {
              name: course.title,
              type: "Course",
            },
          });
        },
      },
      {
        title: "Upload Videos",
        onClick: () => handleSetCourseModal(HubModalStatus.UPLOAD_COURSE_VIDEO),
      },
      {
        title: "Delete Course",
        onClick: () => handleSetCourseModal(HubModalStatus.DELETE_COURSE),
        sx: {
          color: `${getDynamicColor("redError")} !important`,
        },
      },
    ];
    if (videos.length > 0) {
      options.splice(2, 0, {
        title: "Manage Videos",
        onClick: () => handleSetCourseModal(HubModalStatus.EDIT_COURSE_VIDEOS),
      });
    }
    return options;
  }, [videos.length, course, handleCopyCourse, curSpaceId]);

  const getHubNameFromId = (hubId: string): string => {
    return defaultOrg.hubs.find((hub) => hub.id === hubId)?.name || hubId;
  };

  const formatAvailableHubList = (names: string[]): string => {
    let formattedString = "";
    if (names.length === 0) {
      return formattedString;
    } else if (names.length === 1) {
      formattedString = getHubNameFromId(names[0]);
    } else if (names.length === 2) {
      formattedString = `${getHubNameFromId(names[0])} and ${getHubNameFromId(names[1])}`;
    } else {
      formattedString = `${getHubNameFromId(names[0])}, ${getHubNameFromId(names[1])} and ${
        names.length - 2
      } others`;
    }
    return formattedString;
  };

  return (
    <Stack
      border={{ xs: "none", md: `1px solid ${getDynamicColor("dark3")}` }}
      borderRadius="8px"
      px={{ xs: 0, md: 3.5 }}
      py={3}
      gap={2.5}
    >
      <Stack direction="row" justifyContent="space-between" gap={4}>
        <Stack gap={1}>
          <Typography
            color={getDynamicColor("purple3")}
            fontFamily="poppins"
            fontSize="16px"
            fontWeight={600}
          >
            {course.title}
          </Typography>
          <Typography
            color={getDynamicColor("purple3")}
            fontFamily="poppins"
            fontSize="12px"
            fontWeight={500}
          >
            {course.description}
          </Typography>
        </Stack>
        {isHubAdmin && (
          <SectionOptionsMenu
            menuItems={menuItems}
            anchorEl={dropdownAnchorEl}
            setAnchorEl={setDropdownAnchorEl}
          />
        )}
        {!isHubAdmin && course.videos?.length > 0 && (
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, fontSize: 14, color: getDynamicColor("dark4") }}
          >
            {course.videos?.length} video
            {course.videos?.length > 1 ? "s" : ""}
          </Typography>
        )}
      </Stack>
      {videos.length ? (
        <VideoCollection videos={videos} selectedOrgId={selectedOrgId} courseId={course.id} />
      ) : (
        <EmptyVideoView
          handleAddVideo={() => handleSetCourseModal(HubModalStatus.UPLOAD_COURSE_VIDEO)}
          isHubAdmin={isHubAdmin}
        />
      )}
      {course.available_hubs?.length > 0 && (
        <Stack direction="row" alignItems="center" gap={1}>
          <SharedUsersIcon />
          <YoodliTooltip
            title={
              <>
                {course.available_hubs.map((hubId) => (
                  <Typography variant="body1" key={hubId}>
                    {getHubNameFromId(hubId)}
                  </Typography>
                ))}
              </>
            }
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, fontSize: 12, color: getDynamicColor("dark4") }}
            >
              Available to {formatAvailableHubList(course.available_hubs)}
            </Typography>
          </YoodliTooltip>
        </Stack>
      )}
    </Stack>
  );
}
