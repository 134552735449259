import React from "react";

// Components
import { OrgContentPermissions } from "./OrgContentPermissions";
import { OrgContentPermissionsAddAdmin } from "./OrgContentPermissionsAddAdmin";

// Utils
type OrgContentPermissionsTabProps = {
  showBackHeader: boolean;
  setShowBackHeader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OrgContentPermissionsTab = ({
  showBackHeader,
  setShowBackHeader,
}: OrgContentPermissionsTabProps): JSX.Element => {
  const [showAddAdmin, setShowAddAdmin] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (showAddAdmin) {
      setShowBackHeader(true);
    }
  }, [showAddAdmin]);

  React.useEffect(() => {
    setShowAddAdmin(showBackHeader);
  }, [showBackHeader]);

  const handleBack = () => {
    setShowAddAdmin(false);
    setShowBackHeader(false);
  };

  return showAddAdmin ? (
    <OrgContentPermissionsAddAdmin handleBack={handleBack} />
  ) : (
    <OrgContentPermissions setShowAddAdmin={setShowAddAdmin} />
  );
};
