import firebase from "firebase/app";
import React from "react";
import { ToastContainer, Slide as SlideTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import { Button, Tooltip, Box, Stack, Fade } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { GlobalBanner, GLOBAL_BANNER_HEIGHT } from "components/GlobalBanner";

// Utils
import HamburgerButton from "../../../HamburgerButton";
import SelectPracticeTypeDrawer from "../../SelectPracticeType/SelectPracticeTypeDrawer";
import { useUIState } from "contexts/UIStateContext";
import { YoodliActivityContext } from "lib-frontend/contexts/YoodliActivityContext";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
// Utilities/Functions
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { getEnabledFlag, getStringPayload } from "lib-frontend/utils/unleash";
import { UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

function StandardAppBar(props: {
  isBackground?: boolean;
  children?: React.ReactNode;
  sidebarOpen?: boolean;
  setSidebarOpen: (flag: boolean) => void;
  handleUploadSpeechClick: () => void;
}): React.ReactElement {
  const { isFocusedActivity } = React.useContext(YoodliActivityContext);
  const { orgModuleAccess } = React.useContext(UserOrgContext);
  const { isStandardAppBarVisible } = useUIState();

  // Constants
  const siteConf = getStaticFullSiteConf();
  const { currentUser } = firebase.auth();
  const globalBannerMessage =
    getEnabledFlag("app-banner-message") && getStringPayload("app-banner-message");

  // State
  const [selectPracticeTypeDrawerOpen, setSelectPracticeTypeDrawerOpen] = React.useState(false);

  // Effects
  const openPracticeTypeDrawer = new URLSearchParams(window.location.search).has(
    "selectPracticeType",
  );
  const isPracticing = window?.location?.pathname.includes(WebServerExternalPath.PRACTICE);
  const isSmallScreen = useIsSmallScreen();
  React.useEffect(() => {
    if (isSmallScreen && openPracticeTypeDrawer) {
      setSelectPracticeTypeDrawerOpen(true);
    }
  }, [openPracticeTypeDrawer, isSmallScreen]);

  // Handlers
  const handleMobilePracticeClick = () => {
    setSelectPracticeTypeDrawerOpen(!selectPracticeTypeDrawerOpen);
  };

  // Renderers
  const renderMobileScreenButtons = () => {
    if (!currentUser || isPracticing) return null;
    return (
      <Stack flexDirection={"row"} gap={1.5}>
        {!isToastmasters(siteConf) && orgModuleAccess.videoUploadEnabled && (
          <Tooltip title="Upload Speech">
            <Button
              variant="outlined"
              aria-label="Upload"
              onClick={props.handleUploadSpeechClick}
              sx={{ width: 90, borderRadius: "6px", fontSize: 14 }}
            >
              Upload
            </Button>
          </Tooltip>
        )}
        {isSmallScreen && (
          <Button
            variant="gradient"
            aria-label="Practice"
            onClick={handleMobilePracticeClick}
            sx={{ width: 90, borderRadius: "6px", fontSize: 14 }}
            data-testid={UITestId.PracticeButton}
          >
            Practice
          </Button>
        )}
      </Stack>
    );
  };

  const paddingTop = `${
    parseInt(WEBCLIENT_TOP_NAVBAR_HEIGHT, 10) + (globalBannerMessage ? GLOBAL_BANNER_HEIGHT : 0)
  }px`;

  return (
    <Fade in={isStandardAppBarVisible} unmountOnExit appear={false} timeout={200}>
      <span>
        {isSmallScreen && (
          <SelectPracticeTypeDrawer
            open={selectPracticeTypeDrawerOpen}
            setOpen={setSelectPracticeTypeDrawerOpen}
            paddingTop={paddingTop}
          />
        )}
        <AppBar
          position="fixed"
          sx={{
            color: getDynamicColor("purple3"),
            background: getDynamicColor("light1"),
            boxShadow: Y_SHADOWS.box_shadow_1,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          {globalBannerMessage && <GlobalBanner message={globalBannerMessage} />}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: WEBCLIENT_TOP_NAVBAR_HEIGHT,
              p: 2,
              justifyContent: "space-between",
              position: "relative",
              zIndex: 1202,
            }}
          >
            {!isFocusedActivity && (
              <HamburgerButton isOpen={false} onClick={() => props.setSidebarOpen(true)} />
            )}

            {renderMobileScreenButtons()}
          </Box>

          <ToastContainer limit={1} transition={SlideTransition} />
        </AppBar>
      </span>
    </Fade>
  );
}

export default StandardAppBar;
