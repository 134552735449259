import React from "react";

// Utils
import { getLiveUserDocReadonly, userDocsService } from "lib-frontend/utils/LiveUserDocs";
import { PLAN_THRESHOLD_ALMOST_HIT } from "lib-fullstack/utils/constants";
import { UsagePlanDuration, UsagePlanType } from "lib-fullstack/utils/pricingTypes";

export type PricingUsage = {
  thresholdHit: boolean;
  thresholdAlmostHit: boolean;
  usedSpeeches: number | undefined;
  usagePlanType: UsagePlanType | undefined;
  usagePlanDuration: UsagePlanDuration | undefined;
  usagePlanRenewalDate: string | undefined;
  nextUsagePlanStart: string | undefined;
  nextUsagePlanType: UsagePlanType | undefined;
  nextUsagePlanDuration: UsagePlanDuration | undefined;
  usageWindowStart: string | undefined;
  usageWindowEnd: string | undefined;
  quota: number | null;
};

export const usePricingUsage = (): PricingUsage | null => {
  // Ensure userDocs are resolved before we fetch, because we cannot access userDocs in Poodli Notifications.
  const userDocReadonly = userDocsService.liveUserDocsAreResolved()
    ? getLiveUserDocReadonly()
    : null;
  if (!userDocReadonly) {
    return null;
  }

  const thresholdHit = React.useMemo(
    () =>
      userDocReadonly.usedSpeeches >= userDocReadonly.usageQuota &&
      ![UsagePlanType.UNLIMITED, UsagePlanType.ENTERPRISE].includes(
        userDocReadonly.usagePlanType as UsagePlanType,
      ),
    [userDocReadonly.usedSpeeches, userDocReadonly.usageQuota, userDocReadonly.usagePlanType],
  );
  const thresholdAlmostHit = React.useMemo(
    () => userDocReadonly.usedSpeeches >= PLAN_THRESHOLD_ALMOST_HIT * userDocReadonly.usageQuota,
    [userDocReadonly.usedSpeeches, userDocReadonly.usageQuota],
  );

  return {
    thresholdHit,
    thresholdAlmostHit,
    usedSpeeches: userDocReadonly.usedSpeeches,
    usagePlanType: userDocReadonly.usagePlanType as UsagePlanType,
    usagePlanDuration: userDocReadonly.usagePlanDuration as UsagePlanDuration,
    usagePlanRenewalDate: userDocReadonly.usagePlanRenewalDate,
    nextUsagePlanStart: userDocReadonly.nextUsagePlanStart,
    nextUsagePlanType: userDocReadonly.nextUsagePlanType as UsagePlanType,
    nextUsagePlanDuration: userDocReadonly.nextUsagePlanDuration as UsagePlanDuration,
    usageWindowStart: userDocReadonly.usageWindowStart,
    usageWindowEnd: userDocReadonly.usageWindowEnd,
    quota: userDocReadonly.usageQuota,
  };
};
