import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { TEMPLATE_SUB_TYPE_DATA } from "components/ConvoScenarios/convoScenarioUtils";
import { OrgProgramsQueryKeys, ScenarioSubQueryKeys } from "components/Orgs/Programs/OrgPrograms";
import PricingUsageBar from "components/PracticeRecorder/PricingThreshold/PricingUsageBar";
import {
  YoodliSelect,
  YoodliSelectOption,
  YoodliSelectVariant,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import AudioUploadIcon from "images/icons/AudioUploadIcon.svg";
import VideoUploadIcon from "images/icons/VideoUploadIcon.svg";

// Utils
import uploadStyles from "./UploadModal.module.css";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { CurrentlyUploadingFilesContext } from "contexts/CurrentlyUploadingFilesContext";
import { UploadedRecordingIdsContext } from "contexts/UploadedRecordingIdsContext";
import { usePricingUsage } from "hooks";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { useShouldRenderPricingGate, useNavDrawerOffset } from "lib-frontend/hooks";
import { listScenariosMemberView } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { markOnboardingTaskCompleteAndEmitEvent } from "lib-frontend/utils/onboardingUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { UploadProgress } from "lib-frontend/utils/resumableUpload";
import { createSpeechForUpload, reportMediaUpload } from "lib-frontend/utils/SpeechHandlers";
import { uploadVideoFile } from "lib-frontend/utils/speechUploaderWrappers";
import { getEnabledFlag } from "lib-frontend/utils/unleash";
import { GetScenarioLimitedResponse } from "lib-fullstack/api/scenarioApiTypes";
import * as Constants from "lib-fullstack/utils/constants";
import { updateDailyStreak } from "lib-fullstack/utils/dailyStreak";
import {
  FullySupportedLanguages,
  Language,
  RecordingSupportedLanguage,
} from "lib-fullstack/utils/enums";
import { PricingModalCtaLocations } from "lib-fullstack/utils/productAnalyticEvents";
import { KeyActionAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { getUploadDuration, RecordingSupportedLanguageToString } from "utils/Utilities";

enum UploadState {
  Select = "select",
  Uploading = "uploading",
}

/**
 * UploadModal
 *
 * Using the React-dropzone open sourced package: https://github.com/react-dropzone/react-dropzone
 */
const UploadModal = (props: { close: () => void; open: boolean }): React.ReactElement => {
  // Hooks
  const { thresholdHit } = usePricingUsage();
  const { modalStyles } = useNavDrawerOffset();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const shouldRenderPricingGate = useShouldRenderPricingGate();
  const { uploadedRecordingIds, setUploadedRecordingIds } = React.useContext(
    UploadedRecordingIdsContext,
  );
  const { currentlyUploadingFiles, setCurrentlyUploadingFiles } = React.useContext(
    CurrentlyUploadingFilesContext,
  );
  const fileInputRef = React.useRef(null);

  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrgId, ScenarioSubQueryKeys.Member],
    queryFn: () => listScenariosMemberView(defaultOrgId),
    enabled: props.open && !!defaultOrgId,
    refetchOnWindowFocus: false,
  });

  const scenarios = scenariosQuery.data?.contentArray;

  // State
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [uploadState, setUploadState] = React.useState<UploadState>(UploadState.Select);
  const [diarize, setDiarize] = React.useState(false);
  const [applyToScenario, setApplyToScenario] = React.useState(false);
  const [selectedScenario, setSelectedScenario] = React.useState(undefined);
  const [language, setLanguage] = React.useState<RecordingSupportedLanguage>(Language.English);
  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const cx = classNames.bind(uploadStyles);
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setUploadError(null); // Clear any previous error when new files are added
    setSelectedFiles(
      acceptedFiles.map((f) => {
        return { file: f, status: "ready", percentComplete: 0 };
      }),
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*,audio/*",
  });

  const handleUploadFileClick = () => {
    // Trigger the hidden file input element when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /**
   * Upon upload completion or user cancelling the flow, close out and then reset the contents of modal.
   */
  const resetModalContents = (shouldClose: boolean) => {
    if (shouldClose) {
      setUploadError(null);
      props.close();
    }

    setSelectedFiles([]);
    setCurrentlyUploadingFiles([]);
    setUploadState(UploadState.Select);
    setDiarize(false);
    setApplyToScenario(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Update the context with of IDs of uploaded speeches
  const updateUploadedSpeechIDContext = React.useCallback((newId: string) => {
    const recordingIds = uploadedRecordingIds ? uploadedRecordingIds : [];
    recordingIds.push(newId);
    setUploadedRecordingIds(recordingIds);
    console.log(`Updated uploaded speech ID context: ${recordingIds}`);
  }, []);

  const renderScenarioValue = (scenario: GetScenarioLimitedResponse) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          "svg *": {
            fill: getDynamicColor("purple3"),
          },
          width: "100%",
        }}
      >
        {TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.icon}
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {scenario.title}
        </Typography>
      </Stack>
    );
  };

  const handleUploadClick = async () => {
    // Enforce file-size < 3GB and duration < 40m
    // TODO this is pretty bad UX as any file that breaks will abort all files

    for (const file of selectedFiles) {
      if (file.file.size > 3000000000) {
        window.alert("Please choose a file smaller than 3GB");
        return;
      } else {
        try {
          const duration = await getUploadDuration(file.file);
          if (duration > 60 * Constants.RecordingMaxLengthMinutes) {
            window.alert(
              `Please choose a file ${Constants.RecordingMaxLengthString} or less in duration.`,
            );
            return;
          }
        } catch (e) {
          console.log(
            "Issue in getting upload duration; allowing user to continue upload anyway",
            e,
          );
        }
      }
    }
    console.log(`Uploading selected files...`);

    // Initiate an upload for all selected files
    Promise.all(
      selectedFiles.map(async (selectedFile, selectedFileIdx) => {
        // TODO what if multiple files, some of which are valid lengths and others are not?
        const uploadProgressCallback = function (progress: UploadProgress): boolean {
          const percentCompleteRaw = (progress.uploadedBytes / progress.totalBytes) * 100;
          selectedFiles[selectedFileIdx] = {
            ...selectedFile,
            percentComplete: percentCompleteRaw,
          };
          setSelectedFiles([...selectedFiles]);
          setCurrentlyUploadingFiles([...selectedFiles]);
          // TODO: hook up cancel button and cancel upload by returning false
          return true;
        };
        Instrumentation.logKeyAction(KeyActionAnalyticsEvents.UPLOAD);
        const extension = selectedFile.file.path.split(".").slice(-1)[0];

        const speechId = await createSpeechForUpload(
          selectedFile.file.name,
          selectedFile.file.type,
          diarize,
          applyToScenario ? selectedScenario?.path : undefined,
          language,
        );

        props.close();

        const gsUrl = await uploadVideoFile(
          selectedFile.file,
          uploadProgressCallback,
          speechId,
          extension,
        );

        await reportMediaUpload(speechId, gsUrl);

        // Update the context with of IDs of uploaded speeches
        updateUploadedSpeechIDContext(speechId);
      }),
    )
      .then(() => {
        setUploadState(UploadState.Uploading);
        console.log(`Upload Video/Audio finished`);
        updateDailyStreak(getSiteId(), firebase.auth().currentUser.uid, false).catch((err) =>
          console.error(`Error updating daily streak: ${err}`),
        );
        resetModalContents(true);
      })
      .catch((err) => {
        console.error(`Error uploading speech: ${err}`);
        setUploadError("Error uploading, please try again!");
        resetModalContents(false);
      });

    markOnboardingTaskCompleteAndEmitEvent(db.OnboardingChecklistTask.UPLOAD_SPEECH).catch((err) =>
      console.error(`Error marking onboarding task complete: ${err}`),
    );
  };

  const handleCancelClick = () => {
    resetModalContents(true);
  };

  function renderSelectedFileStatus() {
    if (selectedFiles.length === 0) return null;
    return (
      <section className={uploadStyles.progressBarContainer} key={selectedFiles[0].file.name}>
        {uploadState === UploadState.Select &&
          selectedFiles.map((file, idx) => (
            <section key={idx} {...getRootProps()} className={cx({ showDropActive: isDragActive })}>
              <Typography
                className={uploadStyles.progressBarFileIndicator}
                sx={{ pt: idx === 0 ? 0 : 1 }}
              >
                {file.file.name}
              </Typography>
            </section>
          ))}
      </section>
    );
  }

  // We can in theory support other video formats as well, just don't want to add them to the copy without having tried them
  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={() => resetModalContents(true)}
      PaperProps={{
        sx: {
          overflow: "hidden",
          borderRadius: "12px",
          width: { xs: "100%", sm: "min(100%, 500px)", lg: "min(100%, 600px)" },
          maxWidth: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          maxHeight: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          m: { xs: 1.5, md: 3 },
          ...modalStyles,
        },
      }}
    >
      <span
        style={{
          display: shouldRenderPricingGate && thresholdHit ? "none" : "auto",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            zIndex: 2,
            top: 12,
            right: 12,
            p: 0.5,
          }}
          onClick={() => resetModalContents(true)}
        >
          <CloseIcon
            sx={{
              width: 18,
              height: 18,
            }}
          />
        </IconButton>
        <input
          {...getInputProps()}
          type="file"
          accept="video/*,audio/*,audio/x-m4a"
          ref={fileInputRef}
        />
        <Stack
          className={uploadStyles.uploadModal}
          sx={{
            position: "relative",
          }}
        >
          {uploadState === UploadState.Select && (
            <Stack gap={2}>
              <Stack gap={1}>
                <Typography
                  component="h2"
                  sx={{
                    fontFamily: "poppins",
                    fontWeight: 600,
                    fontSize: { xs: 16, md: 18 },
                    width: "100%",
                    textAlign: "center",
                    maxWidth: 350,
                    mx: "auto",
                  }}
                >
                  Upload any video or audio recording to view AI analysis per speaker
                </Typography>
                {selectedFiles.length === 0 && (
                  <Typography
                    sx={{
                      fontSize: { xs: 14, md: 16 },
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    This could be any file you've recorded live or downloaded from a video call
                    (Zoom, Meets, Gong etc.) under {Constants.RecordingMaxLengthString} and{" "}
                    {Constants.RecordingMaxSizeString}.
                  </Typography>
                )}
                {uploadError && (
                  <Typography
                    sx={{
                      color: getDynamicColor("redError"),
                      fontWeight: 600,
                      fontSize: 14,
                      mx: "auto",
                    }}
                  >
                    {uploadError}
                  </Typography>
                )}
              </Stack>
              {selectedFiles.length === 0 && (
                <Stack
                  {...getRootProps()}
                  className={cx({
                    showDropActive: isDragActive,
                  })}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ p: 6, border: `5px dashed ${getDynamicColor("dark2")}` }}
                  gap={3}
                >
                  <Box>
                    <img src={VideoUploadIcon} style={{ padding: "5px" }} alt="Video upload icon" />
                    <img src={AudioUploadIcon} style={{ padding: "5px" }} alt="Audio upload icon" />
                  </Box>
                  <Typography
                    sx={{
                      color: getDynamicColor("dark4"),
                    }}
                  >
                    Drag and drop file or
                  </Typography>
                  <Button variant="contained" onClick={handleUploadFileClick}>
                    Browse files
                  </Button>
                </Stack>
              )}

              {renderSelectedFileStatus()}

              {selectedFiles.length > 0 && (
                <Stack gap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={(_, checked) => setDiarize(checked)} checked={diarize} />
                    }
                    label={
                      <Grid container direction="row" alignItems="center" wrap="nowrap" gap={1}>
                        <Typography
                          sx={{
                            fontSize: { xs: 14, md: 16 },
                            color: getDynamicColor("purple3"),
                            fontFamily: "poppins",
                          }}
                        >
                          Process with multiple speakers
                        </Typography>
                        <YoodliTooltip
                          title="Your transcript and analytics will be separated by speaker"
                          placement="right"
                        >
                          <InfoIcon
                            sx={{
                              color: getDynamicColor("purple3"),
                            }}
                          />
                        </YoodliTooltip>
                      </Grid>
                    }
                  />

                  {scenarios?.length > 0 && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(_, checked) => setApplyToScenario(checked)}
                            checked={applyToScenario}
                          />
                        }
                        label={
                          <Grid container direction="row" alignItems="center" wrap="nowrap" gap={1}>
                            <Typography
                              sx={{
                                fontSize: { xs: 14, md: 16 },
                                color: getDynamicColor("purple3"),
                                fontFamily: "poppins",
                              }}
                            >
                              Apply upload to pitch or roleplaying scenario
                            </Typography>
                            <YoodliTooltip
                              title="Your upload will be analyzed by the rubric of the chosen scenario."
                              placement="right"
                            >
                              <InfoIcon
                                sx={{
                                  color: getDynamicColor("purple3"),
                                }}
                              />
                            </YoodliTooltip>
                          </Grid>
                        }
                      />
                      {applyToScenario && (
                        <YoodliSelect
                          displayEmpty
                          value={selectedScenario?.id || "placeholder"}
                          onChange={(e) =>
                            setSelectedScenario(scenarios.find((s) => s.id === e.target.value))
                          }
                          yoodliVariant={YoodliSelectVariant.PRIMARY}
                          renderValue={() => {
                            if (!selectedScenario) {
                              return <Typography>Select a scenario</Typography>;
                            } else {
                              return renderScenarioValue(selectedScenario);
                            }
                          }}
                          sx={{
                            ".MuiPaper-root": {
                              maxHeight: 600,
                            },
                          }}
                        >
                          {scenarios.map((scenario) => (
                            <MenuItem key={scenario.id} value={scenario.id}>
                              {renderScenarioValue(scenario)}
                            </MenuItem>
                          ))}
                        </YoodliSelect>
                      )}
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: "Poppins",
                      }}
                    >
                      Language
                    </Typography>
                    <YoodliSelect
                      value={language}
                      onChange={(e) => setLanguage(e.target.value as RecordingSupportedLanguage)}
                      sx={{ width: "70%" }}
                      options={
                        Object.keys(RecordingSupportedLanguageToString)
                          .filter(
                            (lang) =>
                              (
                                FullySupportedLanguages as readonly RecordingSupportedLanguage[]
                              ).includes(lang as RecordingSupportedLanguage) ||
                              getEnabledFlag("beta-languages"),
                          )
                          .map((lang) => {
                            return {
                              label: RecordingSupportedLanguageToString[lang],
                              value: lang,
                            };
                          }) as YoodliSelectOption[]
                      }
                    />
                  </Box>
                </Stack>
              )}

              <Stack gap={1.5}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    lineHeight: "20px",
                    width: "100%",
                  }}
                  onClick={handleUploadClick}
                  disabled={
                    thresholdHit ||
                    selectedFiles.length === 0 ||
                    (currentlyUploadingFiles && currentlyUploadingFiles.length) > 0
                  }
                >
                  Upload
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: getDynamicColor("dark4"),
                    "&:hover": {
                      backgroundColor: getDynamicColor("dark5"),
                    },
                    textTransform: "none",
                    fontSize: "14px",
                    lineHeight: "20px",
                    width: "100%",
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </span>
      {shouldRenderPricingGate && (
        <PricingUsageBar
          isWithinModal
          location={PricingModalCtaLocations.UPLOAD}
          showPricingUsageGateModal={shouldRenderPricingGate && thresholdHit}
          onModalClose={() => resetModalContents(true)}
          thresholdSx={{
            minHeight: 500,
            minWidth: "min(100%, 600px)",
            m: { xs: 2, sm: 0 },
          }}
        />
      )}
    </Dialog>
  );
};

export default UploadModal;
