import React from "react";

// Components
import { Box, Stack, Typography, CircularProgress, TablePagination } from "@mui/material";
import { TableSortServer } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortServer";
import { TextOverflowTooltip } from "lib-frontend/components/YoodliComponents/TextOverflowTooltip";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import { MemberInfo } from "./MembersTable";
import { OrgMembersQueryKeys } from "./OrgMembers";
import {
  keepPreviousData,
  useQuery as useApiQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
  PaginationState,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  actOrgInviteV2,
  deleteOrgInviteV2,
  listOrgInvitesV2,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import {
  CheckboxTableElement,
  dataRowStyles,
  defaultTableDataStyles,
  headerRowStyles,
  tableHeaderStyles,
} from "lib-frontend/utils/sharedTableUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetOrgInviteListSortOption, OrgInviteAction } from "lib-fullstack/api/orgApiTypes";
import { OrgInviteResponse } from "lib-fullstack/api/orgApiTypes";
import {
  DEFAULT_ORG_MEMBER_FETCH_COUNT,
  ORG_MEMBER_FETCH_LIMIT,
} from "lib-fullstack/utils/constants";
import { OrgRole } from "lib-fullstack/utils/enums";

type OrgInvitesTableProps = {
  searchText: string;
  setSearchText: (str: string) => void;
  recalcTopContentHeight: () => void;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: React.Dispatch<React.SetStateAction<MemberInfo[]>>;
};

enum MenuAction {
  ResendInvite = "resend_invite",
  CancelInvite = "cancel_invite",
}

export type OrgInvitesTableRow = {
  email: string;
  effective_role: string;
  hubs: string[];
  date_invited: string;
  action: null;
};

const parseOrgRole = (role?: string): string => {
  switch (role) {
    case OrgRole.OWNER:
      return "Org owner";
    case OrgRole.ADMIN:
      return "Org admin";
    default:
      return "Member";
  }
};

const parseDateString = (dateStr: string) => {
  return new Date(dateStr).toLocaleDateString("en-us", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
};

export default function OrgInvitesTable({
  searchText,
  setSearchText,
  recalcTopContentHeight,
  bulkActionUserList,
  setBulkActionUserList,
}: OrgInvitesTableProps): JSX.Element {
  const queryClient = useQueryClient();
  const {
    defaultOrgId,
    isDefaultOrgOwnerAdmin,
    invalidateDefaultOrgQuery,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const isSmallScreen = useIsSmallScreen();
  const columnHelper = createColumnHelper<OrgInvitesTableRow>();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_ORG_MEMBER_FETCH_COUNT,
  });
  const [sortFilter, setSortFilter] = React.useState<string>(
    GetOrgInviteListSortOption.EMAIL_ASC as string,
  );
  const paginationStartInd = (pagination.pageIndex * pagination.pageSize).toString();

  const anyFilterEnabled = React.useMemo(() => {
    return searchText?.length > 0;
  }, [searchText]);

  const invitesQueryResult = useApiQuery({
    queryKey: [
      OrgMembersQueryKeys.OrgInvites,
      defaultOrgId,
      {
        start: paginationStartInd,
        limit: Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString(),
        sort: searchText
          ? GetOrgInviteListSortOption.EMAIL_ASC
          : ((sortFilter as GetOrgInviteListSortOption) ?? GetOrgInviteListSortOption.EMAIL_ASC),
        prefix: searchText,
      },
    ],
    queryFn: async () =>
      listOrgInvitesV2(defaultOrgId, {
        start: paginationStartInd,
        limit: Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString(),
        sort: searchText
          ? GetOrgInviteListSortOption.EMAIL_ASC
          : ((sortFilter as GetOrgInviteListSortOption) ?? GetOrgInviteListSortOption.EMAIL_ASC),
        prefix: searchText,
      }),
    placeholderData: keepPreviousData,
  });

  const { showNotificationBanner } = useNotification();

  // redefine the invites list whenever the data changes
  const invitesList = React.useMemo(
    () => invitesQueryResult.data?.invites ?? [],
    [invitesQueryResult.data?.invites],
  );

  const bulkActionUserEmailSet = new Set(bulkActionUserList.map((user) => user.email));

  React.useEffect(() => {
    recalcTopContentHeight();
  }, [invitesList]);

  const getHubNameFromId = (hubId: string) => {
    const hubList = defaultOrg.hubs;
    const hubArr = hubList.filter((hub) => hubId === hub.id);
    if (hubArr.length === 0) {
      return hubId;
    }
    return hubArr[0].name;
  };

  const resendInviteMutation = useMutation({
    mutationFn: async (email: string) => {
      await actOrgInviteV2(defaultOrg.id, email, OrgInviteAction.RESEND);
    },
    onSuccess: (_, email: string) => {
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgInvites, defaultOrgId],
      });
      showNotificationBanner(
        <>
          Resent invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Success },
      );
    },
    onError: (e: Error, email: string) => {
      console.log("Error in resending invite: ", e);
      showNotificationBanner(
        <>
          Failed to resend invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Danger },
      );
    },
  });

  const cancelInviteMutation = useMutation({
    mutationFn: async (email: string) => {
      await deleteOrgInviteV2(defaultOrgId, email);
    },
    onSuccess: (_, email) => {
      void invalidateDefaultOrgQuery();
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgUserCounts, defaultOrgId],
      });
      return queryClient
        .invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgInvites, defaultOrgId],
        })
        .then(() => {
          showNotificationBanner(
            <>
              Cancelled invite to <strong>{email}</strong>
            </>,
            { variant: YoodliNotificationBannerVariant.Success },
          );
        });
    },
    onError: (e: Error, email) => {
      console.log("Error in cancelling invite: ", e);
      showNotificationBanner(
        <>
          Failed to cancel invite to <strong>{email}</strong>
        </>,
        { variant: YoodliNotificationBannerVariant.Danger },
      );
    },
  });

  const handleMenuItemClick = async (row: Row<OrgInvitesTableRow>, action: MenuAction) => {
    const user = invitesList.find((user) => user.email === row.original.email);
    switch (action) {
      case MenuAction.ResendInvite:
        await resendInviteMutation.mutateAsync(user.email);
        break;
      case MenuAction.CancelInvite:
        await cancelInviteMutation.mutateAsync(user.email);
        break;
    }
  };

  const rowData = React.useMemo(
    () =>
      (invitesList ?? []).map((user) => ({
        effective_role: parseOrgRole(user.effective_role),
        hubs: (user as OrgInviteResponse).hubs.map((hub) => hub.hub_id),
        email: user.email,
        date_invited: user.date_invited,
        action: null,
      })),
    [invitesList],
  );

  const allChecked = React.useMemo(() => {
    return invitesList.every((member) => bulkActionUserEmailSet.has(member.email));
  }, [rowData, bulkActionUserEmailSet]);

  const someChecked = React.useMemo(() => {
    return invitesList.some((member) => bulkActionUserEmailSet.has(member.email));
  }, [rowData, bulkActionUserEmailSet]);

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("email", {
        id: "email",
        header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Invites</Typography>,
        cell: (info) => (
          <Stack direction="row" gap={2} sx={{ alignItems: "center", pr: 0.5 }}>
            <YoodliAvatar
              name={info.getValue()}
              sx={{
                my: 2.5,
                height: 35,
                width: 35,
              }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                color: getDynamicColor("purple3"),
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: { xs: "150px", md: "300px" },
              }}
            >
              {info.getValue()}
            </Typography>
          </Stack>
        ),
      }),
      ...(!isSmallScreen
        ? [
            columnHelper.accessor("date_invited", {
              id: "date_invited",
              header: () => <>Invited on</>,
              cell: (info) => (
                <Typography sx={defaultTableDataStyles}>
                  {parseDateString(info.getValue())}
                </Typography>
              ),
            }),
            columnHelper.accessor("effective_role", {
              id: "effective_role",
              header: () => <>Role</>,
              cell: (info) => (
                <Typography sx={defaultTableDataStyles}>{info.getValue()}</Typography>
              ),
              filterFn: "arrIncludesSome",
            }),
            columnHelper.accessor("hubs", {
              id: "hubs",
              header: () => <>Groups</>,
              cell: (info) => (
                <TextOverflowTooltip
                  sx={{
                    ...defaultTableDataStyles,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "300px",
                    whiteSpace: "nowrap",
                  }}
                  title={info
                    .getValue()
                    .map((hubId) => getHubNameFromId(hubId))
                    .join(", ")}
                  textValue={info
                    .getValue()
                    .map((hubId) => getHubNameFromId(hubId))
                    .join(", ")}
                />
              ),
            }),
          ]
        : []),
      columnHelper.accessor("action", {
        id: "action",
        header: () => <div style={{ width: "36px" }} />,
        cell: (info) => (
          <YoodliMenu
            menuDisabled={bulkActionUserList.length > 0}
            type={YoodliMenuButtonType.Icon}
            menuItems={[
              {
                title: "Resend invite",
                disabled: bulkActionUserList.length > 0,
                onClick: async () => {
                  await handleMenuItemClick(info.row, MenuAction.ResendInvite);
                },
                type: YoodliMenuItemType.Default,
              },
              {
                title: "Cancel invite",
                disabled: bulkActionUserList.length > 0,
                onClick: async () => {
                  await handleMenuItemClick(info.row, MenuAction.CancelInvite);
                },
                type: YoodliMenuItemType.Default,
              },
            ]}
          />
        ),
      }),
    ],
    [invitesList, rowData, bulkActionUserList],
  );

  const filteredColumns = columns.filter(Boolean);

  const table = useReactTable({
    data: rowData,
    columns: filteredColumns,
    state: {
      globalFilter: searchText,
      pagination,
    },
    rowCount: invitesQueryResult.data?.total,
    onGlobalFilterChange: setSearchText,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0, pageSize: parseInt(event.target.value) };
    });
  };

  return (
    <Stack
      gap={1}
      sx={{
        height: "100%",
        fontFamily: "poppins",
      }}
    >
      {invitesQueryResult.isPending ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <Box
          sx={{
            minHeight: invitesList?.length > 4 ? 400 : "unset",
            overflowY: "auto",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderRadius: 2,
          }}
        >
          <table
            style={{
              width: "100%",
              boxShadow: Y_SHADOWS.dark_elevation,
              borderCollapse: "collapse",
              borderRadius: 2,
              borderSpacing: "0px",
            }}
          >
            <thead
              style={{
                backgroundColor: getDynamicColor("light1"),
                borderRadius: "8px",
                height: "40px",
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Box component="tr" key={headerGroup.id} sx={headerRowStyles}>
                  {rowData.length > 0 && isDefaultOrgOwnerAdmin && (
                    <CheckboxTableElement
                      component="th"
                      checked={allChecked}
                      indeterminate={!allChecked && someChecked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const newBulkActionUserList = [...bulkActionUserList];
                          for (const row of rowData) {
                            if (!bulkActionUserEmailSet.has(row.email)) {
                              newBulkActionUserList.push({
                                email: row.email,
                              });
                            }
                          }
                          setBulkActionUserList(newBulkActionUserList);
                        } else {
                          const newBulkActionUserList = bulkActionUserList.filter((userList) => {
                            return !rowData.map((row) => row.email).includes(userList.email);
                          });
                          setBulkActionUserList(newBulkActionUserList);
                        }
                      }}
                    />
                  )}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        minHeight: "40px",
                      }}
                    >
                      <Stack direction="row" gap={1} alignItems="center" sx={tableHeaderStyles}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {["email", "date_invited"].includes(header.id) && (
                          <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />
                        )}
                      </Stack>
                    </th>
                  ))}
                </Box>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Box component="tr" key={row.id} sx={dataRowStyles}>
                    {isDefaultOrgOwnerAdmin && (
                      <CheckboxTableElement
                        component="td"
                        checked={bulkActionUserEmailSet.has(row.original.email)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setBulkActionUserList([
                              ...bulkActionUserList,
                              {
                                email: row.original.email,
                              },
                            ]);
                          } else {
                            setBulkActionUserList(
                              bulkActionUserList.filter(
                                (user) => user.email !== row.original.email,
                              ),
                            );
                          }
                        }}
                      />
                    )}
                    {row.getVisibleCells().map((cell) => (
                      <Box component="td" key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Box>
                    ))}
                  </Box>
                );
              })}
              {table.getRowCount() === 0 && !anyFilterEnabled && (
                <tr>
                  <td colSpan={columns.length}>
                    <Stack
                      direction="column"
                      sx={{
                        mx: "auto",
                        width: "100%",
                        color: getDynamicColor("dark4"),
                        minHeight: "250px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>No pending invites</Typography>
                      <Typography sx={{ textAlign: "center" }}>
                        Invite members to join this organization using the buttons above
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      )}
      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <TablePagination
            component="div"
            sx={{ m: "auto", fontFamily: "poppins !important" }}
            count={table.getRowCount() ?? 0}
            rowsPerPageOptions={[5, 10, 20, 50]}
            page={pagination.pageIndex}
            onPageChange={(e, pageNum) => handlePageChange(pageNum)}
            rowsPerPage={pagination.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Stack>
    </Stack>
  );
}
