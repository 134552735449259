/**
 * Get the accepted file types that can be uploaded to an org
 * @returns An array of objects specifying the accepted file types
 */
export const getAcceptedFileTypes = (): {
  mime: string;
  regexp: RegExp | null;
  maxSize: number;
}[] => {
  const fileTypes = [
    { mime: "application/pdf", regexp: null, maxSize: 50 * 1024 * 1024 /* 50MB */ },
    { mime: "video/*", regexp: new RegExp("^video/.*"), maxSize: 5 * 1024 * 1024 * 1024 /* 5GB */ },
    { mime: "audio/*", regexp: new RegExp("^audio/.*"), maxSize: 5 * 1024 * 1024 * 1024 /* 5GB */ },
    {
      mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      regexp: null,
      maxSize: 50 * 1024 * 1024 /* 50MB */,
    },
    {
      mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      regexp: null,
      maxSize: 50 * 1024 * 1024 /* 50MB */,
    },
    {
      mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      regexp: null,
      maxSize: 50 * 1024 * 1024 /* 50MB */,
    },
  ];

  return fileTypes;
};

/**
 * Parse the file type to a human-readable format
 * @param fileType The file mime type
 * @returns The human-readable file type
 */
export const parseFileType = (fileType: string): string => {
  if (fileType.includes("pdf")) {
    return "PDF";
  } else if (
    fileType.includes("msword") ||
    fileType.includes("vnd.openxmlformats-officedocument.wordprocessingml.document")
  ) {
    return "Document";
  } else if (fileType.includes("video")) {
    return "Video";
  } else if (fileType.includes("audio")) {
    return "Audio";
  } else if (
    fileType.includes("spreadsheet") ||
    fileType.includes("vnd.openxmlformats-officedocument.spreadsheetml.sheet")
  ) {
    return "Spreadsheet";
  } else if (
    fileType.includes("presentation") ||
    fileType.includes("vnd.openxmlformats-officedocument.presentationml.presentation")
  ) {
    return "Presentation";
  } else {
    return "Other";
  }
};

/**
 * Calculate how much the file is over the limit in bytes
 * @param file
 * @returns How much the file is over the limit in bytes
 * @returns 0 if it's not over the limit
 */
export const getBytesOver = (file: File): number => {
  for (const { mime, regexp, maxSize } of getAcceptedFileTypes()) {
    if ((regexp && regexp.test(file.type)) || (!regexp && file.type === mime)) {
      return Math.max(file.size - maxSize, 0);
    }
  }
  // It is unexpected to reach here. Return 1 to block this file anyway.
  console.warn(`Unexpected file type: ${file.type}`);
  return 1;
};

/**
 * Download a file from a signed URL
 * @param signedUrl The signed URL to download the file from
 */
export const downloadFile = (signedUrl: string): void => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.cssText = "display: block";
  a.href = signedUrl;
  a.target = "_blank";
  a.click();
  document.body.removeChild(a);
};

/**
 * Check if the file type is previewable
 * @param fileType The file mime type
 * @returns Whether the file type is previewable
 */
export const isPreviewableFile = (fileType: string): boolean => {
  return fileType.includes("pdf") || fileType.includes("video") || fileType.includes("audio");
};
