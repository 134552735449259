// Components
import { styled } from "@mui/material/styles";
// Utils
import { DynamicColorType, getDynamicColor } from "lib-frontend/utils/Colors";

const AnimatedEllipsis = styled("div")(
  ({
    size = 4,
    top = 0,
    marginLeft = size * 3,
    color = "dark4",
    animationDuration = 0.6,
    gapSize = "large",
  }: {
    size?: number;
    top?: number;
    marginLeft?: number;
    color?: DynamicColorType;
    animationDuration?: number;
    gapSize?: "small" | "medium" | "large";
  }) => `
  position: relative;
  top: ${top}px;
  min-width: ${size}px;
  min-height: ${size}px;
  border-radius: ${size / 2}px;
  margin-left: ${marginLeft}px;
  background-color: ${getDynamicColor(color)};
  color: ${getDynamicColor(color)};
  animation: loading-dot-flashing ${animationDuration}s infinite alternate;
  animation-delay: ${animationDuration / 4}s;
  ::before,
  ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    left: -${size * (gapSize === "small" ? 1.1 : gapSize === "medium" ? 1.5 : 2)}px;
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size / 2}px;
    background-color: ${getDynamicColor(color)};
    color: ${getDynamicColor(color)};
    animation: loading-dot-flashing ${animationDuration}s infinite alternate;
    animation-delay: 0s;
  }
  ::after {
    left: ${size * (gapSize === "small" ? 1.1 : gapSize === "medium" ? 1.5 : 2)}px;
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size / 2}px;
    background-color: ${getDynamicColor("dark4")};
    color: ${getDynamicColor("dark4")};
    animation: loading-dot-flashing ${animationDuration}s infinite alternate;
    animation-delay: ${animationDuration / 2}s;
  }

  @keyframes loading-dot-flashing {
    0% {
      background-color: ${getDynamicColor(color)};
    }
    50%,
    100% {
      background-color: ${getDynamicColor(color, 0.5)};
    }
  }
`,
);

export default AnimatedEllipsis;
