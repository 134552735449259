// Utils
import { UploadProgressCallback, uploadBlobToResumableUploadUrl } from "./resumableUpload";
import { InitiateResumableUpload } from "lib-frontend/modules/AxiosInstance";
import { injectSeekMetadata } from "lib-frontend/utils/VideoUtils";
import { UploadFileType } from "lib-fullstack/api/apiTypes";

// Differentiator of instances for logging purpose.
let uploaderCounter = 0;

/**
 * Uploader to upload a blob (on memory or file) for a speech.
 * Blobs may be media (video), log, and transcript history.
 */
export class SpeechUploader {
  private logPrepend: string;
  private blob: Blob | Uint8Array;
  private speechId: string;
  private fileType: UploadFileType;
  private videoFileExtension: string | undefined;
  private uploadSessionUrl: string;
  private uploadGsUrl: string;

  /**
   * Creates an uploader instance
   * @param blob Data to upload
   * @param speechId speech ID which this data is related to
   * @param fileType File type to upload
   * @param videoFileExtension Required for video uploads, ignored for other types
   */
  constructor(
    blob: Blob | Uint8Array,
    speechId: string,
    fileType: UploadFileType,
    videoFileExtension?: string,
  ) {
    this.blob = blob;
    this.speechId = speechId;
    this.fileType = fileType;
    this.videoFileExtension = videoFileExtension ?? undefined;
    this.logPrepend = `Uploader[${uploaderCounter++}][${this.speechId}][${this.fileType}]`;
  }

  /**
   * Request the server to initiate a resumable upload session
   */
  public async initializeServer(): Promise<void> {
    const uploadInfo = await InitiateResumableUpload(
      this.speechId,
      this.fileType,
      this.videoFileExtension,
    );
    this.uploadSessionUrl = uploadInfo.url;
    this.uploadGsUrl = uploadInfo.gsUrl;
  }

  /**
   * Upload the blob. Promise is resolved when the upload is fully finished.
   * @param durationOverrideMs Duration to fix up webm blob data.
   * @param callback Callback to update the UI with upload progress.
   * If the callback returns false, the upload will be cancelled.
   * Note: it is not used by UI yet.
   * @returns GS path, null for failure or cancel.
   */
  public async upload(
    durationOverrideMs: number | null,
    callback: UploadProgressCallback | null,
  ): Promise<string | null> {
    let blobToUpload: Blob | Uint8Array;
    if (this.fileType === UploadFileType.VIDEO && durationOverrideMs) {
      if (this.blob instanceof Blob) {
        blobToUpload = await injectSeekMetadata(this.blob as Blob, durationOverrideMs);
      } else {
        throw new Error(
          `Unable to inject seek metadata for blob type Invalid blob type ${typeof this.blob}.`,
        );
      }
    } else {
      blobToUpload = this.blob;
    }

    try {
      await uploadBlobToResumableUploadUrl(
        blobToUpload,
        this.uploadSessionUrl,
        callback,
        this.logPrepend,
      );
    } catch (error) {
      console.error(`${this.logPrepend} Failed: ${error}`);
      throw error;
    }

    return this.uploadGsUrl;
  }
}

/**
 * Uploader to upload a speech progressively, as it is being recorded.
 * Blobs should be media (video or audio).
 * When the upload function is called, the blob is queued for upload.
 * The upload is done in order, and the promise is resolved when the final blob is uploaded.
 * The finalize function should be called after all blobs are uploaded, and it will return the GS path.
 * The finalize function should be called only once.
 */
export class ProgressiveSpeechUploader {
  private logPrepend: string;
  private speechId: string;
  private fileType: UploadFileType;
  private videoFileExtension: string | undefined;
  private uploadSessionUrl: string;
  private uploadGsUrl: string;
  private workQueue: { blob: Blob; callback: UploadProgressCallback; isFinal: boolean }[] = [];
  private isProcessingQueue = false;
  private byteOffset = 0;
  // leftover bit of blob from previous upload, because GCS only accepts round sized chunks
  private hangingBlob: Blob | null = null;
  private queueFinishedPromise: Promise<void> | null = null;

  /**
   * Creates an uploader instance
   * @param speechId speech ID which this data is related to
   * @param fileType File type to upload
   * @param videoFileExtension Required for video uploads, ignored for other types
   */
  constructor(speechId: string, fileType: UploadFileType, videoFileExtension?: string) {
    this.speechId = speechId;
    this.fileType = fileType;
    this.videoFileExtension = videoFileExtension ?? undefined;
    this.logPrepend = `ProgressiveUploader[${uploaderCounter++}][${this.speechId}][${
      this.fileType
    }]`;
  }

  /**
   * Request the server to initiate a resumable upload session
   */
  public async initializeServer(): Promise<void> {
    const uploadInfo = await InitiateResumableUpload(
      this.speechId,
      this.fileType,
      this.videoFileExtension,
    );
    this.uploadSessionUrl = uploadInfo.url;
    this.uploadGsUrl = uploadInfo.gsUrl;
  }

  private async processQueue(): Promise<void> {
    if (this.workQueue.length === 0 || this.isProcessingQueue) {
      return;
    }

    this.isProcessingQueue = true;
    let resolveQueueFinishedPromise: () => void;
    this.queueFinishedPromise = new Promise((resolve) => {
      resolveQueueFinishedPromise = resolve;
    });

    while (this.workQueue.length > 0) {
      const uploadJob = this.workQueue.shift();
      if (uploadJob) {
        let { blob } = uploadJob;
        const { callback, isFinal } = uploadJob;
        if (this.hangingBlob) {
          blob = new Blob([this.hangingBlob, blob], { type: blob.type });
          this.hangingBlob = null;
        }
        if (!isFinal && blob.size < 256 * 1024) {
          console.log(`${this.logPrepend} Skipping small blob for now: ${blob.size} bytes.`);
          this.hangingBlob = blob;
          continue;
        }
        const bytesUploadedSoFar = await uploadBlobToResumableUploadUrl(
          blob,
          this.uploadSessionUrl,
          callback,
          this.logPrepend,
          null,
          this.byteOffset,
          isFinal,
        );
        if (!isFinal) {
          if (blob.size > bytesUploadedSoFar - this.byteOffset) {
            this.hangingBlob = blob.slice(bytesUploadedSoFar - this.byteOffset);
          }
          this.byteOffset = bytesUploadedSoFar;
        }
      }
    }

    resolveQueueFinishedPromise();
    this.queueFinishedPromise = null;
    this.isProcessingQueue = false;
  }

  /**
   * Queue a blob upload to the next offset
   * @param blob Data to upload
   * @param callback Callback to update the UI with upload progress.
   */
  public queueUpload(blob: Blob, callback: UploadProgressCallback, isFinal = false): void {
    console.log(`${this.logPrepend} Queued blob upload: ${blob.size} bytes. Final: ${isFinal}`);
    this.workQueue.push({ blob, callback, isFinal });

    this.processQueue().catch((error) => {
      console.error(`${this.logPrepend} Progressive upload Blob failed: ${error}`);
    });
  }

  /**
   * Finalize the upload. Promise is resolved when all blobs are uploaded.
   * @returns GS path, null for failure.
   */
  public async finalize(): Promise<string | null> {
    if (this.queueFinishedPromise !== null) {
      await this.queueFinishedPromise;
    }

    return this.uploadGsUrl;
  }
}
