import parse from "html-react-parser";
import React from "react";
import { isMobile } from "react-device-detect";

// Components
import {
  Edit as EditIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Stack,
  Typography,
  Avatar,
  Fade,
  IconButton,
  Box,
} from "@mui/material";
import {
  CustomizePracticeQueryKey,
  MAX_SCENARIO_DESCRIPTION_LENGTH,
} from "components/ConvoScenarios/convoScenarioUtils";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { getDownloadableReport, getReportProcessing } from "components/Orgs/Reports/reportsUtils";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";
import IconMenu from "lib-frontend/components/IconMenu";
import {
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { TemplateSubTypeChip } from "../../../../ConvoScenarios/TemplateSubTypeChip";
import { GroupAssignmentMenu } from "./GroupAssignmentMenu";
import { ManageLtiIntegrationModalContent } from "./ManageLtiIntegrationModal";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { usePrevious } from "lib-frontend/hooks";
import {
  createReport,
  createScenario,
  deleteScenario,
  listReports,
  patchScenario,
} from "lib-frontend/modules/AxiosInstance";
import { generatePackage } from "lib-frontend/modules/axiosScorm12";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsExtraSmallScreen } from "lib-frontend/utils/themeUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ListOrgIntegrationResponseItem } from "lib-fullstack/api/integrationsApiTypes";
import { PersonaResponse } from "lib-fullstack/api/scenarioApiTypes";
import { OrgScenario, ScenarioTemplateSubType, ScenarioTypeIdEnum } from "lib-fullstack/db";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { ReportParameter, ReportState, ReportType } from "lib-fullstack/utils/enums";
import { IntegrationTypeEnum } from "lib-fullstack/utils/enums/integrationType";
import { OrgReportWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { useNavigate } from "react-router";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

type CustomScenarioCardProps = {
  scenario: GetScenarioResponse;
  selectedOrgId: string;
  handleSelectScenario: (scenario: GetScenarioResponse, action: "edit" | "duplicate") => void;
  persona?: PersonaResponse;
  personaLoading?: boolean;
  integrations: ListOrgIntegrationResponseItem[];
  activeHubIds: string[];
};

const SCENARIO_REPORT_QUERY_KEY = "scenarioReportQueryKey";

type EditableField = "title" | "description";

export const validateUrlRegex =
  "^(http(s)://.)[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$";

export const CustomScenarioCard = ({
  scenario,
  selectedOrgId,
  handleSelectScenario,
  persona,
  personaLoading,
  integrations,
  activeHubIds,
}: CustomScenarioCardProps): JSX.Element => {
  const { editor } = useWYSIWYG({
    content: scenario?.description,
    characterLimit: MAX_SCENARIO_DESCRIPTION_LENGTH,
  });

  React.useEffect(() => {
    if (scenario?.description !== editor?.getHTML()) {
      editor?.commands.setContent(scenario.description);
    }
  }, [scenario?.description]);

  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId, copyContentInfo } = React.useContext(ContentSpacesContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isExtraSmallScreen = useIsExtraSmallScreen();
  const [scenarioHubIds, setScenarioHubIds] = React.useState<string[]>(activeHubIds ?? []);
  const [practiceLinkCopied, setPracticeLinkCopied] = React.useState(false);
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);

  const [hovering, setHovering] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const [editingField, setEditingField] = React.useState<EditableField | null>(null);

  const [newTitle, setNewTitle] = React.useState<string>(scenario.title);

  const [reportProcessing, setReportProcessing] = React.useState<boolean>(false);

  const [openManageIntegrationModal, setOpenManageIntegrationModal] =
    React.useState<boolean>(false);
  const scenarioReportQuery = useApiQuery({
    queryKey: [`${SCENARIO_REPORT_QUERY_KEY}-${scenario?.id}`, defaultOrgId],
    queryFn: async () => {
      const reports = await listReports(defaultOrgId, {
        reportType: ReportType.OrgScenarioEngagement,
        states: [ReportState.Rendered, ReportState.Created],
        parameters: JSON.stringify({
          [ReportParameter.ScenarioId]: scenario?.id,
        }),
      });
      return reports;
    },
    refetchInterval: reportProcessing ? 2000 : 0,
    enabled: !!defaultOrgId && !!scenario?.id,
  });
  const downloadableReport = React.useMemo(() => {
    return getDownloadableReport(scenarioReportQuery.data);
  }, [scenarioReportQuery.data]);

  const prevProcessing = usePrevious(reportProcessing);
  React.useEffect(() => {
    const processing = !!getReportProcessing(scenarioReportQuery.data);
    setReportProcessing(processing);

    // if i move from processing ot not processing, a report must have just come back, so download it
    if (prevProcessing && !processing && downloadableReport?.downloadUrl) {
      void handleDownloadReport(downloadableReport.downloadUrl);
    }
  }, [scenarioReportQuery.data, downloadableReport]);

  React.useEffect(() => {
    if (activeHubIds.sort().join() !== scenarioHubIds.sort().join()) {
      setScenarioHubIds(activeHubIds);
    }
  }, [activeHubIds]);

  const orgHasScormIntegration = integrations.some((integration) => {
    return integration.type === IntegrationTypeEnum.SCORM_1_2;
  });
  const orgHasLtiIntegration = integrations.some((integration) => {
    return (
      integration.type === IntegrationTypeEnum.LTI_1_1 ||
      integration.type === IntegrationTypeEnum.LTI_1_3
    );
  });
  const handleUpdateGroupAssignment = async (groupIds: string[]) => {
    let error = null;
    try {
      // save the scenarioHubIds to the scenario in db
      await patchScenario(selectedOrgId, scenario.id, { activeHubs: groupIds });
    } catch (e) {
      console.error(`Error updating scenario activeHubs with id ${scenario.id}: ${e}`);
      error = e;
    } finally {
      void queryClient.refetchQueries({
        queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
      });
    }
    return error;
  };

  const handleDeleteScenario = async () => {
    setIsDeleting(!isDeleting);
    try {
      if (
        window.confirm(
          `Are you sure you want to delete the scenario "${scenario.title}"? This action cannot be undone.`,
        )
      ) {
        await deleteScenario(selectedOrgId, scenario.id);
        void queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId],
        });
        void queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrgId],
        });
        if (
          [
            ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW,
            ScenarioTypeIdEnum.TECHNICAL_INTERVIEW,
          ].includes(scenario.scenarioTypeId)
        ) {
          void queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrgId],
          });
        }
        await queryClient.refetchQueries({
          queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
        });
        Instrumentation.logOrgScenarioDeleted(defaultOrgId);
      }
    } catch (e) {
      console.error(`Error deleting scenario with id ${scenario.id}: ${e}`);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDownloadReport = async (downloadUrl?: string) => {
    if (downloadUrl) {
      // download report
      window.location.assign(downloadUrl);
    } else {
      const params = {
        [ReportParameter.ScenarioId]: scenario?.id,
        [ReportParameter.OrgId]: defaultOrgId,
      };
      setReportProcessing(true);
      await createReport(defaultOrgId, {
        type: ReportType.OrgScenarioEngagement,
        parameters: JSON.stringify(params),
      });
      Instrumentation.logOrgReportGenerated(defaultOrgId, OrgReportWheres.SCENARIO);
      await queryClient.invalidateQueries({
        queryKey: [`${SCENARIO_REPORT_QUERY_KEY}-${scenario?.id}`],
      });
    }
  };

  const practiceLinkCopiedTimeout = React.useRef<NodeJS.Timeout>(null);
  const renderScenarioOptionsMenu = () => {
    const menuItems = [
      {
        title: "Edit",
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          handleSelectScenario(scenario, "edit");
        },
      },
      {
        title: "Practice now",
        onClick: () => {
          navigate(
            getScenarioPracticePath(
              scenario.id,
              selectedOrgId,
              false,
              scenarioTypeMap[scenario.scenarioTypeId],
            ),
            { replace: true },
          );
        },
      },
      {
        title: practiceLinkCopied ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>Link copied</Typography>
            <CheckCircleRoundedIcon
              sx={{
                color: getDynamicColor("greenSuccess"),
                height: 20,
                width: 20,
              }}
            />
          </Stack>
        ) : (
          "Copy practice link"
        ),
        onClick: () => {
          if (practiceLinkCopiedTimeout.current) {
            clearTimeout(practiceLinkCopiedTimeout.current);
          }

          copyToMyClipboard(
            getScenarioPracticePath(
              scenario.id,
              selectedOrgId,
              true,
              scenarioTypeMap[scenario.scenarioTypeId],
            ),
          );
          setPracticeLinkCopied(true);
          practiceLinkCopiedTimeout.current = setTimeout(() => {
            setPracticeLinkCopied(false);
          }, 3000);
        },
        disableCloseOnClick: true,
      },
      {
        title: "Copy to",
        onClick: async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          copyContentInfo.handleIsCopyDrawerOpen(true);
          copyContentInfo.handleSetCopyContentRequest({
            copyHandler: async (destinationSpaceId: string) => {
              await createScenario(
                selectedOrgId,
                curSpaceId,
                `${scenario.title} (copy)`,
                scenario.id,
                true,
                destinationSpaceId,
              );
              if (curSpaceId === destinationSpaceId) {
                void queryClient.invalidateQueries({
                  queryKey: [CustomizePracticeQueryKey.Scenarios, selectedOrgId, curSpaceId],
                });
              }
            },
            content: {
              name: scenario.title,
              type: "Scenario",
            },
          });
        },
      },
      {
        title: reportProcessing ? (
          <Stack direction="row" alignItems="center" gap={1}>
            <CircularProgress size={16} />
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
              }}
            >
              Report processing
            </Typography>
          </Stack>
        ) : (
          "Download report"
        ),
        onClick: async () => {
          await handleDownloadReport(downloadableReport?.downloadUrl);
        },
      },
      orgHasLtiIntegration && {
        title: "Manage integration",
        onClick: async () => {
          setOpenManageIntegrationModal(true);
        },
      },
      orgHasLtiIntegration && {
        title: "Copy scenario ID",
        onClick: async () => {
          copyToMyClipboard(scenario.id);
        },
      },
      orgHasScormIntegration && {
        title: "Download SCORM package",
        onClick: async () => {
          await generatePackage({ orgId: defaultOrgId, scenarioId: scenario.id });
        },
      },
      {
        title: "Delete scenario",
        onClick: async () => {
          void handleDeleteScenario();
        },
        disabled: isDeleting || scenario.usedByPrograms,
        disabledTooltip: "This scenario is in use by a program and cannot be deleted at this time",
      },
    ].filter(Boolean);
    return (
      <IconMenu
        title="Manage File"
        hideCaret
        hideTooltip
        disableDrag
        openOverride={!!reportProcessing}
        menuItems={menuItems}
        iconButtonSx={{
          height: 24,
          width: 24,
          borderRadius: 100,
          svg: {
            color: getDynamicColor(isDeleting ? "dark3" : "primary"),
          },
        }}
        menuItemSx={{
          fontSize: 14,
          fontWeight: 600,
          fontFamily: "poppins",
          px: 2,
          py: 1.5,
          color: getDynamicColor("primary"),
        }}
        minWidth={"140px"}
        paperSx={{
          borderRadius: "12px",
          minWidth: 190,
        }}
        icon={<MoreVertIcon />}
      />
    );
  };
  const renderPersona = () => {
    if (personaLoading) {
      return <CircularProgress size={16} />;
    } else if (persona) {
      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          <Avatar
            alt={persona?.name}
            src={persona?.profile_picture_signed_url}
            key={persona?.profile_picture_signed_url}
            sx={{
              height: 20,
              width: 20,
            }}
          />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            {persona?.name}
          </Typography>
        </Stack>
      );
    }
    return null;
  };
  const handleSave = async (fieldToEdit: "title" | "description", leaveOpen?: boolean) => {
    try {
      if (newTitle !== scenario.title || editor?.getHTML() !== scenario.description) {
        if (fieldToEdit === "title") {
          await patchScenario(selectedOrgId, scenario.id, { title: newTitle });
        } else if (fieldToEdit === "description") {
          await patchScenario(selectedOrgId, scenario.id, {
            description: editor?.getHTML(),
          });
        }
        await queryClient.refetchQueries({
          queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrgId],
        });
      }
    } catch (e) {
      console.error(`Error updating scenario with id ${scenario.id}: ${e}`);
    } finally {
      if (!leaveOpen) {
        setEditingField(null);
      }
    }
  };

  const renderSaveEdit = (fieldToEdit: "title" | "description") => {
    const saveDisabled =
      fieldToEdit === "title" ? !newTitle : editor?.storage.characterCount.characters() === 0;
    if (editingField === fieldToEdit) {
      return (
        <IconButton
          disabled={saveDisabled}
          sx={{
            p: 0.5,
            color: getDynamicColor("primary"),
            cursor: "pointer",
          }}
          onClick={() => handleSave(fieldToEdit)}
        >
          <CheckRoundedIcon
            sx={{
              height: 22,
              width: 22,
            }}
          />
        </IconButton>
      );
    }
    return (
      <Fade in={hovering || isMobile}>
        <EditIcon
          onClick={() => {
            if (editingField === fieldToEdit) {
              setEditingField(null);
            } else {
              setEditingField(fieldToEdit);
            }
          }}
          sx={{
            color: getDynamicColor("primary"),
            height: 16,
            width: 16,
            cursor: "pointer",
          }}
        />
      </Fade>
    );
  };

  const renderLtiIntegrationModal = () => {
    if (!integrations || integrations.length === 0) {
      return null;
    }
    return (
      <YoodliCtaModal
        ctaBody={{
          title: `Manage integration for "${scenario.title}"`,
          subtitle:
            "Paste the course ID from your LMS. To map this scenario to multiple courses, list them " +
            "with commas (e.g. Course1, Course2, Course3). " +
            `For LMSs which can pass custom parameters to Yoodli, you may use "scenarioId=${scenario.id}" ` +
            "to pass the scenario ID from the LMS to Yoodli.",
        }}
        bodyComponent={
          <ManageLtiIntegrationModalContent scenarioId={scenario.id} integrations={integrations} />
        }
        open={openManageIntegrationModal}
        theme={YoodliCtaModalTheme.Primary}
        close={() => {
          setOpenManageIntegrationModal(false);
        }}
      />
    );
  };

  const renderDescriptionText = () => {
    if (editingField === "description") {
      return (
        <Stack direction="row" gap={1} alignItems="center">
          <WYSIWYGEditor
            editor={editor}
            characterLimit={MAX_SCENARIO_DESCRIPTION_LENGTH}
            mini
            excludeHeaderStyles
          />
          {renderSaveEdit("description")}
        </Stack>
      );
    }
    return (
      <Stack
        direction="row"
        gap={1}
        alignItems="flex-end"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.4,
        }}
      >
        <Box
          sx={{
            "> *": {
              margin: "0px !important",
              width: "fit-content",
              wordBreak: { xs: "break-word", md: "unset" },
            },
          }}
        >
          {parse(scenario.description)}
        </Box>
        {renderSaveEdit("description")}
      </Stack>
    );
  };
  return (
    <Stack
      alignItems="flex-start"
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      gap={1}
      sx={{
        fontFamily: "poppins",
        p: { xs: 1.5, md: 2.5 },
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "4px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={1}
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            width: "calc(100% - 36px)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              maxWidth: "100%",
            }}
          >
            {editingField === "title" ? (
              <YoodliTextfield
                maxChars={200}
                value={newTitle}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                }}
                InputProps={{
                  sx: { fontSize: 14, fontWeight: 600, p: 1 },
                }}
                inputProps={{
                  sx: { p: 0 },
                }}
                sx={{
                  lineHeight: 1.3,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            ) : (
              <Typography
                onClick={() => handleSelectScenario(scenario, "edit")}
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.3,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {scenario.title}
              </Typography>
            )}
            {renderSaveEdit("title")}
          </Stack>
          {scenario.description?.length > 160 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                fontSize: 12,
                my: "0px !important",
                color: getDynamicColor("purple3"),
                "::before": {
                  display: "none !important",
                  position: "relative",
                },
              }}
              onChange={(e, expanded) => {
                setDescriptionOpen(expanded);
              }}
            >
              <AccordionSummary
                sx={{
                  my: 0,
                  px: 0,
                  width: "fit-content",
                  minHeight: "unset !important",
                  ".MuiAccordionSummary-content, .Mui-expanded": {
                    my: "0px !important",
                    mr: 0.5,
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                }
              >
                {descriptionOpen ? "Hide" : "Show"} description
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  pb: "8px !important",
                }}
              >
                {renderDescriptionText()}
              </AccordionDetails>
            </Accordion>
          ) : scenario.description?.length > 0 ? (
            renderDescriptionText()
          ) : null}
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <TemplateSubTypeChip
              templateSubType={scenario.templateSubType as ScenarioTemplateSubType}
            />
            <Typography
              sx={{
                fontSize: 12,
                color: getDynamicColor("dark4"),
              }}
            >
              Created by: {(scenario as OrgScenario).createdByEmail} (
              {getHumanReadableDate((scenario as OrgScenario).createdAt)})
            </Typography>
            {renderPersona()}
          </Stack>
        </Stack>
        {isExtraSmallScreen && renderScenarioOptionsMenu()}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: "space-between", sm: "flex-end" }}
        gap={2}
        sx={{
          width: { xs: "100%", sm: "unset" },
        }}
      >
        <GroupAssignmentMenu
          activeGroupIds={activeHubIds}
          handleUpdateGroupAssignment={handleUpdateGroupAssignment}
        />
        {!isExtraSmallScreen && renderScenarioOptionsMenu()}
        {renderLtiIntegrationModal()}
      </Stack>
    </Stack>
  );
};
