// Components
import { CircularProgress, Box, Typography, Stack } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";

type SeatUtilizationProps = {
  org: OrgV2Response;
};

export const SeatUtilization = ({ org }: SeatUtilizationProps): JSX.Element => {
  const SEAT_UTILIZATION_GRAPHIC_SIZE = 128;

  const showWarning = org.license_used >= org.license_count * 0.8;

  const getTooltipText = () => {
    if (!showWarning) {
      return "";
    }

    let warningText = `This org is ${
      org.license_used >= org.license_count ? "out of" : "running low on"
    } seats. `;
    warningText +=
      org.subscription_type === OrgSubscriptionType.PREPAID
        ? "Talk to sales to continue adding members!"
        : "Upgrade now to continue adding members!";

    return warningText;
  };

  return (
    <YoodliTooltip title={getTooltipText()}>
      <Stack gap={0.5} sx={{ alignItems: "center", justifyContent: "center" }}>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            width: SEAT_UTILIZATION_GRAPHIC_SIZE,
            height: SEAT_UTILIZATION_GRAPHIC_SIZE / 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "inline-flex",
              top: 0,
              left: 0,
              transform: "rotate(-90deg)",
              zIndex: 1,
            }}
          >
            <CircularProgress
              size={SEAT_UTILIZATION_GRAPHIC_SIZE}
              value={Math.min(org.license_used / org.license_count, 1) * 50}
              variant="determinate"
              thickness={10}
              sx={{
                color: getDynamicColor(showWarning ? "redError" : "purple3"),
              }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              display: "inline-flex",
              top: 0,
              left: 0,
              transform: "rotate(-90deg)",
            }}
          >
            <CircularProgress
              size={SEAT_UTILIZATION_GRAPHIC_SIZE}
              value={50}
              variant="determinate"
              thickness={10}
              sx={{ color: getDynamicColor("dark3") }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          {org.license_used}/{org.license_count}
        </Typography>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 400,
          }}
        >
          seats used
        </Typography>
      </Stack>
    </YoodliTooltip>
  );
};
