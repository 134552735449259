import React from "react";

// Components
import { Box, Typography, Stack } from "@mui/material";
import { WizardFormFooter } from "webclient/src/components/Orgs/ManageContent/WizardFormFooter";
import { RouteLeavingGuard } from "webclient/src/components/RouteLeavingGuard";

import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WIZARD_FOOTER_HEIGHT } from "webclient/src/utils/Constants";

type OrgCreateSpaceProps = {
  onComplete: () => void;
};

export const OrgCreateSpace = ({ onComplete }: OrgCreateSpaceProps): JSX.Element => {
  const [inputName, setInputName] = React.useState<string>("");
  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  const renderRouteLeavingGuard = () => {
    return (
      <RouteLeavingGuard
        title="Exit content space creation?"
        body="Any progress you have made so far will not be saved. Are you sure you want to exit?"
        okText="Yes, I'm sure"
        when={true}
        shouldBlockNavigation={() => !routeLeavingModalVisible && inputName.length > 0}
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
      />
    );
  };

  const { isWorking, createSpace } = React.useContext(ContentSpacesContext);

  const handleSave = async () => {
    await createSpace(inputName);
    onComplete();
  };

  return (
    <>
      {renderRouteLeavingGuard()}
      <Box
        sx={{
          pb: WIZARD_FOOTER_HEIGHT,
          px: { xs: 2, md: 7 },
        }}
      >
        <Stack sx={{ maxWidth: 525 }} gap={5}>
          <Stack
            gap={1}
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              mt: 5,
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
              Create a new content space
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
              Organize scenarios, programs, coachbots, videos and courses into one space. You’ll be
              able to copy content from other spaces.
            </Typography>
          </Stack>
          <YoodliLabeledInput
            label="Name"
            inputEl={
              <YoodliTextfield
                InputProps={{
                  sx: { fontWeight: 600 },
                }}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
                maxChars={50}
                hideCharCount={true}
              />
            }
          />
        </Stack>
        <WizardFormFooter
          saving={isWorking}
          handleSave={handleSave}
          disabled={inputName.length === 0}
          label={"Create content space"}
        />
      </Box>
    </>
  );
};
