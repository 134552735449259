import React from "react";

// Components
import ModalWrapper, { Modals } from "components/ModalWrapper";

// Utils
import CreateOrEditCourseModal from "./VideosAndCourses/CreateOrEditCourseModal";
import DeleteModal from "./VideosAndCourses/DeleteModal";
import EditVideosModal from "./VideosAndCourses/EditVideosModal";
import UploadVideoModal from "./VideosAndCourses/UploadVideoModal";
import { HubsCourseAndDemoVideosTypeV2 } from "hooks/useCourseContentAndDemoVideosV2";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { DeleteModalType } from "utils/Enums";
import { HubModalStatus } from "utils/Enums";

type OrgSettingsModalProps = Partial<HubsCourseAndDemoVideosTypeV2> & {
  modalStatus: HubModalStatus;
  setModalStatus: (status: HubModalStatus) => void;
  allHubs: HubV2Response[];
};

export function OrgManageContentModal({
  modalStatus,
  setModalStatus,
  allHubs,
  contentView,
  uploading,
  selectedCourse,
  setSelectedCourse,

  //Courses
  handleCreateCourse,
  handleUpdateCourse,
  handleDeleteCourse,
  // Demo Video Videos
  handleCreateDemoVideos,
  handleCopyDemoVideo,
  handleUpdateDemoVideo,
  handleDeleteDemoVideo,
  // Course Videos
  handleDeleteCourseVideo,
  handleUpdateCourseVideo,
  handleCreateCourseVideos,
  handleUpdateCourseVideos,
  handleCopyCourseVideo,
  // General Videos
  uploadingVideos,
  clearUploadingVideos,
}: OrgSettingsModalProps): JSX.Element {
  const closeModal = (skipConfirmation?: boolean) => {
    if (
      ![
        HubModalStatus.CREATE_INTERVIEW_BANK,
        HubModalStatus.EDIT_INTERVIEW_BANK_QUESTIONS,
      ].includes(modalStatus) ||
      skipConfirmation ||
      window.confirm(
        "Are you sure you want to cancel creating an interview bank? your questions will not be saved",
      )
    ) {
      setModalStatus(HubModalStatus.CLOSED);
      setSelectedCourse(undefined);
    }
  };

  const modals: Modals = {
    [HubModalStatus.UPLOAD_DEMO_VIDEO]: {
      element: uploadingVideos.length ? (
        <EditVideosModal
          videos={uploadingVideos}
          closeModal={closeModal}
          handleDeleteVideo={handleDeleteDemoVideo}
          handleUpdateVideo={handleUpdateDemoVideo}
          allHubs={allHubs}
          isUpload
          uploading={uploading}
          clearUploadingVideos={clearUploadingVideos}
        />
      ) : (
        <UploadVideoModal handleCreateVideos={handleCreateDemoVideos} />
      ),
      wrapperStyles: uploadingVideos.length
        ? {
            width: "min(650px, calc(100% - 16px))",
            maxHeight: "min(750px, calc(100% - 32px))",
            p: "0 !important",
            gap: "0 !important",
          }
        : {
            gap: 8,
            pb: 12,
          },
      showCloseButton: true,
    },
    [HubModalStatus.CREATE_COURSE]: {
      element: (
        <CreateOrEditCourseModal
          closeModal={closeModal}
          allHubs={allHubs}
          handleCreateCourse={handleCreateCourse}
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
      },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_COURSE_DETAILS]: {
      element: (
        <CreateOrEditCourseModal
          closeModal={closeModal}
          allHubs={allHubs}
          handleUpdateCourse={handleUpdateCourse}
          course={selectedCourse}
          isEditing
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
        backgroundColor: getDynamicColor("light2"),
      },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_DEMO_VIDEOS]: {
      element: (
        <EditVideosModal
          videos={contentView?.demo_videos}
          closeModal={closeModal}
          handleCopyVideo={handleCopyDemoVideo}
          handleDeleteVideo={handleDeleteDemoVideo}
          handleUpdateVideo={handleUpdateDemoVideo}
          allHubs={allHubs}
        />
      ),
      wrapperStyles: {
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
        p: "0 !important",
        gap: "0 !important",
      },
      showCloseButton: true,
    },
    [HubModalStatus.UPLOAD_COURSE_VIDEO]: {
      element: uploadingVideos.length ? (
        <EditVideosModal
          videos={uploadingVideos}
          closeModal={closeModal}
          handleDeleteVideo={handleDeleteCourseVideo}
          handleUpdateVideo={handleUpdateCourseVideo}
          handleUpdateVideos={handleUpdateCourseVideos}
          allHubs={allHubs}
          isUpload
          uploading={uploading}
          clearUploadingVideos={clearUploadingVideos}
          isCourse
        />
      ) : (
        <UploadVideoModal handleCreateVideos={handleCreateCourseVideos} isCourse />
      ),
      wrapperStyles: uploadingVideos.length
        ? {
            width: "min(650px, calc(100% - 16px))",
            maxHeight: "min(750px, calc(100% - 32px))",
            p: "0 !important",
            gap: "0 !important",
          }
        : {
            gap: 8,
            pb: 12,
          },
      showCloseButton: true,
    },
    [HubModalStatus.EDIT_COURSE_VIDEOS]: {
      element: (
        <EditVideosModal
          videos={contentView?.courses.find((course) => course.id === selectedCourse?.id)?.videos}
          closeModal={closeModal}
          handleCopyVideo={handleCopyCourseVideo}
          handleDeleteVideo={handleDeleteCourseVideo}
          handleUpdateVideo={handleUpdateCourseVideo}
          handleUpdateVideos={handleUpdateCourseVideos}
          allHubs={allHubs}
          isCourse
        />
      ),
      wrapperStyles: {
        p: "0 !important",
        gap: "0 !important",
        width: "min(650px, calc(100% - 16px))",
        maxHeight: "min(750px, calc(100% - 32px))",
      },
      showCloseButton: true,
    },
    [HubModalStatus.DELETE_COURSE]: {
      element: (
        <DeleteModal
          type={DeleteModalType.COURSE}
          currentName={selectedCourse?.title}
          closeModal={closeModal}
          handleDelete={handleDeleteCourse}
        />
      ),
      showCloseButton: true,
    },
  };
  return <ModalWrapper modalStatus={modalStatus} modals={modals} closeModal={closeModal} />;
}
