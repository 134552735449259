import React from "react";

// Components
import { Button } from "@mui/material";
import { Container } from "@mui/material";

// Utils
import OrgSectionWrapper from "../OrgSectionWrapper";
import { MemberBlurb } from "./MemberBlurb";
import { GoalProgress } from "./MemberDashboardWidgets/GoalProgress";
import { MembershipSettings } from "./MemberDashboardWidgets/MembershipSettings";
import { OverallStats } from "./MemberDashboardWidgets/OverallStats";
import { SharedWithOrg } from "./MemberDashboardWidgets/SharedWithOrg";
import { WidgetWrapper } from "./MemberDashboardWidgets/WidgetWrapper";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";

export const SECTION_TITLE_STYLES = {
  fontSize: 14,
  fontWeight: 700,
};

type MemberDashboardProps = {
  handleBack: () => void;
  backCopy: string;
  handleOpenManageRole: (user: OrgMemberResponse, fromDashboard?: boolean) => void;
  user: OrgMemberResponse;
};

export const MemberDashboard = ({
  handleBack,
  backCopy,
  handleOpenManageRole,
  user,
}: MemberDashboardProps): JSX.Element => {
  return (
    <OrgSectionWrapper
      showFooter={false}
      headerProps={{
        backCopy,
        handleBack,
      }}
      loading={false}
      contentWrapperSx={{
        maxWidth: "unset",
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: 3, md: 6 },
          px: { xs: 2, md: 6 },
          mx: 0,
        }}
      >
        <MemberBlurb user={user} />
        <WidgetWrapper title="Overall stats">
          <OverallStats user={user} />
        </WidgetWrapper>
        <WidgetWrapper title="Goal progress">
          <GoalProgress user={user} />
        </WidgetWrapper>
        <WidgetWrapper title="Recordings shared with organization">
          <SharedWithOrg user={user} />
        </WidgetWrapper>
        <WidgetWrapper
          title="Membership settings"
          cta={
            <Button
              variant="outlined"
              onClick={() => handleOpenManageRole(user, true)}
              sx={{
                px: 3,
                py: 0.5,
                fontSize: 14,
              }}
            >
              Manage
            </Button>
          }
        >
          <MembershipSettings user={user} />
        </WidgetWrapper>
      </Container>
    </OrgSectionWrapper>
  );
};
