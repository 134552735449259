import React from "react";

// Components
import { PlayCircle as PlayCircleIcon, StopCircle as StopCircleIcon } from "@mui/icons-material";
import { Chip, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import {
  DemeanorDescriptions,
  getDemeanorColor,
} from "components/ConvoScenarios/convoScenarioUtils";
import { RouteLeavingGuard } from "components/RouteLeavingGuard";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import {
  SELECT_VALUE_STYLES,
  YoodliSelect,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { WizardType } from "./CreateConvoScenarioWizard";
import { PersonaProfilePictureSelector } from "./PersonaProfilePictureSelector";
import { WizardAddDraggableItems } from "./WizardAddDraggableItems";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { toTitleCase } from "lib-frontend/utils/Utilities";
import { UpdatePersonaRequest } from "lib-fullstack/api/scenarioApiTypes";
import { DemeanorEnum } from "lib-fullstack/db";
import { VoiceMetadata, voices } from "lib-fullstack/utils/voices";

type CreatePersonaFormProps = {
  newPersona: UpdatePersonaRequest;
  handleUpdateNewPersona: (key: keyof UpdatePersonaRequest, value: string | string[]) => void;
  currStepIndex?: number;
  wizardType: WizardType;
};

export const CreatePersonaForm = ({
  newPersona,
  handleUpdateNewPersona,
  currStepIndex,
  wizardType,
}: CreatePersonaFormProps): JSX.Element => {
  const audioPlayingTimeoutRef = React.useRef<NodeJS.Timeout>(null);

  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  const voiceArr = Array.from(voices.values());

  const [currentPlayingVoiceId, setCurrentPlayingVoiceId] = React.useState<string>("");

  const demeanorArr = Object.keys(DemeanorDescriptions).map((key) => ({
    id: key,
    description: DemeanorDescriptions[key],
  }));

  // scroll to top on mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // on mount, if voice_id is set but profile_picture_id is not, set it to the default profile picture id for the voice
  React.useEffect(() => {
    if (!newPersona.profile_picture_id && !!newPersona?.voice_id) {
      handleUpdateNewPersona(
        "profile_picture_id",
        voices.get(newPersona.voice_id)?.defaultProfilePictureId,
      );
    }
  }, []);

  const renderRouteLeavingGuard = () => {
    return (
      <RouteLeavingGuard
        title="Exit persona creation?"
        body="Any progress you have made so far will not be saved. Are you sure you want to exit?"
        okText="Yes, I'm sure"
        when={true}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={() => {
          if (!routeLeavingModalVisible) {
            return true;
          }
          return false;
        }}
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
      />
    );
  };

  const handlePlayPauseAudioSample = (e: React.MouseEvent<HTMLButtonElement>, v: VoiceMetadata) => {
    e.stopPropagation();
    const currentlyPlayingEl = document.getElementById(currentPlayingVoiceId) as HTMLAudioElement;

    // if im clicking the button of the currently playing voice, just stop it, clear the timeout, and return
    if (currentPlayingVoiceId === v.voiceId) {
      setCurrentPlayingVoiceId("");
      if (currentlyPlayingEl) {
        currentlyPlayingEl.pause();
        currentlyPlayingEl.currentTime = 0;
      }
      if (audioPlayingTimeoutRef.current) {
        clearTimeout(audioPlayingTimeoutRef.current);
        audioPlayingTimeoutRef.current = null;
      }
      return;
    }

    // if a voice is currently playing, stop it before playing the new one, and clear the duration timeout
    if (currentlyPlayingEl && !currentlyPlayingEl.paused) {
      currentlyPlayingEl.pause();
      currentlyPlayingEl.currentTime = 0;
      if (audioPlayingTimeoutRef.current) {
        clearTimeout(audioPlayingTimeoutRef.current);
        audioPlayingTimeoutRef.current = null;
      }
    }
    // get the audio element for the voice
    const audioEl = document.getElementById(v.voiceId) as HTMLAudioElement;
    if (audioEl) {
      // always set currentTime to 0 before playing, just in case
      audioEl.currentTime = 0;
      // need to wait until next event loop to get proper duration
      setTimeout(() => {
        audioEl
          .play()
          .then(() => {
            setCurrentPlayingVoiceId(v.voiceId);
            // convert s to ms
            const duration = audioEl.duration * 1000;
            audioPlayingTimeoutRef.current = setTimeout(() => {
              setCurrentPlayingVoiceId("");
              // if new audio src, add a 1s delay if it needs to fetch the audio src, which is not perfect but is pretty solid
            }, duration);
          })
          .catch((er) => {
            console.error(
              `Error playing audio sample (${v.sampleUrl}) for voice (${v.name}): ${er}`,
            );
          });
      }, 0);
    }
  };

  const renderDemeanorValue = (demeanor) => {
    return (
      <Stack
        direction="row"
        flexWrap="wrap"
        columnGap={1}
        rowGap={0.5}
        alignItems="center"
        sx={{
          overflow: "hidden",
          width: "100%",
          py: 1,
        }}
      >
        <Chip
          className="subLabel"
          sx={{
            fontSize: 11,
            fontWeight: 400,
            color: getDynamicColor("purple3"),
            backgroundColor: getDemeanorColor(demeanor.id),
            textTransform: "uppercase",
            letterSpacing: "1px",
            p: 0,
            width: "fit-content",
            height: "unset",
            span: {
              lineHeight: 1,
              py: 1,
            },
          }}
          label={toTitleCase(demeanor.id)}
        />
        <Typography
          sx={{
            ...SELECT_VALUE_STYLES,
            width: "fit-content",
            fontSize: 14,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontWeight: 500,
            lineHeight: 1.2,
            color: getDynamicColor(newPersona?.demeanor === demeanor?.id ? "purple3" : "dark5"),
          }}
        >
          {demeanor.description}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      {renderRouteLeavingGuard()}
      <Stack
        direction="column"
        gap={{ xs: 2, md: 3 }}
        sx={{
          fontFamily: "poppins",
        }}
      >
        <YoodliLabeledInput
          label="Name"
          inputEl={
            <YoodliTextfield
              value={newPersona.name}
              onChange={(e) => handleUpdateNewPersona("name", e.target.value)}
              sx={{
                "*": {
                  borderWidth: "1px !important",
                },
              }}
            />
          }
        />
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "stretch", md: "flex-end" }}
          gap={{ xs: 2, md: 3 }}
          sx={{
            width: "100%",
          }}
        >
          <YoodliLabeledInput
            label="Job Title"
            inputEl={
              <YoodliTextfield
                value={newPersona.job_title}
                onChange={(e) => handleUpdateNewPersona("job_title", e.target.value)}
                sx={{
                  "*": {
                    borderWidth: "1px !important",
                  },
                }}
              />
            }
            wrapperSx={{
              flexBasis: "50%",
            }}
          />
          <YoodliLabeledInput
            label="Company (optional)"
            inputEl={
              <YoodliTextfield
                value={newPersona.company}
                onChange={(e) => handleUpdateNewPersona("company", e.target.value)}
                sx={{
                  "*": {
                    borderWidth: "1px !important",
                  },
                }}
              />
            }
            wrapperSx={{
              flexBasis: "50%",
            }}
          />
        </Stack>
        <YoodliLabeledInput
          label="Voice"
          inputEl={
            <YoodliSelect
              value={newPersona.voice_id}
              renderValue={(value: string) => {
                const voice = voices.get(value);
                return (
                  <Stack direction="row" sx={{ gap: 1, flexWrap: "wrap", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                      }}
                    >
                      {voice.name}
                    </Typography>
                    <Chip
                      label={voice.vibe.join(", ")}
                      sx={{
                        fontSize: 9,
                        fontWeight: 400,
                        textTransform: "uppercase",
                        backgroundColor: getDynamicColor("purple1"),
                        height: 21,
                        letterSpacing: "1px",
                        span: {
                          px: 1,
                        },
                      }}
                    />
                  </Stack>
                );
              }}
              onChange={(e) => {
                const value = e.target.value as string;
                handleUpdateNewPersona("voice_id", value);
                if (!newPersona.profile_picture_id) {
                  handleUpdateNewPersona(
                    "profile_picture_id",
                    voices.get(value)?.defaultProfilePictureId,
                  );
                }
              }}
              sx={{
                flexBasis: "50%",
                height: "fit-content",
                minHeight: 53,
                ".MuiList-root": {
                  minWidth: 320,
                },
              }}
            >
              {voiceArr.map((v) => {
                return (
                  <MenuItem
                    key={v.voiceId}
                    value={v.voiceId}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      px: 1,
                      "&:hover": {
                        "li, p": {
                          color: getDynamicColor("dark5"),
                        },
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      gap={1}
                      flexWrap="wrap"
                      sx={{
                        py: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          color: getDynamicColor(
                            newPersona.voice_id === v.voiceId ? "primary" : "purple3",
                          ),
                        }}
                      >
                        {v?.name ?? ""}
                      </Typography>
                      <Chip
                        label={v.vibe.join(", ")}
                        sx={{
                          fontSize: 9,
                          fontWeight: 400,
                          textTransform: "uppercase",
                          backgroundColor: getDynamicColor("purple1"),
                          height: 21,
                          letterSpacing: "1px",
                          span: {
                            px: 1,
                          },
                        }}
                      />
                    </Stack>
                    <IconButton
                      onClick={(e) => handlePlayPauseAudioSample(e, v)}
                      sx={{
                        p: 0.5,
                      }}
                    >
                      {currentPlayingVoiceId === v.voiceId ? (
                        <StopCircleIcon
                          sx={{
                            color: getDynamicColor("primary"),
                          }}
                        />
                      ) : (
                        <PlayCircleIcon
                          sx={{
                            color: getDynamicColor("primary"),
                          }}
                        />
                      )}
                    </IconButton>
                  </MenuItem>
                );
              })}
            </YoodliSelect>
          }
        />
        <YoodliLabeledInput
          label="Demeanor"
          inputEl={
            <YoodliSelect
              value={newPersona.demeanor}
              renderValue={(value) => {
                const demeanor = demeanorArr.find((d) => d.id === value);
                return renderDemeanorValue(demeanor);
              }}
              MenuProps={{
                sx: {
                  maxHeight: { xs: 400, lg: 500 },
                },
              }}
              onChange={(e) => {
                const value = e.target.value as DemeanorEnum;
                handleUpdateNewPersona("demeanor", value);
              }}
              sx={{
                minHeight: 53,
              }}
            >
              {demeanorArr.map((demeanor) => {
                return (
                  <MenuItem
                    value={demeanor.id}
                    key={demeanor.id}
                    sx={{
                      py: 1.5,
                    }}
                  >
                    {renderDemeanorValue(demeanor)}
                  </MenuItem>
                );
              })}
            </YoodliSelect>
          }
        />
        <Stack
          gap={2}
          sx={{
            p: {
              fontSize: 14,
              color: getDynamicColor("dark5"),
            },
            mt: 1,
          }}
        >
          <Stack gap={0.5}>
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              Additional background (optional)
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
              }}
            >
              Add up to 10 characteristics and background details of the persona. Try to limit each
              item to a single line.
            </Typography>
          </Stack>
          <WizardAddDraggableItems
            items={newPersona.additional_background}
            handleUpdateItems={(items) => handleUpdateNewPersona("additional_background", items)}
            inputPlaceholder="e.g. Passed up for a promotion"
            maxItems={10}
            maxInputLength={500}
            itemLabel="details"
            wrapperSx={{ gap: 2, pb: 0 }}
            disableDrag
            currStepIndex={currStepIndex}
          />
        </Stack>
        <PersonaProfilePictureSelector
          handleUpdateNewPersona={handleUpdateNewPersona}
          persona={newPersona}
          wizardType={wizardType}
        />
      </Stack>
      {voiceArr.map((v) => {
        return (
          <audio
            preload="auto"
            key={v.voiceId}
            id={v.voiceId}
            src={v.sampleUrl}
            style={{ display: "none" }}
          />
        );
      })}
    </>
  );
};
