import { lazy, LazyExoticComponent, ReactElement } from "react";

/**
 * Version of `React.lazy` that reloads the page (only once) if lazy loading fails,
 * which can happen when we re-deploy the website and the filenames change.
 */
export function lazyWithRefresh(componentImport: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (): Promise<any>;
}): LazyExoticComponent<() => ReactElement> {
  // thanks https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false",
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        console.log("Lazy-load error. Reloading page.");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      console.log("Lazy-load error. Page has already been reloaded - not retrying again.");
      throw error;
    }
  });
}
