import React from "react";

// Components
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";

// Utils
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgUsersV2, updateOrgProgram } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramResponse, UpdateProgramResponse } from "lib-fullstack/api/programApiTypes";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import { OrgMembersQueryKeys } from "../Members/OrgMembers";
import { ORG_MEMBER_FETCH_LIMIT } from "lib-fullstack/utils/constants";
import { GetOrgMemberListSortOption } from "lib-fullstack/api/orgApiTypes";
import useDebounce from "hooks/useDebounce";

type ProgramEvaluatorSelectorProps = {
  program: ProgramResponse;
  addingEvaluators: boolean;
  setAddingEvaluators: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProgramEvaluatorSelector = ({
  program,
  addingEvaluators,
  setAddingEvaluators,
}: ProgramEvaluatorSelectorProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const queryClient = useQueryClient();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const [pendingEvaluatorEmails, setPendingEvaluatorEmails] = React.useState<string[]>([]);
  const [failedEvaluatorEmails, setFailedEvaluatorEmails] = React.useState<string[]>([]);
  const [evaluatorEmailSearchText, setEvaluatorEmailSearchText] = React.useState<string>("");

  const debouncedEmailSearchText = useDebounce(evaluatorEmailSearchText, 250);
  const evaluatorsSearchValue = React.useMemo(() => {
    return pendingEvaluatorEmails.map((email) => ({ label: email, value: email }));
  }, [pendingEvaluatorEmails]);

  const { data: evalEmailOptions, isFetching } = useQuery({
    queryKey: [
      OrgMembersQueryKeys.OrgMembers,
      defaultOrgId,
      {
        sort: GetOrgMemberListSortOption.EMAIL_ASC,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        prefix: debouncedEmailSearchText,
      },
    ],
    queryFn: async () =>
      listOrgUsersV2(defaultOrgId, {
        sort: GetOrgMemberListSortOption.EMAIL_ASC,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        prefix: debouncedEmailSearchText,
      }),
    enabled: !!defaultOrgId && debouncedEmailSearchText.length > 0,
  });

  React.useLayoutEffect(() => {
    Instrumentation.logProgramAdminOverviewViewed(defaultOrgId, program.id);
  }, []);

  const updateProgramEvaluatorsMutation = useMutation({
    mutationFn: async (leader_emails: string[]) =>
      await updateOrgProgram(defaultOrgId, program.id, { leader_emails }),
    onSuccess: async (ret: UpdateProgramResponse) => {
      if (ret.failed_leader_emails && ret.failed_leader_emails.length > 0) {
        setFailedEvaluatorEmails(ret.failed_leader_emails);
      } else {
        await queryClient.invalidateQueries({
          queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
        });
        if (addingEvaluators) {
          setAddingEvaluators(false);
          setPendingEvaluatorEmails([]);
        }
      }
    },
  });

  const getCombinedEmails = (newEmails: string[]): string[] => {
    const currLeaderSet = new Set(program?.leaders.map((leader) => leader.email));
    for (const email of newEmails) {
      currLeaderSet.add(email);
    }
    return Array.from(currLeaderSet);
  };

  const getFailedEvaluatorErrorMessage = () => {
    const plural = failedEvaluatorEmails.length > 1;
    const emailStr =
      failedEvaluatorEmails.length <= 2
        ? failedEvaluatorEmails.join(" and ")
        : `${failedEvaluatorEmails.slice(0, -1).join(", ")}, and ${failedEvaluatorEmails.slice(
            -1,
          )}`;
    return `${emailStr} ${plural ? " were" : " was"} not ${
      plural ? "" : " a "
    } valid evaluator email${plural ? "s" : ""}. Please check that
      these emails are from users in your organization and try again.`;
  };

  if (addingEvaluators) {
    return (
      <Stack>
        <Stack
          direction="row"
          sx={{ gap: 1, alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <YoodliMultiSelect
              isCheckboxSelect
              componentsProps={{
                popper: {
                  placement: "top-start",
                  modifiers: [
                    {
                      name: "flip",
                      enabled: false,
                    },
                  ],
                },
              }}
              placeholder="Search evaluator emails"
              value={evaluatorsSearchValue}
              loading={isFetching}
              noOptionsText={`${
                evaluatorEmailSearchText.length > 0
                  ? "No results found"
                  : "Type to search evaluator emails"
              }`}
              onChange={(_, vals: YoodliSelectOption[], _reason, detail) => {
                if (vals.length === 0) {
                  setPendingEvaluatorEmails([]);
                  return;
                }
                if (pendingEvaluatorEmails.includes(detail?.option?.value)) {
                  setPendingEvaluatorEmails(
                    pendingEvaluatorEmails.filter((email) => email !== detail?.option?.value),
                  );
                } else {
                  setPendingEvaluatorEmails([...pendingEvaluatorEmails, detail?.option?.value]);
                }
              }}
              onInputChange={(e, value) => {
                if (value) {
                  setEvaluatorEmailSearchText(value);
                }
              }}
              filterOptions={(x) => x}
              options={
                evalEmailOptions?.users?.map((user) => ({
                  label: user.email,
                  value: user.email,
                })) ?? []
              }
            />
          </Box>
          <Button
            disabled={pendingEvaluatorEmails.length === 0}
            variant="contained"
            onClick={() => {
              updateProgramEvaluatorsMutation.mutate(getCombinedEmails(pendingEvaluatorEmails));
            }}
            startIcon={
              updateProgramEvaluatorsMutation.isPending && (
                <CircularProgress size="14px" sx={{ color: getDynamicColor("light1") }} />
              )
            }
          >
            Add now
          </Button>
        </Stack>
        {failedEvaluatorEmails.length > 0 && (
          <Typography
            sx={{
              color: getDynamicColor("redError"),
              fontFamily: "Poppins",
              fontSize: "12px",
              mt: 0.5,
            }}
          >
            {getFailedEvaluatorErrorMessage()}
          </Typography>
        )}
      </Stack>
    );
  }
  if (program?.leaders.length > 0) {
    return (
      <Stack gap={2}>
        {program.leaders.map((leader, index) => (
          <React.Fragment key={`${leader.id}-${index}`}>
            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
              {!isSmallScreen && (
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {leader.display_name}
                  </Typography>
                </Grid>
              )}
              <Grid item sm={5}>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {leader.email}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={() =>
                    updateProgramEvaluatorsMutation.mutate(
                      program?.leaders
                        .map((l) => l.email)
                        .filter((email) => email !== leader.email),
                    )
                  }
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Stack>
    );
  }
  return (
    <Button
      onClick={() => setAddingEvaluators(true)}
      startIcon={<AddIcon />}
      sx={{ m: "auto", fontSize: "14px" }}
    >
      Add evaluators
    </Button>
  );
};
