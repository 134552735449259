// Components
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack, SxProps } from "@mui/material";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";

type SectionOptionsMenuProps = {
  menuItems: {
    title: string;
    onClick: () => void;
    sx?: SxProps;
  }[];
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
};

const SectionOptionsMenu = ({
  menuItems,
  anchorEl,
  setAnchorEl,
}: SectionOptionsMenuProps): JSX.Element => {
  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          width: "28px",
          height: "28px",
          color: getDynamicColor("primary"),
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "min(200px, 100%)",
              background: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
            },
          },
        }}
      >
        <Stack
          width="min(200px, 100%)"
          py={2}
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{
            background: getDynamicColor("light1"),
            py: 1,
            li: {
              py: 1.5,
              "&:hover": {
                color: getDynamicColor("primary"),
              },
            },
          }}
        >
          {menuItems.map((menuItem) => {
            return (
              <MenuItem key={menuItem.title} onClick={menuItem.onClick} sx={menuItem?.sx ?? {}}>
                {menuItem.title}
              </MenuItem>
            );
          })}
        </Stack>
      </Menu>
    </>
  );
};

export default SectionOptionsMenu;
