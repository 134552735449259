import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import CheckIcon from "@mui/icons-material/Check";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CircularProgress,
  Typography,
  Stack,
  Button,
  Box,
  Pagination,
  Divider,
  IconButton,
  Collapse,
} from "@mui/material";
import { TableSortServer } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortServer";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

// Assets
import FilterIcon from "lib-frontend/assets/FilterIcon";

// Utils
import { OrgProgramsQueryKeys, OrgProgramsSectionStatus } from "./OrgPrograms";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  useReactTable,
  PaginationState,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgProgramUsers } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parseProgramProgressDate } from "lib-frontend/utils/orgUtils";
import { useIsScreenSmallerThanPx } from "lib-frontend/utils/themeUtils";
import {
  ListProgramUsersSortOption,
  ProgramResponse,
  ProgramUserItem,
} from "lib-fullstack/api/programApiTypes";
import { ORG_MEMBER_FETCH_LIMIT } from "lib-fullstack/utils/constants";
import { ProgramProvisioningState, ProgramRecordState } from "lib-fullstack/utils/enums";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";

enum ProgramMembersAllFilter {
  All = "all",
}

const ProgramMembersFilter = { ...ProgramMembersAllFilter, ...ProgramRecordState };
type ProgramMembersFilter = ProgramMembersAllFilter | ProgramRecordState;

type MobileUserRowProps = {
  user: ProgramUserItem;
  numProgramSteps: number;
  handleViewUser: () => void;
};

const MobileUserRow = ({ user, numProgramSteps, handleViewUser }: MobileUserRowProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Stack sx={{ px: 2 }}>
      <Stack gap={1} direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack
          gap={2}
          direction="row"
          sx={{ alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            handleViewUser();
          }}
        >
          <YoodliAvatar name={user.name} />
          <Stack>
            <Typography
              sx={{
                color: getDynamicColor("primary"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              {user.email}
            </Typography>
          </Stack>
        </Stack>
        <IconButton
          onClick={() => setExpanded(!expanded)}
          sx={{
            color: getDynamicColor("primary"),
          }}
        >
          {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </Stack>
      <Collapse in={expanded} unmountOnExit>
        <Stack
          gap={2}
          sx={{
            pt: 2,
          }}
        >
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: "poppins",
            }}
          >
            {parseProgramProgressDate(user)}
          </Typography>
          <Stack
            gap={1}
            direction="row"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: "12px",
                fontWeight: 600,
                fontFamily: "poppins",
              }}
            >
              Scenarios completed
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: "14px",
                fontWeight: 600,
                fontFamily: "poppins",
              }}
            >
              {user.num_steps_completed}/{numProgramSteps}
            </Typography>
          </Stack>
          <Stack
            gap={1}
            direction="row"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: "12px",
                fontWeight: 600,
                fontFamily: "poppins",
              }}
            >
              Minutes recorded
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: "14px",
                fontWeight: 600,
                fontFamily: "poppins",
              }}
            >
              {Math.round(user.total_seconds_attempts / 60)}
            </Typography>
          </Stack>
          <Stack
            gap={1}
            direction="row"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: "12px",
                fontWeight: 600,
                fontFamily: "poppins",
              }}
            >
              Overall attempts
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: "14px",
                fontWeight: 600,
                fontFamily: "poppins",
              }}
            >
              {user.num_attempt_started}
            </Typography>
          </Stack>
          <Button onClick={handleViewUser} sx={{ fontSize: "12px", p: 0 }}>
            View user
          </Button>
        </Stack>
      </Collapse>
    </Stack>
  );
};

type ProgramUserTableProps = {
  program: ProgramResponse;
  searchText: string;
};

export const ProgramUserTable = ({ program, searchText }: ProgramUserTableProps): JSX.Element => {
  const isSmallScreen = useIsScreenSmallerThanPx(1200);
  const isMediumScreen = useIsScreenSmallerThanPx(1400);
  const navigate = useNavigate();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);
  const columnHelper = createColumnHelper<ProgramUserItem>();

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [sortFilter, setSortFilter] = React.useState<string>(
    ListProgramUsersSortOption.EmailAsc as string,
  );
  const [selectedFilter, setSelectedFilter] = React.useState<ProgramMembersFilter>(
    ProgramMembersFilter.All,
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: ORG_MEMBER_FETCH_LIMIT,
  });

  const paginationStartIdx = (pagination.pageIndex * pagination.pageSize).toString();

  const programUsersQuery = useApiQuery({
    queryKey: [
      OrgProgramsQueryKeys.ProgramUsers,
      defaultOrgId,
      program.id,
      {
        sort: searchText
          ? ListProgramUsersSortOption.EmailAsc
          : (sortFilter ?? ListProgramUsersSortOption.EmailAsc),
        start: paginationStartIdx,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        prefix: searchText,
        state: selectedFilter === ProgramMembersFilter.All ? undefined : selectedFilter,
      },
    ],
    queryFn: () =>
      listOrgProgramUsers(defaultOrgId, program.id, {
        sort: searchText
          ? ListProgramUsersSortOption.EmailAsc
          : ((sortFilter as ListProgramUsersSortOption) ?? ListProgramUsersSortOption.EmailAsc),
        start: paginationStartIdx,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        prefix: searchText,
        state: selectedFilter === ProgramMembersFilter.All ? undefined : selectedFilter,
      }),
    refetchInterval: program.provisioning_state === ProgramProvisioningState.InProgress ? 1000 : 0,
  });

  const getProgressStateText = (state: ProgramRecordState) => {
    switch (state) {
      case ProgramRecordState.Completed:
        return "Completed";
      case ProgramRecordState.InProgress:
        return "In progress";
      case ProgramRecordState.NotStarted:
      default:
        return "Not started";
    }
  };

  const rowData = React.useMemo(
    () => programUsersQuery.data?.users ?? [],
    [programUsersQuery.data?.users],
  );

  const columns = React.useMemo(
    () =>
      [
        columnHelper.accessor("name", {
          id: "email",
          header: () => (
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
          ),
          cell: (info) => (
            <Stack
              gap={2}
              direction="row"
              sx={{ alignItems: "center", cursor: "pointer" }}
              onClick={() => handleViewUser(info.row.original.user_id)}
            >
              <YoodliAvatar name={info.getValue()} />
              <Stack
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "14px",
                  textAlign: "left",
                  maxWidth: "280px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: getDynamicColor("primary"),
                  }}
                >
                  {info.getValue()}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {info.row.original.email}
                </Typography>
              </Stack>
            </Stack>
          ),
        }),
        columnHelper.accessor("last_progress_date", {
          id: "last_progress_date",
          header: () => (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Last progress
            </Typography>
          ),
          cell: (info) => (
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
                color: getDynamicColor("dark4"),
              }}
            >
              {parseProgramProgressDate(info.row.original)}
            </Typography>
          ),
        }),
        columnHelper.accessor("state", {
          id: "state",
          header: () => (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              State
            </Typography>
          ),
          cell: (info) => (
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
                color: getDynamicColor("dark4"),
              }}
            >
              {getProgressStateText(info.getValue())}
            </Typography>
          ),
        }),
        columnHelper.accessor("num_steps_completed", {
          id: "num_steps_completed",
          header: () => (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Scenarios completed
            </Typography>
          ),
          cell: (info) => (
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 600,
                color: getDynamicColor("purple3"),
              }}
            >
              {info.getValue()}/{program.plan_steps.length}
            </Typography>
          ),
        }),
        !isMediumScreen &&
          columnHelper.accessor("total_seconds_attempts", {
            id: "total_seconds_attempts",
            header: () => (
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Minutes recorded
              </Typography>
            ),
            cell: (info) => (
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: getDynamicColor("purple3"),
                }}
              >
                {Math.round(info.getValue() / 60)}
              </Typography>
            ),
          }),
        columnHelper.accessor("num_attempt_started", {
          id: "num_attempt_started",
          header: () => (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Overall attempts
            </Typography>
          ),
          cell: (info) => (
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 600,
                color: getDynamicColor("purple3"),
              }}
            >
              {info.getValue()}
            </Typography>
          ),
        }),
      ].filter(Boolean),
    [rowData, isMediumScreen],
  );

  const table = useReactTable({
    data: rowData,
    columns: columns,
    initialState: {
      pagination,
    },
    rowCount: programUsersQuery.data?.total,
    getCoreRowModel: getCoreRowModel(),
  });

  const firstItem = parseInt(paginationStartIdx) + 1;
  const lastItem = Math.min(
    parseInt(paginationStartIdx) + pagination.pageSize,
    table.getRowCount(),
  );

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page - 1, 0) };
    });
  };

  const handleViewUser = (userId) => {
    qp.set(OrgProgramsQueryParams.USER_ID, userId);
    qp.set(OrgProgramsQueryParams.SECTION, OrgProgramsSectionStatus.ProgramUser);
    navigate({ search: qp.toString() });
  };

  const getFilterText = (filter: ProgramMembersFilter) => {
    switch (filter) {
      case ProgramMembersFilter.All:
        return "All";
      case ProgramMembersFilter.NotStarted:
        return "Not started";
      case ProgramMembersFilter.InProgress:
        return "In progress";
      case ProgramMembersFilter.Completed:
        return "Completed";
    }
  };

  const renderDesktopTable = () => {
    return (
      <Stack
        gap={4}
        sx={{ border: `1px solid ${getDynamicColor("dark2")}`, borderRadius: "4px", pb: 1.5 }}
      >
        <Box>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Box
                  component="tr"
                  key={headerGroup.id}
                  sx={{
                    borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        padding: "16px",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        sx={{
                          alignItems: "center",
                          textAlign: "left",
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {[
                          "email",
                          "last_progress_date",
                          "total_seconds_attempts",
                          "total_count_attempts",
                        ].includes(header.id) && (
                          <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />
                        )}
                        {header.id === "state" && (
                          <YoodliMenu
                            type={YoodliMenuButtonType.Icon}
                            menuItems={Object.values(ProgramMembersFilter).map((filter) => ({
                              title: (
                                <Stack
                                  direction="row"
                                  sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "poppins",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      color: getDynamicColor(
                                        selectedFilter === filter ? "primary" : "purple3",
                                      ),
                                    }}
                                  >
                                    {getFilterText(filter)}
                                  </Typography>
                                  {selectedFilter === filter && (
                                    <CheckIcon
                                      sx={{
                                        color: getDynamicColor("primary"),
                                        width: "16px",
                                        height: "16px",
                                      }}
                                    />
                                  )}
                                </Stack>
                              ),
                              onClick: () => setSelectedFilter(filter),
                              type: YoodliMenuItemType.Default,
                            }))}
                            buttonSx={{
                              border: "none",
                              mx: 0,
                            }}
                            icon={<FilterIcon stroke={getDynamicColor("dark3")} />}
                          />
                        )}
                      </Stack>
                      {/* Note: the reason the <TanbleFilters> are here instead of as a columnHelper is because when placed in a columnHelper,
                      it would re-render the menu opening every time the filtering changed, which is undesirable. No re-render problems here.
                  */}
                    </th>
                  ))}
                </Box>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Box
                    component="tr"
                    key={row.id}
                    sx={{
                      borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                      "&:last-of-type": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} style={{ padding: "16px" }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </Box>
                );
              })}
            </tbody>
          </table>
        </Box>
        <Stack alignItems="center">
          {table.getPageCount() > 1 && (
            <Pagination
              sx={{ justifyContent: "center" }}
              count={table.getPageCount()}
              showFirstButton
              showLastButton
              page={pagination.pageIndex + 1}
              onChange={(_, page) => handlePageChange(page)}
            />
          )}
          {table.getRowCount() > 0 && (
            <Typography
              sx={{
                mt: 0.5,
                color: getDynamicColor("dark4"),
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {`Displaying members ${firstItem} - ${lastItem} of ${table.getRowCount()}`}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  const renderMobileTable = () => {
    return (
      <Stack
        gap={2}
        sx={{ borderRadius: "4px", border: `1px solid ${getDynamicColor("dark2")}`, py: 2 }}
      >
        {rowData.map((row, index) => {
          return (
            <React.Fragment key={row.user_id}>
              <MobileUserRow
                user={row}
                numProgramSteps={program.plan_steps.length}
                handleViewUser={() => handleViewUser(row.user_id)}
              />
              {index !== rowData.length - 1 && (
                <Divider sx={{ borderColor: getDynamicColor("dark2") }} />
              )}
            </React.Fragment>
          );
        })}
      </Stack>
    );
  };

  if (
    programUsersQuery.isLoading ||
    program.provisioning_state === ProgramProvisioningState.InProgress
  ) {
    return <CircularProgress />;
  } else if (isSmallScreen) {
    return renderMobileTable();
  }
  return renderDesktopTable();
};
