import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { doLogout } from "webclient/src/modules/FirebaseModule";

// Utils
import { resetAmplitudeUser } from "./ProductAnalyticsUtils";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { validateAndNormalizeEmail } from "lib-fullstack/utils/emails";

export const USER_NAME_MIN_CHAR_LENGTH = 1;
export const USER_NAME_MAX_CHAR_LENGTH = 50;

// keyDbObjects provides fast, synchronous access to up-to-date copies of
// key Db objects. They are updated via change handlers.
// (currently: just dbUser)
export const keyDbObjects: { dbUser?: db.Doc<db.User> } = {};

export function currentUserEmail(): string | null {
  return (
    // We trust Firebase Auth (when available) more than the DB; but
    // ideally we'd trust the DB just as much or more.
    validateAndNormalizeEmail(
      firebase.auth().currentUser?.email ?? keyDbObjects.dbUser?.data?.email,
    )
  );
}

export function currentUserName(): string | null {
  return (
    // We trust Firebase Auth (when available) more than the DB; but
    //  ideally we'd trust the DB just as much or more.
    firebase.auth().currentUser?.displayName ?? keyDbObjects.dbUser?.data?.displayName ?? null
  );
}

export async function updateCurrentUserName(rawNewUserName: string): Promise<void> {
  const newName = rawNewUserName.trim();
  if (newName.length < USER_NAME_MIN_CHAR_LENGTH || newName.length > USER_NAME_MAX_CHAR_LENGTH) {
    console.log(`Invalid user name length of ${newName.length}`);
    return;
  }

  if (firebase.auth().currentUser) {
    const user = keyDbObjects?.dbUser;
    if (user) {
      await firebase.auth().currentUser.updateProfile({ displayName: newName });
      await db.set(db.users(getSiteId()), user.ref.id, {
        ...user.data,
        displayName: newName,
      });
      console.log(`Saved new user name: ${newName}`);
    } else {
      console.error("No user found in keyDbObjects");
    }
  } else {
    console.error("No currentUser in firebase auth");
  }
}

export function currentUserPhotoURL(): string | null {
  return (
    // We trust Firebase Auth (when available) more than the DB; but
    //  ideally we'd trust the DB just as much or more.
    firebase.auth().currentUser?.photoURL ?? keyDbObjects.dbUser?.data?.photoURL ?? null
  );
}

export function currentUserFirstName(): string | null {
  return currentUserName()?.split(" ")[0] ?? null;
}

export function getScopedUserId(): string | undefined {
  if (firebase.auth().currentUser) {
    return `${getSiteId()}/${firebase.auth().currentUser.uid}`;
  } else {
    return;
  }
}

export function getUserId(): string | undefined {
  if (firebase.auth().currentUser) {
    return firebase.auth().currentUser.uid;
  } else {
    return;
  }
}

export const handleDoLogout = async (
  userInOrg: boolean,
  orgId: string,
  logOutEverywhereEnforced: boolean,
): Promise<void> => {
  await resetAmplitudeUser();
  await doLogout(userInOrg, orgId, logOutEverywhereEnforced).catch((er) => {
    console.error("Error logging out:", er);
  });
};

/**
 * Returns the Initials for a given first and last name
 * @param {*} name
 * @returns string of first and last initials
 */
export const getInitialsForName = (name: string): string => {
  if (!name || name.length === 0) {
    return "";
  }
  const rgx = new RegExp("(\\p{L}{1})\\p{L}+", "gu");
  const initials = Array.from(name.matchAll(rgx)) || [];
  const initialsString = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase() as string;
  return initialsString;
};
