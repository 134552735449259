import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import { Stack, Typography, Divider, Button, CircularProgress, Box } from "@mui/material";

// Assets
import { ReactComponent as FileVideoIcon } from "../../../../images/icons/FileVideoIcon.svg";

// Utils
import { UseMutateFunction } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getUploadDuration } from "utils/Utilities";
import { MAX_ORG_VIDEO_LENGTH } from "lib-fullstack/utils/constants";

type UploadVideoModalProps = {
  handleCreateVideos: UseMutateFunction<void, Error, File[]>;
  isCourse?: boolean;
};

export default function UploadVideoModal({
  handleCreateVideos,
  isCourse,
}: UploadVideoModalProps): JSX.Element {
  const [error, setError] = React.useState<string>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const fileInputRef = React.useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length) {
        await doUploadFiles(acceptedFiles);
      }
    },
    accept: "video/*",
    maxFiles: 5,
  });

  const handleFileChange = async (event) => {
    const files = event.target.files;
    await doUploadFiles(files);
  };

  const handleButtonClick = () => {
    // Trigger the hidden file input element when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const doUploadFiles = async (files: File[]) => {
    try {
      setLoading(true);
      const fileDurations = await Promise.all(
        Object.values(files).map(async (file: File) => {
          return await getUploadDuration(file);
        }),
      );

      const fileTooLongDuration = fileDurations.find((duration) => duration > MAX_ORG_VIDEO_LENGTH);
      if (!fileTooLongDuration) {
        await handleCreateVideos(Object.values(files));
      } else {
        setError("Sorry, one of the videos you selected is too long");
        console.log(`Video too large: ${fileTooLongDuration} seconds`);
      }
    } catch (err) {
      setError(err.message);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack gap={3} width="100%">
        <Typography
          color={getDynamicColor("purple3")}
          fontFamily="poppins"
          fontSize="16px"
          fontWeight={600}
        >
          Upload {isCourse ? "to Courses" : "Demo Video"}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: getDynamicColor("dark3") }} />
      </Stack>
      {loading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <Stack gap={3} alignItems="center" {...getRootProps()}>
          <Stack gap={1} alignItems="center">
            <FileVideoIcon />
            <Typography color={getDynamicColor("dark4")} fontSize="20px" fontWeight={500}>
              Drag and drop your video files
            </Typography>
            <Typography color={getDynamicColor("dark6")} fontSize="14px" fontWeight={400}>
              Only 5 videos at a time, no longer than {MAX_ORG_VIDEO_LENGTH / 60} minutes long each
            </Typography>
            {error && (
              <Typography color={getDynamicColor("redError")} fontSize="14px" fontWeight={700}>
                {error}
              </Typography>
            )}
          </Stack>
          <Button
            onClick={handleButtonClick}
            variant="contained"
            sx={{
              height: "44px",
              px: 2.5,
              width: "max-content",
              borderRadius: "50px",
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            Select files
          </Button>
          <input
            type="file"
            accept="video/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
            {...getInputProps()}
          />
        </Stack>
      )}
    </>
  );
}
