import React from "react";
import { db } from "lib-fullstack";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

// Components
import { Box, Button, Stack, Typography, Grid } from "@mui/material";
import { getSpeechTypeStringFromEnum } from "components/VideoJournal/VideoJournalUtils";

// Assets
import { ReactComponent as NoSpeechIcon } from "../../images/icons/NoSpeechIcon.svg";
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSpeechSummaryPath } from "lib-frontend/utils/SpeechHandlers";
import { formatDateShort, getDisplayableTime } from "lib-fullstack/utils/dateUtils";
import { WebServerInternalPath } from "utils/paths";
import { queryThumbnails, getThumbnail } from "utils/thumbnailHandler";
import { useIsMediumScreen, useIsExtraSmallScreen } from "lib-frontend/utils/themeUtils";

type RecentSessionsProps = {
  latestSpeeches: db.Doc<db.Speech>[];
};

export const RecentSessions = ({ latestSpeeches }: RecentSessionsProps): JSX.Element => {
  const navigate = useNavigate();

  const [hoveringId, setHoveringId] = React.useState<string | null>(null);

  const isMediumScreen = useIsMediumScreen();
  const isExtraSmallScreen = useIsExtraSmallScreen();

  const amtToSlice = isMediumScreen && !isExtraSmallScreen ? 3 : 4;

  const elementsToRender = React.useMemo(
    () => latestSpeeches.slice(0, amtToSlice),
    [latestSpeeches, amtToSlice],
  );

  React.useEffect(() => {
    // query thumbnails once on mount of this component, which adds the to the cache in thumbnailHandler.ts
    queryThumbnails(elementsToRender).catch((err) => {
      // This failure has happened due to a) poor network connection and b) throttling by the server.
      // Output a warning, but not an error which would become Sentry report.
      // We have nothing to do with a) and we have server side alert for b).
      console.warn(`queryThumbnails failed, continue without thumbnails: ${err}`);
    });
  }, [elementsToRender]);

  const renderThumbnail = (yoodli: db.Doc<db.Speech>) => {
    const displayableTotalTimeS =
      yoodli.data.totalTimeS && !isNaN(yoodli.data.totalTimeS)
        ? getDisplayableTime(yoodli.data.totalTimeS)
        : null;
    const isHovering = hoveringId === yoodli.ref.id;
    return (
      <Box
        sx={{
          borderRadius: 0,
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          height: "104px",
          backgroundColor: getDynamicColor("light1"),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          border: `1px solid transparent`,
          transition: "border 0.2s ease-in-out",
          ...(isHovering && {
            border: `1px solid ${getDynamicColor("primary")}`,
          }),
        }}
      >
        {yoodli.data.lifecycleState === db.LifecycleState.REDACTED ? (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "40px",
              background: getDynamicColor("dark2"),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0.5,
            }}
          >
            <NoSpeechIcon />
          </Box>
        ) : (
          <img
            src={getThumbnail(yoodli.ref.id) ?? PlaceholderThumbnail}
            style={{
              alignSelf: "center",
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              // if the speech was recorded post canvas off, we need to mirror the thumbnail to match what was/is displayed
              transform:
                getThumbnail(yoodli.ref.id) &&
                (yoodli.data.noCanvasRecording || yoodli.data.mirrored)
                  ? "scaleX(-1)"
                  : null,
            }}
            alt="Speech video thumbnail"
          />
        )}
        {displayableTotalTimeS && (
          <Typography
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              borderRadius: 100,
              backgroundColor: getDynamicColor("dark5", 0.8),

              px: 1,
              py: 0.25,
              color: getDynamicColor("light1"),
              fontFamily: "Inter",
              fontSize: "10px",
              fontWeight: 600,
            }}
          >
            {displayableTotalTimeS}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "end" }}>
        <Typography
          variant="poppins"
          component="h2"
          sx={{
            fontSize: { xs: 18, sm: 20 },
            fontWeight: 600,
          }}
        >
          Recent Sessions
        </Typography>
        <Link to={WebServerInternalPath.LIBRARY} style={{ textDecoration: "none" }}>
          <Button
            variant="text"
            sx={{
              fontSize: 14,
              whiteSpace: "nowrap",
            }}
          >
            See more
          </Button>
        </Link>
      </Stack>
      <Grid
        container
        spacing={0}
        sx={{
          overflowX: "auto",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {elementsToRender.map((yoodli) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{
                p: 0.5,
                borderRadius: "12px",
                backgroundColor: getDynamicColor("light1"),
                cursor: "pointer",
              }}
              key={yoodli.ref.id}
              onClick={() => {
                navigate(getSpeechSummaryPath(yoodli.data.slug, false));
              }}
            >
              <Stack
                onMouseEnter={() => setHoveringId(yoodli.ref.id)}
                onMouseLeave={() => setHoveringId(null)}
                gap={1}
                sx={{
                  p: 0.5,
                }}
              >
                {renderThumbnail(yoodli)}
                <Stack gap={0.25}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 600,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      lineHeight: 1,
                      height: "16px",
                      px: "2px",
                    }}
                  >
                    {yoodli.data.name}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={{ xs: 0.5, sm: 1, lg: 1.5 }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "10px",
                        fontWeight: 600,
                        color: getDynamicColor("dark4"),
                        pr: "2px",
                      }}
                    >
                      {formatDateShort(yoodli.data.recordedDate)}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontWeight: 600,
                        backgroundColor: getDynamicColor("light2"),
                        borderRadius: "20px",
                        px: "6px",
                        py: "2px",
                      }}
                    >
                      {getSpeechTypeStringFromEnum(yoodli.data)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
