import React from "react";

// Components
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";
import { Button, Container, Fade } from "@mui/material";

// Utils
import { UploadingFile } from "../OrgFileLibrary/OrgFileLibrary";
import { CoachBotCard } from "./CoachBotCard";
import { CoachBotContent } from "./CoachBotContent";
import { CoachBotSectionStatus } from "./CoachBotLibrary";
import { CoachBotSettings } from "./CoachBotSettings";
import { CoachBotStyleGuide } from "./CoachBotStyleGuide";
import { useCoachBotContentQuery } from "hooks/useCoachBotContentQuery";
import { GetCoachBotResponse } from "lib-fullstack/api/coachBotApiTypes";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { AnalyticProcessingState, OrgFileState } from "lib-fullstack/utils/enums";

type ManageCoachBotTabProps = {
  setCoachBot: React.Dispatch<React.SetStateAction<GetCoachBotResponse>>;
  coachBot: GetCoachBotResponse;
  orgId: string;
  scenarioInfo: GetScenarioResponse[];
  styleGuideContent?: string;
  setDeleteCoachBotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSectionStatus: React.Dispatch<React.SetStateAction<CoachBotSectionStatus>>;
  uploadingFiles: UploadingFile[];
  handleUploadContent: (files: File[]) => void;
  handleCancelFileUpload: (fileId: string) => void;
};

export const ManageCoachBotTab = ({
  setCoachBot,
  coachBot,
  orgId,
  scenarioInfo,
  setDeleteCoachBotOpen,
  setSectionStatus,
  styleGuideContent,
  uploadingFiles,
  handleUploadContent,
  handleCancelFileUpload,
}: ManageCoachBotTabProps): JSX.Element => {
  const [showStyleGuide, setShowStyleGuide] = React.useState<boolean>(false);

  const { coachBotContentQuery } = useCoachBotContentQuery(coachBot?.botId);

  // TODO @dwiegand: this would be nice to be a flag on thew coachbot itself, instead of having to check all the contents
  const isCoachBotProcessing = React.useMemo(
    () =>
      (coachBotContentQuery?.data?.files ?? []).some(
        (content) =>
          content.status === AnalyticProcessingState.PROCESSING &&
          content.file_state !== OrgFileState.Error,
      ),
    [coachBotContentQuery?.data],
  );

  const renderBackToContentButton = () => {
    return (
      <Button
        variant="text"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => {
          setShowStyleGuide(false);
        }}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          fontSize: 14,
          zIndex: 2,
        }}
      >
        Content
      </Button>
    );
  };

  let content = (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setSectionStatus(CoachBotSectionStatus.Library)}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          fontSize: 14,
          zIndex: 2,
        }}
      >
        Coach Bot Library
      </Button>
      <CoachBotCard
        botName={coachBot?.name}
        isDefault={coachBot?.isDefault}
        dateBuilt={getHumanReadableDate(coachBot?.createdAt, {
          month: "short",
        })}
        isProcessing={isCoachBotProcessing}
        scenarios={scenarioInfo}
        handleDelete={() => setDeleteCoachBotOpen(true)}
      />
      <CoachBotSettings
        coachBot={coachBot}
        setCoachBot={setCoachBot}
        setShowStyleGuide={setShowStyleGuide}
        orgId={orgId}
        currentSelectedScenarios={scenarioInfo}
        styleGuideContent={styleGuideContent}
      />
    </>
  );

  if (showStyleGuide) {
    content = (
      <>
        {renderBackToContentButton()}
        <CoachBotStyleGuide coachBotId={coachBot?.botId} styleGuideContent={styleGuideContent} />
      </>
    );
  }

  return (
    <Fade in>
      <Container
        maxWidth="xxxl"
        sx={{
          width: "100%",
          ml: "unset",
          pb: { xs: 3, md: 4, lg: 6 },
          px: { xs: 1, md: 4, lg: 6 },
          pt: { xs: 8, md: 8, lg: 8 },
          display: "flex",
          flexDirection: "column",
          gap: 6,
          position: "relative",
        }}
      >
        {content}
        {!showStyleGuide && (
          <CoachBotContent
            botId={coachBot?.botId}
            uploadingFiles={uploadingFiles}
            handleUploadContent={handleUploadContent}
            handleCancelFileUpload={handleCancelFileUpload}
          />
        )}
      </Container>
    </Fade>
  );
};
