import React from "react";

// Components
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import { useNotification } from "./useNotification";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  listOrgSpaces,
  createOrgSpace,
  updateOrgSpace,
  deleteOrgSpace,
} from "lib-frontend/modules/axiosOrgSpaces";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { isUserMinimumEffectiveRole } from "lib-frontend/utils/orgUtils";
import { toTitleCase } from "lib-frontend/utils/Utilities";
import {
  CreateOrgSpaceRequest,
  UpdateOrgSpaceRequest,
  OrgSpaceItem,
} from "lib-fullstack/api/orgSpaceApiTypes";
import { EffectiveRole } from "lib-fullstack/utils/enums";

export const LIST_ORG_SPACES_QUERY_KEY = "orgContentSpaces";

type CopyContentRequest = {
  copyHandler: (destinationSpaceId: string) => Promise<void>;
  content: {
    name: string;
    type: string;
  };
};

type CopyContentInfo = {
  isCopyDrawerOpen: boolean;
  handleIsCopyDrawerOpen: (isOpen: boolean) => void;
  copyRequest: CopyContentRequest;
  handleSetCopyContentRequest: (content: CopyContentRequest) => void;
  requestPendingId: string | undefined;
};

const defaultCopyContentInfo: CopyContentInfo = {
  isCopyDrawerOpen: false,
  handleIsCopyDrawerOpen: () => {},
  copyRequest: {
    copyHandler: async (_: string) => Promise.resolve(),
    content: undefined,
  },
  requestPendingId: undefined,
  handleSetCopyContentRequest: (_: CopyContentRequest) => {},
};
interface IContentSpacesContext {
  spaces: OrgSpaceItem[];
  isWorking: boolean;
  curSpaceId: string;
  copyContentInfo: CopyContentInfo;
  selectSpace: (spaceId: string) => Promise<void>;
  createSpace: (name: string) => Promise<string>;
  deleteSpace: (spaceId: string) => Promise<void>;
  updateCurrentSpace: (name: string) => Promise<void>;
  copyContentToSpace: (destinationSpaceId: string) => void;
  invalidateContentSpacesQueries: () => Promise<void>;
}

export const ContentSpacesContext = React.createContext<IContentSpacesContext>({
  spaces: [],
  isWorking: false,
  curSpaceId: "",
  copyContentInfo: defaultCopyContentInfo,

  selectSpace: (_: string) => Promise.resolve(),
  createSpace: (_: string) => Promise.resolve(""),
  deleteSpace: (_: string) => Promise.resolve(),
  updateCurrentSpace: (_: string) => Promise.resolve(),
  invalidateContentSpacesQueries: () => Promise.resolve(),
  copyContentToSpace: () => Promise.resolve(),
});

export const ContentSpacesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { defaultOrgId, effectiveRole } = React.useContext(UserOrgContext);
  const { showNotificationBanner } = useNotification();
  const [curSpaceId, setCurSpaceId] = React.useState<string>(
    getLiveUserDocMain()?.currentOrgSpaceIds?.[defaultOrgId],
  );
  const [isCopyDrawerOpen, setCopyDrawerOpen] = React.useState<boolean>(false);
  const [copyContentRequest, setCopyContentRequest] = React.useState<CopyContentRequest>(undefined);

  const queryClient = useQueryClient();

  const { data, isLoading } = useApiQuery({
    queryKey: [LIST_ORG_SPACES_QUERY_KEY, defaultOrgId],
    queryFn: async () => {
      try {
        const result = await listOrgSpaces(defaultOrgId);
        return { result };
      } catch (e) {
        console.log(e.toString());
        throw e;
      }
    },
    enabled: !!defaultOrgId && isUserMinimumEffectiveRole(effectiveRole, EffectiveRole.SPACE_ADMIN),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const spaces = data?.result?.spaces ?? [];

  React.useEffect(() => {
    if (!defaultOrgId) {
      return;
    }

    const userDocSpaceIds = getLiveUserDocMain()?.currentOrgSpaceIds;
    const orgSpaceId = userDocSpaceIds?.[defaultOrgId];

    // Does the space in the user doc exist in the response?
    const currentSpaceExists = !!spaces.find((space) => space.id === orgSpaceId);

    if (orgSpaceId && currentSpaceExists) {
      // We already have the space id populated in the user doc.
      setCurSpaceId(orgSpaceId);
      return;
    }

    // If there is no current space id on the user doc, then the default space is selected.
    let selectedSpace = spaces.find((space) => space.is_default);

    if (!selectedSpace?.id && spaces.length > 0) {
      // If the default space is not available to the user, select the first one.
      selectedSpace = spaces[0];
    }

    if (!selectedSpace?.id) {
      // Do not try to write the user doc if there are no spaces available to the user
      return;
    }

    void selectSpace(selectedSpace.id);
  }, [spaces, defaultOrgId]);

  const selectSpace = async (spaceId: string): Promise<void> => {
    const userDocSpaceIds = getLiveUserDocMain()?.currentOrgSpaceIds ?? {};
    const newUserDocEntry = { ...userDocSpaceIds, [defaultOrgId]: spaceId };

    try {
      await updateThisUserDocMain({ currentOrgSpaceIds: newUserDocEntry });
      setCurSpaceId(spaceId);
    } catch (e) {
      console.error(e.toString());
    }
  };

  const createOrgContentSpaceMutation = useMutation({
    mutationFn: async ({ name }: CreateOrgSpaceRequest) => {
      const { org_space_id: orgSpaceId } = await createOrgSpace(defaultOrgId, { name });
      return orgSpaceId;
    },
    onSuccess: async (orgSpaceId: string) => {
      // previous org spaces lists are now invalid.
      await invalidateContentSpacesQueries();
      await selectSpace(orgSpaceId);
    },
  });

  const copyOrgContentToSpace = useMutation({
    mutationFn: async (destinationSpaceId: string) => {
      await copyContentRequest.copyHandler(destinationSpaceId);
    },
    onSuccess: async (_, destinationSpaceId) => {
      const destinationSpace = spaces.find((space) => space.id === destinationSpaceId);
      handleIsCopyDrawerOpen(false);
      showNotificationBanner(
        `${toTitleCase(copyContentRequest.content.type ?? "Successfully")} copied to ${destinationSpace?.name ?? "new content space"}`,
        { variant: YoodliNotificationBannerVariant.Success },
      );
    },
    onError: (e) => {
      console.error("Failed to copy content to space: ", e.toString());
    },
  });

  const createSpace = async (name: string): Promise<string> => {
    try {
      return await createOrgContentSpaceMutation.mutateAsync({ name });
    } catch (e) {
      console.error(e.toString());
    }
  };

  const updateOrgSpaceMutation = useMutation({
    mutationFn: ({ name }: UpdateOrgSpaceRequest) => {
      return updateOrgSpace(defaultOrgId, curSpaceId, { name });
    },
    onSuccess: async () => {
      await invalidateContentSpacesQueries();
    },
  });

  const updateCurrentSpace = async (name: string): Promise<void> => {
    try {
      return await updateOrgSpaceMutation.mutateAsync({ name });
    } catch (e) {
      console.error(e.toString());
    }
  };

  const deleteOrgSpaceMutation = useMutation({
    mutationFn: async ({ spaceId }: { spaceId: string }) => {
      return deleteOrgSpace(defaultOrgId, spaceId);
    },
    onSuccess: async (_, { spaceId }) => {
      if (curSpaceId === spaceId) {
        // this space is about to get deleted, so let's move the user back to the default state.
        const defaultSpace = spaces.find((space) => space.is_default);
        await selectSpace(defaultSpace.id);
      }
      await invalidateContentSpacesQueries();
    },
  });

  const deleteSpace = async (spaceId: string): Promise<void> => {
    try {
      return await deleteOrgSpaceMutation.mutateAsync({ spaceId });
    } catch (e) {
      console.error(e.toString());
    }
  };

  const isWorking = isLoading || createOrgContentSpaceMutation.isPending;

  const invalidateContentSpacesQueries = async () => {
    try {
      return await queryClient.invalidateQueries({
        queryKey: [LIST_ORG_SPACES_QUERY_KEY, defaultOrgId],
      });
    } catch (e) {
      console.error(e.toString());
    }
  };

  const handleIsCopyDrawerOpen = (isOpen: boolean) => {
    setCopyDrawerOpen(isOpen);
    if (!isOpen) {
      setCopyContentRequest(undefined);
    }
  };

  const copyContentToSpace = (destinationSpaceId: string) => {
    if (!copyContentRequest || !copyContentRequest.copyHandler || !destinationSpaceId) {
      console.error("Invalid or missing copy request found");
      return;
    }
    copyOrgContentToSpace.mutate(destinationSpaceId);
  };

  const contextValue: IContentSpacesContext = {
    spaces,
    isWorking,
    curSpaceId,
    copyContentInfo: {
      isCopyDrawerOpen,
      handleIsCopyDrawerOpen,
      copyRequest: copyContentRequest,
      handleSetCopyContentRequest: setCopyContentRequest,
      requestPendingId: copyOrgContentToSpace.isPending
        ? copyOrgContentToSpace.variables
        : undefined,
    },
    selectSpace,
    createSpace,
    deleteSpace,
    updateCurrentSpace,
    invalidateContentSpacesQueries,
    copyContentToSpace,
  };

  return (
    <ContentSpacesContext.Provider value={contextValue}>{children}</ContentSpacesContext.Provider>
  );
};
